import React, {useState, useEffect } from 'react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faUser, faEnvelope, faStar, faCheck, faUserLock, faTimes } from '@fortawesome/free-solid-svg-icons';
/*Globales*/
import { version } from '../../../package.json';
/**/
import web2barcode from '../../../src/img/Logo.png'
import { useTranslation } from 'react-i18next';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import { useHistory } from "react-router-dom";

const About = ({user}) => {

    const { t }                     = useTranslation();
    const [userAgent, setuserAgent] = useState( window.navigator.userAgent);
    let   history                   = useHistory();

/*************************************************************************************************************************************/

    const welcome = () => {

        Swal.fire({
            title: "Tutorial",
            text: t("home.welcome_sweet"),
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Tutorial',
            cancelButtonText: 'No',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const log           = await AsyncStorage.getItem('@login');
                const log_json      = JSON.parse(log);
                let   _log          = log_json;
                    _log.tutorial = 0;
                _log = JSON.stringify(_log);
                await AsyncStorage.removeItem(
                    '@login'
                );
                
                await AsyncStorage.setItem(
                        '@login',
                        _log
                );

                history.push('/welcome');
            }
        })

    }
/*************************************************************************************************************************************/

    return (
        <div className="row justify-content-center animate__animated animate__fadeIn">
            <div className="col-md-8">
            {/* <div className="card transparent"> */}
            <div className="card">
                <div className="card-header bg-dark">
                    <span className="fw-bold text-white">{t("home.about")}</span> 
                </div>
                <div className="card-body">
                    <div className='row align-items-center'>

                        <div className='col-1'>
                            <FontAwesomeIcon icon={faUser}/>
                        </div>
                        <div className='col-10'>
                            <span><b>{t("home.user")}:</b> {user.name}</span>                            
                        </div>

                    </div>
                    <div className='row align-items-center'>

                        <div className='col-1'>
                            <FontAwesomeIcon icon={faEnvelope}/>
                        </div>
                        <div className='col-10'>
                            <span><b>{t("home.email")}:</b> {user.email}</span>
                        </div>

                    </div>
                    {
                        user.google == 0
                        ? <div>
                            <p>{t("home.local")}</p>
                        </div>
                        : <div className='row align-items-center'>
                            <div className='col-1'>
                                <FontAwesomeIcon icon={faStar}/>
                            </div>
                            <div className='col-9'>
                            <span>{t("home.google")}</span>
                            </div>
                            <div className='col-1'>
                            <FontAwesomeIcon icon={faCheck} className='text-success'/>
                            </div>
                        </div>
                    }
                    {
                        user.suscripcion_id == null
                        ?  <div className='row align-items-center'>
                            <div className='col-1'>
                                <FontAwesomeIcon icon={faUserLock}/>
                            </div>
                            <div className='col-9'>
                            <span className='text-danger'>
                                {t("home.actually")}
                            </span>
                            </div>
                            <div className='col-1 d-none'>
                            <FontAwesomeIcon icon={faTimes} className='text-danger'/>
                            </div>
                        </div>
                        : <div className='row align-items-center'>
                            <div className='col-1'>
                                <FontAwesomeIcon icon={faUserLock}/>
                            </div>
                            <div className='col-9'>
                            <span className='text-success'>
                                {t("home.profesional")}
                            </span>
                            </div>
                            <div className='col-1'>
                            <FontAwesomeIcon icon={faCheck} className='text-success'/>
                            </div>
                        </div>
                    }
                    

                    {/* <div>
                        <p>{userAgent}</p>
                        <p>{platform}</p>
                    </div> */}

                    <div className='d-flex justify-content-between mt-5'>                            
                        <a href="#" className="text-muted m-0" onClick={() => welcome()}> 
                            <span className='link-secondary'>
                                {
                                    t("home.welcome_tutorial")
                                }
                            </span>
                        </a>

                        {
                        (userAgent != 'Mozilla/5.0 AppleWebKit/535.19 Chrome/56.0.0 Mobile Safari/535.19' && userAgent != 'Dalvik/2.1.0')  &&
                        <p className="text-muted m-0">
                            <a href="https://labeldictate.com/?c=privacy" target="_blank" className="link-secondary">{t("home.privacy")}</a> 
                        </p>
                        }
                    </div>

                </div>

            </div>

                <div className='text-center p-4 opacity-25'>
                    <div>
                        <span>Web2 Barcode: <b>v{ version }</b></span>
                    </div>
                    <img 
                        src={web2barcode} 
                        alt='web2barcode' 
                        className='img-fluid' 
                        width={'50%'}/>
                </div>
            </div>
        </div>
    )
}

export default About
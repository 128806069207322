import React, {useState, useEffect, useContext, useRef} from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {urlApi} from '../../global/Global';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
/**
 * @typedef {Object} Impresora
 * @property {number} puerto
 * @property {string} codepageZpl
 * @property {string} codepageTspl
 * @property {number} temperatureZpl
 * @property {number} temperatureTspl
 * @property {number} speedZpl
 * @property {number} speedTspl
 * @property {string} moneySymbol
 * @returns 
 */

const Configuracion = () => {
    /** @type {[Impresora, React.Dispatch<Impresora>]} */
    const [impresora, setimpresora]       = useState({});
    const [loading, setLoading]           = useState(false);
    const [firstloading, setFirstLoading] = useState(true);
    /*Sweet alert*/
    const MySwal = withReactContent(Swal);
    const codes = [
        {id: 1, zpl: '27', tspl: '1252'},
        {id: 2, zpl: '28', tspl: 'UTF-8'},
        {id: 3, zpl: '35', tspl: '1254'},
        {id: 4, zpl: '31', tspl: '1250'},
    ];
    const [temperatureZplArray]  = useState(Array.from({ length: 61 }, (_, index) => index - 30));
    const [temperatureTsplArray] = useState(Array.from({ length: 16 }, (_, index) => index));
    const [speedZplArray]        = useState(Array.from({ length: 14 }, (_, index) => index + 1));
    const [speedTsplArray]       = useState(Array.from({ length: 18 }, (_, index) => index + 1));
    const money                  = [{value: '_24', name:'$'}, {value: 'Q', name:'Q'}, {value: 'L', name:'L'}, 
    {value: '_80', name:'€'}, {value: 'S', name:'S'}, {value: '_a5', name:'¥'}, 
    {value: '_a3', name:'£'}, {value: '_a2', name:'₡'}
];
  /**************************************************************/
    useEffect(() => {
    
        const first = async () => { 
            setFirstLoading(false);
            const log = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            }
            await axios.get(urlApi+'impresora', {headers: headers})
                    .then(response=>{
                        console.log(response.data);
                        setimpresora(response.data);
                    })
                    .catch(error => {
                        console.log(error);
                        MySwal.fire({
                            icon : 'error',
                            title: 'Error',
                            text : 'Ocurrio un error, vuelva a intentarlo',
                        });
                    });    
         }         
         window.scrollTo(0,0);
         first();
         setFirstLoading(true);

    }, [])
/*****************************************************************************************************/

    const enviarDatos = async(params) => { 
        // setLoading(true)
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
        await axios.put(urlApi+'impresora/0', params, {headers: headers})
                .then(async response=>{
                    console.log(response.data);
                    MySwal.fire({
                        icon : response.data.icon,
                        title: 'Correcto',
                        text : response.data.message,
                        
                    });
                    if(response.data.response){
                        log_json.puerto = params.puerto;
                        await AsyncStorage.removeItem(
                            '@login'
                        );
                        await AsyncStorage.setItem(
                            '@login',
                            JSON.stringify(log_json)
                        );
                    }
                     
                })
                .catch(error => {
                    console.log(error);
                    MySwal.fire({
                        icon : 'error',
                        title: 'Error',
                        text : error,
                    });
                }).then(() =>{
                    setLoading(false);
                }) 

    }
    
/*****************************************************************************************************/

    const setDefaultValues = () => { 
        setimpresora({
            puerto         : 9100,
            codepageZpl    : '28',
            codepageTspl   : 'UTF-8',
            temperatureZpl : 0,
            temperatureTspl: 7,
            speedZpl       : 7,
            speedTspl      : 9,
            moneySymbol    : '$',
        })
    }

/*****************************************************************************************************/

    //Validador de datos
    const formSchema = Yup.object().shape({
        codepageZpl: Yup.string()
            .trim()
            .required('Campo requerido'),
        codepageTspl: Yup.string()
            .trim()
            .required('Campo requerido'),
        temperatureZpl: Yup.number()
            .typeError('Debe ser un número')
            .integer('Debe ser un entero')
            .min(-30)
            .max(30)
            .required('Campo requerido'),
        temperatureTspl: Yup.number()
            .typeError('Debe ser un número')
            .integer('Debe ser un entero')
            .min(0)
            .max(15)
            .required('Campo requerido'),
        speedZpl: Yup.number()
            .typeError('Debe ser un número')
            .integer('Debe ser un entero')
            .min(1)
            .max(14)
            .required('Campo requerido'),
        speedTspl: Yup.number()
            .typeError('Debe ser un número')
            .integer('Debe ser un entero')
            .min(1)
            .max(18)
            .required('Campo requerido'),
        moneySymbol: Yup.string()
            .trim()
            .required('Campo requerido'),
        puerto: Yup.number()
            .typeError('Debe ser un número')
            .integer('Debe ser un entero')
            .positive('El puerto debe ser positivo')
            .min(1)
            .required('Campo requerido'),
        });
/***************************************************************************************************/

  return (
        <div className="row justify-content-center animate__animated animate__fadeIn">
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header bg-card-header">
                        <div className="row align-items-center">
                            <div className="col-md-6 my-2">
                                <span className="fw-bold text-white">Configuración</span>                                
                            </div>
                        </div>
                    </div>
                    <div className="card-body">

                        {
                            
                        !firstloading
                        ?       <div className="spinner-border" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>             
                        :<Formik
                            enableReinitialize
                            initialValues={{
                                puerto         : impresora.puerto,
                                codepageZpl    : impresora.codepageZpl,
                                codepageTspl   : impresora.codepageTspl,
                                temperatureZpl : impresora.temperatureZpl,
                                temperatureTspl: impresora.temperatureTspl,
                                speedZpl       : impresora.speedZpl,
                                speedTspl      : impresora.speedTspl,
                                moneySymbol    : impresora.moneySymbol,
                            }}
                            validationSchema={formSchema}
                            onSubmit={(values, actions) => enviarDatos(values, actions)}
                        >
                            <Form>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-3 d-none">
                                            <label htmlFor="puerto" className="form-label fw-bold">Puerto</label>
                                            <Field 
                                                type="text" 
                                                className="form-control form-select-sm" 
                                                id="puerto" 
                                                placeholder="9100" 
                                                name="puerto"
                                            />
                                            <ErrorMessage
                                                name='puerto'
                                                component='div'
                                                className='field-error text-danger'
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="moneySymbol" className="form-label fw-bold">Simbolo monetario</label>
                                            <Field 
                                                as="select"
                                                className="form-select form-select-sm" 
                                                aria-label=".form-select-sm example"
                                                name="moneySymbol"
                                            >
                                                {
                                                    money.map((e,i) =>{
                                                        return <option key={`momey-${i}`} value={e.name}>{e.name}</option>

                                                    })
                                                }
                                            </Field>
                                            <ErrorMessage
                                                name='moneySymbol'
                                                component='div'
                                                className='field-error text-danger'
                                            />
                                        </div>
                                    </div>

                                    

                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="codepageZpl" className="form-label fw-bold">Codificación ZPL</label>
                                            <Field 
                                                as="select"
                                                className="form-select form-select-sm" 
                                                aria-label=".form-select-sm example"
                                                name="codepageZpl"
                                            >
                                                {
                                                    codes.map((e,i) =>{
                                                        return <option key={`code-${i}`} value={e.zpl}>{e.zpl}</option>

                                                    })
                                                }
                                            </Field>
                                            <ErrorMessage
                                                name='codepageZpl'
                                                component='div'
                                                className='field-error text-danger'
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="codepageTspl" className="form-label fw-bold">Codificación TSPL</label>
                                            <Field 
                                                as="select"
                                                className="form-select form-select-sm" 
                                                aria-label=".form-select-sm example"
                                                name="codepageTspl"
                                            >
                                                {
                                                    codes.map((e,i) =>{
                                                        return <option key={`code-${i}`} value={e.tspl}>{e.tspl}</option>
                                                    })
                                                }
                                            </Field>
                                            <ErrorMessage
                                                name='codepageTspl'
                                                component='div'
                                                className='field-error text-danger'
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="temperatureZpl" className="form-label fw-bold">Temperatura ZPL</label>
                                            <Field 
                                                as="select"
                                                className="form-select form-select-sm" 
                                                aria-label=".form-select-sm example"
                                                name="temperatureZpl"
                                            >
                                                {
                                                    temperatureZplArray.map((e,i) =>{
                                                        return <option key={`tempZpl-${i}`} value={e}>{e}</option>

                                                    })
                                                }
                                            </Field>
                                            <ErrorMessage
                                                name='temperatureZpl'
                                                component='div'
                                                className='field-error text-danger'
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="temperatureTspl" className="form-label fw-bold">Temperatura TSPL</label>
                                            <Field 
                                                as="select"
                                                className="form-select form-select-sm" 
                                                aria-label=".form-select-sm example"
                                                name="temperatureTspl"
                                            >
                                                {
                                                    temperatureTsplArray.map((e,i) =>{
                                                        return <option key={`tempTspl-${i}`} value={e}>{e}</option>
                                                    })
                                                }
                                            </Field>
                                            <ErrorMessage
                                                name='temperatureTspl'
                                                component='div'
                                                className='field-error text-danger'
                                            />
                                        </div>
                                    </div>

                                </div>

                                <div className="row">

                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="speedZpl" className="form-label fw-bold">Velocidad ZPL</label>
                                            <Field 
                                                as="select"
                                                className="form-select form-select-sm" 
                                                aria-label=".form-select-sm example"
                                                name="speedZpl"
                                            >
                                                {
                                                    speedZplArray.map((e,i) =>{
                                                        return <option key={`velZpl-${i}`} value={e}>{e}</option>

                                                    })
                                                }
                                            </Field>
                                            <ErrorMessage
                                                name='temperatureZpl'
                                                component='div'
                                                className='field-error text-danger'
                                            />
                                        </div>
                                    </div>

                                    <div className="col-md-6">
                                        <div className="mb-3">
                                            <label htmlFor="speedTspl" className="form-label fw-bold">Velocidad TSPL</label>
                                            <Field 
                                                as="select"
                                                className="form-select form-select-sm" 
                                                aria-label=".form-select-sm example"
                                                name="speedTspl"
                                            >
                                                {
                                                    speedTsplArray.map((e,i) =>{
                                                        return <option key={`velTspl-${i}`} value={e}>{e}</option>
                                                    })
                                                }
                                            </Field>
                                            <ErrorMessage
                                                name='speedTspl'
                                                component='div'
                                                className='field-error text-danger'
                                            />
                                        </div>
                                    </div>

                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <button 
                                            className="btn btn-sm btn-outline-primary text-uppercase fw-bold"
                                            disabled={loading}
                                            type='button'
                                            onClick={setDefaultValues}
                                        ><span>Reestablecer valores</span>
                                        {
                                            loading &&
                                            <>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                <span className="visually-hidden">Loading...</span>
                                            </>
                                        }
                                            
                                        </button>
                                    </div>
                                    <div className="col-md-6 text-md-end text-start">
                                        <button 
                                            className="btn btn-sm btn-success text-uppercase fw-bold"
                                            disabled={loading}
                                            type='submit'
                                        ><span>Actualizar datos</span>
                                        {
                                            loading &&
                                            <>
                                                <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                <span className="visually-hidden">Loading...</span>
                                            </>
                                        }
                                            
                                        </button>
                                    </div>
                                </div>
                            </Form>
                        </Formik>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Configuracion
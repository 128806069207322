// import {
//     NUEVA_ORDEN,
//     SELECCIONAR_PRODUCTO,
//     CONFIRMAR_ORDENAR_PLATILLO,
//     MOSTRAR_RESUMEN,
//     ELIMINAR_PRODUCTO,
//     PEDIDO_ORDENADO
// } from '../../types'
export default (state,action) => {
    switch (action.type) {
        case 'SELECCIONAR_VISTA':
            return {
                ...state,
                vista: action.payload
            }
        // case 'SELECCIONAR_VISTA':
        //     return {
        //         ...state,
        //         vista: action.payload
        //     }

        default:
            return state;
    }
}
import React, { useState, useContext, useEffect } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import {urlApi} from '../global/Global';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useHistory } from "react-router-dom";
import Google from './Google';
import Main from './Main';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import HeaderHome from './layouts/HeaderHome';
import web2barcode from '../../src/img/Logo.png'
import { version } from '../../package.json';
import { useTranslation } from 'react-i18next';
import UserContext from '../userContext/UserContext';
import Skeleton from './layouts/Skeleton';
import funciones from './utilidades/funciones';
import { GoogleOAuthProvider  } from '@react-oauth/google';

const Login = ({setExist, setUser, exist, setLoading, loading}) => {
    const CLIENT_ID = '215741810169-7f1dbk6hlafe6pg8gj379tbb77sg8adu.apps.googleusercontent.com';
    const Images = () => (
                    <div className="row align-items-center mb-4">
                        <div className='col-md-12 text-center'>
                            <img
                                id='nav_logo'
                                className='img-fluid' 
                                src={web2barcode}
                                alt='web2barcode'
                                width={180}
                            />       
                        </div>
                    </div>
                    );
    /*Sweet alert*/
    const MySwal = withReactContent(Swal);
    //
    const [cuenta, setCuenta]     = useState(0);
    const [carga, setCarga]       = useState(false);
    const [skeleton, setskeleton] = useState(false);
    /**/
    const { t } = useTranslation();
    const { seleccionarUser } = useContext(UserContext);

/*****************************************************************************************************/

    useEffect(() => {
        setTimeout(() => {
            setskeleton(true);
        }, 2000);
    }, []);
        

/*****************************************************************************************************/

    const forgot = async (params) => {
        setCarga(true);
        const headers = {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
        }
        console.log(params);
        // return;
        await axios.post(urlApi+'auth/create', params, {headers: headers})
                    .then(async (response) =>{
                        if(response.data.respuesta ==='correcto'){
                            MySwal.fire({
                                icon : 'success',
                                title: 'Correcto',
                                text : response.data.message,
                            });
                            setCuenta(1);
                        }else{
                            MySwal.fire({
                                icon : 'error',
                                title: 'error',
                                text : response.data.message,
                            });
                        }

                            console.log(response.data);
                    }).catch((error) => {
                        MySwal.fire({
                            icon : 'error',
                            title: 'Ocurrio un error',
                            text : 'Vuelva a intentarlo',
                        });
                            console.log(error);
                    }).then(() =>{
                        setCarga(false);
                    });
    }
/*****************************************************************************************************/

    const login = async (params) => {
        setCarga(true);
        const headers = {
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
        }
        params.cuenta            = cuenta;
        params.sistema_operativo = funciones.getOS();
        await axios.post(urlApi+'auth/login', params, {headers: headers})
                    .then(async (response) =>{


                        if(response.data.respuesta==='error'){
                            let text = '';
                            if(response.data.message === 'unverified') text = t("login.login_1.unverified");
                            if(response.data.message === 'invalid') text    = t("login.login_1.invalid");

                            MySwal.fire({
                                icon : 'error',
                                title: 'Error',
                                text : text,
                            });
                        }else{

                            const jsonValue = JSON.stringify(response.data);
                            await AsyncStorage.setItem(
                                    '@login',
                                    jsonValue
                                );
                                setExist(true);
                                setUser(response.data);
                                seleccionarUser(response.data);
                        }

                            
                    }).catch((error) => {                    
                            MySwal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: error.message,
                            });
                            console.log(error);
                    }).then(() => {
                        setCarga(false);
                    });
    }
    

/*****************************************************************************************************/

    const signup = async (params) => {
        console.log(params);
        setCarga(true);
        params.google = 0;
        params.cuenta = cuenta;
        params.sistema_operativo = funciones.getOS();
        await axios.post(urlApi+'auth/signup', params)
        .then((response) => {
            //Actualiza estados del formulario
            if(response.data.respuesta ==='correcto'){
                MySwal.fire({
                    icon : 'success',
                    title: 'Correcto',
                    text : response.data.message,
                });
                setCuenta(1);
            }
            else if(response.data.respuesta ==='google'){
                MySwal.fire({
                    icon : 'warning',
                    title: 'Atención',
                    text : response.data.message,
                });
                setCuenta(1);
            }
            else if(response.data.respuesta ==='error'){
                let texto = '<ul>';
                Object.keys(response.data.errors).map((e,i) =>{
                    if(e === 'email'){
                        texto += '<li>Email invalido o duplicado</li>';
                    }
                    if(e === 'password'){
                        texto += '<li>Contraseña invalida o menor a 8 caracteres</li>';
                    }
                    texto += '</ul>';
                });
                MySwal.fire({
                    icon: 'error',
                    title: 'Error!',
                    html: texto,
                });
            }
            console.log(response.data);
        })
        .catch((error) => {
                MySwal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Ocurrio un error en la acción',
                  })
            console.log(error);
        })
        .then(() =>{
            setCarga(false);
        });
    }
    

/*****************************************************************************************************/
  //Validador de datos
  const formSchemaLogin = Yup.object().shape({
    email: Yup.string()
        .trim()
        .email()
        .required('Campo requerido'),
    password: Yup.string()
        .trim()
        .required('Campo requerido'),
    });

  //Validador de datos
  const formSchemaSingUp = Yup.object().shape({
    email: Yup.string()
        .trim()
        .email()
        .required('Campo requerido'),
    name: Yup.string()
        .trim()
        .required('Campo requerido'),
    password: Yup.string()
        .min(8)
        .trim()
        .required('Campo requerido'),
    passwordConfirm: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Las contraseñas deben ser iguales')
        .required('Campo requerido'),
    });

  //Validador de datos
  const formSchemaPassword = Yup.object().shape({
    email: Yup.string()
        .trim()
        .email()
        .required('Campo requerido'),
    });
/*****************************************************************************************************/
    return (
        <>
        {
        !skeleton 
        ? <Skeleton/>
        :<>
        {
            cuenta === 0 &&
            <Main
                cuenta={cuenta}
                setCuenta={setCuenta}
            />
        }
        <div className='principal'>
            <HeaderHome
                setCuenta={setCuenta}
            />
            <div className='container' style={{marginTop: '10rem'}}>
            {
            cuenta === 1 &&
                <div className="row justify-content-center animate__animated animate__fadeIn">
                    <div className="col-md-8">
                        <div className="card">
                            <div className="card-header bg-card-header">

                                <FontAwesomeIcon className='create-account' icon={faArrowAltCircleLeft} color={'#fff'} onClick={() => setCuenta(0)} />
                                <span className="fw-bold text-white" style={{marginLeft: '1rem'}}>{t('home.login')}</span>
                                {/* <span className="fw-bold text-white" style={{marginLeft: '1rem'}}>Iniciar Sesion</span> */}
                            </div>
                            <div className="card-body">  
                            <div className='row'>
                                <div className='col-md-12 text-center'>
                                    <h1 className="h3 mb-3 fw-normal">Web2 Barcode</h1>
                                </div>
                            </div>        
                                <Formik
                                    initialValues={{
                                        email: '',
                                        password: '',
                                    }}
                                    validationSchema={formSchemaLogin}
                                    onSubmit={(values, actions) => login(values, actions)}
                                >
                                    <Form>
                                        {/* <img className="mb-4" src="/docs/5.1/assets/brand/bootstrap-logo.svg" alt="" width="72" height="57" /> */}
                                        
                                        <div className="form-floating mb-3">
                                            <Field disabled={carga} type="email" className="form-control" id="floatingInput" placeholder="name@example.com" name="email"/>
                                            <label htmlFor="floatingInput">Email</label>
                                            <ErrorMessage
                                                name='email'
                                                component='div'
                                                className='field-error text-danger'
                                            />
                                        </div>
                                        <div className="form-floating mb-3">
                                            <Field disabled={carga} type="password" className="form-control" id="floatingPassword" placeholder={t("login.login_1.password")} name="password"/>
                                            <label htmlFor="floatingPassword">{t("login.login_1.password")}</label>
                                            <ErrorMessage
                                                name='password'
                                                component='div'
                                                className='field-error text-danger'
                                            />
                                        </div>

                                        <div className=" mb-3">
                                            <a href="#" className="link-primary" onClick={
                                                carga ?
                                                () => console.log(2)
                                                : () => setCuenta(2)
                                            }
                                            >
                                                {t("login.login_1.not_account")}
                                            </a>
                                        </div>

                                        <button disabled={carga} className="w-100 btn btn-lg btn-primary fw-bold mb-3" type="submit">
                                            <span style={{marginRight: '.5rem'}}>{t('home.login')}</span>                                         
                                            {/* <span style={{marginRight: '.5rem'}}>Iniciar sesión</span>                                          */}
                                        </button>

                                        <GoogleOAuthProvider clientId={CLIENT_ID}>
                                            <Google
                                                setExist={setExist}
                                                setUser={setUser}
                                                carga={carga}
                                                setCarga={setCarga}
                                            />
                                        </GoogleOAuthProvider>
                                        

                                        
                                        {
                                            carga &&                                            
                                                <div className="row">
                                                    <div className="text-center">
                                                        <div className="spinner-border" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div>
                                                    </div>                                        
                                                </div>                                        
                                        }
                                        <div className="text-end">
                                            <a href="#" className="link-primary" onClick={
                                                carga ?
                                                () => console.log(3)
                                                : () => setCuenta(3)
                                            }>
                                                {t("login.login_1.forgot_pw")}
                                            </a>
                                        </div>
                                        <p className="mt-3 mb-3 text-muted">© 2022 - Version {version}</p>
                                    </Form>
                                </Formik>
                            </div>                        
                        </div>
                    </div>
                </div>
            }
            {
            cuenta === 2 &&
                <div className="row justify-content-center animate__animated animate__fadeIn">
                    <div className="col-md-10">
                        <div className="card mb-3">
                            <div className="card-header bg-dark">
                                <span className="fw-bold text-white">{t("login.login_2.create_account")}</span>
                            </div>
                            <div className="card-body">
                            <h1 className="h3 mb-3 fw-normal d-none">Ingresar datos</h1>
                            {/* <Images/> */}
                                <Formik
                                        initialValues={{
                                            email: '',
                                            name: '',
                                            password: '',
                                            passwordConfirm: '',
                                        }}
                                        validationSchema={formSchemaSingUp}
                                        onSubmit={(values, actions) => signup(values, actions)}
                                >
                                    <Form>
                                        {/* <img className="mb-4" src="/docs/5.1/assets/brand/bootstrap-logo.svg" alt="" width="72" height="57" /> */}

                                        <div className="form-floating mb-3">
                                            <Field disabled={carga} type="email" className="form-control" id="floatingInput" placeholder="name@example.com" name="email"/>
                                            <label htmlFor="floatingInput">Email</label>
                                            <ErrorMessage
                                                name='email'
                                                component='div'
                                                className='field-error text-danger'
                                            />
                                        </div>

                                        <div className="form-floating mb-3">
                                            <Field disabled={carga} type="text" className="form-control" id="floatingInput2" placeholder="namexample" name="name"/>
                                            <label htmlFor="floatingInput2">{t("login.login_2.name")}</label>
                                            <ErrorMessage
                                                name='name'
                                                component='div'
                                                className='field-error text-danger'
                                            />
                                        </div>

                                        <div className="form-floating mb-3">
                                            <Field disabled={carga} type="password" className="form-control" id="floatingPassword" placeholder="Password" name="password"/>
                                            <label htmlFor="floatingPassword">{t("login.login_1.password")}</label>
                                            <ErrorMessage
                                                name='password'
                                                component='div'
                                                className='field-error text-danger'
                                            />
                                        </div>

                                        <div className="form-floating mb-3">
                                            <Field disabled={carga} type="password" className="form-control" id="floatingPassword2" placeholder="Password" name="passwordConfirm"/>
                                            <label htmlFor="floatingPassword2">{t("login.login_2.confirm_pw")}</label>
                                            <ErrorMessage
                                                name='passwordConfirm'
                                                component='div'
                                                className='field-error text-danger'
                                            />
                                        </div>

                                        <div className=" mb-3">
                                            <a href="#" className="link-primary" onClick={
                                                    
                                                    carga 
                                                    ? () => console.log(1)
                                                    : () => setCuenta(1)
                                            }>
                                                {t("login.login_2.login")}
                                            </a>
                                        </div>

                                        {
                                            carga &&                                            
                                                <div className="row">
                                                    <div className="text-center">
                                                        <div className="spinner-border" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div>
                                                    </div>                                        
                                                </div>                                        
                                        }

                                        <button disabled={carga} className="w-100 btn btn-lg btn-primary fw-bold" type="submit">{t("login.login_2.create_account")}</button>
                                        <p className="mt-5 mb-3 text-muted">© 2022 - Version {version}</p>
                                    </Form>     
                                </Formik>        
                            </div>                        
                        </div>
                    </div>
                </div>
            }
            {
            cuenta === 3 &&
                <div className="row justify-content-center animate__animated animate__fadeIn">
                    <div className="col-md-10">
                        <div className="card">
                            <div className="card-header bg-dark">
                                <span className="fw-bold text-white">{t("login.login_3.recover_pw")}</span>
                            </div>
                            <div className="card-body">
                            <h1 className="h3 mb-3 fw-normal">{t("login.login_3.put_email")}</h1>
                            {/* <Images/> */}
                                <Formik
                                        initialValues={{
                                            email: '',
                                        }}
                                        validationSchema={formSchemaPassword}
                                        onSubmit={(values, actions) => forgot(values, actions)}
                                >
                                    <Form>
                                        {/* <img className="mb-4" src="/docs/5.1/assets/brand/bootstrap-logo.svg" alt="" width="72" height="57" /> */}
                                    
                                        <div className="form-floating mb-3">
                                            <Field disabled={carga} type="email" className="form-control" id="floatingInput" placeholder="name@example.com" name="email"/>
                                            <label htmlFor="floatingInput">Email</label>
                                            <ErrorMessage
                                                name='email'
                                                component='div'
                                                className='field-error text-danger'
                                            />
                                        </div>

                                        <div className=" mb-3">
                                            <a href="#" className="link-primary" onClick={() => setCuenta(2)}>
                                                {t("login.login_1.not_account")}
                                            </a>
                                        </div>

                                        <button disabled={carga} className="w-100 btn btn-lg btn-primary fw-bold" type="submit">
                                            {t("login.login_3.recover_pw")}
                                        </button>
                                        {
                                            carga &&                                            
                                                <div className="row">
                                                    <div className="text-center">
                                                        <div className="spinner-border" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div>
                                                    </div>                                        
                                                </div>                                        
                                        }
                                        <p className="mt-5 mb-3 text-muted">© 2022 - Version {version}</p>
                                    </Form>     
                                </Formik>        
                            </div>                        
                        </div>
                    </div>
                </div>        
            }
            </div>
        </div>
        </>
        }
        </>
    )
}

export default Login

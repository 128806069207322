import React, {useEffect, useState, useRef, Fragment} from "react";
import styled from "styled-components";
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGroupBy,
  useExpanded,
  useRowSelect,
  useGlobalFilter,
  useAsyncDebounce,
  useResizeColumns,
  useFlexLayout
} from "react-table";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faSearch} from '@fortawesome/free-solid-svg-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {gapi} from 'gapi-script';
import '@fortawesome/fontawesome-free/css/fontawesome.css';
import { unauthenticated } from '../../helpers/Helper';
/*Globales*/
import {url, urlApi} from '../../global/Global';
import funciones from "../utilidades/funciones";
import { t } from 'i18next';

const Styles = styled.div`
.table{
  width: 100%;
},

table {

  input {
    font-size: 1rem;
    padding: 0;
    margin: 0;
    border: 0;
    background-color: transparent;
    width: 70%;
    text-align:center
  },

  thead{
    position: sticky;
    top: 0;
    right: 0;
    z-index:1;
  }
},
  tr{    
    td {
      :last-child {
        position: sticky;
        right:0px;
        top:0px;
        box-sizing: border-box; 
        flex: 150 0 auto; 
        min-width: 0px; 
        width: 90px;
        z-index: 0;
        background:#f6f7c3;
        border-left: 1px solid black;
      }
    }
    td {
      :last-child {
        input {
          width: 100%;
        },
      }
    }
    th {
        position:sticky;
        right:0px;
        top:0px;
        z-index: 1059;
        background:white;
        border-top: 1px solid black;
      }
    th {
      :last-child {
        box-sizing: border-box; 
        flex: 150 0 auto; 
        min-width: 0px; 
        width: 90px;
        background:#f6f7c3;
        border-left: 1px solid black;
      }
    }
  }        

}
`
// background:#a9ec9d;

 // Define a default UI for filtering
 const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  globali,
}) => {
  const count = preGlobalFilteredRows.length
  const [_value, _setValue] = useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <span>
        <span>Buscar: </span>
        <FontAwesomeIcon icon={faSearch}/>{' '}
        <input
          value={_value || ""}
          onChange={e => {
            _setValue(e.target.value);
            onChange(e.target.value);
            // console.log(preGlobalFilteredRows);
          }}
          placeholder="..."
          style={{
            fontSize: '1.1rem',
            border: '0',
            textAlign: 'left',
            background: 'white',
            borderRadius: '3px',
            border: '1px solid #2a2b2d',
            padding: '2px'
          }}
          className="w-10rem"
          id="buttonRef"
        />
    </span>
  )
}

// Be sure to pass our updateMyData and the skipReset option
function Table({ columns, 
                 data, 
                updateMyData, 
                skipReset, 
                setSelectedRows, 
                setSelectedRowsId,
                headers, 
                _cantidades,
                _setCantidades,
                _cantidadesFilter,
                _setCantidadesFilter,
                mas,
                setmas,
                boton_imprimir,
                validacionImpresion,
                setValidacionImpresion
            }) {

  // Create an editable cell renderer
    const EditableCell = ({
      value: initialValue,
      row: { index },
      column: { id },
      updateMyData, // This is a custom function that we supplied to our table instance
      editable
    }) => {
      // We need to keep and update the state of the cell normally
      const [value, setValue]                 = React.useState(initialValue);
      const [contador_rows, setcontador_rows] = useState(0);

      

      const onChange = (e) => {
        const num_col = headers.length-1
        const columna = 'col'+num_col;
        const re = /^\d*\.?\d*$/;
        console.group('update');
        console.log(index);
        console.log(id);
        console.log(headers);
        console.log(e.target.value);
        console.groupEnd('end update');
        let bool = false;

        headers.map(element => {
          if(element.accessor === id){
            if(element.Header == 'Cantidad' || element.Header.includes('Precio') ){
              if (!re.test(e.target.value)) {
                bool = true;
              }
            }
          }
        });
        
        if(bool){
          return false;
        }

        setValue(e.target.value);
      };

      // We'll only update the external data when the input is blurred
      const onBlur = () => {
        updateMyData(index, id, value);
      };

      const keyPress = (e) => {
        if(e.which == 9){
          e.preventDefault();        
        }
        // console.log(e.which);
      };

      // If the initialValue is changed externall, sync it up with our state
      React.useEffect(() => {
        setValue(initialValue);
      }, [initialValue]);

      if (!editable) {
        return `${initialValue}`;
      }

      return <input value={value} onChange={onChange} onBlur={onBlur} onKeyDown={(e) => keyPress(e)} tabIndex={"-1"}/>;
    };
  
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: GlobalFilter,
      // And also our default editable cell
      Cell: EditableCell,
      // minWidth: 200, // minWidth is only used as a limit for resizing
      // width: '100%', // width is used for both the flex-basis and flex-grow
    }),
    []
  );

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    toggleAllRowsSelected,
    toggleRowSelected,

    state: {
      pageIndex,
      pageSize,
      sortBy,
      groupBy,
      expanded,
      globalFilter,
      selectedRowIds
    }
  } = useTable(
    {
      columns,
      data,
      defaultColumn,

      
      // updateMyData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      updateMyData,
      // We also need to pass this so the page doesn't change
      // when we edit the data.
      autoResetPage: false,
      // autoResetPage: !skipReset,
      autoResetSelectedRows: !skipReset,
      disableMultiSort: true
    },
    useFilters,
    useGlobalFilter,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useResizeColumns,
    useFlexLayout,
    
    // Here we will use a plugin to add our selection column
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            //Size
            disableResizing: true,
            // minWidth: 100,
            // width: '100%',
            width: 30,
            // maxWidth: 300,
            // Make this column a groupByBoundary. This ensures that groupBy columns
            // are placed after it
            groupByBoundary: true,
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps}) => (
              // i++;
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()}/>
                {/* <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} onClick={(e) => catchInput(e.target, false, true)}/> */}
              </div>
              
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                {/* e.target.value, row.index */}
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
                {/* <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} onClick={(e) => changeCantidades('1', parseInt(e.target.parentElement.parentElement.parentElement.getAttribute('unique')))}/> */}
                {/* <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} onClick={(e) => catchInput(e.target, e.target.parentElement.parentElement.parentElement, false)}/> */}
              </div>
            )
          },
          ...columns
        ];
      });
    }
  );

  React.useEffect(() => {
    setSelectedRows(selectedFlatRows.map((e) => e.original));
    setSelectedRowsId(selectedRowIds);
    //Darle valor de uno a la seleccionada
    
  }, [selectedFlatRows]);

  
  useEffect(() => {
    gotoPage(0);
  }, [globalFilter])


  useEffect(() => {
    boton_imprimir.current.addEventListener('click', () =>{
      setGlobalFilter('');
      document.querySelector('#buttonRef').value=""
      console.log('kkkkkkkkkkkkkkkkkkkkkkk')
      // toggleAllRowsSelected(false);
    });
  }, [])

  useEffect(() => {
    
      if(validacionImpresion){
          toggleAllRowsSelected(false);
          _setCantidades(_cantidadesFilter);
      }
  
  }, [validacionImpresion])
  

 //cambia las cantidades individuales.
  const changeCantidades = (value, con) => {
        const re = /^\d*\.?\d*$/;
        if (!re.test(value)) {
            return false;
        }
        // console.log(selectedFlatRows);
        // console.log(toggleRowSelected)
        if(value > 0){
          toggleRowSelected(con.toString(), true);
        }else{
          toggleRowSelected(con.toString(), false);
        }
        console.log(con);
        _setCantidades(
            _cantidades.map((e,i) =>
                i === con 
                ? e = value
                : e
            )
        )

  }
  
  // Render the UI for your table
  return (
    <>
    <div className="border border-dark rounded table-responsive mb-4" style={{height: '550px'}}>
      <table {...getTableProps()}  className="table table-striped table-w-100">
        <thead className="sticky-header thead">
        <tr
          style={{
            textAlign: 'left',
            borderBottom: '#787777 1px solid',
            background: '#bebebe'
          }}
        >
          <th
            colSpan={visibleColumns.length}
            style={{
              textAlign: 'left',
              borderBottom: '#787777 1px solid',
              background: '#bebebe',
              borderLeft: 'none',
              borderTop: 'none',
            }}
          >
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </th>
          </tr>

              {headerGroups.map((headerGroup, ii) => (
                    // <div {...headerGroup.getHeaderGroupProps()} className="tr">
                    <tr {...headerGroup.getHeaderGroupProps()} 
                        key={'ii'+ii}    
                    >
                      {headerGroup.headers.map((column, xi) => {
                        return <Fragment key={'xi-'+xi}>
                          {/* <div {...column.getHeaderProps()} className="th"> */}
                          <th {...column.getHeaderProps()}
                            >
                            {column.render('Header')}
                          </th>
                          {/* </div> */}
                          {/* {column.canResize && (
                            <div
                                {...column.getResizerProps()}
                                className={`resizer ${
                                  column.isResizing ? 'isResizing' : ''
                                }`}
                            />
                            )
                          } */}
                          </Fragment>
                        })}
                      <th role="columnheader">Cantidad</th>
                      {/* <div colSpan="1" role="columnheader" className="thp">Cantidad</div> */}
                    </tr>
                    // </div>
                  ))}
          {/* {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </th>
                  ))}
                  <th colSpan="1" role="columnheader">Cantidad</th>
                </tr>
              ))} */}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}
                key={'iii'+i}
                unique={row.index}
              >
                {row.cells.map((cell, k) => {
                  return <Fragment key={'kk-'+k}>
                    <td {...cell.getCellProps()} key={'asd'+k}>
                      {
                        cell.render("Cell", { editable: true })
                      }
                    </td>
                    {
                      k+1 == row.cells.length &&
                        <td 
                          role="cell" 
                          unique={row.index}
                        >
                            <input 
                                // defaultValue={_cantidades[row.index]} 
                                value={_cantidades[row.index]} 
                                onChange={(e) => changeCantidades(e.target.value, row.index)} 
                                onClick={(e) => e.target.select()}
                                inputMode="numeric"

                            />
                        </td>
                    }
                    </Fragment>
                })}
                {/* <div role="cell" className="tdp"> */}
                
                    {/* <input className="input" value={_cantidades[i+mas]} onChange={(e) => changeCantidades(e.target.value, i+mas)}/> */}
                {/* </div> */}
              </tr>
            );
          })}
        </tbody>
      </table>
      {/*
        Pagination can be built however you'd like.
        This is just a very basic UI implementation:
      */}
      </div>
      <div className="row">
          <div className="col-md-2">
            <p>Registros: <b>{preGlobalFilteredRows.length}</b></p>
          </div>
          <div className="col-md-3">
              <nav>
                <ul className="pagination">
                  <li className="page-item">
                    <button className="page-link" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                      {'<<'}
                    </button>{' '}
                  </li>
                  <li className="page-item">
                    <button className="page-link" onClick={() => previousPage()} disabled={!canPreviousPage}>
                      {'<'}
                    </button>{' '}
                  </li>
                  <li className="page-item">
                    <button className="page-link" onClick={() => nextPage()} disabled={!canNextPage}>
                      {'>'}
                    </button>{' '}
                  </li>
                  <li className="page-item">
                    <button className="page-link" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                      {'>>'}
                    </button>{' '}
                  </li>
                </ul>
              </nav>
            </div>
            <div className="col-md-3">
              <span>
                Página{' '}
                <strong>
                  {pageIndex + 1} de {pageOptions.length}
                </strong>{' '}
              </span>
            </div>
          <div className="col-md-2">
            <span>
              Ir a la página:{' '}
            </span>{' '}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(page)
              }}
              className="form-control form-control-sm"
            />
          </div>
          <div className="col-md-2">
            <span>
              Mostrar
            </span>{' '}
            <select
              className="form-select form-select-sm"
              value={pageSize}
              onChange={e => {
                setPageSize(Number(e.target.value))
              }}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Mostrar {pageSize}
                </option>
              ))}
            </select>
          </div>
          {/* <pre className="d-none">
          <code>
            {JSON.stringify(
              {
                pageIndex,
                pageSize,
                pageCount,
                canNextPage,
                canPreviousPage,
                sortBy,
                groupBy,
                expanded: expanded,
                selectedRowIds: selectedRowIds,
                selectedRows: selectedFlatRows.map((d) => d.original)
              },
              null,
              2
            )}
          </code>
        </pre> */}
      </div>
    </>
  );
}
/**************************************************************************************************/
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
  // (props, ref) => {
    const defaultRef    = React.useRef();
    const resolvedRef   = ref || defaultRef;
    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
      // console.log(rest);
      // console.log(resolvedRef.current);
      // console.log(getToggleRowSelectedProps);
      // console.log(resolvedRef.current.parentElement.parentElement.parentElement);
      // console.log(resolvedRef.current);
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest}/>
      </>
    );
  }
);


/*************************************************************************************************/
const TableEditablePage = ({catalogo, etiqueteta, user}) => {

/*******************************************************************/
    const canti     = useRef();
    const buttonRef = useRef();
/*******************************************************************/
    // Client ID and API key from the Developer Console
    const CLIENT_ID = '191181423961-lqknnrp0amj9pj0o4d16r8aafu611le9.apps.googleusercontent.com';
    const API_KEY   = 'AIzaSyBIK-3IqFaUg03I0elDDLpqUO4UaKIQ6RY';

    // Array of API discovery doc URLs for APIs used by the quickstart
    const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"];

    // Authorization scopes required by the API; multiple scopes can be
    // included, separated by spaces.
    const SCOPES = 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile';
    const [signedInUser, setSignedInUser] = useState('');
    const [isLoadingGoogleDriveApi, setIsLoadingGoogleDriveApi] = useState(false);

/*******************************************************************/

    const [loading, setloading] = useState(false);

    const MySwal                                    = withReactContent(Swal);
    const etiqueta                                  = useRef();
    const boton_imprimir                            = useRef();
    const [data, setData]                           = useState([]);
    const [headers, setheaders]                     = useState([]);
    const [plus, setplus]                           = useState(false);
    const [_plus, set_plus]                         = useState(false);
    const [originalData]                            = useState(data);
    const [etiquetas, setetiquetas]                 = useState();
    const [modCantidad, setmodCantidad]             = useState(0);
    const [_cantidades, _setCantidades]             = useState([]);
    const [_cantidadesFilter, _setCantidadesFilter] = useState([]);
    const [mas, setmas]                             = useState(0);

    //Added this hook to know what's selected when I submit a form or something
    const [selectedRows, setSelectedRows] = useState([]);
    const [selectedRowsId, setSelectedRowsId] = useState([]);

    const [local_drive, set_local_drive] = useState('local');

    /* Validación para ver si imprimio o no */
    const [validacionImpresion, setValidacionImpresion] = useState(false);
    const abecedario = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];

/*****************************************************************************************************/
  useEffect(() => {
      console.log(catalogo);
      const getCatalogo = async (params) => {
          const log = await AsyncStorage.getItem('@login');
          const log_json = JSON.parse(log);
          const headers = {
              'Authorization': log_json.token_type+' '+log_json.access_token,
              'Content-Type': 'application/json',
              'X-Requested-With': 'XMLHttpRequest',
          }

          const send_data = {
            id: catalogo
          }
          // console.log(send_data);
          // return;
          await axios.post(urlApi+'catalogo/show', send_data, {headers: headers} )
              .then(async (response) => {
                  setData(response.data.campos);
                  console.log(response.data);
                  _setCantidades(response.data.cantidad);
                  _setCantidadesFilter(response.data.cantidad);
                  // response.data.headers.map((e,i) => {
                  response.data.headers.map((e,i) => {
                    // console.log(e);
                      let n = capitalizarPrimeraLetra(e);
                      setheaders(headers => [...headers, {"Header": n, 'accessor': 'col'+abecedario[i], filter: 'fuzzyText'}])
                      // setheaders(headers => [...headers, {"Header": n, 'accessor': 'col'+i, filter: 'fuzzyText'}])
                  });
                  setplus(true);
              })
              .catch((error) => {
                  unauthenticated(error);
                  console.log(error);
              });     
      }

      const getEtiqueta = async (params) => {
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
        const send_data = {
          id: etiqueteta
        }
        await axios.post(urlApi+'etiquetas/show', send_data, {headers: headers})
            .then(async (response) => {
                setetiquetas(response.data.etiquetas);
                console.log(response.data.etiquetas);
                set_plus(true);
            })
            .catch((error) => {
                console.log(error);
            });             
        // await axios.get(urlApi+'etiquetas/index', {headers: headers})
        //     .then(async (response) => {
        //         setetiquetas(response.data.etiquetas);
        //         console.log(response.data);
        //         set_plus(true);
        //     })
        //     .catch((error) => {
        //         console.log(error);
        //     });             
      }      

      handleClientLoad();
      getCatalogo();
      getEtiqueta();
      
  }, []);
  
/********************************************************************************************************/

    /**
     *  Sign in the user upon button click.
     */
     const handleAuthClick = (event) => {
        gapi.auth2.getAuthInstance().signIn();
    };

    /**
     *  Called when the signed in status changes, to update the UI
     *  appropriately. After a sign-in, the API is called.
     */

    /**
     *  Sign out the user upon button click.
     */
    const handleSignOutClick = (event) => {
      MySwal.fire({
        title: '¿Cerrar sesión de Google?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
            // setListDocumentsVisibility(false);
            gapi.auth2.getAuthInstance().signOut();
            // setDocuments([]);    
            setSignedInUser('');
        }
      })
    };

/********************************************************************************************************/
    const updateSigninStatus = (isSignedIn) => {
        if (isSignedIn) {
            // Set the signed in user
            setSignedInUser(gapi.auth2.getAuthInstance().currentUser);
            // setIsLoadingGoogleDriveApi(false);
            console.log(gapi.auth2.getAuthInstance().currentUser.le.wt);
        } else {
            // prompt user to sign in
            // handleAuthClick();
        }
    };

    /**
     *  Initializes the API client library and sets up sign-in state
     *  listeners.
     */
    const initClient = () => {
        setIsLoadingGoogleDriveApi(true);
        gapi.client
            .init({
                apiKey: API_KEY,
                clientId: CLIENT_ID,
                discoveryDocs: DISCOVERY_DOCS,
                scope: SCOPES,
            })
            .then(
                function () {
                    // Listen for sign-in state changes.
                    gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);

                    // Handle the initial sign-in state.
                    updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
                },
                function (error) {
                    console.log(error);
                }
            );
    };

    const handleClientLoad = () => {
        gapi.load('client:auth2', initClient);
    };

/*****************************************************************************************************************************/

    const selectDoc = async(doc, name) => {
        let _drive = '';
        // setloading(true);
        console.log(doc);
        await gapi.client.drive.files.get({ 
            fileId: doc,
            alt: 'media'
        }).then(async res =>{
            console.log(res);
            // console.log( unescape(encodeURI(res.body)));
            // _drive = unescape(encodeURI(res.body));
            _drive = res.body;
            await enviarCatalogo(_drive, name);
            document.querySelector('#buttonRef')
            // enviarCatalogo(res.body, name);
        });

    }  

/**************************************************************************************************/

    const capitalizarPrimeraLetra = (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }

/**************************************************************************************************/
  const enviarCatalogo = async () => {
    console.log(Object.values(selectedRowsId).length)
    if(Object.values(selectedRowsId).length <= 0){
      Swal.fire({
        icon: 'warning',
        title: t("view.select_at_least")
      });
      return false;
    }
    setloading(true);
    setValidacionImpresion(false);
    
    const x       = etiquetas;
    let   lista   = '';
    let   conteo  = 0;
    let   pattern = 'pattern';
    // console.log(x);
    // return;
    console.log(x);
    // return;
    if(x.formulario == 1){ // si el formulario no esta desactivado
      x.configuraciones.map((e,i) => {
          console.log(e);
          // if(e.type_config_id == 2){
          if(e.types.front_back == 'front'){
            if(e.type_config_id == 4){
                pattern='pattern="[0-9]*" inputmode="numeric"';
            }else{
                pattern = 'pattern=""'
            }
                lista+=`
                    <div class="col-md-12 mb-2">
                        <label class="fw-bold mb-1">${e.valor}</label>
                        <input ${pattern} type="${e.types.type}" name="${e.variable}" required type="text" class="form-control text-center blc-fl" id="swal-input${i}" placeholder="${e.variable}">
                    </div>`;
                    conteo++;
            }
      });
    }
    // return;
    let jwal = '';
    let preguntas = {};
    if(conteo > 0){
      jwal = await MySwal.fire({
        html: `<div class="row">
          <div class="col">
            <span class="errores text-danger text-uppercase fw-bold mb-3 d-none">
              *Llene los campos
            </span>
          </div>  
        </div>  
        <div class="row">
          ${lista}
        </div>`,
        // allowOutsideClick: false,
        // showCancelButton: true,        
        allowOutsideClick: true,
            // showCancelButton: true,        
        confirmButtonText: 'Print',       
        showCloseButton: true,
        preConfirm: () => {

          const x = Array.from(document.querySelectorAll('.blc-fl'));
          x.forEach(e =>{
            if(e.value.trim() == ''){
                if(document.querySelector('.errores').classList.contains('d-none')){
                  document.querySelector('.errores').classList.remove('d-none')
                }
                MySwal.showValidationMessage('Llene los campos')
                // return false;
            }
          })
          return x;
          // return document.querySelectorAll('.blc-fl');
        }
      });
      if(!jwal.isConfirmed){
        setloading(false);
        return false;
      } 
      // console.log(JSON.parse(jwal.value));
      console.log(jwal.value);
      
      jwal.value.map((e,i)=>{
        preguntas[e.name] = e.value;
        console.log(e.name+' - '+e.value);
      })
      
    }else{
      preguntas = '';
    }

    // return;
    const cantidad_send = new Array();
    for (const i in selectedRowsId) {
        // cantidad_send.push(y[i]);
        cantidad_send.push(_cantidades[i]);
    }
    //Obtenemos fechas
    const d = new Date();
    // const anio = d.getDate+'/'+(d.getMonth()+1)+'/'+d.getFullYear()
    const anio = d.toLocaleDateString('default');
    const hora = d.toLocaleTimeString('default', {
        // en-US can be set to 'default' to use user's browser settings
        hour: '2-digit',
        minute: '2-digit',
        hour12: true
    });

    const datos_enviar = {
        campos           : selectedRows,
        catalogo         : catalogo,
        etiqueta         : etiqueta.current.value,
        cantidades       : cantidad_send,
        preguntas        : preguntas,
        anio             : anio,
        hora             : hora,
        sistema_operativo: funciones.getOS(),
    };

    if(cantidad_send.length > 1){
      // const cant = Object.keys(datos_enviar.campos[data.length-2]).length;
      const cant = Object.keys(datos_enviar.campos[selectedRows.length-2]).length;
      if(cant !== Object.keys(datos_enviar.campos[selectedRows.length-1]).length ){
        const num = cant-1;
        const col = 'col'+num
        datos_enviar.campos[selectedRows.length-1][col] = '';
      }
    }
    console.log(datos_enviar);
    const log = await AsyncStorage.getItem('@login');
    const log_json = JSON.parse(log);
    const headers = {
        'Authorization'   : log_json.token_type+' '+log_json.access_token,
        'Content-Type'    : 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    }
    await axios({
        url: urlApi+'catalogo/imprimirvista',
        method: 'POST',
        data: datos_enviar,
        headers: headers,
        // responseType: 'blob', // important
    }).then(async (response) =>{

      if(!response.data.response){        
          setValidacionImpresion(false);
          return false;
      }else{        
          const x = await funciones._write(log_json, response.data.impresora, response.data.mensaje);
          (x == false) ? setValidacionImpresion(false) : setValidacionImpresion(true);                  
      }
      setSelectedRows([]);
      setSelectedRowsId([]);
      setmodCantidad(0);
    })
    .catch(function (error) {
      if(error.response.data.message != undefined && error.response.data.message == 'Unauthenticated.'){
        unauthenticated(error);
      }else{
          setValidacionImpresion(false);
          MySwal.fire({
              icon: 'error',
              title: error?.response?.data?.message || 'ocurrio un error',
              toast: true,
              position: 'top-end',
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
              didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
          });
            console.log(error);
      }
    }).then(()=>{
      setloading(false);
    });
  
  }
/*************************************************************************************************/

  const guardarCambios = async (params) => {
      // guardarcambios
      // setloading(true);
      console.log(local_drive);
      // return;
      console.log(data);
      const datos_enviar = {
        campos     : data,
        catalogo   : catalogo,
        cantidades : _cantidades,
        importacion: params,
        headers    : headers,
        local_drive: local_drive
      };
      console.log(datos_enviar);
      // return;
  
      const log = await AsyncStorage.getItem('@login');
      const log_json = JSON.parse(log);
      const headerss = {
          'Authorization': log_json.token_type+' '+log_json.access_token,
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
      }

      let responseType = '';
      
      if (local_drive === 'drive') {
        responseType = 'blob';
      }else{
        responseType = 'json'
      }
      // if (catalogo.importacion === 'drive') {
      //   responseType = 'blob';
      // }else{
      //   responseType = 'json'
      // }

      await axios({
        url: urlApi+'catalogo/guardarcambios',
        method: 'POST',
        data: datos_enviar,
        headers: headerss,
        // responseType: responseType, // important
    }).then(function (response) {
        if (local_drive === 'drive') {
            const csv = new Blob([response.data.contents]);
            console.log(response.data)
            // const csv = response.data;
            updateFile(csv, catalogo.drive_id, catalogo.importacion, catalogo.nombre);
        }else{
          MySwal.fire({
                icon: 'success',
                title: 'Guardado localmente',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
          });
        }
    })
    .catch(function (error) {
      MySwal.fire({
            icon: 'error',
            title: 'Ocurrio un error',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
      });
      console.log(error);
    }).then(()=>{
      setloading(false);
    });
  }
  


/*************************************************************************************************/

  const setCantidades = async (cambioOnlyChange = false, params = null) => {
    setloading(true);
    const _mod_cantidad = (cambioOnlyChange) ? params : modCantidad;
    // const x = _cantidades;
    setTimeout(() => {
      // console.log('stop');
        
      skipResetRef.current = true;
      Object.keys(selectedRowsId).map(e =>{
        console.log(e);
      })

      // const arreglo_seleccionado = Object.keys(selectedRowsId)
      _setCantidades(_cantidades.map((e,i)=>_mod_cantidad ));
      // _setCantidades(
      //   _cantidades.map((e,i)=>{
      //     return arreglo_seleccionado.includes(i.toString())
      //     ? _mod_cantidad
      //     : e
      //   })
      // );

        // console.log(x);
        // _setCantidades(x);
        setloading(false);
        // return x;
    }, 200);
  
  }
  
/*************************************************************************************************/

  const onlyNumbers = (params) => {
    const re = /^\d*\.?\d*$/;
    if (!re.test(params)) {
        return false;
    }
    if(params.trim().length === 0){
      setmodCantidad(0);
      return false;
    }
    setmodCantidad(params);
    setCantidades(true, params)
  }
/*************************************************************************************************/

  const updateFile = async (fileData, drive_id, importacion, name) => {
      // console.log(fileData);
      const headers = {
        'Authorization': 'Bearer '+gapi.auth.getToken().access_token,
      }
      const extension = name.substring(name.lastIndexOf('.')+1, name.length) || name;
      const _name     = name.substring(0, name.indexOf('.'));

      let ex = '';
      if(extension =='csv'){
          ex = 'text/csv';
      }else if(extension =='xlsx'){
          ex = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      }
      else if(extension =='xls'){
          ex = 'application/vnd.ms-excel';
      }


      const metadata = { 
        name    : _name+'.'+extension,
        mimeType: ex,
        // mimeType: "text/csv",
      };
      let formData = new FormData();
      formData.append("metadata", new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
      formData.append("file", fileData);
      // if(importacion==='drive'){
        // await axios.patch('https://www.googleapis.com/upload/drive/v3/files/'+drive_id+'?uploadType=media', fileData, {headers: headers})
        await axios({
          method: 'PATCH',
          url: 'https://www.googleapis.com/upload/drive/v3/files/'+drive_id+'?uploadType=multipart',
          headers: headers,
          data: formData,
        }).then(response =>{
          console.log(response.data);
          MySwal.fire({
            icon: 'success',
            title: 'Guardado en drive',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        });
        }).catch((err) =>{
          console.log(err.response.data.error.message);
          MySwal.fire({
              icon: 'warning',
              title: 'Error',
              text: err.response.data.error.message,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              allowOutsideClick: false
          })
        });
     
  }

/*************************************************************************************************/

  const columns = React.useMemo(
    () => headers,
    [headers]
  )

  //Added this hook to know what's selected when I submit a form or something
  // const [selectedRows, setSelectedRows] = React.useState([]);

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.
  const skipResetRef = React.useRef(false);

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
   

    skipResetRef.current = true;
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  // After data changes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset
  React.useEffect(() => {
    skipResetRef.current = false;    
  }, [data]);

  // Let's add a data resetter/randomizer to help
  // illustrate that flow...
  const resetData = () => {
    // Don't reset the page when we do this
    skipResetRef.current = true;
    setData(originalData);
  };

  return (
    <>
      <div className="card-body animate__animated animate__fadeIn">
        {/* row */}
        <div className="row align-items-center d-none">

          <div className="col-md-12">
            <div className="row flex-sm-row-reverse align-items-center">
                <div className="col-md-6 d-none">
                  <h6 className="fw-bold">{catalogo.nombre}</h6>
                </div>

                <div className="col-md-6">
                  <div className="row align-items-center">
                    <label className="col-sm-4 col-form-label fw-bold d-none">Selecciona tu etiqueta: </label>
                    <div className=" col-sm-12">
                      {
                        etiquetas !== undefined  &&
                        <>
                          <input 
                              className="form-control form-control-sm d-none" 
                              ref={etiqueta}
                              value={etiquetas.id}
                          />     
                          <span>
                            <b>Etiqueta: </b>
                            {etiquetas.nombre}
                          </span>                       
                        </>
                      }

                    </div>
                  </div>
                </div>

                <div  className="col-md-6">                
                {
              user.google == 0 &&
              <>
              {              
                signedInUser =='' ?
                  <>
                      <div>
                          <p className='mb-0 fw-bold text-danger'>
                              Debes iniciar sesión con una cuenta de Google para guardar el CSV en Drive
                          </p>
                      </div>
                      <button 
                          className="btn btn-sm btn-light p-0 px-2 bg-white border my-3" 
                          type="button"
                          onClick={() => handleAuthClick()}
                      >
                          <div className='d-flex align-items-center justify-content-center'>
                              <img src='https://developers-dot-devsite-v2-prod.appspot.com/identity/sign-in/g-normal.png'/>
                              <span>Iniciar sesión con Google</span>
                          </div>
                          {/* <FontAwesomeIcon icon={fabGoogle} size="3x"/> Iniciar sesión con Google */}
                      </button>
                  </>
                  :
                    <div className='d-flex align-items-center'>
                        <button 
                            data-bs-toggle="tooltip" data-bs-placement="top" title="Cerrar Sesión"
                            className="btn btn-sm p-0 px-2 bg-white border rounded"
                            onClick={() => handleSignOutClick()}
                            disabled={loading}
                        ><img src='https://developers-dot-devsite-v2-prod.appspot.com/identity/sign-in/g-normal.png'/>
                        {
                            signedInUser != '' &&
                            <span>{signedInUser.le.wt.cu}</span>
                        }
                        </button>
                    </div>
                  }
                </>
              }
                </div>
            </div>
          <hr className="d-none"/>
          </div>

          <div className="col-md-12">
            {
              user.google == 0 ?
              <>
              {              
                signedInUser =='' ?
                  <>
                        <div className="row d-none">
                            
                            <div className="col-md-6">
                              <select style={{fontFamily:'Arial, FontAwesome'}}    className="form-select form-select-sm" aria-label="Default select example" onChange={(e) => set_local_drive(e.target.value)}>
                                <option value="local">Localmente  &#xf15c; &nbsp;</option>
                                <option value="" disabled>Google Drive &#xf3aa; &nbsp;</option>
                              </select>
                            </div>

                            <div className="col-md-6">
                                <button 
                                    className="btn btn-sm btn-dark "
                                    type="button"
                                    onClick={() => guardarCambios(catalogo.importacion)}
                                    disabled={loading}
                                >
                                  {
                                      <span>Guardar archivo</span>
                                  }              
                              </button>
                            </div>
                      </div>
                  </>
                  :
                  <>
                    <div className="row d-none">
                          
                          <div className="col-md-6">
                            <select style={{fontFamily:'Arial, FontAwesome'}}    className="form-select form-select-sm" aria-label="Default select example" onChange={(e) => set_local_drive(e.target.value)}>
                              <option value="local">Localmente  &#xf15c; &nbsp;</option>
                              <option value="drive">Google Drive  &#xf3aa; &nbsp;</option>
                            </select>
                          </div>

                          <div className="col-md-6">
                              <button 
                                  className="btn btn-sm btn-dark "
                                  type="button"
                                  onClick={() => guardarCambios(catalogo.importacion)}
                                  disabled={loading}
                              >
                                {
                                    <span>Guardar archivo</span>
                                }              
                            </button>
                          </div>

                    </div>
                  </>
                  }
                </>
                :
                <>
                  <div className="row d-none">
                      
                      <div className="col-md-6 mb-3">
                        <select 
                              className="form-select form-select-sm" 
                              aria-label="Default select example" 
                              onChange={(e) => set_local_drive(e.target.value)}
                              style={{fontFamily:'Arial, FontAwesome'}}      
                        >
                          <option value="local">Localmente  &#xf15c; &nbsp;</option>
                          {/* <option value="local">Localmente &#xf3aa; &nbsp;</option> */}
                          <option value="drive">Google Drive &#xf3aa; &nbsp;</option>
                        </select>
                      </div>

                      <div className="col-md-6 mb-3">
                          <button 
                              className="btn btn-sm btn-dark "
                              type="button"
                              onClick={() => guardarCambios(catalogo.importacion)}
                              disabled={loading}
                          >
                            {
                                <span>Guardar archivo</span>
                            }              
                        </button>
                      </div>

                  </div>
                </>
            }
          </div>

        </div>
        {/* Row */}
        <hr className="d-none"/>

        <div className="row align-items-center mb-1">
            <div className="col-md-4 d-none">
              <div className="row align-items-center">
                <label className="col-sm-4 col-form-label fw-bold d-none">Selecciona tu etiqueta: </label>
                <div className=" col-sm-12">
                  {
                    etiquetas !== undefined  &&
                    <>
                      <input 
                          className="form-control form-control-sm d-none" 
                          ref={etiqueta}
                          value={etiquetas.id}
                      />     
                      <span>
                        <b>Etiqueta: </b>
                        {etiquetas.nombre}
                      </span>                       
                    </>
                  }

                </div>
              </div>
            </div>
            
            <div className="col-md-6">
                  <button 
                      className="btn btn-lg btn-success fw-bold text-uppercase w-100"
                      type="button"
                      onClick={() => enviarCatalogo()}
                      disabled={loading}
                      ref={boton_imprimir}
                      >Imprimir
                  </button>
            </div>

            <div className="col-md-6">
                <div className="row justify-content-end align-items-center">
                    <label className="col-sm-6 col-form-label fw-bold">Cambiar cantidad de todas las filas: </label>
                    <div className="col-md-6">
                        {/* <div className="input-group"> */}
                            <input 
                                className="form-control form-control-lg" 
                                type="text" 
                                placeholder="Cantidad" 
                                aria-label="default input example"
                                pattern="[0-9]*"
                                inputMode="numeric"
                                value={modCantidad}
                                onChange={(e => onlyNumbers(e.target.value))}
                                // onBlur={() => setCantidades()}
                                onClick={(e) => e.target.select()}
                                style={{backgroundColor: '#f6f7c3'}}
                            />
                        {/* </div> */}
                    </div>
                </div>
            </div>

        </div>
        {
        (plus && _plus) ?
          <Styles>
            {/* <button onClick={resetData}>Reset Data</button> */}
            <Table
              columns={columns}
              data={data}
              updateMyData={updateMyData}
              skipReset={skipResetRef.current}
              setSelectedRows={setSelectedRows}
              setSelectedRowsId={setSelectedRowsId}
              headers={headers}
              _cantidades={_cantidades}
              _setCantidades={_setCantidades}
              _cantidadesFilter={_cantidadesFilter}
              _setCantidadesFilter={_setCantidadesFilter}
              mas={mas}
              setmas={setmas}
              boton_imprimir={boton_imprimir}
              validacionImpresion={validacionImpresion}
              setValidacionImpresion={setValidacionImpresion}
            />
          </Styles>
          :
          <div className="d-flex align-items-center justify-content-center">
              <div style={{marginRight: '1rem'}}>
                  <span>Cargando ...</span>
              </div>
              <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
              </div>
          </div>
      }
      </div>
    </>
  );
}


export default TableEditablePage;
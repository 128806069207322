import React, {useState, useEffect, useRef} from 'react'
// import { useHistory } from "react-router-dom";
import {
  NavLink, Link
} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faTrash, faCog, faPlus, faMinus, faEdit, faClone, faListOl } from '@fortawesome/free-solid-svg-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
/*Globales*/
import {urlApi} from '../../global/Global';
import ComprobarVista from '../vistaUsuarioContext/ComprobarVista';
import ModalCatalogos from '../etiquetas/ModalCatalogos';

const EtiquetasUsuario = (props) => {

    const [etiquetas, setetiquetas]   = useState([]);
    const [idEtiqueta, setidEtiqueta] = useState(props.match.params.id);
    const [loading, setLoading]       = useState(false);
    const [url, seturl]               = useState('');
    const [usuario, setusuario]       = useState('');
    const MySwal                      = withReactContent(Swal);
    const [eti, seteti]               = useState();
    const btn_ref                     = useRef();
    const [catalogos, setcatalogos]   = useState([]);
    const btn_close                   = useRef();
    const [_btn_ref, _set_btn_ref] = useState(false);

    useEffect(() => {      

          const getEtiquetas = async () => { 

              const log = await AsyncStorage.getItem('@login');
              const log_json = JSON.parse(log);
              const headers = {
                  'Authorization': log_json.token_type+' '+log_json.access_token,
                  'Content-Type': 'application/json',
                  'X-Requested-With': 'XMLHttpRequest',
              }
              await axios.get(urlApi+'usuarios/etiquetas/'+idEtiqueta, {headers: headers})
                        .then((response) => {
                            //Actualiza estados del formulario
                            setetiquetas(response.data.etiquetas);                    
                            seturl(response.data.url);                    
                            setusuario(response.data.usuario);                    
                            console.log(response.data);
                        })
                        .catch((error) => {
                            console.log(error);
                        }).then(() =>{
                          setLoading(false);
                        });     
          }

            const getCatalogos = async (params) => {
                const log = await AsyncStorage.getItem('@login');
                const log_json = JSON.parse(log);
                const headers = {
                    'Authorization': log_json.token_type+' '+log_json.access_token,
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                }
                await axios.get(urlApi+'usuarios/catalogos/'+idEtiqueta, {headers: headers})
                    .then((response) => {
                        //Actualiza estados del formulario
                        setcatalogos(response.data.catalogos);
                        // setLoading(false);
                        console.log(response.data);
                    })
                    .catch((error) => {
                        
                        console.log(error);
                    });  
            }

          getEtiquetas();
          getCatalogos();

    }, [])
    
/***********************************************************************************************/

const deleteEtiqueta = async (params) => {
  // console.log(params)
  const log = await AsyncStorage.getItem('@login');
  const log_json = JSON.parse(log);
  const headers = {
      'Authorization': log_json.token_type+' '+log_json.access_token,
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
  }
  const send_data = {
      id: params,
      usuario: usuario
  }
  Swal.fire({
      title: '¿Eliminar Plantilla?',
      text: "Esta acción no se puede revertir",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, borrar',
      cancelButtonText: 'Cancelar'
    }).then(async (result) => {
      if (result.isConfirmed) {
          await axios({
              method: 'delete',
              url: urlApi+'etiquetas/destroy',
              data: send_data,
              headers: headers
            }).then((response) => {
                if(response.data.respuesta === 'correcto'){
                  MySwal.fire({
                      icon: 'success',
                      title: 'Correcto',
                      text: 'Se elimino la etiqueta correctamente',
                  })
                  setetiquetas(response.data.etiquetas);
                }else{
                  MySwal.fire({
                      icon: 'error',
                      title: 'error',
                      text: 'Ocurrio un error en la eliminacion',
                  })
                }
                  // console.log(response.data)
              }).catch(error => { 
                  MySwal.fire({
                      icon: 'error',
                      title: 'error',
                      text: 'Ocurrio un error en la eliminacion',
                  })           
                  console.log(error);
            });
      }
    })        
}

/***********************************************************************************************/

    const clonarEtiqueta = (e) => { 
        
      console.log(e);

      Swal.fire({
          title: '¿Clonar etiqueta?',
          icon: 'info',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si, clonar',
          cancelButtonText: 'Cancelar'
        }).then(async (result) => {
          if (result.isConfirmed) {

              const log = await AsyncStorage.getItem('@login');
              const log_json = JSON.parse(log);
              const headers = {
                  'Authorization': log_json.token_type+' '+log_json.access_token,
                  'Content-Type': 'application/json',
                  'X-Requested-With': 'XMLHttpRequest',
              }

              const send_data = {
                  etiqueta: e,
                  usuario: usuario.id
              };

              await axios.post(urlApi+'etiquetas/clonar', send_data, {headers: headers})
                  .then((response) => {
                      //Actualiza estados del formulario
                      if(response.data.respuesta == 'correcto'){
                          setetiquetas(response.data.etiquetas)
                          Swal.fire({
                              icon: 'success',
                              title: response.data.mensaje,
                              toast: true,
                              position: 'top-end',
                              showConfirmButton: false,
                              timer: 3000
                          })                        
                          console.log(response.data);
                      }else{
                          Swal.fire({
                              icon: 'error',
                              title: response.data.respuesta,
                              text: response.data.mensaje,
                              
                          })
                      }
                      console.log(response.data);
                  })
                  .catch((error) => {
                      Swal.fire({
                          icon: 'error',
                          title: error
                      })
                      console.log(error);
                  });  
          }
        })

    }
/***********************************************************************************************/

    const mostrarCatalogos = (e) => { 
        console.log(e);
        seteti(e);
        // btn_ref.current.click();
        _set_btn_ref(true);
    }
/***********************************************************************************************/

    const asignarCatalogo = async (e, catalogo) => { 
        console.log(e);        

        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }

      
        const send_data = {
            etiqueta : eti.id,
            catalogo : catalogo,
            cabeceras: e,
            usuario : idEtiqueta
        };


        await axios.post(urlApi+'etiquetas/asignar-catalogo', send_data, {headers: headers})
            .then((response) => {
                //Actualiza estados del formulario
                if(response.data.respuesta == 'correcto'){
                    setetiquetas(response.data.etiquetas)
                    Swal.fire({
                        icon: 'success',                            
                        title: response.data.mensaje,
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    })                        
                    console.log(response.data);
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: response.data.respuesta,
                        text: response.data.mensaje
                    })
                }
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: error
                })
                console.log(error);
            });  

    }

/***********************************************************************************************/
    const quitarCatalogo = async (e) => { 
        console.log(e);

        Swal.fire({
        title: '¿Quitar catalogo?',
        text: "Esto no elimina el catalogo, pero si lo desvincula de la etiqueta",
        icon: 'info',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, quitar',
        cancelButtonText: 'Cancelar'
        }).then(async (result) => {
        if (result.isConfirmed) {

            const log = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            }

            const send_data = {
                etiqueta: e,
                usuario : idEtiqueta
            };

            await axios.post(urlApi+'etiquetas/quitar-catalogo', send_data, {headers: headers})
                .then((response) => {
                    //Actualiza estados del formulario
                    if(response.data.respuesta == 'correcto'){
                        setetiquetas(response.data.etiquetas)
                        Swal.fire({
                            icon: 'success',
                            title: response.data.mensaje,
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000
                        })                        
                        console.log(response.data);
                    }else{
                        Swal.fire({
                            icon: 'error',
                            title: response.data.respuesta,
                            text: response.data.mensaje,
                            
                        })
                    }
                    console.log(response.data);
                })
                .catch((error) => {
                    Swal.fire({
                        icon: 'error',
                        title: error
                    })
                    console.log(error);
                });  


            btn_close.current.click();
        }
        })

    }

    //Imprimir etiquetas********************************************************************************//
    const imprimirEtiquetas = async (etiqueta) => {
            
        // return;
        setLoading(true);
        // const x      = etiquetas.find(en => en.id == etiqueta.id);
        let lista   = '';
        let conteo  = 0;
        let pattern = 'pattern';
        // console.log(x);
        console.log(etiqueta);
        // return;
        etiqueta.configuraciones.map(async (e,i) => {
            console.log(e);
            if(e.types.front_back == 'front'){
                if(e.type_config_id == 4){
                    pattern='pattern="[0-9]*" inputmode="numeric"';
                }else{
                    pattern = 'pattern=""'
                }
                    lista+=`
                        <div class="col-md-12 mb-2">
                            <label class="fw-bold mb-1">${e.valor}</label>
                            <input ${pattern} type="${e.types.type}" name="${e.variable}" required type="text" class="form-control text-center blc-fl" id="swal-input${i}" placeholder="${e.variable}">
                        </div>`;
            conteo++;
            }
        });
        //Cantidades
        // lista+=`
        //     <div class="col-md-12 mb-2">
        //         <label class="fw-bold mb-1">Cantidad a Imprimir</label>
        //         <input name="_cantidad" required type="text" class="form-control text-center blc-fl" aria-describedby="button-addonalue="1" id="swal-input${conteo}" placeholder="Cantidad">
        //     </div>`;
        //Cantidades

        // return;
        let jwal = '';
        let preguntas = {};
        if(conteo > 0){
        jwal = await MySwal.fire({
            html: `<div class="row">
            <div class="col">
                <span class="errores text-danger text-uppercase fw-bold mb-3 d-none">
                *Llene los campos
                </span>
            </div>  
            </div>  
            <div class="row">
            ${lista}
            </div>`,
            allowOutsideClick: true,
            // showCancelButton: true,        
            confirmButtonText: 'Imprimir',       
            showCloseButton: true,
            preConfirm: () => {

            const x = Array.from(document.querySelectorAll('.blc-fl'));
            x.forEach(e =>{
                if(e.value.trim() == ''){
                    if(document.querySelector('.errores').classList.contains('d-none')){
                        document.querySelector('.errores').classList.remove('d-none')
                    }
                    MySwal.showValidationMessage('Llene los campos')
                    // return false;
                }
            })
            return x;
            // return document.querySelectorAll('.blc-fl');
            }
        });

        // console.log(JSON.parse(jwal.value));
        if(!jwal.isConfirmed){
            setLoading(false);
            return false;
        } 
        console.log(jwal);
        // console.log(jwal.value);
        // return;
        jwal.value.map((e,i)=>{
            preguntas[e.name] = e.value;
            // console.log(e.name+' - '+e.value);
        })
        
        }else{
            preguntas = '';
        } 

        //Obtenemos fechas
        const d = new Date();
        // const anio = d.getDate+'/'+(d.getMonth()+1)+'/'+d.getFullYear()
        const anio = d.toLocaleDateString('default');
        const hora = d.toLocaleTimeString('default', {
            // en-US can be set to 'default' to use user's browser settings
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });

        const datos_enviar = {
            etiqueta: etiqueta.id,
            // cantidades: cantidad_send,
            preguntas: preguntas,
            anio     : anio,
            hora     : hora
        };

        console.log(preguntas);
        console.log(datos_enviar);

        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
        await axios({
            url: urlApi+'etiquetas/print',
            method: 'POST',
            data: datos_enviar,
            headers: headers,
            // responseType: 'blob', // important
        }).then(function (response) {
            console.log(response.data);
            // return;
            const currentdate = new Date();
            const datetime = currentdate.getDate() + "/"
            + (currentdate.getMonth()+1)  + "/" 
            + currentdate.getFullYear() + " - "  
            + currentdate.getHours() + ":"  
            + currentdate.getMinutes() + ":" 
            + currentdate.getSeconds();
        
            const url       = window.URL.createObjectURL(new Blob([response.data.mensaje]));
            const link      = document.createElement('a');
                link.href = url;
            link.setAttribute('download', datetime+'.txt'); //or any other extension
            document.body.appendChild(link);
            link.click();
            console.log(response.data);

            MySwal.fire({
                icon: 'success',
                title: 'Etiqueta descargada',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            });
        })
        .catch(function (error) {
            console.log(error);
            // return;
            MySwal.fire({
                icon: 'error',
                title: 'Ocurrio un error',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            });
            

        }).then(()=>{
            setLoading(false);
        });
    }

/**************************************************************************************************/

  return (
    <>
        {/* Modal Catalogos  */}
        {
            _btn_ref &&
                <ModalCatalogos
                    btn_ref={btn_ref}
                    catalogos={catalogos}
                    asignarCatalogo={asignarCatalogo}
                    btn_close={btn_close}
                    eti={eti}
                    _set_btn_ref={_set_btn_ref}
                />

        }
        {/* Modal Catalogos  */}

        {
        !loading ?
        <>
             {/* <!-- Button trigger modal --> */}
            <button 
                ref={btn_ref}
                type="button" 
                className="btn btn-primary d-none" 
                data-bs-toggle="modal" 
                data-bs-target="#staticBackdrop"
            >
                Launch static backdrop modal
            </button>

            {/* <!-- Modal --> */}
            <div style={{zIndex: 10000}} className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title fw-bold " id="staticBackdropLabel">Catalogos</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                    <div className="modal-body">
                        <ul className="list-group list-group-flush list-group-numbered">
                            {
                                catalogos.length > 0 &&
                                catalogos.map((e,i) =>{
                                    return <li className="list-group-item" key={`list-${i}`}>
                                                <a href="#" 
                                                onClick={() => asignarCatalogo(e.id)} 
                                                >
                                                    {e.nombre}
                                                </a>
                                            </li>
                                })

                            }
                        </ul>
                    </div>
                        <div className="modal-footer">
                            <button 
                                type="button" 
                                className="btn btn-secondary" 
                                data-bs-dismiss="modal"
                                ref={btn_close}
                            >Cerrar</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* Fin de modal */}

          <div className="bg-white px-2 p-1 rounded border">
            <h4 className='m-0'>Etiquetas del usuario: <b>{usuario.name}</b></h4>
          </div> 
          <hr/>
            <div className="row row-cols-1 row-cols-md-3 g-4 animate__animated animate__fadeIn">
                {
                    etiquetas.length > 0 &&
                        etiquetas.map((e, i) =>{
                            return <div className="col" key={`etiquetas-${i}`}>
                                <div className="card h-100 shadow border border-3 rounded">
                                    <div className='p-1 shadow-sm'>

                                        <div className='row justify-content-end text-end'>
                                            <div className='col-md-5 px-3'>

                                              
                                                    
                                                <div className={`dropdown ${loading && 'disabled'}`}>
                                                    <a href="#" className="btn btn-secondary shadow w-auto" type="button" 
                                                        id="dropdownMenuButton1" 
                                                        data-bs-toggle="dropdown" 
                                                        aria-expanded="false"
                                                        style={{borderRadius: '20px'}}
                                                    >
                                                        <span className='d-none'>Opciones </span>
                                                        <FontAwesomeIcon icon={faCog}/>
                                                    </a>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                        
                                                        <li>
                                                        {
                                                            e.catalogo_id == null ?
                                                                <a 
                                                                    className="dropdown-item" 
                                                                    href="#" 
                                                                    onClick={() => mostrarCatalogos(e)}
                                                                >
                                                                    <div className='d-flex align-items-center'>
                                                                        <label className='col-2'><FontAwesomeIcon icon={faPlus} /> </label>                                                                                        
                                                                        <div className='col-md-10'>
                                                                            <span>Agregar catalogo </span>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            :
                                                                <a 
                                                                    className="dropdown-item" 
                                                                    href="#" 
                                                                    onClick={() => quitarCatalogo(e.id)}
                                                                >   
                                                                    <div className='d-flex align-items-center'>
                                                                        <label className='col-2'><FontAwesomeIcon icon={faMinus} /> </label>                                                                                        
                                                                        <div className='col-10'>
                                                                            <span >Quitar catalogo </span>
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                        }
                                                        </li>
                                                        <li>
                                                            
                                                            <Link className="dropdown-item"
                                                                to={
                                                                    {  
                                                                        pathname:"/edit-label/"+e.id,
                                                                        state:{etiqueta: e}
                                                                    }
                                                                }
                                                            >
                                                                <div className='d-flex align-items-center'>
                                                                    <label className='col-2'><FontAwesomeIcon icon={faEdit} /> </label>                                                                                        
                                                                    <div className='col-10'>
                                                                        <span>Editar etiqueta </span>
                                                                    </div>
                                                                </div>
                                                                
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            
                                                            <a className="dropdown-item" href='#' 
                                                              onClick={() => clonarEtiqueta(e.id)}
                                                            >
                                                                <div className='d-flex align-items-center'>
                                                                    <label className='col-2'><FontAwesomeIcon icon={faClone} /> </label>                                                                                        
                                                                    <div className='col-10'>
                                                                        <span>Clonar etiqueta </span>
                                                                    </div>
                                                                </div>
                                                                
                                                            </a>
                                                        </li>

                                                        <li>                                                                            
                                                            <Link className="dropdown-item"
                                                                to={
                                                                    {  
                                                                        pathname:"/label-configuration/"+e.id,
                                                                        state:{etiqueta: e}
                                                                    }
                                                                }
                                                            >
                                                                <div className='d-flex align-items-center'>
                                                                    <label className='col-2'><FontAwesomeIcon icon={faListOl} /> </label>                                                                                        
                                                                    <div className='col-10'>
                                                                        <span>Configuración </span>
                                                                    </div>
                                                                </div>
                                                                
                                                            </Link>
                                                        </li>

                                                        <li>
                                                            <hr className='dropdown-divider'/>                                                        
                                                        </li>
                                                        
                                                        <li>
                                                            <a className="dropdown-item" href="#" 
                                                              onClick={()=> deleteEtiqueta(e.id)}
                                                              >
                                                                <div className='d-flex align-items-center'>
                                                                    <label className='col-2'><FontAwesomeIcon icon={faTrash} /> </label>                                                                                        
                                                                    <div className='col-10'>
                                                                        <span>Eliminar </span> 
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                </div>
                                            
                                            </div>
                                        </div>

                                        <div className='p-1 text-center border rounded bg-white'>
                                            <img src={url+'/'+e.image} className="card-img-top w-75" alt={e.nombre} />
                                        </div>
                                    </div>

                                    <div className="card-body">
                                        <div className='row'>
                                                <h5 className="card-title fw-bold">{e.nombre}</h5>
                                                <p className="card-text mb-0">Lenguaje: {e.language.name}</p>
                                                {
                                                    e.catalogo_id != null &&
                                                    <p className="card-text text-primary fw-bold">Tiene catalogo</p>
                                                }
                                        </div>
                                    </div>

                                    <div className='p-3 row d-'>                                                           

                                        {
                                            e.catalogo_id == null ?
                                            <>
                                            <button 
                                                disabled={loading}
                                                onClick={() => imprimirEtiquetas(e)}
                                                className='btn btn-success btn-xl fw-bold text-uppercase border shadow mb-2 fs-3 text'
                                            > Test</button> 

                                            </>
                                            :            
                                            <>
                                            <NavLink 
                                                disabled={loading}
                                                to={`/vista/${e.catalogo_id}/${e.id}`}
                                                className={`${loading ? 'disabled btn btn-success btn-xl fw-bold text-uppercase border shadow mb-2 fs-3 text': 'btn btn-success btn-xl fw-bold text-uppercase border shadow mb-2 fs-3 text'}`}
                                            > Test</NavLink> 
                                            {/* <Link 
                                                disabled={loading}
                                                to={
                                                    {  
                                                        pathname:"/vista",
                                                        state:{catalogo: e.catalogo_id, etiqueta: e.id, nombre_etiqueta: e.nombre }
                                                    }
                                                }
                                                className='btn btn-success btn-xl fw-bold text-uppercase border shadow mb-2 fs-3 text'
                                            > Test</Link>  */}
                                            </>                                       
                                        }                                                                                                     
                                    </div>
                                </div>
                            </div>
                        })
                }
            </div>                            
        </>
        :
        <div className="row">
            <div className="col-md-12 text-center">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
        }
    </>
  )
}

export default EtiquetasUsuario
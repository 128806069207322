import React, {useState, useRef, useEffect} from 'react';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import axios from 'axios';
/*Globales*/
import {urlApi} from '../../global/Global';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';

const Replaces = () => {

    const MySwal = withReactContent(Swal);

    const [arr, setarr] = useState([]);

    const [languages, setLanguages] = useState([]);
    const [lang, setlang] = useState(1);
    const [loading, setLoading] = useState(false);

/*********************************************************************************************/

    useEffect(() => {

        const getLanguages = async (params) => {
            const log = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            }
            await axios.get(urlApi+'languages/index', {headers: headers})
                        .then((response) => {
                            //Actualiza estados del formulario
                            setLanguages(response.data);
                                if (response.data[0].replaces !=='') {
                                    setarr(response.data[0].replaces.map((e,i) =>{
                                           return {[`name`]:e.find}
                                    }));
                                    const x = response.data[0].replaces.map((e,i) =>{
                                        return {[`name`]:e.find}
                                    });
                                    console.log(x);
                                }
                            // setLoading(false);
                            console.log(response.data);
                            console.log(response.data);
                        })
                        .catch((error) => {
                            console.log(error);
                        });     
        }
        
        getLanguages();
        
    }, [])

/*********************************************************************************************/

    const changeLan = (params) => {
        const valores = JSON.parse(params);
        setlang(valores.value);
        // console.log(valores);
        setarr(languages[valores.index].replaces.map((e,i) =>{
            return {[`name`]:e.find}
        }));
    }
    

/*********************************************************************************************/

    const enviarDatos = async (params) => {
        setLoading(true);
        console.log(params);
        params.language = lang;
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
        await axios.post(urlApi+'languages/update', params, {headers: headers})
                    .then((response) => {
                        if(response.data.respuesta === 'correcto'){
                            setLanguages(response.data.languages);
                            MySwal.fire({
                                icon: 'success',
                                title: 'Correcto',
                                text: 'Actualizado correctamente',
                            })
                          }else{
                            MySwal.fire({
                                icon: 'error',
                                title: 'error',
                                text: 'Ocurrio un error en la actualizacion',
                            })
                          }
                    })
                    .catch((error) => {
                        MySwal.fire({
                            icon: 'error',
                            title: 'error',
                            text: 'Ocurrio un error en la actualizacion -> 2',
                        })
                        console.log(error);
                    }).then(() =>{
                        setLoading(false);
                    });    
    }
    

//Validador de datos************************************************************************

    const formSchema = Yup.object().shape({
        lista: Yup
            .array()
            .of(
                Yup.object().shape({
                    name: Yup.string().required("Campo requerido")
        })
        ).required('debes tener al menos un prefijo')
         .min(1, 'Debe tener al menos un elemento en la lista'),
    });

/*********************************************************************************************/
    
    return (
        
        <div className="row justify-content-center animate__animated animate__fadeIn">
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header bg-card-header">
                        <span className='text-white fw-bold'>Reemplazos de prefijos</span> 
                    </div>
                    <div className="card-body">
                        <div className="row">   
                            <div className="col-md-12 mb-3">
                                <label htmlFor="language" className="form-label fw-bold">Seleccionar lenguaje</label>
                                <select 
                                    className="form-select" 
                                    name="language"
                                    onChange={(e) => changeLan(e.target.value)}
                                    defaultValue={1}
                                    disabled={loading}
                                >
                                    {
                                        languages.map((element,index) => {
                                            return <option
                                                        value={
                                                            JSON.stringify({
                                                                value: element.id,
                                                                index: index
                                                            })
                                                        }
                                                        index={index}
                                                        key={index}
                                                        >{element.name}
                                                    </option>
                                        })
                                    }
                                </select>
                                <div
                                    name='language'
                                    className='field-error text-danger d-none'
                                >
                                    
                                </div>
                            </div>
                            <Formik
                                enableReinitialize
                                initialValues={{
                                    lista: arr,
                                }}
                                validationSchema={formSchema}
                                onSubmit={(values, actions) => enviarDatos(values, actions)}
                            >
                            {({ values, errors, touched, handleReset }) => {
                                return <Form>                                                         
                                        <div className="mb-3">
                                            <FieldArray
                                                name="lista"
                                                render={({insert, remove, push}) => ( 
                                                <>
                                                    <div className="row">
                                                            <label className="col-form-label col-md-10 fw-bold">Listado de prefijos</label>
                                                            <div className="col-sm-2">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-secondary mb-3 form-control-plaintext"
                                                                    onClick={() => push({ name: "" })}
                                                                    disabled={loading}
                                                                >+</button>
                                                            </div>
                                                    </div>
                                                    {values.lista.map((element, index) => (
                                                        <div key={index}>
                                                            <div className="input-group mb-3">
                                                                <Field disabled={loading} type="text" className="form-control" name={`lista.${index}.name`} placeholder={`${index}`}/>
                                                                <button className="btn btn-dark" type="button" onClick={() => remove(index)}>-</button>
                                                            </div>
                                                        <ErrorMessage
                                                            name={`lista.${index}.name`}
                                                            component='div'
                                                            className='field-error text-danger'
                                                        />
                                                    </div>
                                                    ))}
                                                    
                                                </>
                                                )}
                                            />
                                        </div>
                                        {/* FIN Datos de usuario */}
                                        <hr/>
                                        <div className="my-4">
                                            <button type="submit" 
                                                    disabled={loading}
                                                    className="btn btn-dark w-100"
                                                    // onClick={sendDataPaypal}
                                            >Añadir prefijos</button>
                                        </div>
                                        {
                                            loading &&
                                            <div className="w-100 text-center">
                                                <div className="spinner-border" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        }
                                </Form>      
                             
                        }}
                        </Formik>                  
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Replaces

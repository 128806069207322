import React, {Fragment, useEffect, useState, useRef} from 'react';
import axios from 'axios';
/*Globales*/
import {urlApi, urlImages} from '../../global/Global';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faDownload, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import ComprobarVista from '../vistaUsuarioContext/ComprobarVista';
import Swal from 'sweetalert2';
import { unauthenticated } from '../../helpers/Helper';
import { useTranslation } from 'react-i18next';
import { Formik, Form, ErrorMessage, useFormikContext, Field } from 'formik';
import * as Yup from 'yup';
import Checkbox from '../layouts/Checkbox';
import ReactTooltip from 'react-tooltip';
import { t } from 'i18next';
import useSound from 'use-sound';
import downloadSfx from '../../sounds/download.wav';
import { useHistory } from "react-router-dom";
import funciones from '../utilidades/funciones';

const Catalogo = ({user, setdownloadPlantilla, setdownloadPlantillaCount, downloadPlantillaCount}) => {

    const [ftps, setftps]       = useState([]);
    const [url, seturl]         = useState('');
    const [loading, setLoading] = useState(false);

    // const [filtros, setfiltros] = useState([]);
    const [secondCharge, setsecondCharge] = useState(false);
    const [filtros, setfiltros]           = useState({});
    const [codigo, setcodigo]             = useState('');
    
    const { t }                   = useTranslation();
    const [database, setdatabase] = useState([]);
    const [basic, setbasic]       = useState([1])

    const mostrarTodo     = useRef(null);
    const sendFilters     = useRef(null);
    const btn_filtros     = useRef(null);
    const btnCleanFilters = useRef(null);

    const [play]  = useSound(downloadSfx);
    let   history = useHistory();

    const [kk, setkk] = useState(0)
    useEffect(() => {       
        window.scrollTo(0, 0)   
        const getFtp = async (params) => {
            setLoading(true);
            const log = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            }
            await axios.get(urlApi+'etiquetas/connect-ftp', {headers: headers})
                        .then((response) => {
                            //Actualiza estados del formulario
                            // setLoading(false);
                            setftps(response.data.etiquetas);
                            seturl(response.data.url);
                            setsecondCharge(true);
                            console.log(response.data);
                        })
                        .catch((error) => {
                            unauthenticated(error);
                            console.log(error);
                        }).then(()=>{
                            setLoading(false);
                        });     
        }

        const getFiltros = async() => {

            const log = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            }
            await axios.get(urlApi+'etiquetas/filtros', {headers: headers})
                        .then((response) => {
                            //Actualiza estados del formulario
                            // setLoading(false);
                            setfiltros(response.data);     
                            setdatabase([{"id": 1, "name": "Con base de datos"}, {"id": 0, "name": "Sin base de datos"}]);                   
                            console.log(response.data);
                        })
                        .catch((error) => {
                            console.log(error);
                        });        

        }
        
        getFtp();
        getFiltros();

    }, []); // Use effect
    
//Descargar etiqueta a usuario----------------------------------------------------------------
    const handleClick = async (e) => { 
            setLoading(true);
            const barra = document.querySelector('#nav-bar');
            if(!barra.classList.contains('show')){
                document.querySelector('#header-toggle').click();
            }
            const log = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            }

            const send_data = {
                etiqueta: e.id
            }

            await axios.post(urlApi+'etiquetas/download', send_data,{headers: headers})
                        .then((response) => {
                            //Descarga la etiqueta para el usuario
                            Swal.fire({
                                icon: 'success',
                                title : t("catalogs.go_to_my_labels"),
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                                didOpen: (toast) => {
                                    toast.addEventListener('click', () =>{
                                        history.push('/see-labels')
                                    })
                                }
                            });
                            // setftps(response.data.etiquetas);
                            play();
                            setdownloadPlantilla(true);
                            setdownloadPlantillaCount(downloadPlantillaCount+1);
                            console.log(response.data);
                        })
                        .catch((error) => {
                            console.log(error);
                        }).then(() =>{
                            setLoading(false);
                        });     
    }
//---------------------------------------------------------------------------------------------

    const getFtpFilters = async (params) => {
        setLoading(true);
        params.codigo = codigo;
        console.log(params);
        // return;

        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
        await axios.post(urlApi+'etiquetas/connect-ftp-filters', params, {headers: headers})
                    .then((response) => {
                        //Actualiza estados del formulario
                        // setLoading(false);
                        console.log(response.data);
                        setftps(response.data.etiquetas);                       
                        // return;
                    })
                    .catch((error) => {
                        console.log(error);
                    }).then(() =>{
                        setLoading(false);
                    });            

    }

//---------------------------------------------------------------------------------------------
    const AutoSubmitToken = () => {
        // Grab values and submitForm from context
        const { values, submitForm } = useFormikContext();
        useEffect(() => {
            // Submit the form imperatively as an effect as soon as form values.token are 6 digits long
                // console.log(values)

            // sendFilters.current.click();
            // submitForm()
            // getFtpFilters(values);
        }, [values]);




        return null;
    };

//---------------------------------------------------------------------------------------------

    const _mostrarTodo = async (resetForm) => {
        setcodigo('');
        resetearForm(resetForm)
    }

//---------------------------------------------------------------------------------------------

    const resetearForm = (resetForm) => { 
        resetForm();
        setbasic([]);   
        sendFilters.current.click();
    }

//---------------------------------------------------------------------------------------------

     //Validador de datos
     const formSchema = Yup.object().shape({
        material: Yup.array(),
        medidas : Yup.array(),
        tags    : Yup.array(),
        database: Yup.array(),
        lenguaje: Yup.array(),
        basica  : Yup.array(),
    });

//---------------------------------------------------------------------------------------------

  return (
    <>
     {/* < ComprobarVista/> */}

        <div className="card mb-3 animate__animated animate__fadeIn">
            <div className="card-header">
                <h5 className='text-dark fw-bold text-uppercase rounded m-0'>{t("catalog.templates")}</h5>

            </div>
            <div className="card-body bg-white">
                <div className='d-flex align-items-center mb-3'>
                    <h5 className="card-title text-success fw-bold mb-0">{t("catalog.filters")}</h5>
                    <button 
                        className="btn btn-sm btn-success" 
                        style={{marginLeft: '1rem'}} 
                        type="button" data-bs-toggle="collapse" 
                        data-bs-target="#collapseExample" 
                        aria-expanded="false" 
                        aria-controls="collapseExample"
                        ref={btn_filtros}
                        >
                        <i className="fas fa-chevron-down"></i>                
                    </button>
                    <div className='ms-3 border-start'>
                        <button className='btn btn-sm btn-outline-secondary' type='button' onClick={() => mostrarTodo.current.click()}>
                            <span>
                                {t("utilidades.view_all")}
                            </span>
                        </button>
                    </div>    
                    <div className='col-md-4 ms-3' >
                        <div class="input-group">

                            <input 
                                aria-label="Ingresa el Código" 
                                aria-describedby="button-addon2"
                                type="text" 
                                className="form-control form-control-sm" 
                                name='codigo' 
                                placeholder="Ingresa el Código" 
                                disabled={loading}
                                value={codigo}
                                onChange={(e) => setcodigo(e.target.value)}
                                onKeyDown={e => {
                                    console.log(e.key)
                                    if(e.key === 'Enter'){
                                        sendFilters.current.click();
                                    }
                                }}
                            />
                            <button class="btn btn-secondary btn-sm" type="button" id="button-addon2" onClick={() => {
                                btnCleanFilters.current.click();
                                sendFilters.current.click();
                            }}
                            >Buscar</button>
                    </div>    
                    </div>    
                </div>

                <Formik
                    enableReinitialize
                    initialValues={{
                        "material": [],
                        "medidas" : [],
                        "tags"    : [],
                        "database": [],
                        "lenguaje": [],
                        "basica"  : basic,
                    }}
                    validationSchema={formSchema}
                    onSubmit={(values, actions) => getFtpFilters(values, actions)}
                    render={({ values, errors, touched, handleReset, resetForm }) => {
                    return (
                        <Form
                            className="collapse"
                            id="collapseExample"
                            onChange={() => sendFilters.current.click()}
                        >
                        <AutoSubmitToken/>
                            <button className='d-none' ref={mostrarTodo} type='submit' onClick={() => _mostrarTodo(resetForm, values)}></button>
                            <div className="row border-bottom pb-3">                                
                                <div className="col-md-4">
                                    <div>                                    
                                        <label htmlFor="material" className="form-label fw-bold">Material</label>
                                        {
                                            Object.keys(filtros).length > 0 &&
                                            filtros.materiales.map((element,index) => {
                                                return <div  key={"a"+index}>
                                                        <div className="form-check">
                                                            <Checkbox 
                                                                name="material" 
                                                                className="form-check-input" 
                                                                value={element.id} 
                                                                id={"material"+index} 
                                                            />
                                                            <label className="form-check-label" htmlFor={"material"+index}>
                                                                {element.name}
                                                            </label>
                                                        </div>
                                                    </div>
                                            })
                                        }
                                        <ErrorMessage
                                            name='material'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>

                                    <div className='mt-3 pt-2 border-top'>
                                        <label htmlFor="database" className="form-label fw-bold">{t("catalog.data_base")}</label>
                                        {
                                            database.length > 0 &&
                                            database.map((element,index) => {
                                                return <div key={"e"+index}>
                                                        <div className="form-check">
                                                            <Checkbox 
                                                                name="database" 
                                                                className="form-check-input" 
                                                                value={element.id} 
                                                                id={"database"+index} 
                                                            />
                                                            <label className="form-check-label" htmlFor={"database"+index}>
                                                                {element.name}
                                                            </label>
                                                        </div>
                                                    </div>
                                            })
                                        }
                                        <ErrorMessage
                                            name='database'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>
                                </div>

                                <div className="col-md-4 border-change">
                                    <label htmlFor="medidas" className="form-label fw-bold">{t("catalog.sizes")}</label>
                                    <div className='row'>                                        
                                    
                                        {
                                            Object.keys(filtros).length > 0 &&
                                            filtros.medidas.map((element,index) => {
                                                return <div className='col-md-6' key={"f"+index}>
                                                        <div className="form-check d-flex align-items-center">
                                                            <Checkbox 
                                                                name="medidas" 
                                                                className="form-check-input" 
                                                                value={element.id} 
                                                                id={"medidas"+index} 
                                                            />
                                                            <label className="form-check-label" htmlFor={"medidas"+index}>
                                                                {element.name} {element.medida} <b>({funciones.inToMm(element)} mm)</b>
                                                            </label>
                                                        </div>
                                                    </div>
                                            })
                                        }
                                        
                                        <ErrorMessage
                                            name='medidas'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>
                                </div>

                                <div className="col-md-4">

                                    <div>                                    
                                        <label htmlFor="tags" className="form-label fw-bold">{t("catalog.propose")}</label>
                                        <div className='row'>                                        
                                        
                                            <div className='col-md-6'>
                                                <div className="form-check d-flex align-items-center">
                                                    <Checkbox 
                                                        name="basica" 
                                                        className="form-check-input" 
                                                        value={1} 
                                                        id="basica" 
                                                    />
                                                    <label className="form-check-label" htmlFor="basica">
                                                        Básica
                                                    </label>
                                                </div>
                                            </div>
                                            {
                                                Object.keys(filtros).length > 0 &&
                                                filtros.tags.map((element,index) => {
                                                    return <div className='col-md-6'  key={"b"+index}>
                                                            <div className="form-check d-flex align-items-center">
                                                                <Checkbox 
                                                                    name="tags" 
                                                                    className="form-check-input" 
                                                                    value={element.id} 
                                                                    id={"tags"+index} 
                                                                />
                                                                <label className="form-check-label" htmlFor={"tags"+index}>
                                                                    {element.name}
                                                                </label>
                                                            </div>
                                                        </div>
                                                })
                                            }
                                            
                                            <ErrorMessage
                                                name='tags'
                                                component='div'
                                                className='field-error text-danger'
                                            />
                                        </div>
                                    </div>

                                    <div className='mt-3 pt-2 border-top d-none'>                                    
                                        <label htmlFor="lenguaje" className="form-label fw-bold">{t("catalog.language")}</label>
                                        <div className='row'>                                                                                
                                            {
                                                Object.keys(filtros).length > 0 &&
                                                filtros.lenguajes.map((element,index) => {
                                                    return <div className='col-md-5' key={"d"+index}>
                                                            <div className="form-check d-flex align-items-center">
                                                                <Checkbox 
                                                                    name="lenguaje" 
                                                                    className="form-check-input " 
                                                                    value={element.id} 
                                                                    id={"lenguaje"+index} 
                                                                />
                                                                <label className="form-check-label wrap-text" htmlFor={"lenguaje"+index}>
                                                                    {element.name}
                                                                </label>
                                                            </div>
                                                        </div>
                                                })
                                            }
                                            
                                            <ErrorMessage
                                                name='lenguaje'
                                                component='div'
                                                className='field-error text-danger'
                                            />
                                        </div>
                                    </div>


                                </div>
                                
                            </div>
                            <div className='row mt-3'>
                                    <div className='col-md-4 mb-3'>
                                        <button
                                            type='button'
                                            className="btn btn-success fw-bold w-100"
                                            onClick={() =>{
                                                btn_filtros.current.click();
                                                window.scrollTo(0,0);
                                            }}
                                        >
                                            <i className="fas fa-chevron-up"></i>                
                                        </button>
                                        <button
                                            type='submit'
                                            className='btn btn-success btn-sm fw-bold w-100 d-none'
                                            ref={sendFilters}
                                        >
                                            {t("catalog.filter")}
                                        </button>
                                    </div>
                                    <div className='col-md-4 mb-3'>
                                        <button
                                            type='button'
                                            className='btn btn-outline-success btn-sm fw-bold w-100'
                                            onClick={() => resetearForm(resetForm)}  
                                            ref={btnCleanFilters}                                          
                                        >
                                            {t("catalog.clean")}
                                        </button>
                                    </div>
                            </div>
                        </Form>
                    )}}
                />
                
            </div>
        </div>

        <div className="row row-cols-1 row-cols-md-3 g-4 ">
            {
                ftps.length > 0 ?
                    ftps.map((e, i) =>{
                        return <div className="col animate__animated animate__fadeIn" key={"ftps"+i}>
                            <div className="card h-100 shadow rounded">
                                {/* <div className='p-1 shadow-sm text-center border rounded bg-white'>
                                    <img src={urlImages+'/'+e.image} className="card-img-top p-2 border-bottom" alt={e.nombre} />
                                </div> */}
                                <div className='p-1 text-center shadow-sm  rounded bg-transparent'>
                                    <img src={urlImages+e.image} className="card-img-top w-100 border border-1" alt={e.nombre} />
                                </div>
                                <div className="card-body">
                                    <h5 className="card-title fw-bold">
                                        {e.nombre} - {e.medida?.name}in ({e.medida != null && funciones.inToMm(e.medida) }mm)
                                        {/* {
                                            (e.medida != null) && 
                                            e.medida
                                        }  */}
                                    </h5>
                                    <p className="card-text mb-0">{e.descripcion}</p>
                                    <p className="text-muted mb-0">{t("see_labels.language")}: {e.language.name}</p>
                                    {
                                        (e.medida != null) && 
                                        <p className="text-muted mb-0">{t("see_labels.size")}: {e.medida.name} {e.medida.medida} ({funciones.inToMm(e.medida)}mm)</p>
                                    }
                                    {
                                        (e.material != null) && 
                                        <p className="text-muted mb-0">Material: {e.material.name}</p>
                                    }

                                    {
                                        e.catalogo_id != null &&
                                        <p className="card-text text-success fw-bold">{t("catalog.cat_demo")}</p>
                                    }
                                    <p className="card-text mb-0">Tags: </p>
                                    <div className='d-flex flex-wrap'>
                                    {
                                        (e.basica == 1 || e.basica == '1')&&
                                        <> 
                                            <div className='mr-2' style={{marginRight: '1rem'}}>
                                                <span
                                                    style={{cursor: 'default'}}
                                                    data-tip="Básica"
                                                    data-for='basic'
                                                    className='badge bg-danger rounded-pill text-wrap'>#Básica
                                                </span >
                                                {/* <span class="sr-only">{e.name}</span> */}
                                            </div>
                                            <ReactTooltip 
                                                id='basic' 
                                                place="top" 
                                                type="dark" 
                                                effect="solid"
                                                className='tooltip-width'
                                            />
                                        </>
                                    }
                                        {

                                            e.tags.length > 0 &&
                                            e.tags.map((e,i) => {
                                                return <Fragment key={'frag-'+i}> 
                                                        <div className='mr-2' style={{marginRight: '1rem'}}>
                                                            <span
                                                                style={{cursor: 'default'}}
                                                                data-tip={e.name}
                                                                data-for='test'
                                                                className='badge bg-danger rounded-pill text-wrap'>#{e.name}
                                                            </span >
                                                            {/* <span class="sr-only">{e.name}</span> */}
                                                        </div>
                                                        <ReactTooltip 
                                                            id='test' 
                                                            place="top" 
                                                            type="dark" 
                                                            effect="solid"
                                                            className='tooltip-width'
                                                            />
                                                        </Fragment>
                                            })
                                        }
                                    </div>
                                </div>
                                <div className='p-3'>
                                    <div className='row'>
                                        <div className="col-md-12 text-end">
                                            <span className='text-dark'>ID: <b>{e.codigo}</b></span>
                                        </div>
                                    </div>
                                    <div className='row'>
                                    {
    
                                                <button 
                                                    disabled={loading}
                                                    onClick={() => handleClick(e)}
                                                    className='btn btn-primary btn-sm fw-bold text-uppercase border shadow'
                                                >
                                                <span>{t("welcome.btn_download")}</span> 
                                                    <FontAwesomeIcon icon={faDownload}/>
                                                </button>

                                    }
                                    </div>
                                </div>
                            </div>
                        </div>                    
                    })
                    :
                    loading 
                    ? <div className="col-md-12 text-center animate__animated animate__zoomIn">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    : <p>Not found result</p>
            }
        </div>
    </>
  )
}

export default Catalogo
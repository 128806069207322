import React, {useState, useEffect, useContext, useRef} from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {urlApi} from '../../global/Global';
import {
  Link ,NavLink
} from "react-router-dom";
import { useHistory } from "react-router-dom";
import Table from './Table';
import { unauthenticated } from '../../helpers/Helper';

const UsuariosAdm = () => {

    const [usuarios, setusuarios] = useState([]);
    const [carga, setCarga]       = useState(false);
    useEffect(() => {
      
        setCarga(true);
        const getUsuarios = async () => {
            
            const log = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            }
            
            await axios.get(urlApi+'adminuser', {headers: headers})
                        .then(async (response) =>{
                            console.log(response.data);
                            setusuarios(response.data);
                        }).catch((error) => {
                            unauthenticated(error);
                            console.log(error);
                        }).then(() =>{
                            setCarga(false);
                        });            
        }

        getUsuarios();
      
    }, [])
    

    return (
      <div className="row justify-content-center animate__animated animate__fadeIn">
            <div className="col-md-12">
                <div className="card ">
                    <div className="card-header bg-card-header">
                        <div className="row align-items-center">
                            <div className="col-md-6 my-2">
                                <span className="fw-bold text-white">Usuarios</span>
                                
                            </div>
                            <div className='col-md-6 my-2 text-end'>
                                <NavLink to="/usuarios/adm/create" className="btn btn-warning btn-sm fw-bold shadow res-w100 "> 
                                    <span>Crear usuario</span>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="card-body ">
                    {
                    carga ?
                        <div className="row">
                            <div className=" col-md-12 text-center">
                                <div className="spinner-grow text-info" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </div>
                    :
                        <div className="table-responsive p-2"  style={{height:' 35rem'}}>
                            <table className="border rounded table table-striped table-hover table-sm mb-0 overflow-auto">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Nombre</th>
                                        <th scope="col">Correo</th>
                                        <th scope="col">Roles</th>
                                        <th scope="col">Cuenta</th>
                                        <th scope="col">Opciones</th>
                                    </tr>
                                </thead>                                                                 
                                <tbody>
                                    {
                                        usuarios != undefined &&
                                            usuarios.map((e,i) =>
                                                <tr key={'usuarios-'+i}>    
                                                    <th scope="row">{e.id}</th>
                                                    <td>{e.user.name}</td>
                                                    <td>{e.user.email}</td>
                                                    <td>{
                                                            e.user.roles.map((em,k) =>{
                                                                return <p className='m-0' key={'k'+k}>{em.description}</p>
                                                            })
                                                        }
                                                    </td>
                                                    <td>
                                                        {
                                                            e.user.suscripcion == null 
                                                            ? <p className='m-0'>Cuenta normal</p>
                                                            : <p className='m-0 text-success'>Cuenta pro</p>
                                                        }
                                                    </td>
                                                    <td>
                                                        <div className="row">
                                                            <Table
                                                                usuario={e.user}
                                                                setCarga={setCarga}
                                                                setusuarios={setusuarios}
                                                            />
                                                            
                                                        </div>
                                                    </td>
                                                </tr>                        
                                            )
                                    } 
                                </tbody>
                            </table>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UsuariosAdm
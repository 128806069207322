import React, {useEffect, useState, useRef, Fragment} from 'react'
import { Formik, Field, Form, ErrorMessage, FieldArray, useFormikContext } from 'formik';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as Yup from 'yup';
import axios from 'axios';
/*Globales*/
import {urlApi, url} from '../../global/Global';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useHistory } from "react-router-dom";
import ComprobarVista from '../vistaUsuarioContext/ComprobarVista';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faChevronDown, faCog } from '@fortawesome/free-solid-svg-icons';
import Checkbox from '../layouts/Checkbox'
import { t } from 'i18next';
import { Link } from "react-router-dom";

const EditEtiqueta = (props) => {

    let   history                         = useHistory();
    const MySwal                          = withReactContent(Swal);
    const [etiqueta, setetiqueta]         = useState('');
    const [intialValues, setintialValues] = useState({});
    // const [etiqueta, setetiqueta] = useState(props.location.state.etiqueta);
    const [idEtiqueta, setidEtiqueta]     = useState(props.match.params.id);
    const [user, setuser]                 = useState(props.user);
    const [comprobacion, setcomprobacion] = useState(false);
    const [languages, setLanguages]       = useState([]);
    const [image, setimage]               = useState(null);
    const [fileUrl, setFileUrl]           = useState('');
    // const [fileUrl, setFileUrl] = useState(props.location.state.etiqueta.image);
    const [tags, setTags]             = useState([]);
    const [medidas, setMedidas]       = useState([]);
    const [medidasMat, setMedidasMat] = useState(null);
    
    const [arr, setarr] = useState('');
    // const [arr, setarr] = useState(props.location.state.etiqueta.configuraciones);
    const [loading, setloading] = useState(false);
    const [carga, setcarga]     = useState(false);
    const url_image             = process.env.NODE_ENV !== 'production' ? url+'images/' : 'https://webtobarcodeback.labeldictate.com/images/';

/*************************************************************************************************/

    const AutoSubmitToken = () => {
        // Grab values and submitForm from context
        const { values, submitForm } = useFormikContext();
        useEffect(() => {
        // Submit the form imperatively as an effect as soon as form values.token are 6 digits long
            console.log(values.material)
            // console.log(values.medida)
            setMedidasMat(values.material);
        }, [values, submitForm]);

        return null;
    };
/*******************************************************************************************************/


    useEffect(() => {
        // console.log(props)
        const getEtiqueta = async() => { 
            console.log(user)
            let material = null;
            let medida   = null;
            let basica   = null;
            setuser(props.user);
            const log = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            }
            const send_data = {
                id: idEtiqueta
            }
            console.log(send_data);
            await axios.post(urlApi+'etiquetas/show', send_data, {headers: headers})
                        .then((response) => {
                            //Actualiza estados del formulario
                            console.log(response?.data);
                            if(response?.data?.respuesta === 'correcto'){
                                setetiqueta(response?.data?.etiquetas);
                                setarr(response?.data?.etiquetas?.configuraciones);
                                setFileUrl(url_image+response.data.etiquetas.image);
                                
                                if(response.data.etiquetas.material_id != null || response.data.etiquetas.material_id !=undefined){
                                    material = response.data.etiquetas.material_id.toString()
                                }
                                if(response.data.etiquetas.medida != null || response.data.etiquetas.medida !=undefined){
                                    medida = response.data.etiquetas.medida_id.toString()
                                }
                                if(response.data.basica != null || response.data.basica !=undefined){
                                    basica = response.data.basica.map(Number)
                                }
                                const _tags = response.data.tags.map(Number);
                                setintialValues({
                                    nombre     : response.data.etiquetas.nombre,
                                    etiqueta   : response.data.etiquetas.etiqueta,
                                    descripcion: response.data.etiquetas.descripcion,
                                    ocultar    : (response.data.etiquetas.ocultar == 1 ) ? "1" : "0",
                                    language   : response.data.etiquetas.language.id,
                                    image      : response.data.etiquetas.image,
                                    material   : material,
                                    medida     : medida,
                                    basica     : basica,
                                    tag        : _tags,
                                    // tag        : response.data.tags,
                                    // lista      : response.data.etiquetas.configuraciones
                                });
                                setcarga(true);
                            }else if(response.data.respuesta === 'error'){
                                history.push("/see-labels");
                            }
                            // return;
                        })
                        .catch((error) => {
                            console.log(error);
                            setcarga(true);
                        });
            setcomprobacion(true);
       
        }

        const getLanguages = async (params) => {
            const log = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            }
            await axios.get(urlApi+'languages/index', {headers: headers})
                        .then((response) => {
                            //Actualiza estados del formulario
                            setLanguages(response.data);
                            // setLoading(false);
                            console.log(response.data);
                        })
                        .catch((error) => {
                            console.log(error);
                        });     
        }

        const getMedidas = async () => {

            const log      = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            }
            await axios.get(urlApi+'medida', {headers: headers})
                        .then((response) => {
                            //Actualiza estados del formulario
                            setMedidas(response.data.medida);
                            setTags(response.data.tag);
                            console.log(response.data);
                        });

        }
        getLanguages();
        getMedidas();
        getEtiqueta();
        
        
    }, [])


/*******************************************************************************************************/

    const processImage = (e) => {
        const imageFile = e.target.files[0];
        const imageUrl = URL.createObjectURL(imageFile);
        setFileUrl(imageUrl)
        setimage(e.target.files[0])
    }

/*************************************************************************************************/

    //Validador de datos
    const formSchema = Yup.object().shape({
        nombre: Yup.string()
            .required('Campo requerido'),
        etiqueta: Yup.string()
            .required('Campo requerido'),
        language: Yup.string()
            .required('Campo requerido'),
    });

/*******************************************************************************************************/

    const editarEtiqueta = async (params) => {
        setloading(true);
        let datos_enviar = params;
        datos_enviar.id = etiqueta.id;
        console.log(datos_enviar);
        // return;
        let formData = new FormData();
        if(image != null){
            formData.append('imagen', image);
        }
        formData.append('data', JSON.stringify(datos_enviar));

        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
        await axios({
            method: 'post',
            url: urlApi+'etiquetas/update',
            data: formData,
            // data: datos_enviar,
            headers: headers
          }).then((response) => {
              if(response.data.respuesta === 'correcto'){
                MySwal.fire({
                    icon: 'success',
                    title: 'Correcto',
                    text: 'Se actualizo la etiqueta correctamente',
                })
              }
                console.log(response.data)
            }).catch(error => {            
                console.log(error)
          }).then(() =>{
                setloading(false);
          });
    }
    
/*******************************************************************************************************/

    return (
    <div className="row justify-content-center">
        {
            comprobacion &&
                user.role != 1 &&
                    <ComprobarVista/>
        }
        {
        carga 
        ?    <div className="col-md-12 animate__animated animate__fadeIn">                
                <div className="card">
                    <div className="card-header bg-dark">
                        <div className='row'>
                            <div className='col-md-6'>
                                <span className="fw-bold text-white">Etiquetas</span>
                            </div>
                            <div className='col-md-6 text-end'>
                            {
                                etiqueta?.nueva != '1' &&
                                <Link className="fw-bold btn btn-sm btn-light"
                                    to={{  
                                        pathname:"/label-configuration/"+idEtiqueta,
                                        state:{etiqueta: idEtiqueta}
                                    }}
                                >                               
                                    <div className='d-flex align-items-center'>
                                        <FontAwesomeIcon icon={faCog}/>
                                        <span className='ms-1'>
                                            Configuración avanzada
                                        </span>

                                    </div>
                                </Link>
                            }
                            </div>

                        </div>
                    </div>
                    <Formik
                        enableReinitialize
                        initialValues={intialValues}
                        validationSchema={formSchema}
                        onSubmit={(values, actions) => editarEtiqueta(values, actions)}
                        render={({ values, errors, touched, handleReset }) => {
                        return (
                        <Form>
                            <AutoSubmitToken />
                            <div className="card-body">
                                <div className={`${user.role == 1 ? 'col-md-6' : 'd-none'}`}>
                                    <p className='text-success'>
                                        ID: <b>{etiqueta.codigo}</b>
                                    </p>
                                </div>
                                <div className="row mb-3">                                                                
                                    <div className="col-md-12">
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <label htmlFor="nombre" className="form-label fw-bold">{t("utilidades.name")}</label>
                                                <Field 
                                                    type="text" 
                                                    className="form-control" 
                                                    name='nombre' 
                                                    placeholder="ejemplo: etiqueta-catalogo" 
                                                />
                                                <ErrorMessage
                                                    name='nombre'
                                                    component='div'
                                                    className='field-error text-danger'
                                                />
                                            </div>
                                            <div className="col-md-6">
                                                <label htmlFor="language" className="form-label fw-bold">{t("see_labels.language")}</label>
                                                <Field 
                                                    as="select" 
                                                    className="form-select select-custom" 
                                                    name='language' 
                                                >

                                                {
                                                    languages!=undefined &&
                                                    languages.map((element,index) => {
                                                            return <option
                                                                        value={element.id} 
                                                                        key={index}                                                                    
                                                                        >{element.name}
                                                                    </option>
                                                    })
                                                }

                                                </Field>
                                                
                                                <ErrorMessage
                                                    name='language'
                                                    component='div'
                                                    className='field-error text-danger'
                                                />
                                            </div>
                                        </div>
                                        <div className='row'>
            
                                            <div className='col-md-6'>

                                                <div className='mb-3'>
                                                    <label className="form-label fw-bold">{t("utilidades.description")}</label>
                                                    <div className="form-floating">
                                                        <Field  as="textarea" disabled={loading} className="form-control" name="descripcion" id="descripcion" />
                                                        <ErrorMessage
                                                            name='descripcion'
                                                            component='div'
                                                            className='field-error text-danger'
                                                        />

                                                    </div>
                                                </div>

                                                <div className="file color">
                                                    <label htmlFor="formFile" className="form-label fw-bold">{t("edit_label.image_pre")}</label>
                                                    <input disabled={loading} className="form-control" id="formFileSm" type="file" name="csv" onChange={(e) => processImage(e)} accept="image/*"/>
                                                </div>
                                                <div className="border rounded text-center">
                                                    <img className='img-fluid' src={fileUrl}/>
                                                </div>
                                            </div>

                                            <div className={`${user.role == 1 ? 'col-md-6' : 'd-none'}`}>

                                                <div className='mb-3'>
                                                    <p className='form-label fw-bold'>Material</p>
                                                    <div className="form-check form-check-inline">
                                                        <Field 
                                                            className="form-check-input" 
                                                            type="radio" 
                                                            name="material" 
                                                            id="material1" 
                                                            value={"1"}
                                                        />
                                                        <label className="form-check-label" htmlFor="material1">Etiqueta</label>
                                                    </div>
                                                    <div className="form-check form-check-inline">
                                                        <Field 
                                                            className="form-check-input" 
                                                            type="radio" 
                                                            name="material" 
                                                            id="material2" 
                                                            value={"2"}
                                                        />
                                                        <label className="form-check-label" htmlFor="material2">Ticket</label>
                                                    </div>
                                                    <ErrorMessage
                                                        name='material'
                                                        component='div'
                                                        className='field-error text-danger'
                                                    />
                                                </div>
                                                <div className='row'>
                                                    <div className='col-md-6 mt-3'>
                                                        <p className='form-label fw-bold'>Medidas</p>
                                                        {
                                                            medidas.length > 0 &&
                                                            medidas.map((e,i)=>{
                                                                return e.etiqueta == medidasMat &&
                                                                <div className="form-check">
                                                                    <Field 
                                                                        className="form-check-input" 
                                                                        type="radio" 
                                                                        name="medida" 
                                                                        id={`"inlineRadio${i}"`} 
                                                                        value={e.id.toString()}
                                                                    />
                                                                    <label className="form-check-label" htmlFor={`"inlineRadio${i}"`}>{e.name} {e.medida}</label>
                                                                </div>
                                                            })
                                                        }
                                                        <ErrorMessage
                                                            name='medida'
                                                            component='div'
                                                            className='field-error text-danger'
                                                        />

                                                            <p className='form-label fw-bold mt-3 border-top'>Ocultar etiqueta</p>
                                                                <Field 
                                                                    as="select" 
                                                                    className="form-select select-custom" 
                                                                    name='ocultar' 
                                                                    disabled={loading}
                                                                >

                                                                <option value="0">Mostrar</option>
                                                                <option value="1">Ocultar</option>                                                            

                                                            </Field>
                                                        
                                                        <ErrorMessage
                                                            name='ocultar'
                                                            component='div'
                                                            className='field-error text-danger'
                                                        />
                                                    </div>

                                                    <div className='col-md-6 mt-3'>                                            
                                                        <p className='form-label fw-bold'>Hashtags (Labels, finalidad)</p>
                                                        {
                                                            carga &&
                                                            <div className="form-check">
                                                                <Checkbox 
                                                                    className="form-check-input" 
                                                                    id="basica" 
                                                                    name="basica" 
                                                                    value={1}
                                                                />
                                                                <label className="form-check-label" htmlFor="basica">Básica </label>
                                                            </div>
                                                        }
                                                        {
                                                            carga &&
                                                            tags.map((e,i)=>{
                                                                console.log(e.id)
                                                                return <div className="form-check" key={'tags-'+i}>
                                                                    <Checkbox 
                                                                        className="form-check-input" 
                                                                        value={e.id}
                                                                        id={`"inlineCheckbox${i}"`} 
                                                                        name="tag" 
                                                                    />
                                                                    <label className="form-check-label" htmlFor={`"inlineCheckbox${i}"`}>{e.name} </label>
                                                                </div>
                                                            })
                                                        }
                                                        <ErrorMessage
                                                            name='tag'
                                                            component='div'
                                                            className='field-error text-danger'
                                                        />
                                                    </div>
                                                </div>
                                                
                                            </div>

                                        </div>
                                        
                                    </div>
                                        
                                    <div className="col-md-4 border-left-top d-none">
                                        <FieldArray
                                                name="lista"
                                                render={({insert, remove, push}) => ( 
                                                <>
                                                    <div className="row p-1 mb-1">
                                                            
                                                        <div className="col-md-8">
                                                            <label className="form-label fw-bold">Configuraciones</label>
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className='row'>
                                                                <div className='col-md-6 p-0'>
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-sm btn-primary form-control-plaintext"
                                                                        onClick={() => push({ variable: "", type_config_id: "0", valor: "" })}
                                                                        // onClick={() => push({ variable: "", configuracion: "0", valor: "" })}
                                                                        disabled={loading}
                                                                        // ref={plus}
                                                                    >+</button>
                                                                </div>

                                                                <div className='col-md-6 text-center p-0'>
                                                                    <a className="btn btn-sm btn-outline-dark " data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                                                                        <FontAwesomeIcon icon={faChevronDown}/>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className='row collapse' id="collapseExample">
                                                        <span className='mb-2'><b>Nota</b>: si usamos dos configuraciones para una variable, debe ir primero CARACTERES antes que PREGUNTA.</span>
                                                        <span><b>Nota2</b>: Si queremos agregar fecha de hoy u hora no debemos agregar ninguna configuración, solo debemos colocar "DATE_NOW" para fecha o "HOUR_NOW" para hora en el código de la etiqueta/plantilla</span>
                                                    </div>
                                                    <div className="p-2 border rounded text-muted " style={{backgroundColor: 'whitesmoke'}}>
                                                    {
                                                    values.lista != undefined &&
                                                    values.lista.map((element, index) => (
                                                        <div className='row' key={index}>
                        
                                                            <div className='col-md-12'>
                                                                <label className="form-label fw-bold">Variable</label>
                                                                <Field className='form-control form-control-sm' 
                                                                    placeholder='variable' 
                                                                    name={`lista.${index}.variable`} 
                                                                    disabled={loading}
                                                                />
                                                                <ErrorMessage
                                                                    name={`lista.${index}.variable`}
                                                                    component='div'
                                                                    className='field-error text-danger'
                                                                /> 

                                                                <label className="form-label fw-bold">Configuración</label>
                                                                <Field 
                                                                    as="select"
                                                                    className='form-select form-select-sm'
                                                                    disabled={loading}
                                                                    name={`lista.${index}.type_config_id`} 
                                                                >
                                                                    <option value={"0"} disabled>-- Seleccione una opción --</option>
                                                                    <option value={"1"}>Caracteres</option>
                                                                    <option value={"2"}>Pregunta</option>
                                                                    <option value={"3"}>Precio</option>
                                                                    {/* <option value={"3"}>Año</option>
                                                                    <option value={"4"}>Mes</option>
                                                                    <option value={"5"}>Día</option> */}
                                                                </Field>

                                                                <label className="form-label fw-bold">Valor</label>
                                                                <Field className='form-control form-control-sm' 
                                                                    placeholder='valor'
                                                                    name={`lista.${index}.valor`} 
                                                                    disabled={loading}
                                                                    required
                                                                />
                                                            </div>
                                                            <div className='col-md-12 mt-2'>
                                                                <button className="btn btn-sm btn-danger fw-bold w-25" 
                                                                        type="button" 
                                                                        onClick={() => remove(index)}
                                                                        disabled={loading}
                                                                >-</button>
                                                            </div>
                                                            
                                                            <div className='col-md-12'>
                                                                <hr/>
                                                            </div>

                                                        </div>
                                                    ))}
                                                    </div>
                                                </>
                                                )}
                                            />
                                    
                                    
                                    </div>
                                    <div className='row mt-3'>
                                        <div className='col-md-12'>
                                            <label className="form-label fw-bold">{t("utilidades.template")} ({t("utilidades.code")})</label>
                                            <div className="form-floating">
                                                {/* <textarea className="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style={{height: '400px'}}></textarea> */}
                                                <Field  
                                                    as="textarea" 
                                                    disabled={loading} 
                                                    className="form-control" 
                                                    name="etiqueta" 
                                                    id="etiqueta" 
                                                    style={{height: '400px'}}
                                                    readOnly
                                                    onDoubleClick={(e) => e.target.readOnly=false}
                                                />
                                                <ErrorMessage
                                                    name='etiqueta'
                                                    component='div'
                                                    className='field-error text-danger'
                                                />

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row mt-3">
                                    <div className='col-md-4'>
                                        <button type='submit' className="btn btn-dark fw-bold w-100">{t("edit_label.update")}</button>    
                                    </div>
                                </div>
                            </div>
                        </Form>
                        ); 
                    }}
                    />
                </div>
             </div>
        :   <div className='col-md-12'>
                <div className='justify-content-center d-flex'>
                    <div className="spinner-border text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        }
    </div>
    )
}

export default EditEtiqueta

import React, {useState, useEffect} from 'react'

/*Globales*/
import TableEditablePage from './TableEditablePage';
import EncuentraImprime from '../EncuentraImprime/EncuentraImprime';
import BootstrapSwitchButton from 'bootstrap-switch-button-react'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from "react-router-dom";
import { t } from 'i18next';
import { unauthenticated } from '../../helpers/Helper';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { urlApi } from '../../global/Global';
import useWindowDimensions from '../layouts/windowsDimensions';
import CryptoJS from 'crypto-js';
import funciones from "../utilidades/funciones";

const Vista = (props) => {

    let   history                 = useHistory();
    const [catalogo, setcatalogo] = useState('');
    const [etiqueta, setetiqueta] = useState('');

    // const { height, width } = useWindowDimensions();
    const [drops, setdrops] = useState(true);

    const [charge, setcharge]               = useState(false);
    const [isShowingAlert, setShowingAlert] = useState(false);
    const [loading, setloading]             = useState(false);

    const [flecha, setflecha] = useState(true);

/************************************************************************************************/
    const first = async () => {
        if(!isNaN(props.match.params?.catalogo) && !isNaN(props.match.params?.etiqueta) ){
            setcatalogo(props.match.params.catalogo);
            setetiqueta(props.match.params.etiqueta);
            setcharge(true);
            console.log(props.match.params.catalogo);
        }else{
            history.push('/see-labels');
        }
    }
/************************************************************************************************/
    useEffect(() => {
        window.scrollTo(0, 0);
        first();
        const x = funciones.getOS();
        (x == 'android' || x == 'ios') ? setdrops(true) : setdrops(false);
    }, []);
    
/**************************************************************************************************/
    return (
        <>
            <div className="row justify-content-center animate__animated animate__fadeIn">
                <div className="col-md-12">                
                    <div className="card">
                        <div className="card-header bg-dark d-flex justify-content-between align-items-center">
                            {
                                !isShowingAlert
                                ? <span className="fw-bold text-white">Cátalogo</span>
                                : <span className="fw-bold text-white">{t("view.find_print")}</span>
                                                                
                            }
                            <a className={`${drops ? "btn btn-light fw-bold" : "d-none "}`} data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample" onClick={() => setflecha(!flecha)}>
                                <span style={{marginRight: '1rem'}}>{t("view.more")}</span>
                                {
                                    flecha
                                    ?   <FontAwesomeIcon icon={faChevronDown} className="animate__animated animate__rotateIn"/>
                                    :   <FontAwesomeIcon icon={faChevronUp} className="animate__animated animate__rotateIn"/>
                                }
                            </a>
                        </div>

                        <div className={`${drops ? 'row collapse' : 'row'}`} id="collapseExample">
                            <div className='col-md-6'>
                                <div className='pt-2 px-3'>
                                    <div className='row align-items-center'>
                                        <div className='col-md-7'>
                                            <label htmlFor="etiqueta" className="form-label fw-bold" style={{marginRight: '5px'}}>{t("view.find_print")}: </label>
                                        </div>
                                        <div className='col-md-5'>
                                            <BootstrapSwitchButton 
                                                style='w-100'
                                                onlabel='ON'
                                                offlabel='OFF'
                                                onChange={
                                                    () => setShowingAlert(!isShowingAlert)
                                                }                             
                                                checked={isShowingAlert}  
                                                disabled={loading}                                                
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='pt-2 px-3'>
                                    {/* <span><b>Etiqueta: </b>{nombre}</span> */}
                                </div>
                            </div>

                            <div className='col-md-6'>
                                <div className='pt-2 px-3'>
                                    {
                                        !isShowingAlert
                                        ? <span>{t("view.description_1")}</span>
                                        : <span>{t("view.description_2")}</span> 
                                    }
                                </div>
                            </div>

                        </div>
                        <div className='p-1'>
                            <hr className='m-0 '/>
                        </div>
                            {
                            (charge) &&
                                !isShowingAlert 
                                    ?
                                    <TableEditablePage
                                        catalogo={catalogo}
                                        etiqueteta={etiqueta}
                                        user={props.user}
                                    />
                                    :
                                    (catalogo!= '' && etiqueta != '') &&
                                    <EncuentraImprime
                                        _catalogo_={catalogo}
                                        etiqueteta={etiqueta}
                                        user={props.user}
                                    />
                            }   
                    </div>
                </div>
            </div>
        </>

    )
}

export default Vista

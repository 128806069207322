import React, {useState, useEffect} from 'react'
import getExcel from './getExcel'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

const UltimaSesion = ({btn_modal, btn_close}) => {

    const [hoy, sethoy] = useState('')

    useEffect(() => {
      
        let   today = new Date();
        const x     = today.toISOString().split('T')[0];
        sethoy(x);
        console.log(x);
    }, [])    

    const handleClickCliente = async (params) => {
        const send_data ={
            params: params,
        }
        getExcel('reportes/ultimo-inicio-sesion', send_data, 'ultimo inicio sesion', btn_modal, btn_close);
    }

    //Validador de datos
    const formSchema = Yup.object().shape({

        desde: Yup.string()
            .required('Campo requerido'),
        hasta: Yup.string()
            .required('Campo requerido'),
       
    });

  return (
    <>
        <div className="col animate__animated animate__fadeIn">
            <div className="card h-100 shadow rounded">
                <div className="card-body">             
                    <Formik
                        enableReinitialize
                        initialValues={{
                            desde:'2022-04-20',
                            hasta: hoy,
                        }}
                        validationSchema={formSchema}
                        onSubmit={(values, actions) => handleClickCliente(values, actions)}
                    >
                        <Form className="d-flex flex-column h-100">
                            <div className="card-header">
                                <strong className="text-uppercase">Reporte de ultima sesión iniciada</strong>                                
                            </div>
                            <div className="card-body">
                                <div className='row'>
                                    <div className="col-md-12">
                                        <label htmlFor="desde" className="form-label fw-bold text-uppercase">Desde:</label>
                                        <div className="col-10">
                                            <Field className="form-control" type="date" id="desde" name="desde"/>
                                        </div>
                                        <ErrorMessage
                                            name='desde'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        <label htmlFor="hasta" className="form-label fw-bold text-uppercase">hasta:</label>
                                        <div className="col-10">
                                            <Field className="form-control" type="date" id="hasta" name="hasta"/>
                                        </div>
                                        <ErrorMessage
                                            name='hasta'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="card-footer text-muted my-auto">
                                <button type="submit" className="btn btn-dark text-uppercase fw-bold">Obtener reporte</button>
                            </div>
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>
    </>
  )
}

export default UltimaSesion
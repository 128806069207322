import React, {useEffect, useState, useRef} from 'react'
import _catalogos from '../../video/catalogos.mp4';
import { t } from 'i18next';

const VideoTutorialCatalogos = ({btn_t2b, btn_paso2_cls}) => {

    const [carga, setcarga] = useState(false);
    const pause_video       = useRef(null)

    const pauseVideo = () => {
        pause_video.current.pause();
        pause_video.current.currentTime = 0;
    }
    return (
        <>
        {/* <!-- Button trigger modal --> */}
            <button type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#staticBackdrop" ref={btn_t2b}>
                Launch static backdrop modal
            </button>
        {/* <!-- Modal --> */}
            <div className="modal border fade" style={{zIndex: 10000}} id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title fw-bold" id="staticBackdropLabel">
                                {t("import.how_to_use")}
                            </h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={ btn_paso2_cls } onClick={() => pauseVideo()}></button>
                        </div>
                        <div className="modal-body">
                            {
                                carga &&
                                    <div className='row'>
                                        <div className="col-md-12 text-center">
                                            <div className="spinner-border text-info" role="status">
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    </div>
                            }

                            <div className='text-center bg-secondary rounded p-1'>
                                <video ref={pause_video} className='rounded border border-3' src={_catalogos} width={"100%"} controls></video>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>  
    )
}

export default VideoTutorialCatalogos
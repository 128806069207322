import React, {useEffect, useState, useRef} from 'react'
import { Formik, Field, Form, ErrorMessage, useFormikContext } from 'formik';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as Yup from 'yup';
import axios from 'axios';
/*Globales*/
import {urlApi} from '../../global/Global'
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useHistory } from "react-router-dom";
import ComprobarVista from '../vistaUsuarioContext/ComprobarVista';
import { unauthenticated } from '../../helpers/Helper';
import { t } from 'i18next';
const Etiquetas = ({user}) => {

    const MySwal                    = withReactContent(Swal);
    const [languages, setLanguages] = useState([]);

    const [loading, setloading] = useState(false);

    const url                   = process.env.NODE_ENV !== 'production' ? 'https://webtobarcodeback.test/images/' : 'https://webtobarcodeback.labeldictate.com/images/';
    const [image, setimage]     = useState('');
    const [fileUrl, setFileUrl] = useState('');
    let   history               = useHistory();
    //
    const [switchLabelary, setswitchLabelary] = useState(false);
    const [dpis, setdpis]                     = useState('8dpmm');
    const [width, setwidth]                   = useState('4');
    const [height, setheight]                 = useState('6');
    const [camposVacios, setcamposVacios]     = useState(false);
    const [tags, setTags]                     = useState([]);
    const [medidas, setMedidas]               = useState([]);
    const [medidasMat, setMedidasMat]         = useState(null);
    // const [changeConfig, setchangeConfig] = useState(1);
    // const sel                             = useRef(null);
    const formRef = useRef(null);

/*************************************************************************************************/

    const AutoSubmitToken = () => {
        // Grab values and submitForm from context
        const { values, submitForm } = useFormikContext();
        useEffect(() => {
        // Submit the form imperatively as an effect as soon as form values.token are 6 digits long
            console.log(values.material)
            // console.log(values.medida)
            setMedidasMat(values.material);
        }, [values, submitForm]);

        return null;
    };
  

/*************************************************************************************************/

    useEffect(() => {
    
        const getLanguages = async (params) => {
            const log      = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            }
            await axios.get(urlApi+'languages/index', {headers: headers})
                        .then((response) => {
                            //Actualiza estados del formulario
                            setLanguages(response.data);
                            // setLoading(false);
                            console.log(response.data);
                        })
                        .catch((error) => {
                            unauthenticated(error);
                            console.log(error);
                        });     
        }        


        const getMedidas = async () => {

            const log      = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            }
            await axios.get(urlApi+'medida', {headers: headers})
                        .then((response) => {
                            //Actualiza estados del formulario
                            setMedidas(response.data.medida);
                            setTags(response.data.tag);
                            console.log(response.data);
                        });

        }
           
        getLanguages();
        getMedidas();
        
    }, [])
    

/*******************************************************************************************************/

    const labelaryApi = async () => { 
        console.log(dpis);    
        console.log(width);    
        console.log(height);   
        const x = document.querySelector('#etiqueta').innerHTML; 
        console.log(x);
        // return;
        if(width.trim() == '' || height.trim() == '' || x.trim() == ''){
            setcamposVacios(true);
            return;
        }
        setcamposVacios(false);

        // const headers = {
        //     'Accept': 'image/png'
        // }
        const url_labelary = `https://api.labelary.com/v1/printers/${dpis}/labels/${width}x${height}/${0}/${x}`;
        await axios({url: url_labelary,
                    method: 'GET',
                    // headers: headers,
                    // responseType: 'blob', // important
                    responseType: 'arraybuffer', // important
                    }).then((response) => {
                        //Actualiza estados del formulario
                        setFileUrl(response.config.url);
                        setimage(`${Buffer.from(response.data, 'binary').toString('base64')}`);
                        // setLoading(false);
                        console.log(response);
                    })
                    .catch((error) => {
                        MySwal.fire({
                            icon: 'warning',
                            title: 'Asegurese que el código sea ZPL y este correctamente escrito',
                            text: error,
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', MySwal.stopTimer)
                                toast.addEventListener('mouseleave', MySwal.resumeTimer)
                            }
                        });
                        console.log(error);
                    }); 

        // http://api.labelary.com/v1/printers/{dpmm}/labels/{width}x{height}/{index}/{zpl}
    }

/*******************************************************************************************************/

    const processImage = (e) => {
        const imageFile = e.target.files[0];
        const imageUrl = URL.createObjectURL(imageFile);
        setFileUrl(imageUrl)
        setimage(e.target.files[0])
    }

/*************************************************************************************************/

    const enviarEtiqueta = async (params, actions) => {
        console.log(params);
        // console.log(image);
        // return;
        if (image=='') {
            document.getElementById("formFileSm").value = "";
            setimage('');
            MySwal.fire({
                icon: 'info',
                title: 'Debe subir una imagen',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', MySwal.stopTimer)
                    toast.addEventListener('mouseleave', MySwal.resumeTimer)
                }
            })
            return;
        }

        setloading(true);

        let formData = new FormData();
        formData.append('imagen', image);
        formData.append('data', JSON.stringify(params));
        formData.append('base64', switchLabelary);



        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
        await axios({
            method : 'post',
            url    : urlApi+'etiquetas/store',
            data   : formData,
            headers: headers
          }).then((response) => {
              if(response.data.respuesta === 'correcto'){
                actions.resetForm({
                    values: {
                      // the type of `values` inferred to be Blog
                        nombre:"",
                        language: "1",
                        lista: []
                    },
                  });
                MySwal.fire({
                    icon: 'success',
                    title: 'Correcto',
                    text: 'Se creo la etiqueta correctamente',
                });
                if(user.role == 1){
                    history.push("/preformatos");
                }else{
                    history.push("/see-labels");
                }
              }
              else{
                MySwal.fire({
                    icon: 'error',
                    title: 'error',
                    text: 'Error al crear etiqueta',
                })
              }
                console.log(response.data)
            }).catch(error => { 
                unauthenticated(error)          
                console.log(error)
          }).then(() =>{         
              setloading(false);
          });
    }
    

/*************************************************************************************************/
    //Validador de datos
    const formSchema = Yup.object().shape({
        nombre: Yup.string()
            .required('Campo requerido'),
        etiqueta: Yup.string()
            .required('Campo requerido'),
        language: Yup.string()
            .required('Campo requerido'),
        descripcion: Yup.string()
            .required('Campo requerido'),
        medida: Yup.string()
            .required('Campo requerido'),
        material: Yup.string()
            .required('Campo requerido'),
        basico: Yup.array(),
            // .required('Campo requerido'),
        tag: Yup.array()
            .min(1)
            .required('Campo requerido'),
    });
/*************************************************************************************************/
    //Validador de datos
    const formSchema2 = Yup.object().shape({
        nombre: Yup.string()
            .required('Campo requerido'),
        etiqueta: Yup.string()
            .required('Campo requerido'),
        language: Yup.string()
            .required('Campo requerido'),
        descripcion: Yup.string()
            .required('Campo requerido'),
        ocultar: Yup.string()
            .required('Campo requerido'),
    });
/*************************************************************************************************/

    return (
    <div className="row justify-content-center">
        {
            user.role != 1 &&
                <ComprobarVista/>
        }
        <div className="col-md-12 ">                
            <div className="card">
                <div className="card-header bg-card-header">
                    <span className="fw-bold text-white">Crear plantilla</span>
                </div>
                <Formik
                    innerRef={formRef}
                    enableReinitialize
                    initialValues={{
                        nombre     : '',
                        etiqueta   : '',
                        language   : '1',
                        descripcion: '',
                        ocultar    : '0',
                        medida     : '',
                        material   : '',
                        basica     : [],
                        tag        : [],
                        // lista: arr
                    }}
                    validationSchema={
                        user.role == 1 
                        ? formSchema
                        : formSchema2
                    }
                    onSubmit={(values, actions) => enviarEtiqueta(values, actions)}
                    render={({ values, errors, touched, handleReset }) => {
                    return (
                    <Form>
                        <AutoSubmitToken />
                        <div className="card-body animate__animated animate__fadeIn">
                            <div className="row mb-3">                                                                
                                <div className="col-md-12">
                                    <div className="row mb-3">
                                        <div className="col-md-6">
                                            <label htmlFor="nombre" className="form-label fw-bold">{t("utilidades.name")}</label>
                                            <Field 
                                                type="text" 
                                                className="form-control" 
                                                name='nombre' 
                                                placeholder="ejemplo: etiqueta-catalogo" 
                                                disabled={loading}
                                            />
                                            <ErrorMessage
                                                name='nombre'
                                                component='div'
                                                className='field-error text-danger'
                                            />
                                        </div>
                                        <div className="col-md-6">
                                            <label htmlFor="language" className="form-label fw-bold">{t("see_labels.language")}</label>
                                            <Field 
                                                as="select" 
                                                className="form-select select-custom" 
                                                name='language' 
                                                disabled={loading}
                                            >

                                            {
                                                languages!=undefined &&
                                                languages.map((element,index) => {
                                                        return <option
                                                                    value={element.id} 
                                                                    key={index}
                                                                    >{element.name}
                                                                </option>
                                                })
                                            }

                                            </Field>
                                            
                                            <ErrorMessage
                                                name='language'
                                                component='div'
                                                className='field-error text-danger'
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        
                                        <div className='col-md-6'>

                                            <div className='mb-3'>
                                                <label className="form-label fw-bold">{t("utilidades.description")}</label>
                                                <div className="form-floating">
                                                    <Field  as="textarea" disabled={loading} className="form-control" name="descripcion" id="descripcion" />
                                                    <ErrorMessage
                                                        name='descripcion'
                                                        component='div'
                                                        className='field-error text-danger'
                                                    />

                                                </div>
                                            </div>

                                            {
                                                user.role == 1 &&
                                                <div className="form-check form-switch">
                                                    <input className="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" onChange={() => setswitchLabelary(!switchLabelary)} checked={switchLabelary}/>
                                                    <label className="form-check-label" for="flexSwitchCheckDefault">Labelary</label>
                                                </div>

                                            }

                                        {
                                            user.role == 1 ?
                                                !switchLabelary ?
                                                    <>
                                                            
                                                        <div className="file color">
                                                            <label htmlFor="formFile" className="form-label fw-bold">{t("edit_label.image_pre")}</label>
                                                            <input disabled={loading} className="form-control" id="formFileSm" type="file" name="csv" onChange={(e) => processImage(e)} accept="image/*"/>
                                                        </div>
                                                        <div className="border rounded text-center">
                                                            <img className='img-fluid' src={fileUrl}/>
                                                        </div>
                                                        <div className='mt-3'>
                                                            <button 
                                                                className='btn btn-sm btn-primary d-none'
                                                                onClick={() => setswitchLabelary(!switchLabelary)}
                                                            >
                                                                labelary
                                                            </button>
                                                            
                                                        </div>
                                                    </>
                                                :
                                                <>                                                
                            
                                                        <div className="mb-1">
                                                            <label for="density" className="form-label fw-bold">dpi (dpmm)</label>
                                                            <select name="density" id="density" className='form-select' value={dpis} onChange={(e) => setdpis(e.target.value)}>
                                                                <option value="6dpmm">6 dpmm (152 dpi)</option>
                                                                <option value="8dpmm">8 dpmm (203 dpi)</option>
                                                                <option value="12dpmm">12 dpmm (300 dpi)</option>
                                                                <option value="24dpmm">24 dpmm (600 dpi)</option>
                                                            </select>
                                                        </div>

                                                        <div className='row align-items-center mb-1'>
                                                            <div className="col-md-6">
                                                                <label for="width" className="form-label fw-bold">{t("utilidades.width")}</label>
                                                                <input name="width" type="number" className="form-control" placeholder="width" value={width} onChange={(e) => setwidth(e.target.value)}/>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <label for="height" className="form-label fw-bold">{t("utilidades.height")}</label>
                                                                <input name="height" type="number" className="form-control" placeholder="height" value={height} onChange={(e) => setheight(e.target.value)}/>
                                                            </div>
                                                        </div>
                                                        

                                                        <div className="border rounded text-center">
                                                            <img className='img-fluid' src={fileUrl}/>
                                                        </div>

                                                        <div className='row'>
                                                            <div className='col-md-12'>
                                                                {
                                                                    camposVacios &&
                                                                    <div className='text-danger fw-bold'>
                                                                        <p className='mb-1'>{t("utilidades.fill_fields")}</p>
                                                                    </div>
                                                                }

                                                                <button     
                                                                    className='btn btn-secondary w-100'
                                                                    type='button'
                                                                    onClick={() => labelaryApi()}
                                                                >
                                                                    Image Labelary
                                                                </button>
                                                            </div>
                                                        </div>
                                                        <div className=''>
                                                            <button 
                                                                className='btn btn-sm btn-primary d-none'
                                                                onClick={() => setswitchLabelary(!switchLabelary)}
                                                            >
                                                                labelary
                                                            </button>
                                                        </div>
                                                </>
                                            :
                                            
                                            <div>
                                                <div className="file color">
                                                    <label htmlFor="formFile" className="form-label fw-bold">{t("edit_label.image_pre")}</label>
                                                    <input disabled={loading} className="form-control" id="formFileSm" type="file" name="csv" onChange={(e) => processImage(e)} accept="image/*"/>
                                                </div>
                                                <div className="border rounded text-center">
                                                    <img className='img-fluid' src={fileUrl}/>
                                                </div>
                                            </div>
                                        }
                                        </div>

                                        {
                                            user.role == 4 &&
                                            <div className='col-md-6'>
                                                <p className='h2'>
                                                    Esta plantilla será ser compartida con otras cuentas mediante el código que se creará

                                                </p>
                                            </div>
                                        }

                                        <div className={`${user.role == 1 ? 'col-md-6' : 'd-none'}`}>

                                            <div className='mb-3'>
                                                <p className='form-label fw-bold'>Material</p>
                                                <div className="form-check form-check-inline">
                                                    <Field 
                                                        className="form-check-input" 
                                                        type="radio" 
                                                        name="material" 
                                                        id="material1" 
                                                        value={"1"}
                                                    />
                                                    <label class="form-check-label" for="material1">Etiqueta</label>
                                                </div>
                                                <div className="form-check form-check-inline">
                                                    <Field 
                                                        className="form-check-input" 
                                                        type="radio" 
                                                        name="material" 
                                                        id="material2" 
                                                        value={"2"}
                                                    />
                                                    <label class="form-check-label" for="material2">Ticket</label>
                                                </div>
                                                <ErrorMessage
                                                    name='material'
                                                    component='div'
                                                    className='field-error text-danger'
                                                />
                                            </div>
                                            <div className='row'>
                                                <div className='col-md-6 mt-3'>
                                                    <p className='form-label fw-bold'>Medidas</p>
                                                    {
                                                        medidas.length > 0 &&
                                                        medidas.map((e,i)=>{
                                                            return e.etiqueta == medidasMat &&
                                                            <div className="form-check">
                                                                <Field 
                                                                    className="form-check-input" 
                                                                    type="radio" 
                                                                    name="medida" 
                                                                    id={`"inlineRadio${i}"`} 
                                                                    value={e.id.toString()}
                                                                />
                                                                <label class="form-check-label" for={`"inlineRadio${i}"`}>{e.name} {e.medida}</label>
                                                            </div>
                                                        })
                                                    }
                                                    <ErrorMessage
                                                        name='medida'
                                                        component='div'
                                                        className='field-error text-danger'
                                                    />

                                                    <p className='form-label fw-bold mt-3 border-top'>Ocultar etiqueta</p>
                                                        <Field 
                                                            as="select" 
                                                            className="form-select select-custom" 
                                                            name='ocultar' 
                                                            disabled={loading}
                                                        >

                                                        <option value="0">Mostrar</option>
                                                        <option value="1">Ocultar</option>
                                                            

                                                        </Field>
                                                    
                                                    <ErrorMessage
                                                        name='ocultar'
                                                        component='div'
                                                        className='field-error text-danger'
                                                    />
                                                </div>

                                                <div className='col-md-6 mt-3'>                                            
                                                    <p className='form-label fw-bold'>Hashtags (Labels, finalidad)</p>
                                                    <div className="form-check">
                                                        <Field 
                                                            className="form-check-input" 
                                                            type="checkbox" 
                                                            name="basica" 
                                                            id="basica"
                                                            value={"1"}
                                                        />
                                                        <label class="form-check-label" for="basica">Básico </label>
                                                    </div>
                                                    {
                                                        tags.length > 0 &&
                                                        tags.map((e,i)=>{
                                                            return <div className="form-check">
                                                                <Field 
                                                                    className="form-check-input" 
                                                                    type="checkbox" 
                                                                    name="tag" 
                                                                    id={`"inlineCheckbox${i}"`} 
                                                                    value={e.id.toString()}
                                                                />
                                                                <label class="form-check-label" for={`"inlineCheckbox${i}"`}>{e.name} </label>
                                                            </div>
                                                        })
                                                    }
                                                    <ErrorMessage
                                                        name='tag'
                                                        component='div'
                                                        className='field-error text-danger'
                                                    />
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>

                                    <div className='row mt-3'>
                                        <div className='col-md-12'>
                                            <label className="form-label fw-bold">{t("utilidades.template")} ({t("utilidades.code")})</label>
                                            <div className="form-floating">
                                                {/* <textarea className="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style={{height: '400px'}}></textarea> */}
                                                <Field  as="textarea" disabled={loading} className="form-control" name="etiqueta" id="etiqueta" style={{height: '400px'}}/>
                                                <ErrorMessage
                                                    name='etiqueta'
                                                    component='div'
                                                    className='field-error text-danger'
                                                />

                                            </div>
                                        </div>
                                    </div>

                                    <div className="my-3">
                                        <button className="btn btn-dark "
                                                disabled={loading}
                                                type="submit"
                                        >{t("utilidades.save_changes")}</button>    
                                    </div>
                                </div>

                            </div>
                        </div>
                    </Form>
                    ); 
                }}
                />
            </div>
        </div>
    </div>
    )
}

export default Etiquetas

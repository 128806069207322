import React, {useState, useEffect} from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import {url, urlApi} from '../../global/Global';
import {
    Link
  } from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

const Modal = ({urlString}) => {


    const [catalogos, setCatalogos] = useState([]);        
    const [loading, setLoading] = useState(false);
    const [nombreModal, setnombreModal] = useState('');
/********************************************************************/
    const obtenerCatalogo = async (params) => {
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
        await axios.get(urlApi+'catalogo/index', {headers: headers})
                  .then((response) => {
                      //Actualiza estados del formulario
                      setCatalogos(response.data);
                      setLoading(false);
                      console.log(response.data);
                  })
                  .catch((error) => {
                      console.log(error);
                  });     
      }

      useEffect(() => {
        obtenerCatalogo();
        console.log(urlString);
      }, [])

      useEffect(() => {
        if(urlString == 'vista'){
            setnombreModal('Ver catalogo');
        }else{
            setnombreModal('Busca e imprime');
        }
      }, [urlString])
      

/********************************************************************/
  return (
    <div
        style={{zIndex: 10000}}
        className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true"
    >
        <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
                <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">{nombreModal}</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                    <ul className="list-group list-group-flush">
                        {
                            urlString != '' &&
                                catalogos.map((e,i) =>{
                                        return <Link to={
                                                {  
                                                    pathname:urlString,
                                                    state:{catalogo: e}
                                                }
                                            } 
                                                className="list-group-item" key={'lista-'+i}>
                                                    <div className='d-flex align-items-center text-primary'>  
                                                        <FontAwesomeIcon style={{marginRight: '1rem'}} icon={faCircleNotch} />  
                                                        <span className="nav_namze">{e.nombre}</span>
                                                    </div>
                                            </Link>                                                    
                                })
                        }
                    </ul>
                </div>
            </div>
        </div>
    </div>
  )
}

export default Modal
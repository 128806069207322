
// const  _url         = process.env.NODE_ENV !== 'production' ? 'http://127.0.0.1:8000/' : 'https://webtobarcodeback.labeldictate.com/';
// const  _urlApi      = process.env.NODE_ENV !== 'production' ? 'http://127.0.0.1:8000/api/' : 'https://webtobarcodeback.labeldictate.com/api/';
// const  _urlImages   = process.env.NODE_ENV !== 'production' ? 'http://127.0.0.1:8000/images/' : '//webtobarcodeback.labeldictate.com/images/';
//QA
const  _url         = process.env.NODE_ENV !== 'production' ? 'http://192.168.0.102:8000/' : 'https://webtobarcodeback.labeldictate.com/';
const  _urlApi      = process.env.NODE_ENV !== 'production' ? 'http://192.168.0.102:8000/api/' : 'https://webtobarcodeback.labeldictate.com/api/';
const  _urlImages   = process.env.NODE_ENV !== 'production' ? 'http://192.168.0.102:8000/images/' : 'https://webtobarcodeback.labeldictate.com/images/';
export const urlApi    = _urlApi;
export const url       = _url;
export const urlImages = _urlImages;

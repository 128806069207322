import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';

/*Globales*/
import {urlApi} from '../../global/Global'
const getExcel = async (name, params, doc, btn_modal, btn_close) => {
        await btn_modal.current.click();
        // console.log(params);
        const log      = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers  = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
        
            await axios({
                url: urlApi+name,
                method: 'POST',
                data: params.params,
                headers: headers,
                responseType: 'blob', // important
            }).then( (response) => {
                const url       = window.URL.createObjectURL(new Blob([response.data]));
                const link      = document.createElement('a');
                      link.href = url;
                link.setAttribute('download', doc+'.csv'); //or any other extension
                document.body.appendChild(link);
                link.click();
                console.log(response.data);
            }).catch((error) =>{
                console.log(error);
            }).then(() => {
                console.log(btn_close.current);
                setTimeout(() => {
                    btn_close.current.click();            
                }, 500);
            });
 

}

export default getExcel

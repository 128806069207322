import React, {useState, useEffect } from 'react'
import { useHistory } from "react-router-dom";
import AsyncStorage from '@react-native-async-storage/async-storage';
import Login from './Login'

import { useTranslation } from 'react-i18next';
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {  faPlusCircle, faBarcode } from '@fortawesome/free-solid-svg-icons';
import web2barcode from '../../src/img/Logo.png'

const Home = ({setExist, setUser, exist, user}) => {

    const { t }                     = useTranslation();
    const [loading, setLoading]     = useState(false);
    let   history                   = useHistory();
    const [userAgent, setuserAgent] = useState( window.navigator.userAgent);

    /*************************************************************************************************************************************/

    const first = async() => {        
        // setLoading(true);
        const log      = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        
        // if(log_json != null && log_json.tutorial == 0 && log_json.role == 2){
        if(log_json != null && log_json.tutorial == 0 && log_json.role == 2){
            history.push('/welcome');
        }else{
            Swal.fire({
                // title: t("login.welcome")+' '+log_json.name,                
                title: t("login.welcome"),                
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 1500,
                // timerProgressBar: true,
                showClass: {
                popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
                },              
            });
        }
        // setLoading(false);
    }

/*************************************************************************************************************************************/

    const welcome = () => {

        Swal.fire({
            title: "Tutorial",
            text: t("home.welcome_sweet"),
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Tutorial',
            cancelButtonText: 'No',
        }).then(async (result) => {
            if (result.isConfirmed) {
                const log           = await AsyncStorage.getItem('@login');
                const log_json      = JSON.parse(log);
                let   _log          = log_json;
                    _log.tutorial = 0;
                _log = JSON.stringify(_log);
                await AsyncStorage.removeItem(
                    '@login'
                );
                
                await AsyncStorage.setItem(
                        '@login',
                        _log
                );

                history.push('/welcome');
            }
        })

    }
/*************************************************************************************************************************************/

    useEffect(() => {      
        window.scrollTo(0,0);         
        // setPlatform( window.navigator?.userAgentData?.platform ?? window.navigator.platform)
    }, [])


/*************************************************************************************************************************************/

    useEffect(() => {
        
        if(exist){
        first();
        // setLoading(true)
        }
    }, [exist]);


/*************************************************************************************************************************************/

    return (
        <>
        {
        // !loading ?
        loading ?
            <div className="row justify-content-center">
                <div className="col-md-8 text-center">
                    <div className="spinner-grow text-primary" role="status">
                        <span className="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        :
            !exist ?
            <Login
                setExist={setExist}
                setUser={setUser}
                exist={exist}
                setLoading={setLoading}
                loading={loading}
            />
            :
            <div className='animate__animated animate__fadeIn'>
                <div className='row justify-content-center'>
                    <div className="col-md-12">                  
                        <div className="card">
                            <div className="card-header">
                                <h2 className='mb-0 fw-bold'>Menu</h2>
                            </div>
                            <div className="card-body">
                                <div className='row justify-content-center'>
                                    <div className='col-md-6 border border-2 border-gray bg-white'>
                                        <div className='d-flex flex-column'>

                                            <div className='text-center'>

                                                <div className="my-3">
                                                    <Link to="/see-labels" className="btn btn-sm btn-primary w-100 text-uppercase fw-bold">
                                                        <div className='d-flex align-items-center justify-content-center'>
                                                            <FontAwesomeIcon icon={faBarcode} className='nav_icon me-2'/>  
                                                            { t("menu.my_labels")}
                                                        </div>
                                                    </Link>
                                                </div>
                                                <div className="mb-3">
                                                    <Link to="/catalogo" className="btn btn-sm btn-warning w-100 text-uppercase fw-bold">
                                                        <FontAwesomeIcon icon={faPlusCircle}/> { t("menu.catalog")}
                                                    </Link>
                                                </div>
                                            </div> 

                                        </div> 
                                    </div>                                                
                                </div>
                                <div className='d-flex justify-content-between mt-5'>                            
                                    <a href="#" className="text-muted m-0" onClick={() => welcome()}> 
                                        <span className='link-secondary'>
                                            {
                                                t("home.welcome_tutorial")
                                            }
                                        </span>
                                    </a>

                                    {
                                    (userAgent != 'Mozilla/5.0 AppleWebKit/535.19 Chrome/56.0.0 Mobile Safari/535.19' && userAgent != 'Dalvik/2.1.0')  &&
                                    <p className="text-muted m-0">
                                        <a href="https://labeldictate.com/?c=privacy" target="_blank" className="link-secondary">{t("home.privacy")}</a> 
                                    </p>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row justify-content-center'>
                    <div className='col-md-6'>
                        <div className='text-center p-4 opacity-25'>
                            <img 
                                src={web2barcode} 
                                alt='web2barcode' 
                                className='img-fluid' 
                            />
                        </div>
                    </div>
                </div>
            </div>
            }        
        </>
    )
    }

    export default Home

import React, {useState} from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import {urlApi} from '../../global/Global';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const CreateDispositivo = () => {

    /*Sweet alert*/
    const MySwal = withReactContent(Swal);
    const [loading, setloading] = useState(false);
/*****************************************************************************************************/

    const enviarDatos = async (params, actions) => {
        setloading(true);
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
        await axios.post(urlApi+'dispositivos', params, {headers: headers})
                .then(response=>{
                    console.log(response.data);
                    if(response.data.respuesta === 'correcto'){
                        MySwal.fire({
                            icon : 'success',
                            title: 'Correcto',
                            text : 'Se agrego correctamente',
                        });
                        actions.resetForm({
                            values: {
                              // the type of `values` inferred to be Blog
                                dispositivo:"",
                                token:"",
                            },
                        });                    
                    }else{
                        MySwal.fire({
                            icon : 'error',
                            title: 'Error',
                            text : 'Ocurrio un error, vuelva a intentarlo',
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                    MySwal.fire({
                        icon : 'error',
                        title: 'Error',
                        text : 'Ocurrio un error, vuelva a intentarlo',
                    });
                }).then(() =>{
                    setloading(false);
                })
    }
    

/*****************************************************************************************************/

    //Validador de datos
    const formSchema = Yup.object().shape({
        dispositivo: Yup.string()
            .trim()
            .required('Campo requerido'),
        token: Yup.string()
            .trim()
            .required('Campo requerido'),
        });
/***************************************************************************************************/

    return (
        <div className="row justify-content-center">
            <div className="col-md-8">
                <div className="card">
                    <div className="card-header">
                        <div className="col-md 6">
                            <span className="fw-bold">Generar Token para dispositivo</span>
                        </div>
                    </div>
                    <div className="card-body">
                    <Formik
                            initialValues={{
                                dispositivo: '',
                                token: '',
                            }}
                            validationSchema={formSchema}
                            onSubmit={(values, actions) => enviarDatos(values, actions)}
                        >
                        <Form>
                            <div className="mb-3">
                                <label htmlFor="dis" className="form-label">ID. de dispositivo</label>
                                <Field 
                                    type="text" 
                                    className="form-control" 
                                    id="dis" 
                                    placeholder="ID: 12:34:56:78" 
                                    name="dispositivo"
                                />
                                <ErrorMessage
                                    name='dispositivo'
                                    component='div'
                                    className='field-error text-danger'
                                />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="token" className="form-label">Token</label>
                                <Field 
                                    type="text" 
                                    className="form-control" 
                                    id="token" 
                                    placeholder="Ds3fd3fGd243kfd" 
                                    name="token"
                                />
                                <ErrorMessage
                                    name='token'
                                    component='div'
                                    className='field-error text-danger'
                                />
                            </div>

                            <div className="mb-3">
                                <button 
                                    className="btn btn-outline-info text-uppercase fw-bold"
                                    disabled={loading}
                                ><span style={{marginRight: '5px'}} >Enviar datos</span>
                                {
                                    loading &&
                                    <>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        <span className="visually-hidden">Loading...</span>
                                    </>
                                }
                                    
                                </button>
                            </div>
                        </Form>
                    </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateDispositivo

import React, {useState, useEffect} from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
/*Globales*/
import {urlApi} from './global/Global';
import "animate.css";
// import 'bootstrap-switch-button/css/bootstrap-switch-button.css';
// import 'bootstrap-switch-button/dist/bootstrap-switch-button.min.js';
import './App.css';
/* Importacion de componentes */
import Importar from './views/importacion/Importar';
import Catalogos from './views/importacion/Catalogos';
import UpdateCatalogo from './views/importacion/UpdateCatalogo';
import Headers from './views/importacion/Headers';
import Vista from './views/vista/Vista';
import TableEditablePage from './views/vista/TableEditablePage';
import Home from './views/Home';
import Password from './views/Password';
import EncuentraImprime from './views/EncuentraImprime/EncuentraImprime';

import Etiquetas from './views/etiquetas/Etiquetas';
import VerEtiquetas from './views/etiquetas/VerEtiquetas';
import EditEtiqueta from './views/etiquetas/EditEtiqueta';
import ConfiguracionEtiqueta from './views/etiquetas/ConfiguracionEtiqueta';

import ImportarZpl from './views/etiquetas/ImportarZpl';
import Dispositivos from './views/dispositivos/Dispositivos';
import CreateDispositivo from './views/dispositivos/CreateDispositivo';
import EditDispositivo from './views/dispositivos/EditDispositivo';
import Config from './views/dispositivos/Config';
import Usuarios from './views/usuarios/Usuarios';
import EditUsuario from './views/usuarios/EditUsuario';
import CreateUsuario from './views/usuarios/CreateUsuario';
import Header from './views/layouts/Header';
import Test from './views/test/Test';
import TestPort from './views/test/TestPort';
import Suscripciones from './views/suscripciones/Suscripciones';
import Replaces from './views/languages/Replaces';
import Preformatos from './views/preformatos/Preformatos';
import VerPreformatos from './views/preformatos/VerPreformatos';
import EditPreformatos from './views/preformatos/EditPreformatos';
import Catalogo from './views/etiquetas/Catalogo';

import PreCatalogos from './views/precatalogos/PreCatalogos';
import CreatePrecatalogo from './views/precatalogos/CreatePrecatalogo';
import EtiquetasUsuario from './views/usuarios/EtiquetasUsuario';
import CatalogosUsuario from './views/usuarios/CatalogosUsuario';

import Configuracion from './views/configuracion/Configuracion';

import UsuariosAdm  from './views/usuariosadmin/UsuariosAdm';
import UsuariosAdmCreate from './views/usuariosadmin/UsuariosAdmCreate';
import UsuariosAdmEdit from './views/usuariosadmin/UsuariosAdmEdit';
/* Context global */
import VistaState from './views/vistaUsuarioContext/VistaState';
import UserState from './userContext/UserState';
// Mantenimiento
import Mantenimiento from './views/mantenimiento/Mantenimiento';
// NotFound
import NotFound from './views/layouts/NotFound';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import Welcome from './views/layouts/primer_sesion/Welcome';
import Skeleton from './views/layouts/Skeleton';
import Barcode from './views/test/Barcode';
import About from './views/profile/About';
import Reportes from './views/reportes/Reportes';
import Swal from 'sweetalert2';
import Design from './views/design/Design';

function App() {
  //ELIMINA consoles logs en produccion
  if (process.env.NODE_ENV !== 'development') {
    console.log = () => {}
  }

  const [user, setUser]                                     = useState('');
  const [exist, setExist]                                   = useState(false);
  const [downloadPlantilla, setdownloadPlantilla]           = useState(false);
  const [downloadPlantillaCount, setdownloadPlantillaCount] = useState(0);
  //***/ VARIABLE PARA HACER ENTRAR A LA PAGINA EN MANTENIMIENTO //****/
  //***/ VARIABLE PARA HACER ENTRAR A LA PAGINA EN MANTENIMIENTO //****/
  //***/ VARIABLE PARA HACER ENTRAR A LA PAGINA EN MANTENIMIENTO //****/
  /****/ const [mantenimiento, setMantenimiento] = useState(false) /****/
  /****/ const [fechaMan, setfechaMan] = useState('2022-04-18T15:00:00')
  // /****/ const [fechaMan, setfechaMan] = useState('18/04/2022T13:50:00')
  //***/ VARIABLE PARA HACER ENTRAR A LA PAGINA EN MANTENIMIENTO //****/
  //***/ VARIABLE PARA HACER ENTRAR A LA PAGINA EN MANTENIMIENTO //****/
  //***/ VARIABLE PARA HACER ENTRAR A LA PAGINA EN MANTENIMIENTO //****/

/*************************************************************************************************/
  useEffect(() => {

    const obtenerLog = async () => {
      try {
        const log = await AsyncStorage.getItem('@login');
        if (typeof log =='string') {
          const log_json = JSON.parse(log);
          let headers = '';

          if(log_json !== null){
            // console.log(log_json);
            headers = {
              'Authorization': log_json.token_type+' '+log_json.access_token,
              'Content-Type': 'application/json',
              'X-Requested-With': 'XMLHttpRequest'
            }
            await axios.get(urlApi+'auth/user', {headers: headers})
                      .then(async (response) =>  { 
                        console.log(response);

                        setUser(log_json);
                        setExist(true);
                        console.log('correcto');
                      })
                      .catch(async(error) => {
                        // console.log(error);
                        await AsyncStorage.removeItem(
                          '@login'
                        );
                      });
                      ;
          }

        }
      } catch (error) {
        console.log(error)
      }
    }
    
    // const second = () => { 
    //   if(document.cookie.indexOf('no_mostrar_name2=')==-1){
    //     //Alerta de sistema actualmente en mantenimiento COMENTAR SI YA NO HAY MANTENIMIENTO
    //          Swal.fire({
    //          html: `<h2><span>Aviso de Mantenimiento<span></h2>
    //          <p>Con la finalidad de seguir brindando un servicio de excelencia <b>Web2 Barcode</b> estará en mantenimiento el 
    //             dia <b>viernes 16 de diciembre del año 2022 de 9:00pm a 11:59pm</b>. Reanudando así
    //             los servicios el dia <b>17 de diciembre del 2022 a las 12:00am</b>
    //          </p>

    //          <p>Agradecemos su comprensión</p>

    //         <p>Equipo de <strong>LabelDictate Technologies LLC</strong></p>`,
    //       confirmButtonText:
    //           'OK',
    //         showCloseButton: true,
    //         showCancelButton: true,
    //         cancelButtonText: 'No volver a mostrar',
    //         showClass: {
    //           popup: 'animate__animated animate__fadeInDown'
    //         },
    //         hideClass: {
    //           popup: 'animate__animated animate__fadeOutUp'
    //         }
    //       }).then((result) => {
    //         if (result.value) {
    //           return true;
    //         }else if(result.dismiss=="cancel"){
    //           console.log('adasd');
    //               let expiresdate =  new Date('2068', '1', '02', '11', '20');
    //               let cookievalue = "no_mostrar_value2";
    //               document.cookie = "no_mostrar_name2=" + encodeURIComponent( cookievalue ) + ";expires=" + expiresdate.toUTCString()+'; SameSite=Strict';
    //         }
    //     });
    //     }else{
    //         console.log(document.cookie);
    //   } 
    // }

    obtenerLog();
    // second();
  }, [])

/*************************************************************************************************/
    const roleMiddlewareAll = (role) => { 
      return (role == 1 || role == 2 || role == 3 || role === 4) ? true : false;
    }
/*************************************************************************************************/
    const roleMiddlewareAdmin = (role) => { 
      return (role == 1) ? true : false;
    }
/*************************************************************************************************/

  return (
    <main>
      <UserState
        user={user}
      >
        <VistaState>
          <Router>

            <Header
              exist={exist}
              user={user}
              setExist={setExist} 
              setUser={setUser}
              mantenimiento={mantenimiento}
              fechaMan={fechaMan}
              downloadPlantilla={downloadPlantilla}
              downloadPlantillaCount={downloadPlantillaCount}
            />       

            <Mantenimiento
                mantenimiento={mantenimiento}
                fechaMan={fechaMan}
            />

            <div className="height-100">
              <div className="pb-5" style={{marginTop: '5rem'}}>
                {/* <div className="container">           */}
                  <Switch>
                    {/* <Route exact path="/barcode" render={() => <Barcode />}/> */}
                    <Route exact path="/" render={() => <Home setExist={setExist} setUser={setUser} exist={exist} user={user} />}/>
                    <Route exact path="/reset-password/:token" render={(props) => <Password {...props}/>  }/>
                    <Route exact path="/design" render={() => <Design exist={exist}/>}/>
                                          
                    {/* Para todos los roles y usuarios // (user.role == 1 || user.role == 2 || user.role == 3)*/}
                      <Route exact path="/about" render={() => roleMiddlewareAll(user?.role) ? <About user={user}/> : <NotFound/> } />
                      <Route exact path="/welcome" render={() => roleMiddlewareAll(user?.role) ? <Welcome user={user}/> : <NotFound/> } />
                      <Route exact path="/test" render={() => roleMiddlewareAll(user?.role) ? <Test/> : <NotFound/> }/>
                      <Route exact path="/importar" render={() => roleMiddlewareAll(user?.role) ? <Importar user={user}/> : <NotFound/> }/>
                      <Route exact path="/catalogos" render={() => roleMiddlewareAll(user?.role) ? <Catalogos/> : <NotFound/> }/>
                      <Route exact path="/catalogos/headers" render={(props) => roleMiddlewareAll(user?.role) ? <Headers {...props} /> : <NotFound/> }/>
                      <Route exact path="/catalogos/update" render={(props) => roleMiddlewareAll(user?.role) ? <UpdateCatalogo {...props} user={user}/> : <NotFound/> }/>
                      {/* <Route exact path="/table" render={() => roleMiddlewareAll(user?.role) ? <TableEditablePage /> : <NotFound/> }/> */}
                      <Route exact path="/vista/:catalogo/:etiqueta" render={(props) => roleMiddlewareAll(user?.role) ? <Vista user={user} {...props}/> : <NotFound/> }/>
                      {/* <Route exact path="/encuentraimprime" render={(props) => roleMiddlewareAll(user?.role) ? <EncuentraImprime  {...props}/> : <NotFound/> }/> */}
                      <Route exact path="/etiquetas" render={() => roleMiddlewareAll(user?.role) ? <Etiquetas user={user} /> : <NotFound/> }/>
                      <Route exact path="/see-labels" render={() => roleMiddlewareAll(user?.role) ? <VerEtiquetas setdownloadPlantillaCount={setdownloadPlantillaCount} setdownloadPlantilla={setdownloadPlantilla}/> : <NotFound/> }/>
                      <Route exact path="/edit-label/:id" render={(props) => roleMiddlewareAll(user?.role) ? <EditEtiqueta {...props} user={user}/> : <NotFound/> }/>
                      <Route exact path="/label-configuration/:id" render={(props) => roleMiddlewareAll(user?.role) ? <ConfiguracionEtiqueta {...props} user={user}/> : <NotFound/> }/>
                      <Route exact path="/importar-zpl" render={() => roleMiddlewareAll(user?.role) ? <ImportarZpl/> : <NotFound/> }/>
                      <Route exact path="/dispositivos" render={() => roleMiddlewareAll(user?.role) ? <Dispositivos/> : <NotFound/> }/>
                      <Route exact path="/dispositivos/create" render={() => roleMiddlewareAll(user?.role) ? <CreateDispositivo/> : <NotFound/> }/>
                      <Route exact path="/dispositivos/edit" render={(props) => roleMiddlewareAll(user?.role) ? <EditDispositivo {...props}/> : <NotFound/> }/>
                      <Route exact path="/dispositivos/config" render={(props) => roleMiddlewareAll(user?.role) ? <Config user={user} {...props}/> : <NotFound/> }/>
                      <Route exact path="/suscripciones" render={() => roleMiddlewareAll(user?.role) ? <Suscripciones setUser={setUser} user={user} /> : <NotFound/> }/>                  
                      <Route exact path="/catalogo" render={(props) => roleMiddlewareAll(user?.role) ? <Catalogo user={user} downloadPlantillaCount={downloadPlantillaCount} setdownloadPlantillaCount={setdownloadPlantillaCount} setdownloadPlantilla={setdownloadPlantilla}/> : <NotFound/> }/>                  
                      <Route exact path="/usuarios/adm" render={(props) => roleMiddlewareAll(user?.role) ? <UsuariosAdm user={user}/> : <NotFound/> }/>                  
                      <Route exact path="/usuarios/adm/create" render={() => roleMiddlewareAll(user?.role) ? <UsuariosAdmCreate /> : <NotFound/> }/>                  
                      <Route exact path="/usuarios/adm/edit/:id" render={(props) => roleMiddlewareAll(user?.role) ? <UsuariosAdmEdit {...props} /> : <NotFound/> }/>                  
                      <Route exact path="/configuracion" render={() => roleMiddlewareAll(user?.role) ? <Configuracion /> : <NotFound/> }/>                  
                    {/* {user.role == 1 &&  */}
                      <Route exact path="/usuarios/" render={() => roleMiddlewareAdmin(user?.role) ? <Usuarios/> : <NotFound/> }/>
                      <Route exact path="/usuarios/edit/:id" render={(props) => roleMiddlewareAdmin(user?.role) ? <EditUsuario {...props}/> : <NotFound/> }/>    
                      <Route exact path="/usuarios/etiquetas/:id" render={(props) => roleMiddlewareAdmin(user?.role) ? <EtiquetasUsuario {...props}/> : <NotFound/> }/>    
                      <Route exact path="/usuarios/catalogos/:id" render={(props) => roleMiddlewareAdmin(user?.role) ? <CatalogosUsuario {...props}/> : <NotFound/> }/>    
                      <Route exact path="/usuarios/create" render={() => roleMiddlewareAdmin(user?.role) ? <CreateUsuario /> : <NotFound/> }/>    
                      <Route exact path="/languages/replaces" render={() => roleMiddlewareAdmin(user?.role) ? <Replaces /> : <NotFound/> }/>
                      <Route exact path="/preformatos/" render={(props) => roleMiddlewareAdmin(user?.role) ? <VerPreformatos user={user} {...props}/> : <NotFound/> }/>
                      <Route exact path="/preformatos/edit" render={(props) => roleMiddlewareAdmin(user?.role) ? <EditPreformatos {...props}/> : <NotFound/> }/>
                      <Route exact path="/preformatos/create" render={() => roleMiddlewareAdmin(user?.role) ? <Preformatos /> : <NotFound/> }/>
                      <Route exact path="/precatalogos" render={(props) => roleMiddlewareAdmin(user?.role) ? <PreCatalogos user={user} {...props}/> : <NotFound/> }/>
                      <Route exact path="/precatalogos/create" render={() => roleMiddlewareAdmin(user?.role) ? <CreatePrecatalogo /> : <NotFound/> }/>

                      <Route exact path="/reportes" render={() => roleMiddlewareAdmin(user?.role) ? <Reportes /> : <NotFound/> }/>
                    
                    <Route exact path="*" render={() => <NotFound noTimeInterval={true}/>} /> 
                  </Switch>
                </div>
              </div>
          </Router>
        </VistaState>
      </UserState>
    </main>
  );
}

export default App;

import React, {useEffect, useState, useRef, useContext} from 'react'
import axios from 'axios';
import Swal from 'sweetalert2';
/*Globales*/
import {urlApi} from '../../../global/Global';
import { useHistory } from "react-router-dom";
import AsyncStorage from '@react-native-async-storage/async-storage';
import VistaContext from '../../vistaUsuarioContext/VistaContext';
import { unauthenticated } from '../../../helpers/Helper';
import funciones from '../../utilidades/funciones';
import ModalImpresoras from '../../etiquetas/ModalImpresoras';
import { useTranslation } from 'react-i18next';


const Paso3 = ({impresoras, setImpresoras, setpasos}) => {

    let history = useHistory();
    const { t }                 = useTranslation();

    const [loading, setloading] = useState(false);
    const [carga, setCarga]     = useState(false);
    const [url, seturl]         = useState('');

    const btn_ref_imp   = useRef();
    const btn_close_imp = useRef();
    const btn_conc      = useRef();
    const btn_conc_cls  = useRef();
    const btn_cont_imp  = useRef();

    const [etiquetas, setetiquetas] = useState([]);
    const [eti, seteti]             = useState();

    const [elementoResaltante, setElementoResaltante] = useState(false);
    const [tutorialConcluido, settutorialConcluido]   = useState(false);

    const [contador, setcontador]                     = useState(false);
//***************************************************************************************************//

    useEffect(() => {
        setloading(true);

        const getEtiquetas = async (params) => {
            const log = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            }
            await axios.get(urlApi+'etiquetas/index', {headers: headers})
                .then((response) => {
                    //Actualiza estados del formulario
                    setetiquetas(response.data.etiquetas);
                    seturl(response.data.url);
                    // setLoading(false);
                    console.log(response.data);
                })
                .catch((error) => {
                    console.log(error);
                }); 
        }
        
        getEtiquetas();
        setloading(false);

    }, [])

//Imprimir etiquetas********************************************************************************//
    const imprimirEtiquetas = async (etiqueta) => {
        setElementoResaltante(true);
        console.log(etiqueta);
        // return;
        setCarga(true);
        let   lista  = '';
        let   conteo = 0;
        let   pattern = 'pattern';
        // console.log(x);
        console.log(etiqueta);
        // return;
        etiqueta.configuraciones.map(async (e,i) => {
            console.log(e);
            // if(e.type_config_id == 2){
            if(e.types.front_back == 'front'){

                if(e.type_config_id == 4){
                    pattern='pattern="[0-9]*" inputmode="numeric"';
                }else{
                    pattern = 'pattern=""'
                }

                lista+=`
                    <div class="col-md-12 mb-2">
                        <label class="fw-bold mb-1">${e.valor}</label>
                        <input ${pattern} type="${e.types.type}" name="${e.variable}" required class="form-control text-center blc-fl" aria-describedby="button-addonalue="1" id="swal-input${i}" placeholder="${e.variable}">
                    </div>`;
                conteo++;
                }
        });

        // return;
        let jwal = '';
        let preguntas = {};
        if(conteo > 0){
        jwal = await Swal.fire({
            html: `<div class="row">
            <div class="col">
                <span class="errores text-danger text-uppercase fw-bold mb-3 d-none">
                *Llene los campos
                </span>
            </div>  
            </div>  
            <div class="row">
            ${lista}
            </div>`,
            allowOutsideClick: false,
            // showCancelButton: true,        
            confirmButtonText: 'Imprimir',       
            showCloseButton: false,
            customClass: 'z-index-custom-sa',
            didOpen: () =>{
                if(document.querySelector('#swal-input0')){
                    document.querySelector('#swal-input0').focus();                 
                }           
            },  
            preConfirm: () => {

            const x = Array.from(document.querySelectorAll('.blc-fl'));
            x.forEach(e =>{
                if(e.value.trim() == ''){
                    if(document.querySelector('.errores').classList.contains('d-none')){
                        document.querySelector('.errores').classList.remove('d-none')
                    }
                    Swal.showValidationMessage('Llene los campos')
                    // return false;
                }
            })
            return x;
            // return document.querySelectorAll('.blc-fl');
            }
        });

        // console.log(JSON.parse(jwal.value));
        if(!jwal.isConfirmed){
            setCarga(false);
            return false;
        } 
        console.log(jwal);
        // console.log(jwal.value);
        // return;
        jwal.value.map((e,i)=>{
            preguntas[e.name] = e.value;
            // console.log(e.name+' - '+e.value);
        })
        
        }else{
            preguntas = '';
        } 

        //Obtenemos fechas
        const d = new Date();
        // const anio = d.getDate+'/'+(d.getMonth()+1)+'/'+d.getFullYear()
        const anio = d.toLocaleDateString('default');
        const hora = d.toLocaleTimeString('default', {
            // en-US can be set to 'default' to use user's browser settings
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });

        const datos_enviar = {
            etiqueta: etiqueta.id,
            // cantidades: cantidad_send,
            preguntas: preguntas,
            anio     : anio,
            hora     : hora
        };

        console.log(preguntas);
        console.log(datos_enviar);

        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
        await axios({
            url: urlApi+'etiquetas/print',
            method: 'POST',
            data: datos_enviar,
            headers: headers,
            // responseType: 'blob', // important
        }).then(async (response) =>{
            console.log(response.data);
            if(!response.data.response){
                Swal.fire({
                    icon: 'info',
                    title: response.data.mensaje,
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                });

                return false;
            }

            console.log(etiqueta.impresora.impresora);
            const res = await funciones._write(log_json, etiqueta.impresora.impresora, response.data.mensaje, true);
            if(res.data != false){
                btn_conc.current.click();
                // history.push('/');
            }else{

            }
        })
        .catch(function (error) {
            console.log(error);
            if(error.response.data.message != undefined && error.response.data.message == 'Unauthenticated.'){
                unauthenticated(error);
            }else{
                // return;
                Swal.fire({
                    icon: 'error',
                    title: error,
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                });
            }

        }).then(()=>{
            setCarga(false);
        });
    }

/****************************************************************************************************/

    const concluirTutorial = async() => {
        const log      = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers  = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
        await axios.get(urlApi+'usuarios/tutorial', {headers: headers})
                .then(async (response) => {
                    console.log(response.data);
                    let _log = log_json;
                    if(response.data.response){
                        _log.tutorial = 1;
                        _log = JSON.stringify(_log);
                        await AsyncStorage.removeItem(
                            '@login'
                        );
                        
                        await AsyncStorage.setItem(
                                '@login',
                                _log
                        );

                        // window.addEventListener('popstate', function(event){});
                        // window.addEventListener('popstate', function(event) {
                        //     window.history.pushState("/", document.title, window.location.href);
                        // });
                        history.push("/");
                    }
            })
        history.push('/');
    
    }

/****************************************************************************************************/

    // useEffect(() => {
    //     if(contador){
    //         btn_cont_imp.current.click();
    //     }
    // }, [impresoras])
    

/****************************************************************************************************/

    const impresora = async (params, primer_asignacion = false) => { 
        setCarga(true);
        console.log(params);
        seteti(params);
        const log      = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const _text    = await AsyncStorage.getItem('i18nextLng');
        // const _text  = t('utilidades.funciones.error_text2barcode');
        const _impresoras = await funciones._available(log_json, _text);
        console.log(_impresoras);
        if(_impresoras != false){
            // setcontador(true);
            setImpresoras(_impresoras.data.printer);
            btn_ref_imp.current.click();
        }
        setCarga(false);

    }

/***************************************************************************************************/

    const returnPrint = (params) => { 

        const x = eval('(' + params + ')');
        console.log(x.name);
        return x.name;

    }

/**************************************************************************************************/
  return (
    <>

        {/* Modal impresoras  */}
            <ModalImpresoras
                btn_ref_imp={btn_ref_imp}
                btn_close_imp={btn_close_imp}
                impresoras={impresoras}
                setImpresoras={setImpresoras}
                funciones={funciones}
                etiqueta={eti}
                setetiquetas={setetiquetas}
                // refreshEtiqueta={false}
                etiquetas={etiquetas}
            />
        {/* Fin modal impresoras  */}

        <div className=' loader-cotizador'></div>
        <div className="row justify-content-center">
            <div className="col-md-12">                
                <div className="card shadow ">                
                    <div className="card-header bg-card-header ">
                        <div className='row align-items-center'>
                            <div className='col-md-6 my-2'>
                                <span className="fw-bold text-white">Plantillas de tickets y etiquetas</span>

                            </div>
                            

                            {/* <div className='col-md-6 my-2 text-end elemento-resaltante'> */}
                            <div className="col-md-6 my-2 text-end">
                                <a href="#" className="btn btn-warning btn-sm fw-bold shadow res-w100 "> 
                                    <span>Catalogo de plantillas web</span>
                                </a>
                            </div>
                            

                        </div>
                    </div>
                    <div className="card-body">

                        {
                        !loading ?
                        <>
                            <div className={`${carga ? 'row pb-5' : 'd-none'}`}>
                                <div className="col-md-12 text-center">
                                    <div className="spinner-border text-info" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </div>

                            <div className="row row-cols-1 row-cols-md-3 g-4 border-top">
                                {
                                    etiquetas.length > 0 &&
                                        etiquetas.map((e, i) =>{
                                            return <div className={`${(!elementoResaltante && i == 0) ? "col elemento-resaltante2" : "col"}`} key={`etiquetas-${i}`}>
                                                <div className="card h-100 shadow border border-3 rounded">
                                                    <div className='p-1 shadow-sm'>

                                                        <div className='p-1 text-center border rounded bg-white'>
                                                            <img src={url+'/'+e.image} className="card-img-top w-75" alt={e.nombre} />
                                                        </div>
                                                    </div>

                                                    <div className="card-body">
                                                        <div className='row'>
                                                                <h5 className="card-title fw-bold">{e.nombre}</h5>
                                                                <p className="card-text mb-0">Lenguaje: {e.language.name}</p>
                                                                <p className="card-text mb-0">Desc: {e.descripcion}</p>
                                                                {
                                                                    e.impresora_id != null &&
                                                                    <div className='col-md-12'>
                                                                        <p className="card-text fw-bold fw-bold mt-2 mb-0">
                                                                            Impresora: 
                                                                        </p>
                                                                        <p className="card-text fw-bold badge bg-secondary text-wrap ">
                                                                            {                                                                                
                                                                                returnPrint(e.impresora.impresora)                                                                                    
                                                                            }
                                                                        </p>
                                                                    </div>
                                                                }

                                                        </div>
                                                    </div>
                                                    <div className='p-3 row'>                                                                                                                                                                                                                                                                       
                                                        
                                                        <button 
                                                            ref={btn_cont_imp}
                                                            disabled={carga}
                                                            onClick={
                                                                e.impresora_id == null 
                                                                ? () => impresora(e.id)
                                                                : () => imprimirEtiquetas(e)
                                                                
                                                            }
                                                            className={
                                                                e.impresora_id == null 
                                                                ? 'btn btn-danger btn-xl fw-bold text-uppercase border shadow mb-2 fs-3 text'
                                                                : 'btn btn-success btn-xl fw-bold text-uppercase border shadow mb-2 fs-3 text' 
                                                            }
                                                        > 
                                                        {
                                                            e.impresora_id == null 
                                                            ? t("see_labels.print_2")
                                                            : t("see_labels.print")
                                                        }
                                                        </button> 
                                                                                                                                                                    
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                }
                            </div>                            
                        </>
                        :
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </div>
                        }
                    </div>

                </div>
            </div>
        </div>

        {/* Tutorial Concluido  */}
         {/* <!-- Button trigger modal --> */}
         <button type="button" class="d-none" data-bs-toggle="modal" data-bs-target="#staticBackdrop3" ref={btn_conc}>
            Launch static backdrop modal
        </button>

        {/* <!-- Modal --> */}
        <div class="modal border fade" style={{zIndex: 10000}} id="staticBackdrop3" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel3" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div class="modal-content">
                    <div class="align-items-center border-bottom pb-2">
                        <div className='d-flex justify-content-center mt-2'>
                            <i class="fab fa-angellist h1 mb-0 gif_lado_lado"></i>
                            <i class="fa-regular fa-face-laugh-wink h1 mb-0"></i>
                        </div>
                        <h3 class="modal-title text-center fw-bold" id="staticBackdropLabel3">
                           {
                               t("welcome.tutorial_concluded")
                           }
                        </h3>
                        <button type="button" class="btn-close d-none" data-bs-dismiss="modal" aria-label="Close" ref={btn_conc_cls}></button>
                    </div>
                    <div class="modal-body">  
                           <span className='h5'>
                               {
                                   t("welcome.tutorial_concluded_text")
                               }
                           </span>
                    </div>
                    <div class="modal-footer"> 
                        <button type="button" 
                                class="btn btn-primary" 
                                data-bs-dismiss="modal"
                                onClick={() => concluirTutorial()}
                        >OK</button>
                    </div>
                </div>
            </div>
        </div>  
    </>
  )
}

export default Paso3
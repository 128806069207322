import React, {useEffect, useState} from 'react'
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
/*Globales*/
import {urlApi} from '../../global/Global';
import {gapi} from 'gapi-script';
// import { Tooltip } from 'bootstrap';
// import 'bootstrap-switch-button/css/bootstrap-switch-button.css';
// import 'bootstrap-switch-button/dist/bootstrap-switch-button.js';
import BootstrapSwitchButton from 'bootstrap-switch-button-react';
import ComprobarVista from '../vistaUsuarioContext/ComprobarVista';
const Config = ({user}) => {

/*******************************************************************/

    const [signedInUser, setSignedInUser] = useState('');
    const [isLoadingGoogleDriveApi, setIsLoadingGoogleDriveApi] = useState(false);
    /*--------------*/

/*******************************************************************/
    // Client ID and API key from the Developer Console
    const CLIENT_ID = '191181423961-lqknnrp0amj9pj0o4d16r8aafu611le9.apps.googleusercontent.com';
    const API_KEY = 'AIzaSyBIK-3IqFaUg03I0elDDLpqUO4UaKIQ6RY';

    // Array of API discovery doc URLs for APIs used by the quickstart
    const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"];

    // Authorization scopes required by the API; multiple scopes can be
    // included, separated by spaces.
    const SCOPES = 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile';

/******************************************************************************************************************************/

    // const tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    // const tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    //     return new Tooltip(tooltipTriggerEl)
    // });
    const [menu, setMenu] = useState([]);
    const [loading, setLoading] = useState(false);
    const [firstloading, setFirstLoading] = useState(false);
    /*Sweet alert*/
    const MySwal = withReactContent(Swal);
    /****************************************/
    const configs = async (params) => {
               
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
        await axios.get(urlApi+'config', {headers: headers})
                .then(response=>{
                    console.log(response.data);
                    setMenu(response.data);
                })
                .catch(error => {
                    console.log(error);
                    MySwal.fire({
                        icon : 'error',
                        title: 'Error',
                        text : 'Ocurrio un error, vuelva a intentarlo',
                    });
                }).then(() =>{
                    // setloading(false);
                })                 
    }
/*******************************************************/      

    const changeValues = (value, i, j) => {
        // document.querySelector(`#inlineCheckbox${i}${j}`).checked = value;
        console.log(value);
        console.log(i);
        console.log(j);
        // return;
        const x = menu[i].config.map((item, c) => {
                        return c == j
                        ? { ...item,  values : {...item.values, value: value} }
                        : item
                    })
        setMenu(
            menu.map((item, c) =>
                c == i
                ? {...item, config: x}
                : item
            )
        )
        console.log(x);
        console.log(menu);
    }
    

/*******************************************************/        

    const guardar = async () => {
        console.log(menu);
        // return;
        setLoading(true);
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }

        // const formdata = new FormData();
        // formdata.append("input", menu);
        const send_data = {
            menu: menu,
            conta: menu.length
        }
                        
        await axios.post(urlApi+'config', send_data, {headers: headers})
                .then(async response=>{
                    console.log(response.data);
                    if(response.data.respuesta === 'correcto'){        
                    /*CREAMOS EL ARCHIVO */
                        var blob = new Blob([response.data.contents],
                            { type: "text/plain;charset=utf-8" });
                            
                           guardarDrive(blob);                                                
                    }else{                             
                        MySwal.fire({
                            icon : 'error',
                            title: 'Error',
                            text : 'Ocurrio un error, vuelva a intentarlo',
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                    MySwal.fire({
                        icon : 'error',
                        title: 'Error',
                        text : 'Ocurrio un error, vuelva a intentarlo',
                    });
                });
    }
    

/*******************************************************/        
    useEffect(() => {           
        setFirstLoading(true);
        handleClientLoad();
        console.log(signedInUser);
        configs();
        setFirstLoading(false);
    }, []);


/**********************************************************/

    const guardarDrive = async (file) => {
        /*CREAMOS EL ARCHIVO */
        /**OBTENEMOS LA CARPETA LDDATA */
        let carpeta = '';
        let id_carpeta = '';
        await gapi.client.drive.files.list({ 
            pageSize: 1,
            fields  : 'nextPageToken, files(id, name, mimeType, modifiedTime)',
            'q'     : "mimeType ='application/vnd.google-apps.folder' and trashed = false and name contains 'LDDATA'",
        }).then(res =>{
            console.log(res);
            carpeta = JSON.parse(res.body);
        });
        if(carpeta.files.length <= 0){

            const fileMetadata = {
                'name'    : 'LDDATA',
                'mimeType': 'application/vnd.google-apps.folder'
            };
              
            await gapi.client.drive.files.create({ 
                resource: fileMetadata,
                fields: 'id'
            }).then(res =>{
                console.log(res);
                carpeta = JSON.parse(res.body);
            });

            id_carpeta = carpeta.id;
        }else{
            id_carpeta = carpeta.files[0].id;
        }

        let config = '';

        await gapi.client.drive.files.list({ 
            pageSize: 1,
            fields: 'nextPageToken, files(id, name, mimeType, modifiedTime)',
            q: `mimeType = 'text/csv' and '${id_carpeta}' in parents and trashed = false and name contains 'config'`,
        }).then(res =>{
            // console.log(res.body);
            config = JSON.parse(res.body);
            console.log(config.files);
        });
        
        /*CREAMOS O ACTUALIZAMOS ARCHIVO*/
        let fileMetadata = {};
        if(config.files.length <= 0){
            fileMetadata = {
                name    : 'config.properties',
                mimeType: 'text/csv',
                parents : [id_carpeta]
            };
        }else{
            fileMetadata = {
                name    : 'config.properties',
                mimeType: 'text/csv',
                
            };
        }

        const headers = {
            'Authorization': 'Bearer '+gapi.auth.getToken().access_token,
        }

        let formData = new FormData();
        formData.append("metadata", new Blob([JSON.stringify(fileMetadata)], { type: 'application/json' }));
        formData.append("file", file);

        if(config.files.length <= 0){
              
            await axios({
                method: 'POST',
                url: 'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart',
                headers: headers,
                data: formData,
                }).then(response =>{
                    console.log(response);
                    MySwal.fire({
                            icon : 'success',
                            title: 'Correcto',
                            text : 'Se agrego correctamente',
                    }); 
                }).catch(err =>{
                    console.log(err);
                    MySwal.fire({
                        icon : 'error',
                        title: 'Error',
                        text : 'Ocurrio un error, vuelva a intentarlo',
                    });
                }).then(() =>{
                    setLoading(false);
                });

        }else{
            const drive_id = config.files[0].id;
            await axios({
                method: 'PATCH',
                url: 'https://www.googleapis.com/upload/drive/v3/files/'+drive_id+'?uploadType=multipart',
                headers: headers,
                data: formData,
                }).then(async response =>{
                    console.log(response);
                    MySwal.fire({
                        icon : 'success',
                        title: 'Correcto',
                        text : 'Se agrego correctamente',
                    }); 
                }).catch(err =>{
                    console.log(err);
                    MySwal.fire({
                        icon : 'error',
                        title: 'Error',
                        text : 'Ocurrio un error, vuelva a intentarlo',
                    });
                }).then(() =>{
                    setLoading(false);
                });
        }

    }
        
/*******************************************************************************/
/*******************************************************************************/
/***************************** DRIVE API ***************************************/
/*******************************************************************************/
/*******************************************************************************/
   /**
     *  Sign in the user upon button click.
     */
    const handleAuthClick = (event) => {
        gapi.auth2.getAuthInstance().signIn();
    };

    /**
     *  Called when the signed in status changes, to update the UI
     *  appropriately. After a sign-in, the API is called.
     */

    /**
     *  Sign out the user upon button click.
     */
    const handleSignOutClick = (event) => {

        MySwal.fire({
            title: '¿Cerrar sesión de Google?',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si',
            cancelButtonText: 'Cancelar'
          }).then((result) => {
            if (result.isConfirmed) {
                // setListDocumentsVisibility(false);
                gapi.auth2.getAuthInstance().signOut();
                // setDocuments([]);    
                setSignedInUser('');
            }
          })
        
    };
    
    /**
     *
     */
    
    const updateSigninStatus = (isSignedIn) => {
        if (isSignedIn) {
            // Set the signed in user
            setSignedInUser(gapi.auth2.getAuthInstance().currentUser);
            // setIsLoadingGoogleDriveApi(false);
            console.log(gapi.auth2.getAuthInstance().currentUser.le.wt);
            // list files if user is authenticated
            // listFiles();
        } else {
            // prompt user to sign in
            // handleAuthClick();
        }
    };

/*--------------------------------------------- */
    const initClient = () => {
        setIsLoadingGoogleDriveApi(true);
        gapi.client
            .init({
                apiKey: API_KEY,
                clientId: CLIENT_ID,
                discoveryDocs: DISCOVERY_DOCS,
                scope: SCOPES,
            })
            .then(
                function () {
                    // Listen for sign-in state changes.
                    gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);

                    // Handle the initial sign-in state.
                    updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
                },
                function (error) {
                    console.log(error);
                }
            );
    };
/*--------------------------------------------- */
    const handleClientLoad = () => {
        gapi.load('client:auth2', initClient);
    };
/*--------------------------------------------- */
    return (
        <>
         < ComprobarVista/>
        {/* {
            firstloading 
            ? <p>cargando</p>
            : */}
            <div className="row justify-content-center">
                <div className="col-md-12">
                    <div className="card">                    
                        <div className="card-header" style={{backgroundColor: '#212529'}}>
                            <span className='text-white fw-bold'>Configuración de Suite Mobile POS PRO</span> 
                        </div>
                        
                        {/* <input id="chkSwitch" type="checkbox" data-toggle="switchbutton" /> */}
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="card border-secondary mb-3">
                                        <div className="card-body">
                                            <form>
                                                <div className='row'>
                                                    {
                                                    menu.length > 0 &&
                                                        menu.map((e,i) => {                                            
                                                            return <div className='col-md-6 mb-3' key={'asd-'+i}>
                                                                <div className="rounded border border-secondary h-100">
                                                                    <div className="card-header text-center" style={{height: '5rem'}}>
                                                                        <h5 className='fw-bold'>{e.menu}</h5>
                                                                    </div>
                                                                    <div className="card-body">
                                                                        {
                                                                        e.config != undefined &&
                                                                            e.config.map((confi, j) =>{
                                                                                return <div key={'inputs-'+j}>
                                                                                    {                                                                            
                                                                                    confi.type == 'checkbox' &&
                                                                                    <>
                                                                                        <div className="row">
                                                                                            {/* <div className="form-check form-check-inline"> */}
                                                                                            {/* <div className="form-check form-switch">
                                                                                                {
                                                                                                    <input 
                                                                                                        className="form-check-input" 
                                                                                                        type="checkbox"
                                                                                                        role="switch"
                                                                                                        id={`inlineCheckbox${i}${j}`} 
                                                                                                        // name={`${confi.name}`} 
                                                                                                        onChange={
                                                                                                            (e) => changeValues(e.target.checked, i, j)
                                                                                                        }
                                                                                                        checked={
                                                                                                            confi.values == null
                                                                                                            ? true
                                                                                                            : confi.values.value == 0
                                                                                                                ? false
                                                                                                                : true
                                                                                                        }
                                                                                                        disabled={loading}

                                                                                                    />
                                                                                                }
                                                                                                <label className="form-check-label" htmlFor={`inlineCheckbox${i}${j}`}>{confi.descrip}</label>
                                                                                            </div> */}
                                                                                            <div className="col-md-4">
                                                                                                <BootstrapSwitchButton 
                                                                                                    style='w-100'
                                                                                                    onlabel='ON'
                                                                                                    offlabel='OFF'
                                                                                                    onChange={
                                                                                                        (checked) => changeValues(checked, i, j)
                                                                                                    }
                                                                                                    checked={
                                                                                                        confi.values == null
                                                                                                        ? true
                                                                                                        : confi.values.value == 0
                                                                                                            ? false
                                                                                                            : true
                                                                                                    }
                                                                                                    disabled={loading}
                                                                                                />
                                                                                            </div>
                                                                                            <div className="col-md-8">
                                                                                                <span className="form-check-label ml-3">{confi.descrip}</span>
                                                                                            </div>
                                                                                        </div>                                                                                    
                                                                                    </>
                                                                                    }
                                                                                    {
                                                                                    confi.type == 'text' &&
                                                                                    <>
                                                                                        <div className="row align-items-center">
                                                                                            <label htmlFor="inputPassword" className="col-sm-6 col-form-label">{confi.descrip}</label>
                                                                                            <div className="col-sm-6">
                                                                                            {
                                                                                                    <input 
                                                                                                        type="text" 
                                                                                                        className="form-control" 
                                                                                                        name={`${confi.name}`} 
                                                                                                        onChange={(e) => changeValues(e.target.value, i, j)}
                                                                                                        value={
                                                                                                            confi.values == null 
                                                                                                            ? ''
                                                                                                            : confi.values.value
                                                                                                        }
                                                                                                        disabled={loading}
                                                                                                    />
                                                                                            }
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                    }
                                                                                    {
                                                                                    confi.type == 'number' &&
                                                                                    <>
                                                                                        <div className="row align-items-center">
                                                                                            <label htmlFor="inputPassword" className="col-sm-6 col-form-label">{confi.descrip}</label>
                                                                                            <div className="col-sm-6">
                                                                                            {
                                                                                                    <input 
                                                                                                        type="number" 
                                                                                                        className="form-control" 
                                                                                                        name={`${confi.name}`} 
                                                                                                        onChange={(e) => changeValues(e.target.value, i, j)}
                                                                                                        value={
                                                                                                            confi.values == null 
                                                                                                            ? ''
                                                                                                            : confi.values.value
                                                                                                        }
                                                                                                        disabled={loading}
                                                                                                        required
                                                                                                    />
                                                                                            }
                                                                                            </div>
                                                                                        </div>
                                                                                    </>
                                                                                    }
                                                                                    <div className='border-bottom border-2 my-1'></div>
                                                                                    {/* <hr className='my-2' style={{border: '5px'}}/> */}
                                                                                </div>
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        })

                                                    }
                                                </div>
                                                <>
                                    {
                                        user.google == 0 ?
                                        <>
                                            {                                        
                                            signedInUser =='' ?
                                            <>
                                                <div>
                                                    <p className='mb-0 fw-bold text-danger text-uppercase'>
                                                        Debes iniciar sesión con una cuenta de Google para guardar la configuración
                                                    </p>
                                                </div>
                                                <button 
                                                    className="btn btn-lg btn-light bg-white border my-3" 
                                                    type="button"
                                                    onClick={() => handleAuthClick()}
                                                >
                                                    <div className='d-flex align-items-center justify-content-center'>
                                                        <img src='https://developers-dot-devsite-v2-prod.appspot.com/identity/sign-in/g-normal.png'/>
                                                        <span>Iniciar sesión con Google</span>
                                                    </div>
                                                    {/* <FontAwesomeIcon icon={fabGoogle} size="3x"/> Iniciar sesión con Google */}
                                                </button>
                                            </>
                                            :
                                                <div className='row align-items-center mt-3'>
                                                    <div className='col-md-6 mb-3'>
                                                        <button 
                                                            type='button' 
                                                            className='btn btn-dark text-uppercase fw-bold'  
                                                            onClick={() => guardar()}
                                                            disabled={loading}    
                                                        >
                                                            <div className='align-items-center'>
                                                                <span >
                                                                    Guardar y enviar
                                                                </span>
                                                                {
                                                                    loading &&
                                                                    <>
                                                                        <span style={{marginLeft: '.5rem'}} className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                        <span className="visually-hidden">Loading...</span>
                                                                    </>
                                                                }

                                                            </div>
                                                        </button>
                                                    </div>
                                                    <div className='col-md-6 d-flex align-items-center mb-3'>
                                                        <button 
                                                            data-bs-toggle="tooltip" data-bs-placement="top" title="Cerrar Sesión"
                                                            className="btn bg-white border rounded d-flex align-items-center"
                                                            onClick={() => handleSignOutClick()}
                                                            disabled={loading}
                                                        ><img src='https://developers-dot-devsite-v2-prod.appspot.com/identity/sign-in/g-normal.png'/>
                                                        {
                                                            signedInUser != '' &&
                                                            <span>{signedInUser.le.wt.cu}</span>
                                                        }
                                                        </button>
                                                    </div>
                                                </div>
                                            }
                                        </>
                                        :
                                        // <div className='row'>
                                            <div className='row align-items-center'>
                                                <div className='col-md-4'>
                                                    <button 
                                                        type='button' 
                                                        className='btn btn-dark text-uppercase fw-bold res-w100'  
                                                        onClick={() => guardar()}
                                                        disabled={loading}    
                                                        >
                                                        <div className='align-items-center'>
                                                            <span >
                                                                Guardar y enviar
                                                            </span>
                                                            {
                                                                loading &&
                                                                <>
                                                                    <span style={{marginLeft: '.5rem'}} className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                    <span className="visually-hidden">Loading...</span>
                                                                </>
                                                            }

                                                        </div>
                                                    </button>
                                                </div>
                                                <div className='col-md-8 d-flex align-items-center'>
                                                    <div 
                                                        className="btn d-flex align-items-center p-1 res-w100"
                                                    ><img src='https://developers-dot-devsite-v2-prod.appspot.com/identity/sign-in/g-normal.png'/>
                                                    {
                                                        signedInUser != '' &&
                                                        <span>{signedInUser.le.wt.cu}</span>
                                                    }
                                                    </div>
                                                </div>
                                            </div>

                                        // </div>
                                    }
                                    </>
                                        
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
        // }
    )
}

export default Config

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
// import t2b_win from '../../img/t2b_win.gif';
/**/
import _windows from '../../video/windows.mp4';
import _android from '../../video/android.mp4';
/************************************************************************/
const funciones = {
    _get_url: async function(){
        const userAgent        = window.navigator.userAgent,
        platform         = window.navigator?.userAgentData?.platform ?? window.navigator.platform,
        macosPlatforms   = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms     = ['iPhone', 'iPad', 'iPod'],
        os               = null;

        // let url = 'http://localhost:';
        let url = 'https://localhost:9101';
        
        try {
            await axios.get(`${url}/default`)            
            return url;
            
        } catch (error) {
            url = 'http://localhost:9100'
            return url;
        }
        
    },
    _available: async function(log_json, _text, fail = false){ // Tipo post

        let url = await funciones._get_url();
        console.log('Avaiable impresoras');
        // console.log(url+log_json.puerto);
        console.log(url);
        let x;
        let text = '';
        (_text === 'es') 
        ? text = 'Error debe iniciar el servicio de text2barcode o instalarlo en el siguiente enlace: '  
        : text = 'Error you must start the text2barcode service or install it in the following link: '  
        
        try {
            // x = await axios.get(`${url}${log_json.puerto}/available`);
            x = await axios.get(`${url}/available`);
            return x;
        } catch (error) {
            if(!fail){
                this._fail_text2barcode(error, text)
            }
            // alert(error);
            console.log(error);
            return false;
        }
        
    },
/************************************************************************/
    // _default: async function(log_json, data){ // Tipo post
    _default: async function(log_json){ // Tipo post

        let url = await funciones._get_url();
        console.log('Default impresorasa');
        // console.log(url+log_json.puerto);
        console.log(url);
        try {
            // const res = await axios.get(`${url}${log_json.puerto}/default`)            
            const res = await axios.get(`${url}/default`)            
            return res.status;
        } catch (error) {
            return error;
        }
        
    },
/************************************************************************/
    _write: async function(log_json, _device, data, fail = false){ // Tipo post

        const MySwal = withReactContent(Swal);
        console.log(_device);
        let device =''
        // return;
        if(fail){
            device = JSON.parse(_device);
        }else{

            device = eval('(' + _device + ')');
        }
        const params ={
            device : device,
            data : data
        }
        const headers = {
            'Content-Type': 'text/plain',
        }
        const _text = await AsyncStorage.getItem('i18nextLng');
        let   text  = '';
        (_text === 'es') 
        ? text = 'Error debe iniciar el servicio de text2barcode o instalarlo en el siguiente enlace: '  
        : text = 'Error you must start the text2barcode service or install it in the following link: '

        let url = await funciones._get_url();
        console.log('Impresion directa');
        // console.log(`${url}${log_json.puerto}/write`);
        console.log(`${url}/write`);
        let res;
        try {
            // res = await axios.post(`${url}${log_json.puerto}/write`, params, {headers:headers})
            res = await axios.post(`${url}/write`, params, {headers:headers})
            console.log(res);
            let icon = '';
            (res.data.result) ? icon = 'success' : icon = 'error'
                
            MySwal.fire({
                icon: icon,
                title: res.data.message,
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            });
            if(fail){
                return res;
            }
        } catch (err) {

            this._fail_text2barcode(err, text);
            console.log(err)
            return false;
        }        
    },
/************************************************************************/
    _fail_text2barcode: async function(error, text){
        let _os  = '';
        let vid = '';
        const userAgent        = window.navigator.userAgent,
                  platform         = window.navigator?.userAgentData?.platform ?? window.navigator.platform,
                  macosPlatforms   = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
                  windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
                  iosPlatforms     = ['iPhone', 'iPad', 'iPod'],
                  os               = null;


        if (macosPlatforms.indexOf(platform) !== -1) {
            _os = "https://labeldictate.com/text2barcode/download/mac/Text2%20Barcode%20v5.0.0.dmg";
            vid = _windows;
        } else if (iosPlatforms.indexOf(platform) !== -1) {
            _os = "https://labeldictate.com/text2barcode/";
            vid = _android;
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            _os = "https://labeldictate.com/text2barcode/download/win/Text2%20Barcode%20Pro%20setup%20v5.0.0.exe";
            vid = _windows;
        } else if (/Android/.test(userAgent)) {
            _os = "https://play.google.com/store/apps/details?id=com.text2barcode";
            vid = _android;
        } else if (!os && /Linux/.test(platform)) {
            _os = "https://labeldictate.com/text2barcode/";
            vid = _windows;
        }

        const MySwal = withReactContent(Swal);

        MySwal.fire({
            icon: 'error',
            title: error,
            imageAlt: 'Custom image',
            html: text+
                  `<a href="https://labeldictate.com/text2barcode/ class="mb-2">https://labeldictate.com/text2barcode/</a>
                    <div class='text-center bg-secondary rounded p-1 mt-2'>
                        <video class='rounded border border-3' src=${vid} width=${"100%"} autoPlay loop controls></video>
                    </div>`,
            showCancelButton: true,
            // cancelButtonText: 'Cerrar',
            confirmButtonText: 'Download Text2 Barcode'
        }).then(result =>{
            console.log(result);
            if (result.isConfirmed) {
            
                window.open(_os, "_blank").focus();
                
            }
        });
    },
/********************************************************************************/
    _onlyNumbers: async function(params){
        const re = /^\d*\.?\d*$/;
        if (!re.test(params)) {
            return false;
        }

    },
/********************************************************************************/
    inToMm: function(params) {
        const mm = 25.4;
        if(params.etiqueta == 0 ){
            const _medidas = params.name.replace('"', '');
            let   mm1      = parseInt(_medidas)*mm;
                  mm1      = Math.round(mm1);

            return mm1.toString();

        }else{
            
            const _medidas = params.name.split('x');

            let mm1 = parseFloat(_medidas[0])*mm;
                mm1 = Math.round(mm1)

            let mm2 = parseFloat(_medidas[1])*mm;
                mm2 = Math.round(mm2)

            const r_medida = mm1+'x'+mm2;
            return r_medida.toString();

        }
        console.log(params);
        // return params.name
    },
/**********************************************************************************
 * @TODO Function to get operative system
 * @return {string|null}
 */    
    getOS: () => {

        const userAgent      = window.navigator.userAgent,
            platform         = window.navigator?.userAgentData?.platform ?? window.navigator.platform,
            macosPlatforms   = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
            windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
            iosPlatforms     = ['iPhone', 'iPad', 'iPod'],
            os               = null;

        console.log(userAgent);
        console.log(platform);
        let _os = os;
        if (macosPlatforms.indexOf(platform) !== -1) {
            _os = 'mac';
        } else if (iosPlatforms.indexOf(platform) !== -1 || userAgent.includes('iPhone')) {            
            _os = 'ios';
        } else if (windowsPlatforms.indexOf(platform) !== -1) {
            _os = 'windows';
        } else if (/Android/.test(userAgent) || userAgent == 'Dalvik/2.1.0') {
            _os = 'android';
        } else if ((!os && !_os) && /Linux/.test(platform)) {            
            _os = 'linux';
        } else if (/\bCrOS\b/.test(userAgent)) {            
            _os = 'chrome os';
        }

        return _os;
    },
/*************************************************************************************************
 * TODO function to get string betweeen two strings
 * @param {string} string FULL STRING
 * @param {string} start string start
 * @param {string} end end string
 * @returns {string}
 */
getStringBetween: (string, start, end) => {
    string = ' ' + string;
    const ini = string.indexOf(start);
    if (ini === -1) return '';
    const len = string.indexOf(end, ini + start.length) - ini - start.length;
    return string.substring(ini + start.length, ini + start.length + len);
},
  /***************************************************************************************************
 * @TODO get all variables from a label format 
 * @param {string} etiqueta
 * @returns {string}
 */
  getVariables: (etiqueta, language) => { 

    let _label = etiqueta;

    if(_label.includes('@ZPL') && _label.includes('@TSPL')){
        if(language === 1){
            _label = funciones.getStringBetween(_label, '@ZPL', '@END_ZPL')
        }else if(language === 2){
            _label = funciones.getStringBetween(_label, '@TSPL', '@END_TSPL')
        }
    }

    if(_label.includes('@LABEL_OFFER')){
        const str    = funciones.getStringBetween(_label, '@LABEL_OFFER', '@END_OFFER')
              _label = str;
    }
    // console.log(_label);
    // return _label;
    const regex   = /%([^%]+)%/g;
    const matches = _label.match(regex);
    
    if (matches) {
        // console.log(matches);
        const valuesToRemove = ['DATE_NOW', 'HOUR_NOW', 'MONEY_SYMBOL', '_SPEED_', '_MD_', '_CODEPAGE_'];
        const valuesToAdd    = ['QUANTITY'];
        let   results        = [...matches];
        
        for (let i = 0; i < results.length; i++) {
            results[i] = results[i].replace(/%/g, '');
        }
        // Combina campos PRICE_INT y PRICE_CENT en un solo campo PRECIO
        if (results.includes('PRICE_INT') && results.includes('PRICE_CENT')) {
            const indexInt  = results.indexOf('PRICE_INT');
            const indexCent = results.indexOf('PRICE_CENT');
            
            // Agrega el campo PRECIO
            results[indexInt] = 'PRICE';

            // Elimina los campos PRICE_INT y PRICE_CENT originales
            results = results.filter(x => x !== 'PRICE_INT' && x !== 'PRICE_CENT')                
        }
        // Combina campos PRICE_INT y PRICE_CENT en un solo campo PRECIO
        if (results.includes('PRECIO_INT') && results.includes('PRECIO_CENT')) {
            const indexInt = results.indexOf('PRECIO_INT');
            const indexCent = results.indexOf('PRECIO_CENT');
            
            // Agrega el campo PRECIO
            results[indexInt] = 'PRICE';

            // Elimina los campos PRICE_INT y PRICE_CENT originales
            results = results.filter(x => x !== 'PRECIO_INT' && x !== 'PRECIO_CENT')                             
        }
        
        // Combina campos OFFER_INT y OFFER_CENT en un solo campo PRECIO
        if (results.includes('OFFER_INT') && results.includes('OFFER_CENT')) {
            const indexInt = results.indexOf('OFFER_INT');
            const indexCent = results.indexOf('OFFER_CENT');
            
            // Agrega el campo OFFER
            results[indexInt] = 'OFFER';
            // Elimina los campos OFFER_INT y OFFER_CENT originales
            results = results.filter(x => x !== 'OFFER_INT' && x !== 'OFFER_CENT')                
        }
        
        // Quita QUANTITY, DATE y HOUR
        const filteredResults = results.filter(value => !valuesToRemove.includes(value));

        if (!filteredResults.includes('QUANTITY')) {
            filteredResults.push('QUANTITY');
        }
        
        const arregloSinDuplicados = [...new Set(filteredResults)];
        const variables = arregloSinDuplicados;
        // const variables = results;

        return variables;
    }
},
  /***************************************************************************************************
 * @TODO get all variables from a label format 
 * @param {string} etiqueta
 * @returns {string}
 */
  changeport: () => { 

  }

/**********************************************************************************/    
}

export default funciones;
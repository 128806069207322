'use client'
import React, { useState, useEffect } from 'react'
import axios from 'axios';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import { CiLineHeight, CiUser } from 'react-icons/ci';
import { FaRegBuilding } from 'react-icons/fa';
import { MdApps, MdEmail } from 'react-icons/md';
import Swal from 'sweetalert2';
import * as Yup from 'yup'
// import sizes from '@/public/images/medidas.jpg'
import { RxWidth } from 'react-icons/rx';
import { FiPrinter } from 'react-icons/fi';
// import { useReCaptcha } from 'next-recaptcha-v3';
import HeaderDesign from './HeaderDesign';
import { url } from '../../global/Global'

const one   = 'design/imagen_1.png'
const two   = 'design/imagen_2.png'
const three = 'design/sizes.png'
const s3    = 'https://codaly-public.s3.amazonaws.com/images/';

const CustomLoader = () => {
    return (
        <div className="col-md-12 text-center animate__animated animate__zoomIn">
            <div className="spinner-border text-primary" role="status">
                <span className="visually-hidden">Loading...</span>
            </div>
        </div>
    )
}
const DesignForm = ({exist}) => {
/*******************************************************************************/  
    const [loading, setloading] = useState(false);
    const [image, setImage]     = useState(null);
    const [logo, setLogo]       = useState(null);

    const [imageError, setImageError] = useState(false);
    const [logoError, setLogoError]   = useState(false);

/*******************************************************************************/   
    // const { executeRecaptcha } = useReCaptcha(); 

    const sendForm = async(values, actions) => {
        console.log('asdjalskjdlkasjd');
        setloading(true);
        let   formData = new FormData();
        if(image !== null){
            formData.append('image', image);
            setImageError(false);
        }else{
            setImageError(true);
            return;
        }
        
        if(parseInt(values.logo) === 1){
            if(logo !== null){
                formData.append('logoImage', logo);
                setLogoError(false);
            }else{
                setLogoError(true);
                return;
            }
        }

        // const token = await executeRecaptcha('yourAction');
        // // Verifica si token_verify es undefined antes de usarlo
        // if (token !== undefined) {
        //     formData.append('token_verify', token);
        // }             

        for (const key in values) {
            formData.append(key, values[key]);
        }        

        const headers = {
            'Content-Type': 'multipart/form-data'
        }
        await axios.post(`${url}api/design`, formData, {headers})
                    .then(res =>{
                        // console.log(res);
                        Swal.fire({
                            icon : 'success',
                            title: res.data.response,
                            text : res.data.message,
                        });
                        actions.resetForm();
                        document.getElementById("formFileSm").value = "";
                        if(document.getElementById("formFileLogo")){
                            document.getElementById("formFileLogo").value = "";
                        }
                        setImage(null);
                        setLogo(null);
                    })
                    .catch(err =>{
                        console.log(err)
                        Swal.fire({
                            icon: 'error',
                            title: err?.response?.data?.response || err,
                        })
                    }).then(() =>{
                        setloading(false)
                    });
        setloading(false)
    }
/********************************************************************************************/

    const handleImage = (e, logo = false) => {
        const _extension_ = e.target.files[0].name.substring(e.target.files[0].name.lastIndexOf('.')+1, e.target.files[0].name.length) || e.target.files[0].name;
        console.log(e)
        console.log(e.target.files[0].name)
        console.log(_extension_)
        if(_extension_ !== 'png' && _extension_ !== 'jpg' && _extension_ !== 'jpeg' && _extension_ !== 'gif' && _extension_ !== 'bmp'){
            
            if(!logo){
                setImage(null);
                (document.getElementById("formFileSm")).value = "";
                setImageError(true);
            }else{
                setLogo(null);
                (document.getElementById("formFileLogo")).value = "";
                setLogoError(true)
            }            
            Swal.fire({
                icon: 'info',
                title: 'Extensión invalida',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            });
            return false;
        }else{
            if(!logo){
                setImage(e.target.files[0]);
                setImageError(false);
            }else{
                setLogo(e.target.files[0]);
                setLogoError(false);
            }     
        }
    }

/********************************************************************************************/
    //Validate form
    const formSchema = Yup.object().shape({
        email: Yup.string().min(1).max(30).email()
            .required('Correo eléctronico requerido'),
        name: Yup.string().min(1).max(50)
            .required('Nombre requerido'),
        company: Yup.string().min(1).max(30)
            .required('Compañia requerida'),
        software: Yup.string().min(1).max(30)
            .required('App requerida'),
        comments: Yup.string().min(1).max(255)
            .required(),
        height : Yup.number().min(1),
        width  : Yup.number().min(1),
        logo   : Yup.number().min(0),
        printer: Yup.string().min(1).max(255)
            .required('Detalles requeridos'),
    });

    return (
    <>
    <div className={`${exist ? '' : 'principal'}`}>
        {
            !exist &&
                <HeaderDesign/>
        }
        <div className='container' style={{marginTop: '10rem'}}>

            <section className='flex flex-col justify-center'>
                <h1 className='text-center fw-bold mb-4'>Cotiza una etiqueta personalizada</h1>
                <div className='px-5'>
                    <p> 
                        Bienvenido al formulario de cotización para el diseño personalizado de tu etiqueta. Aquí podrás especificar los detalles y solicitar un diseño personalizado que se adapte perfectamente a tus necesidades.                    </p>
                    <p> 
                        Solicitala para la aplicación de tu interés:
                    </p>
                    <ul>
                        <li>Codaly</li>
                        <li>Suite Mobile POS</li>
                        <li>Web2 Barcode</li>
                        <li>Text2 Barcode</li>
                    </ul>
                    <p className='text-center fw-bold fs-3'>
                        Precios
                    </p>

                    {/* prices  */}
                    <div className='border rounded d-flex justify-content-center' style={{backgroundColor: 'rgb(249, 249, 249)'}}>
                        {/* basic  */}
                        <div className='p-5 w-full border-b border-e-0 md:border-b-0 md:border-e'>
                            <p className='text-center fw-bold fs-4'>
                                Diseño Básico
                            </p>
                            <p className='text-center fw-bold fs-1'>
                                $18.00 USD
                            </p>
                            <p className='fw-bold mb-2'>Diseño básico que muestra el precio regular del producto.</p>
                            <p className='fw-bold text-center'>Incluye (Opcionales)</p>
                            <ul className='list-inside list-disc'>
                                <li>Código de producto (Texto, código de barras o QR).</li>
                                <li>Descripción o nombre del producto.</li>
                                <li>Precio.</li>
                                <li>Unidad (pza, kg, lts).</li>
                                <li>Observaciones.</li>
                                <li>Logo</li>
                            </ul>

                            <p className='my-3 text-center text-xl'>
                                Ejemplo
                            </p>
                            <div className='d-flex justify-content-center'>
                                <img 
                                    src={s3+one} 
                                    alt='Imagen ejemplo 1'
                                    width={200}
                                    className='img-fluid border p-1 rounded'
                                />
                            </div>
                        </div>
                    </div>

                    {/* <Accordion /> */}
                    <div className="accordion pt-4 mt-4 border-top" id="accordionExample">
                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Proceso de Cotización:
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <p className='mb-3'>
                                        Completa el formulario:
                                    </p>
                                    <ul className="list-decimal mb-2 dark:text-gray-400 list-inside space-y-5">
                                        <li>
                                            Llena todos los campos requeridos y adjunta cualquier imagen o diseño preliminar (Foto, diseño, dibujo, etc).
                                        </li>
                                        Nota: Asegúrate de utilizar formatos de imagen compatibles (JPG).
                                        <li>
                                            Recepción de Propuesta:
                                            Recibirás un correo con la propuesta y el costo del diseño con liga de pago incluido. Por favor, revisa y autoriza la propuesta respondiendo al correo.
                                        </li>
                                        <li>
                                            Realización del Pago:
                                            Una vez autorizada la propuesta, realiza el pago correspondiente y adjunta el comprobante de pago (Puede ser en el mismo correo de confirmación).
                                        </li>
                                        <li>
                                            Entrega del Diseño:
                                            Tras confirmar el pago, recibirás el diseño final o un código para descargar tu diseño personalizado.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="accordion-item">
                            <h2 className="accordion-header">
                                <button className="accordion-button collapsed fw-bold" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    Consideraciones
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    <ul className="list-disc mb-2  dark:text-gray-400 list-inside space-y-5">
                                        <li>
                                            La compatibilidad del diseño con tu impresora es crucial; recibirás archivos de ejemplo para confirmar la compatibilidad antes de finalizar el diseño.                            </li>
                                        <li>
                                            No nos hacemos responsables por incompatibilidades de formato o por problemas derivados del firmware de la impresora que no soporte los diseños.
                                        </li>
                                        <li>
                                            Las imágenes en la propuesta pueden variar ligeramente al imprimir debido al tipo de letra y firmware de la impresora.
                                        </li>
                                        <li>
                                        Es responsabilidad del cliente proporcionar información precisa al llenar el formulario para asegurar el mejor servicio posible.
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
                <div className='border-top mx-5 mt-3'></div>
            </section>

            <div className='d-flex justify-content-center my-5 z-0'>
                <Formik
                    initialValues={{ 
                        name    : '',
                        email   : '',
                        company : '',
                        software: '0',
                        height  : '0',
                        width   : '0',
                        logo    : '0',
                        comments: '',
                        printer : '',
                    }}
                    validationSchema={formSchema}
                    onSubmit={(values, actions) => sendForm(values, actions)}
                >
                {({values}) => (
                    <Form encType="multipart/form-data" className="p-5 bg-white border border-gray-200 rounded shadow mt-3">           
                        <p className='fs-4 fw-bold mb-5 text-center'>
                            ¡Solicita tu etiqueta ahora y da un paso adelante en la presentación de tu producto!
                        </p>
                        <div className='gap-3'>
                            {/* name  */}
                            <div className="mb-4">
                                <label htmlFor="name" className="form-label fw-bold">Nombre</label>
                                <div className="position-relative">
                                    <div className="position-absolute ps-1 pt-1">
                                        <CiUser  className='text-gray-500' size={18}/>
                                    </div>
                                    <Field 
                                        type="text" 
                                        name="name" 
                                        id="name" 
                                        className="form-control ps-4"
                                        placeholder="John Doe" 
                                        required
                                    />
                                </div>                        
                                <ErrorMessage name="name" component="div" className='text-danger field-error'/>
                            </div>
                            {/* email */}
                            <div className="mb-4">
                                <label htmlFor="email" className="form-label fw-bold">Correo electrónico</label>
                                <div className="position-relative">
                                    <div className="position-absolute ps-1 pt-1">
                                        <MdEmail className='text-gray-500' size={18}/>
                                    </div>
                                    <Field 
                                        type="text" 
                                        name="email" 
                                        id="email" 
                                        className="form-control ps-4"
                                        placeholder="correo@flowbite.com" 
                                        required
                                    />
                                </div>                        
                                <ErrorMessage name="email" component="div" className='text-danger field-error'/>
                            </div>                
                            {/* company  */}
                            <div className="mb-4">
                                <label htmlFor="name" className="form-label fw-bold">Compañia</label>
                                <div className="position-relative">
                                    <div className="position-absolute ps-1 pt-1">
                                        <FaRegBuilding className='text-gray-500' size={18}/>
                                    </div>
                                    <Field 
                                        type="text" 
                                        name="company" 
                                        id="company" 
                                        className="form-control ps-4"
                                        placeholder="Mi empresa" 
                                        required
                                    />
                                </div>                        
                                <ErrorMessage name="company" component="div" className='text-danger field-error'/>
                            </div>
                            {/* software */}
                            <div className="mb-4">
                                <label htmlFor="software" className="form-label fw-bold">Software</label>
                                <div className="position-relative">
                                    <div className="position-absolute ps-1 pt-1">
                                        <MdApps className='text-gray-500' size={18}/>
                                    </div>
                                    <Field 
                                        as="select"
                                        type="text" 
                                        name="software" 
                                        id="software" 
                                        className="form-control ps-4"
                                        placeholder="Mi empresa" 
                                        required

                                    >
                                        <option value="0" disabled>Selecciona una opción</option>
                                        <option value="Codaly">Codaly</option>
                                        <option value="Suite Mobile POS">Suite Mobile POS</option>
                                        <option value="Web2 Barcode">Web2 Barcode</option>
                                    </Field>
                                </div>                        
                                <ErrorMessage name="company" component="div" className='text-danger field-error'/>
                            </div>
                            {/* sizes  */}
                            <div className='row row-cols-1 row-cols-md-2 align-items-center border-top border-bottom py-3'>
                                <div className='col'>
                                    <img 
                                        src={s3+three} 
                                        alt='Label sizes'
                                        width={200}
                                        className='img-fluid'
                                    />
                                </div>

                                <div className='col'>
                                    <div className="mb-4">
                                        <label htmlFor="width" className="form-label fw-bold">Ancho en en milimetros</label>
                                        <div className="position-relative">
                                            <div className="position-absolute ps-1 pt-1">
                                                <RxWidth className='text-gray-500' size={18}/>
                                            </div>
                                            <Field 
                                                type="number" 
                                                name="width" 
                                                id="width" 
                                                className="form-control ps-4"
                                                placeholder="101.8" 
                                                step="0.1" min="1" max="1000"
                                                required 
                                            />
                                        </div>                        
                                        <ErrorMessage name="width" component="div" className='text-danger field-error'/>
                                    </div>  
                                    <div className="mb-4">
                                        <label htmlFor="height" className="form-label fw-bold">Alto en en milimetros</label>
                                        <div className="position-relative">
                                            <div className="position-absolute ps-1 pt-1">
                                                <CiLineHeight className='text-gray-500' size={18}/>
                                            </div>
                                            <Field 
                                                type="number" 
                                                name="height" 
                                                id="height" 
                                                className="form-control ps-4"
                                                placeholder="51.2" 
                                                step="0.1" min="1" max="1000"
                                                required 
                                            />
                                        </div>                        
                                        <ErrorMessage name="height" component="div" className='text-danger field-error'/>
                                    </div>      
                                        
                                </div>
                            </div>
                            {/* text  */}
                            <div className='mb-4'>
                            <p className='font-medium'>Nota: Se recomienda que el diseño contenga</p>
                                <ol className="list-decimal list-inside">
                                    <li>Codigo</li>
                                    <li>Descripción/Nombre</li>
                                    <li>Precio</li>
                                    <li>Observaciones</li>
                                </ol>
                            </div>
                            {/* design file  */}
                            <div className='mb-4'>
                                <label htmlFor="formFileSm" className="form-label fw-bold">Adjunta un dibujo o diseño del formato</label>
                                <input 
                                    className="form-control ps-4" 
                                    name="formFileSm"
                                    id="formFileSm"
                                    type="file" 
                                    accept=".png,.jpg,.jpeg,.gif,.bmp"
                                    onChange={(e) => handleImage(e, false)} 
                                    required
                                />
                                {
                                    imageError &&
                                    <div className={`text-danger field-error`}>
                                        <p>Imagen requerida</p>
                                    </div>
                                }
                                
                            </div>
                            {/* comments */}
                            <div className="mb-4">
                                <label htmlFor="comments" className="form-label fw-bold">Agrega detalles de la etiqueta o una breve descripcion</label>
                                <Field 
                                    as="textarea" 
                                    className="form-control ps-4" 
                                    id="comments" 
                                    rows="3" 
                                    name='comments'
                                    required
                                ></Field>
                                <ErrorMessage name="comments" component="div" className='text-danger field-error'/>
                            </div>
                            {/* radio buttons  */}
                            <div className='mb-4'>
                                <p className='form-label fw-bold'>¿Tú plantilla tiene logotipo de tu empresa?</p>
                                <div className="flex items-center mb-2">
                                    <Field id="default-radio-1" type="radio" value="1" name="logo" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <label htmlFor="default-radio-1" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">Si</label>
                                </div>
                                <div className="flex items-center">
                                    <Field id="default-radio-2" type="radio" value="0" name="logo" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                    <label htmlFor="default-radio-2" className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">No</label>
                                </div>
                            </div>
                            {/* logo printer  */}
                            {
                                values.logo === '1' &&
                                <div className='mb-4'>
                                    <label htmlFor="formFileLogo" className="form-label fw-bold">Adjunta tu logo de preferencia en blanco y negro</label>
                                    <input 
                                        className="form-control ps-4" 
                                        name="formFileLogo"
                                        id="formFileLogo"
                                        type="file" 
                                        onChange={(e) => handleImage(e, true)} 
                                        accept=".png,.jpg,.jpeg,.gif,.bmp"
                                        required
                                    />
                                    {
                                        logoError &&
                                        <div className={`text-danger field-error`}>
                                            <p>Logo requerido</p>
                                        </div>
                                    }
                                    {/* <ErrorMessage name="formFileLogo" component="div" className='text-danger field-error'/>                     */}
                                </div>
                            }
                            {/* model printer */}                
                            <div className="mb-4">
                                <label htmlFor="printer" className="form-label fw-bold">¿Que modelo es tu impresora?</label>
                                <div className="position-relative">
                                    <div className="position-absolute ps-1 pt-1">
                                        <FiPrinter className='text-gray-500' size={18}/>
                                    </div>
                                    <Field 
                                        type="text" 
                                        name="printer" 
                                        id="printer" 
                                        className="form-control ps-4"
                                        placeholder="Nombre y modelo de impresora" 
                                        required
                                    />
                                </div>                        
                                <ErrorMessage name="printer" component="div" className='text-danger field-error'/>
                            </div>
                            {/* Button send  */}
                            <div className="mb-4 pt-10">
                                <button 
                                    className="btn btn-primary " 
                                    type="submit"
                                    disabled={loading}
                                ><span>
                                    Enviar formulario
                                </span>
                                {
                                    loading &&                        
                                    <CustomLoader/>


                                }
                                </button>                                                     
                            </div>
                            <div className="p-1">
                                <div className='p-1' style={{backgroundColor: '#e9ecef'}}>
                                    <span>  Este sitio está protegido por reCAPTCHA y se aplican la </span>
                                    <a href="https://policies.google.com/privacy" className=' capitalize text-blue-600 hover:text-blue-900 visited:text-purple-600 ease-in-out duration-150'>Politica de privacidad</a> <span> & </span>
                                    <a href="https://policies.google.com/terms" className=' capitalize text-blue-600 hover:text-blue-900 visited:text-purple-600 ease-in-out duration-150'>Politica de privacidad</a> <span> de google</span>
                                </div>
                            </div> 
                        </div>
                    </Form>
                )}
                </Formik>
            </div>
        </div>

        <footer className="mt-auto py-4 bg-dark box-sh">
            <div className="container">
                <div className="d-flex justify-content-between">
                    <div className="text-white text-center w-100">
                        <p className="m-0">© Copyright <strong>Label Dictate Latam S.A. de C.V.</strong> Todos los derechos reservados.</p>
                        <p className="m-0 text-muted">Independencia 1018 I-201, Parques del Bosque, Tlaquepaque, Jalisco, México. 45609</p>
                        <span className="text-muted">
                            Diseñado por <a href="/" className="link-secondary">Label Dictate Latam S.A. de C.V</a> 
                        </span>
                        <p className="text-muted m-0">
                            <a href="https://labeldictate.com/?c=privacy" className="link-secondary">Privacy Policy</a> 
                        </p>
                    </div>
                </div>
            </div>
        </footer>

    </div>

    </>
  )
}

export default DesignForm

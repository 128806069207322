import React, {useEffect, useState} from 'react';
import web2barcode from '../../img/Logo.png';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import {
    Link
} from "react-router-dom";
const HeaderHome = () => {

    const { t, i18n }         = useTranslation();
    const [drops, setdrops]   = useState(false);
    
    

  return (
    <nav className="navbar navbar-expand-lg navbar-light fixed-top px-5 border-bottom border-secondary" style={{backgroundColor: '#44ab53'}}>
        <div className="container-fluid">
            <Link className="navbar-brand" to="/">
                <img src={web2barcode} className='img-fluid border rounded' width={80}/>
            </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse align-items-center" id="navbarNavAltMarkup">
                <div className="navbar-nav me-auto mb-2 mb-lg-0">

                </div>

                {/* <div> */}
                    <div className="dropdown "  style={{marginRight: '3rem'}}>
                        <Link className="link-dark me-3" to="/" role="button">
                            Inicio
                        </Link>
                        
                    </div>

                {/* </div> */}

            </div>
        </div>
    </nav>
  )
}

export default HeaderHome
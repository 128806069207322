import React, {useState, useRef, Fragment, useEffect} from 'react'
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as Yup from 'yup';
import axios from 'axios';
/*Globales*/
import {urlApi} from '../../global/Global';
import Drive from './Drive';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {gapi} from 'gapi-script';
import { useHistory } from "react-router-dom";
/**/
import ComprobarVista from '../vistaUsuarioContext/ComprobarVista';
import { t } from 'i18next';

const Importar = ({user}) => {

    let history = useHistory();

    const [csv, setcsv]                       = useState('');
    const [extension, setextension]           = useState('');
    const [filename, setfilename]             = useState('');
    const [pasos, setpasos]                   = useState(0);
    const [nombre, setnombre]                 = useState('');
    const [cabeceras, setcabeceras]           = useState([]);
    const [changeCabecera, setchangeCabecera] = useState([]);
    const [campos, setcampos]                 = useState([]);
    const [_lineHead, _setlineHead]           = useState(true);

    const [nombreuuid, setnombreuuid]   = useState('');
    const [columnas, setcolumnas]       = useState([]);
    const [importacion, setimportacion] = useState('local');
    const [drive, setDrive]             = useState('');

    const MySwal = withReactContent(Swal);
    // const [arreglo, setArreglo] = useState(['-x-', 'DESCRIPCION', 'PRECIO', 'CANTIDAD', 'CODIGO', 'CODIGO_INT', 'OFERTA', 'UNIDAD', 'UBICACION', 'OBSERVACION']);
    const [arreglo, setArreglo] = useState(['DESCRIPCION', 'PRECIO', 'CANTIDAD', 'CODIGO', 'CODIGO_INT', 'OFERTA', 'UNIDAD', 'UBICACION', 'OBSERVACION']);
    // const [arreglo, setArreglo] = useState(['', 'DESCRIPCION', 'PRECIO', 'CANTIDAD', 'CODIGO', 'CODIGO_INT', 'OFERTA', 'UNIDAD', 'UBICACION', 'OBSERVACION']);
    const [respaldo, setRespaldo] = useState([]);
    // const [respaldo, setRespaldo] = useState(['DESCRIPCION', 'PRECIO', 'CANTIDAD', 'CODIGO', 'CODIGO_INT', 'OFERTA', 'UNIDAD', 'UBICACION', 'OBSERVACION']);
    const handlecsv = (e) => {
        setcsv(e.target.files[0]);
        const extension= e.target.files[0].name.substring(e.target.files[0].name.lastIndexOf('.')+1, e.target.files[0].name.length) || e.target.files[0].name;
        setextension(extension);
        const name= e.target.files[0].name.substring(0, e.target.files[0].name.indexOf('.'));
        setfilename(name);
    }
    let   cabecerasNoreal       = new Array();
    const itemsRef              = useRef([]);
    const [loading, setloading] = useState(false);

    const [signedInUser, setSignedInUser]                       = useState('');
    const [isLoadingGoogleDriveApi, setIsLoadingGoogleDriveApi] = useState(false);

/*******************************************************************/
    // Client ID and API key from the Developer Console
    const CLIENT_ID = '191181423961-lqknnrp0amj9pj0o4d16r8aafu611le9.apps.googleusercontent.com';
    const API_KEY   = 'AIzaSyBIK-3IqFaUg03I0elDDLpqUO4UaKIQ6RY';

    // Array of API discovery doc URLs for APIs used by the quickstart
    const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"];

    // Authorization scopes required by the API; multiple scopes can be
    // included, separated by spaces.
    const SCOPES = 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile';

/******************************************************************************************************************************/

    useEffect(() => {
        handleClientLoad();
        window.
        console.log(signedInUser);
        console.log(user.google);
    }, [])


/*******************************************************************************/
    useEffect(() => {
        if(csv != ''){
            comprobarCatalogo();
        }
    }, [csv])


/*******************************************************************************/
    const sobreEscribirCatalogo = async (formData, catalogo) => {
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
        await axios({
            method: 'post',
            url: urlApi+'catalogo/sobreescribir',
            data: formData,
            headers: headers
          }).then((response) => {
              if(response.data.respuesta === 'correcto'){


                actualizarDrive(response.data.catalogo.drive_id, catalogo);
                
              }
                console.log(response.data)
            }).catch(error => {            
                console.log(error)
                setloading(false);
          }).then(() =>{
            // setloading(false);
          });
        // console.log(formData)
    }

/*******************************************************************************/
    const comprobarCatalogo = async (params) => {
        console.log(csv);
        console.log(extension);
        console.log(filename);
        // return;
        if (csv==='') {
            document.getElementById("formFileSm").value = "";
            setcsv('');
            setextension('');
            setfilename('');
            MySwal.fire({
                icon: 'info',
                title: 'Debe subir un archivo csv o xls',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                toast.addEventListener('mouseenter', MySwal.stopTimer)
                toast.addEventListener('mouseleave', MySwal.resumeTimer)
                }
            })
            return;
        }
        setloading(true);
        setTimeout(() => {}, 400);
        
        let formData = new FormData();
        formData.append('archivo', csv);
        formData.append('datas', 'local');
        const options = {
            onUploadProgress: (progressEvent) => {
                const {loaded, total} = progressEvent;
                let percent = Math.floor( (loaded*100) / total);
                console.log( `${loaded}kb of ${total}kb | ${percent}%` );
            }
        }
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
        // await axios.post(urlApi+'catalogo/comprobar', formData, options, {headers: headers})
        await axios.post(urlApi+'catalogo/comprobar', formData, {headers: headers})
          .then((response) => {
                //Si existe una configuracion previa nos pregunat si sobreescribimos o actualizamos
                // if(response.data.respuesta ==='configuracion_previa'){
                //     formData.append('data', response.data.catalogo);
                //     MySwal.fire({
                //         icon: 'warning',
                //         title: 'Configuracion previa',
                //         text: 'Actualmente cuenta con una configuración para este archivo ¿Sobreescribir datos?',
                //         showCancelButton: true,
                //         confirmButtonColor: '#3085d6',
                //         cancelButtonColor: '#d33',
                //         confirmButtonText: 'Sobreescribir todos los datos',
                //         cancelButtonText: 'Cancelar',
                //         // cancelButtonText: 'Agregar los nuevos datos a catalogo',
                //         // allowOutsideClick: false
                //       }).then((result) => {
                //         if (result.isConfirmed) { //Si elegimos la opcion de sobreescribir
                //             sobreEscribirCatalogo(formData, response.data.catalogo);
                            
                //         }else{ // si elegimos la opcion de solo agregar nuevos datos
                //             // AgregarDatosCatalogo(formData);
                //             document.getElementById("formFileSm").value = "";
                //             setcsv('');
                //             setextension('');
                //             setfilename('');
                //             setloading(false);
                //         }
                //       })
                // }//Si no hay configuraion previa traemos los datos
                if(response.data.respuesta ==='correcto'){
                // else if(response.data.respuesta ==='correcto'){
                    setnombre(response.data.nombre);
                    setcabeceras(response.data.cabeceras);
                    setchangeCabecera(new Array(response.data.cabeceras.length));
                    setcampos(response.data.campos);
                    setnombreuuid(response.data.nombreuuid);
                    setcolumnas(response.data.columnas);
                    console.log(response.data.campos);
                    itemsRef.current = itemsRef.current.slice(0, response.data.cabeceras.length);
                    setpasos(1);
                    setloading(false);
                    
                }else if(response.data.respuesta ==='extension'){
                    MySwal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: 'Extensión no valida',
                    });
                    setloading(false);
                }else{
                    MySwal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: response.data.mensaje,
                    });
                    setloading(false);
                }
                console.log(response.data);
            })
            .catch((error) => {
                MySwal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: error,
                  });
                  setloading(false);
                console.log(error);
                setloading(false);
            });
    }
/*******************************************************************************/

    const handleClickArreglo = (params, j, i) => {
        console.log(params.target.textContent);
        console.log(j);
        console.log(i);
        console.log(changeCabecera);
        let x = changeCabecera;
        // itemsRef.current[i].value = params.target.textContent;
        
        const result = changeCabecera.some(element => element == params.target.textContent)
        // console.log(result);
        if(!result){
            setRespaldo(respaldo =>(
                 [...respaldo, params.target.textContent]
            ));
        }else{

            console.log('else');
            // x = changeCabecera.filter(element => element != params.target.textContent)
            x = changeCabecera.map(element => {
                    return element == params.target.textContent
                    ? ''
                    : element
                })
            setchangeCabecera(x);

        }
        // console.log(changeCabecera[i]+' <=> '+ params.target.textContent)
        if(changeCabecera[i] == params.target.textContent){
            x[i] = '';
        }else{
            x[i] = params.target.textContent;
        }
        setchangeCabecera(x);
        // console.log(changeCabecera);
        // console.log(respaldo);
    }    

/*******************************************************************************/

    const enviarCatalogo = async (params) => {
        setloading(true);
        console.log(params);
        // console.log(itemsRef.current[1].value);
       
        // itemsRef.current.forEach(element => {
        //     cabecerasNoreal.push(element.value);
        // });
                
        let encabezado = 0;
        if(_lineHead){
            encabezado = 1;
        }
        const datos = {
            catalogo : params.nombre,
            cabeceras: cabeceras,
            // cabeceras_no_real: cabecerasNoreal,
            cabeceras_no_real: changeCabecera,
            csv              : nombreuuid,
            encabezado       : encabezado,
            columnas         : columnas,
            importacion      : importacion,
            drive_id         : drive,
            catalogo_web     : 1
        }
        console.log(cabeceras);
        console.log(cabecerasNoreal);
        console.log(changeCabecera);
        console.log(csv);
        console.log(datos);
        // return;
        // return;
        const options = {
            onUploadProgress: (progressEvent) => {
                const {loaded, total} = progressEvent;
                let percent = Math.floor( (loaded*100) / total);
                console.log( `${loaded}kb of ${total}kb | ${percent}%` );
            }
        }

        console.log(datos);
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization'   : log_json.token_type+' '+log_json.access_token,
            'Content-Type'    : 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
        // await axios.post(urlApi+'catalogo/store', datos, options, {headers: headers})
        await axios.post(urlApi+'catalogo/store', datos, {headers: headers})
                .then((response) => {
                    console.log(response.data);
                    if(response.data.respuesta === 'correcto'){

                        MySwal.fire({
                            icon: 'success',
                            title: 'Catalogo creado correctamente',
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                        });
                        setcsv('');
                        setextension('');
                        setfilename('');
                        setpasos(0);
                        setloading(false);
                        history.push("/catalogos");
                        // enviarDrive(response.data.config, response.data.catalogo.id);

                    }
                }).catch(error =>{
                        console.log(error)
                }).then(() =>{
                    cabecerasNoreal = [];
                    // setchangeCabecera(new Array(cabeceras.length));
                    // setloading(false);
                });
    }
    
/*******************************************************************************/

    const carpetaLddata = async (params) => {
        let carpeta = '';
        let id_carpeta = '';        
        await gapi.client.drive.files.list({ 
            pageSize: 1,
            fields: 'nextPageToken, files(id, name, mimeType, modifiedTime)',
            'q': "mimeType = 'application/vnd.google-apps.folder' and trashed = false and name contains 'LDDATA'",
        }).then(res =>{
            console.log(res);
            carpeta = JSON.parse(res.body);
        });
        if(carpeta.files.length <= 0){

            const fileMetadata = {
                'name': 'LDDATA',
                'mimeType': 'application/vnd.google-apps.folder'
            };
              
            await gapi.client.drive.files.create({ 
                resource: fileMetadata,
                fields: 'id'
            }).then(res =>{
                console.log(res);
                carpeta = JSON.parse(res.body);
            });

            id_carpeta = carpeta.id;
            return id_carpeta;
        }else{
            id_carpeta = carpeta.files[0].id;
            return id_carpeta;
        }
        // console.log(carpeta);
        // console.log(carpeta.files);
        // id_carpeta = carpeta.files[0].id
    }
    

/*******************************************************************************/

    const enviarDrive = async (config, catalogo) => {
     
        /**OBTENEMOS LA CARPETA LDDATA */
        let id_carpeta = '';
        id_carpeta = await carpetaLddata();

       /*CREAMOS EL ARCHIVO */
       var blob = new Blob([config],
       { type: "text/plain;charset=utf-8" });

        /* ENVIAMOS EL ARCHIVO A DRIVE */
        const headers = {
            'Authorization': 'Bearer '+gapi.auth.getToken().access_token,
        }
        
        const arr_meta = new Array();
        const arr_form = new Array();
        const arr_file = new Array();

        /* Asignamos el mimetype*/
        let ex = '';
        if(extension =='csv'){
            ex = 'text/csv';
        }else if(extension =='xlsx'){
            ex = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        }
        else if(extension =='xls'){
            ex = 'application/vnd.ms-excel';
        }


        arr_meta.push({ 
            name    : filename+'.'+extension,
            // name    : csv.name.replace('.csv', '')+'.csv',
            mimeType: ex,
            // mimeType: "text/csv",
            parents : [id_carpeta]
        });
        arr_meta.push({ 
            name    : filename+'.headers',
            // name    : csv.name.replace('.csv', '')+'.headers',
            mimeType: "text/plain",
            parents : [id_carpeta]
        });
        // console.log(metadata);
        arr_form.push(new FormData());
        arr_form.push(new FormData());
        
        arr_file.push(csv);
        arr_file.push(blob);
        for (let i = 0; i < 2; i++) {
                        
            arr_form[i].append("metadata", new Blob([JSON.stringify(arr_meta[i])], { type: 'application/json' }));
            arr_form[i].append("file", arr_file[i]);
            await axios({
                method: 'POST',
                url: 'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart',
                headers: headers,
                data: arr_form[i],
            }).then(async response =>{
                console.log(response.data);
                if(i == 0){
                /*******/
                    const log = await AsyncStorage.getItem('@login');
                    const log_json = JSON.parse(log);
                    const _headers = {
                        'Authorization': log_json.token_type+' '+log_json.access_token,
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                    };
                    const send_data = {
                        catalogo : catalogo,
                        id_drive : response.data.id
                    }
                    await axios({
                        method: 'POST',
                        url: urlApi+'catalogo/update',
                        headers: _headers,
                        data: send_data,
                        }).then(response =>{
                            console.log(response.data);
                        }).catch((err) =>{
                            console.log(err);
                        });
                }/*******/
                if(i == 1){                
                    MySwal.fire({
                        icon: 'success',
                        title: 'Creado y guardado en drive',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    });
                    setcsv('');
                    setextension('');
                    setfilename('');
                    setpasos(0);
                    setloading(false);
                    history.push("/catalogos");
                }
            }).catch((err) =>{
                console.log(err);
                console.log(err.response.data.error.message);
                if(i == 1){
                    MySwal.fire({
                        icon: 'warning',
                        title: 'Error',
                        text: 'err',
                        // text: err.response.data.error.message,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        allowOutsideClick: false
                    })
                }
            }); 
        }
    }
    

/*******************************************************************************/
    const actualizarDrive = async (drive_id, catalogo) => {
        /**OBTENEMOS LA CARPETA LDDATA */
        let id_carpeta = '';
        let _name      = filename+'.'+extension
        // let _name      = csv.name.replace('.csv', '');
            // _name      = _name.replace('.CSV', '')+'.csv';
        let ex = '';
        if(extension =='csv'){
            ex = 'text/csv';
        }else if(extension =='xlsx'){
            ex = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        }
        else if(extension =='xls'){
            ex = 'application/vnd.ms-excel';
        }


            id_carpeta = await carpetaLddata();

        let config = '';

        await gapi.client.drive.files.list({ 
            pageSize: 1,
            fields: 'nextPageToken, files(id, name, mimeType, modifiedTime)',
            q: `mimeType = '${ex}' and '${id_carpeta}' in parents and trashed = false and name contains '${_name}'`,
            // q: `mimeType = 'text/csv' and '${id_carpeta}' in parents and trashed = false and name contains '${_name}'`,
        }).then(res =>{
            // console.log(res.body);
            config = JSON.parse(res.body);
            console.log(config.files);
        });
        
        /* COMPROBAMOS QUE EXISTA EL ARCHIVO */
        /* Si existe lo actualizamos, si no existe, lo creamos y cambiamos el ID en la base de datos */
        let fileMetadata = {};
        if(config.files.length <= 0){
            fileMetadata = {
                name    : _name,
                mimeType: ex,
                // mimeType: 'text/csv',
                parents : [id_carpeta]
            };
        }else{
            fileMetadata = {
                name    : _name,
                mimeType: ex,
                // mimeType: 'text/csv',
                
            };
        }
       /*CREAMOS CONFIGURACION DEL ARCHIVO */
        const headers = {
            'Authorization': 'Bearer '+gapi.auth.getToken().access_token,
        }

        console.log(drive_id);    

        let formData = new FormData();
        formData.append("metadata", new Blob([JSON.stringify(fileMetadata)], { type: 'application/json' }));
        formData.append("file", csv);



        if(config.files.length <= 0){
            
            await axios({
                method: 'POST',
                url: 'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart',
                headers: headers,
                data: formData,
                }).then(async response =>{

                    console.log(response.data);
                    const send_data ={
                        id_drive: response.data.id,
                        catalogo: catalogo
                    }
                    /* actualizamos el id_drive */
                    const log = await AsyncStorage.getItem('@login');
                    const log_json = JSON.parse(log);
                    const headers = {
                        'Authorization': log_json.token_type+' '+log_json.access_token,
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                    }
                    await axios.post(urlApi+'catalogo/update', send_data, {headers: headers})
                                .then((response) => {
                                    console.log(response.data);
                                    document.getElementById("formFileSm").value = "";
                                    setcsv('');
                                    setextension('');
                                    setfilename('');
                                    MySwal.fire({
                                        icon: 'success',
                                        title: 'Creado y guardado en drive',
                                        toast: true,
                                        position: 'top-end',
                                        showConfirmButton: false,
                                        timer: 3000,
                                        timerProgressBar: true,
                                        didOpen: (toast) => {
                                            toast.addEventListener('mouseenter', Swal.stopTimer)
                                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                                        }
                                    });      
                                    history.push("/catalogos");                                                   
                        }).catch(error =>{
                                console.log(error);
                                MySwal.fire({
                                    icon: 'warning',
                                    title: 'Error',
                                    text: 'err',
                                    // text: err.response.data.error.message,
                                    confirmButtonColor: '#3085d6',
                                    cancelButtonColor: '#d33',
                                    allowOutsideClick: false
                                })
                        }).then(() =>{
                            cabecerasNoreal = [];
                            // setchangeCabecera(new Array(cabeceras.length));
                            // setloading(false);
                    });
                   
                }).catch(err =>{
                    console.log(err);
                    // console.log(err.response.data.error.message);
                    MySwal.fire({
                        icon: 'warning',
                        title: 'Error',
                        text: 'err',
                        // text: err.response.data.error.message,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        allowOutsideClick: false
                    })
                }).then(() =>{
                    setloading(false);
                });

        }else{
            await axios({
                method: 'PATCH',
                url: 'https://www.googleapis.com/upload/drive/v3/files/'+drive_id+'?uploadType=multipart',
                headers: headers,
                data: formData,
                }).then(response =>{
                    console.log(response.data);
                    document.getElementById("formFileSm").value = "";
                    setcsv('');
                    setextension('');
                    setfilename('');
                    MySwal.fire({
                        icon: 'success',
                        title: 'Creado y guardado en drive',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                            toast.addEventListener('mouseenter', Swal.stopTimer)
                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                    });
            }).catch((err) =>{
                console.log(err);
                // console.log(err.response.data.error.message);
                MySwal.fire({
                    icon: 'warning',
                    title: 'Error',
                    text: 'err',
                    // text: err.response.data.error.message,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    allowOutsideClick: false
                })
            }).then(() =>{
                setloading(false);
            }); 
        }

    }
    

/*******************************************************************************/

    const handleChangeInput = (params, i) => {
        console.log(itemsRef.current[i].value);
    }
    

/*******************************************************************************/

    const lineHead = () => {
        _setlineHead(!_lineHead);
    }


/*******************************************************************************/

    //Validador de datos
    const formSchema = Yup.object().shape({
        
    });

/*******************************************************************************/
/***************************** DRIVE API ***************************************/
/*******************************************************************************/
    /**
     *  Sign in the user upon button click.
     */
    const handleAuthClick = (event) => {
        gapi.auth2.getAuthInstance().signIn();
    };


    /**
     *  Sign out the user upon button click.
     */
    const handleSignOutClick = (event) => {

        MySwal.fire({
            title: '¿Cerrar sesión de Google?',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si',
            cancelButtonText: 'Cancelar'
            }).then((result) => {
            if (result.isConfirmed) {
                // setListDocumentsVisibility(false);
                gapi.auth2.getAuthInstance().signOut();
                // setDocuments([]);    
                setSignedInUser('');
            }
            })
        
    };

/***************************************************************************/
    const updateSigninStatus = (isSignedIn) => {
        if (isSignedIn) {
            // Set the signed in user
            setSignedInUser(gapi.auth2.getAuthInstance().currentUser);
            // setIsLoadingGoogleDriveApi(false);
            console.log(gapi.auth2.getAuthInstance().currentUser.le.wt);
            // list files if user is authenticated
            // listFiles();
        } else {
            // prompt user to sign in
            // handleAuthClick();
        }
    };


    /**
     *  Initializes the API client library and sets up sign-in state
     *  listeners.
     */
    const initClient = () => {
        setIsLoadingGoogleDriveApi(true);
        gapi.client
            .init({
                apiKey: API_KEY,
                clientId: CLIENT_ID,
                discoveryDocs: DISCOVERY_DOCS,
                scope: SCOPES,
            })
            .then(
                function () {
                    // Listen for sign-in state changes.
                    gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);

                    // Handle the initial sign-in state.
                    updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
                },
                function (error) {
                    console.log(error);
                }
            );
    };

    const handleClientLoad = () => {
        gapi.load('client:auth2', initClient);
    };

/*******************************************************************************/
    return (
        <div className="row justify-content-center">
            < ComprobarVista/>
            <div className="col-md-12">
                {
                    pasos === 0 &&
                    <>
                        <div className="card">
                            <div className="card-header bg-card-header">
                                <span className='fw-bold text-white'>{t("import.t_import")}</span>
                            </div>
                            <div className="card-body">
                                {
                                    // signedInUser != '' ?
                                    <>                    
                                        <Formik
                                            // enableReinitialize
                                            initialValues={{
                                                // observaciones: observaciones
                                            }}
                                            onSubmit={(values, actions) => comprobarCatalogo(values, actions)}
                                        >
                                            <Form>
                                                <div className="row">
                                                    {/* <label htmlFor="formFile" className="form-label">Selecciona un catalogo desde tu dispositivo en formato <b>CSV </b>o<b> XLS</b></label> */}
                                                    <label htmlFor="formFile" className="form-label" dangerouslySetInnerHTML={{__html: t('import.select_file')}}></label>
                                                    <div className='files2'>
                                                        <div className="mb-3 files">
                                                            <input disabled={loading} className="form-control" id="formFileSm" type="file" name="csv" onChange={handlecsv} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" required/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 d-none">
                                                        <button type="submit" className="btn btn-dark " disabled={loading}>Guardar y enviar</button>                                                
                                                    </div>
                                                    {
                                                        loading &&
                                                        <div className="col-md-12 text-center">
                                                            <div className="spinner-border text-primary" role="status">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </Form>
                                        </Formik>                                    
                                    </>
                                    // :
                                    // <div>
                                    //     <p className='mb-0 fw-bold text-danger text-uppercase'>
                                    //         Debes iniciar sesión con una cuenta de Google Drive para exportar el catalogo
                                    //     </p>
                                    // </div>
                                }
                                
                                <>
                                    {
                                        // user.google != 1 &&
                                        // <>
                                        //     {                                        
                                        //     signedInUser =='' ?
                                        //     <button 
                                        //         className="btn btn-lg btn-light bg-white border my-3" 
                                        //         type="button"
                                        //         onClick={() => handleAuthClick()}
                                        //     >
                                        //         <div className='d-flex align-items-center justify-content-center'>
                                        //             <img src='https://developers-dot-devsite-v2-prod.appspot.com/identity/sign-in/g-normal.png'/>
                                        //             <span>Iniciar sesión con Google</span>
                                        //         </div>
                                        //         {/* <FontAwesomeIcon icon={fabGoogle} size="3x"/> Iniciar sesión con Google */}
                                        //     </button>
                                        //     :
                                        //         <div className='d-flex align-items-center mt-3'>
                                        //             <button 
                                        //                 data-bs-toggle="tooltip" data-bs-placement="top" title="Cerrar Sesión"
                                        //                 className="btn bg-white border rounded d-flex align-items-center"
                                        //                 onClick={() => handleSignOutClick()}
                                        //                 disabled={loading}
                                        //             ><img src='https://developers-dot-devsite-v2-prod.appspot.com/identity/sign-in/g-normal.png'/>
                                        //             {
                                        //                 signedInUser != '' &&
                                        //                 <span>{signedInUser.le.wt.cu}</span>
                                        //             }
                                        //             </button>
                                        //         </div>
                                        //     }
                                        // </>
                                    }
                                    </>
                                
                            </div>
                            {
                                // user.google == 1 &&
                                // <div className='d-flex align-items-center pb-4 px-3'>
                                //     <div 
                                //         className="btn d-flex align-items-center p-1 res-w100"
                                //     ><img src='https://developers-dot-devsite-v2-prod.appspot.com/identity/sign-in/g-normal.png'/>
                                //     {
                                //         signedInUser != '' &&
                                //         <span>{signedInUser.le.wt.cu}</span>
                                //     }
                                //     </div>
                                // </div>
                            }
                        </div>
                        
                    </>
                }

                {
                    pasos === 1 &&                
                    <div className="card">
                        <div className="card-header bg-dark">
                            <span className=" text-white">{t("import.title_import")}</span>
                        </div>
                        <div className="card-body">
                                            
                            <div className="row">
                            <Formik
                                enableReinitialize
                                initialValues={{
                                    nombre: nombre
                                }}
                                validationSchema={formSchema}
                                onSubmit={(values, actions) => enviarCatalogo(values, actions)}
                            >
                                <Form>
                                    <div className="row">
                                        <div className="row mb-3 align-items-center">
                                            <div className="col-md-4">
                                                <div className="col-sm-10">
                                                <label htmlFor="nombre" className="col-sm-12 col-form-label">{t("login.login_2.name")}</label>
                                                    <Field type="text" className="form-control" name="nombre" id="nombre" disabled={loading}/>
                                                    <ErrorMessage
                                                        name='nombre'
                                                        component='div'
                                                        className='field-error text-danger'
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="nombre" className="col-sm-12 col-form-label">&nbsp;</label>
                                                <div className="form-check form-switch">
                                                    <input 
                                                        className="form-check-input" 
                                                        type="checkbox" 
                                                        id="flexSwitchCheckChecked" 
                                                        checked={_lineHead}
                                                        onChange={lineHead}
                                                        disabled={loading}
                                                    />
                                                    <label className="form-check-label" htmlFor="flexSwitchCheckChecked">{t("import.use_header")}</label>
                                                </div>
                                            </div>
                                            <div className="col-md-4 text-end">
                                                <label htmlFor="nombre" className="col-sm-12 col-form-label">&nbsp;</label>
                                                <button 
                                                    type="submit" 
                                                    className="btn btn-dark "
                                                    disabled={loading}
                                                >
                                                    
                                                   <span>
                                                        {t("import.save_template")}   
                                                    </span> 
                                                </button>
                                            </div>
                                        </div>                                            
                                    </div>     
                                    {
                                        loading &&
                                        <div className="row">
                                            <div className="col-md-12 text-center">
                                                <div className="spinner-border text-primary" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                            {/* <p className='mb-0 fw-bold'>{t("catalogs.header")}s</p> */}
                                    <div className="row mb-3">
                                        <div className="col-md-12 ">
                                            <div className='table-responsive border border-secondary border-3 rounded rounded-3 p-0'>                                            
                                                <table className="table table-striped table-bordered mb-0">
                                                    <thead>
                                                        <tr>
                                                            <td className='text-center headers-inputs fw-bold' colSpan={cabeceras.length+1}>
                                                                <span>COLUMNS</span>
                                                            </td>
                                                        </tr>
                                                        <tr className='bb-3'>
                                                            {
                                                                columnas.length > 0 &&
                                                                columnas.map((e,i)=>{
                                                                    return <th className='headers-inputs' scope="col" key={"columna-"+i}>
                                                                            <span 
                                                                                className="headerss-inputs"
                                                                            >{e}</span>
                                                                    </th>
                                                                })
                                                            }
                                                        </tr>
                                                        {/* <tr>
                                                            {   _lineHead 
                                                                ? cabeceras.map((e,i) =>{
                                                                    return <td className='text-center' scope="col" key={"_cabecera-"+i}>
                                                                                <span 
                                                                                >{e}</span>
                                                                        </td>
                                                                })
                                                                : cabeceras.map((e,i) =>{
                                                                    return <td className='text-center' scope="col" key={"_cabecera_-"+i}>
                                                                                <span
                                                                                >-</span>
                                                                        </td>
                                                                })
                                                            }
                                                        
                                                        </tr> */}
                                                        <tr className='d-none'>
                                                            <th colSpan={cabeceras.length+1} className="text-start">
                                                                <span>{t("catalogs.select_header")}</span>
                                                            </th>
                                                        </tr >
                                                        <tr className='d-none'>
                                                            {   
                                                                cabeceras.map((e,i) =>{
                                                                return <th scope="col" key={"cabecera-"+i} style={{background: '#47b805'}}>
                                                                            <div className="dropdown">
                                                                                <a 
                                                                                    className="dropdown-toggle link-dark" 
                                                                                    href="#" 
                                                                                    role="button" 
                                                                                    id="dropdownMenuLink" 
                                                                                    data-bs-toggle="dropdown" 
                                                                                    aria-expanded="false"
                                                                                >
                                                                                    <span className='m-0'>{changeCabecera[i]}</span>
                                                                                </a>

                                                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                                                    
                                                                                    {
                                                                                        arreglo.map((em,j) =>{
                                                                                            return <Fragment key={"sess-"+j}>
                                                                                                {
                                                                                                    em != '' &&                                                                                                            
                                                                                                    <li>
                                                                                                        
                                                                                                        <a 
                                                                                                            className="dropdown-item link-dark" 
                                                                                                            href="#"
                                                                                                            onClick={(e) => handleClickArreglo(e, j, i)}
                                                                                                        >
                                                                                                            { 
                                                                                                            changeCabecera.filter(item => item == em).length == 0 &&
                                                                                                                <p className='text-dark fw-bold m-0'>{em}</p>
                                                                                                            }
                                                                                                            { 
                                                                                                            changeCabecera.filter(item => item == em).length >= 1 &&
                                                                                                                <p className='text-danger fw-bold m-0'>{em}</p>
                                                                                                            }
                                                                                                            {/* { em } */}
                                                                                                        </a>
                                                                                                    </li>
                                                                                                }                                                                                                                                                                                                        
                                                                                                </Fragment>
                                                                                        })
                                                                                    }

                                                                                </ul>
                                                                            </div>                                                                                                                                            
                                                                        </th>
                                                                })
                                                            }
                                                        
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {   
                                                            // !_lineHead  &&            
                                                            // <tr scope="col" className='d-none'>
                                                            //     {

                                                            //         cabeceras.map((e,i) =>{
                                                            //             return <td scope="col" key={"_cabecera.-"+i}>
                                                            //                     <span 
                                                            //                         className="headerss-inputs"
                                                            //                         disabled
                                                            //                         >{e}</span>
                                                            //             </td>
                                                            //         })
                                                            //     }
                                                            // </tr>
                                                            <tr>
                                                                {
                                                                _lineHead 
                                                                ? cabeceras.map((e,i) =>{
                                                                    return <td key={"_cabecera-"+i}>
                                                                                {e}
                                                                        </td>
                                                                })
                                                                : cabeceras.map((e,i) =>{
                                                                    return <td key={"_cabecera_-"+i}>
                                                                                --- &nbsp;
                                                                        </td>
                                                                })
                                                                }
                                                            </tr>
                                                            
                                                        }
                                                        {
                                                            

                                                            campos.map((e,i) =>{
                                                                return <tr key={"campos"+i}>
                                                                    {
                                                                        <>
                                                                        {
                                                                            e.map((en,id) =>{
                                                                                return <td key={"entei-"+id}>{en}</td> 
                                                                            })
                                        
                                                                        }
                                                                        </>
                                                                    }
                                                                    </tr>  
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>                                  
                                </Form>
                            </Formik>
                            </div>

                        </div>
                        {
                             user.google == 1 &&
                             <div className='d-flex align-items-center pb-5 px-3'>
                                 <div 
                                     className="btn d-flex align-items-center p-1 res-w100"
                                 ><img src='https://developers-dot-devsite-v2-prod.appspot.com/identity/sign-in/g-normal.png'/>
                                 {
                                     signedInUser != '' &&
                                     <span>{signedInUser.le.wt.cu}</span>
                                 }
                                 </div>
                             </div>
                        }
                    </div>
                }
            </div>
        </div>
    )
}

export default Importar

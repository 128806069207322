import React, {useMemo, useEffect, useState, useRef} from 'react'
import axios from 'axios';
import { useTable, usePagination, useRowSelect, useAsyncDebounce, useFilters, useGlobalFilter } from 'react-table';
/*Globales*/
import {urlApi} from '../../global/Global';
import Swal from 'sweetalert2';
import {matchSorter} from 'match-sorter';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faSearch} from '@fortawesome/free-solid-svg-icons';
import styled from "styled-components";
import funciones from '../utilidades/funciones';
import { unauthenticated } from '../../helpers/Helper';

const Styles = styled.div`

table {
  thead{
    position: sticky;
    top: 0;
    right: 0;
    z-index:1;
}
  },
  tr{   
    td {
        box-sizing: border-box; 
        flex: 150 0 auto; 
        min-width: 0px; 
        width: 90px;
        z-index: 0;
      }
    }
  }        

}
`

const EncuentraImprime = ({_catalogo_, etiqueteta ='', user =''}) => {

    const [catalogo, setcatalogo] = useState(_catalogo_)
    const [_catalogo, set_catalogo] = useState();
    const [__campos, set__campos] = useState();
    const buttonRef = useRef(null);
    const etiqueta = useRef();

    const [etiquetas, setetiquetas] = useState();

    const [plus, setplus] = useState(false);
    const [headers, setheaders] = useState([]);
    const [data, setData] = useState();
    const abecedario = ['A','B','C','D','E','F','G','H','I','J','K','L','M','N','O','P','Q','R','S','T','U','V','W','X','Y','Z'];
    
/*******************************************************************************************************/
    useEffect(() => {

      const getCatalogo = async (params) => {
            const log = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            }
            const send_data = {
              id: catalogo
            }
            console.log(send_data);
            // return ;
            await axios.post(urlApi+'catalogo/show', send_data, {headers: headers})
            // await axios.post(urlApi+'catalogo/show', catalogo, {headers: headers})
                .then(async (response) => {
                    set_catalogo(response.data.catalogo);
                    set__campos(response.data.campos);
                    setData(response.data.campos);
                    response.data.headers.map((e,i) => {
                        let n = capitalizarPrimeraLetra(e)
                        setheaders(headers => [...headers, {"Header": n, 'accessor': 'col'+abecedario[i], filter: 'fuzzyText'}])
                    });
                    setplus(true);
                    
                    })
                    .catch((error) => {
                        console.log(error);
                    });     

        }

        const getEtiqueta = async (params) => {
            const log = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            }

            const send_data = {
              id: etiqueteta
            }

            await axios.post(urlApi+'etiquetas/show', send_data, {headers: headers})
            // await axios.get(urlApi+'etiquetas/index', {headers: headers})
                .then(async (response) => {
                    setetiquetas(response.data.etiquetas);
                    // setetiquetas(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });     

        }
        
        getCatalogo();
        getEtiqueta();
        // alert('1')
    }, [])

/*******************************************************************************************************/

    const capitalizarPrimeraLetra = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

/*******************************************************************************************************/
    const columns = useMemo(
        () =>  
            headers,
            [headers]
        );
        
/*********************************************************************************************** */
    const onClickTr = async (params) => {
        
        console.log(params);

        //Obtenemos fechas
        const d = new Date();
        // const anio = d.getDate+'/'+(d.getMonth()+1)+'/'+d.getFullYear()
        const anio = d.toLocaleDateString('default');
        const hora = d.toLocaleTimeString('default', {
            // en-US can be set to 'default' to use user's browser settings
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });

        const datos_enviar = {
            catalogo         : _catalogo,
            campos           : params,
            etiqueta         : etiquetas,
            anio             : anio,
            hora             : hora,
            sistema_operativo: funciones.getOS(),
            // etiqueta: etiqueta.current.value
        }
        console.log(datos_enviar);
        // return;
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
        await axios({
            url    : urlApi+'catalogo/buscaeimprime',
            method : 'POST',
            data   : datos_enviar,
            headers: headers,
            // responseType: 'blob', // important
        }).then(async (response) => {

            const x = await funciones._write(log_json, response.data.impresora, response.data.mensaje);
            buttonRef.current.select();
        })
        .catch(function (error) {
          console.log(error);
          if(error.response?.data.message != undefined && error.response?.data.message == 'Unauthenticated.'){
            unauthenticated(error);
          }else{
            Swal.fire({
              icon             : 'error',
              title            : error,
              toast            : true,
              position         : 'top-end',
              showConfirmButton: false,
              timer            : 3000,
              timerProgressBar : true,
              didOpen: (toast) => {
                  toast.addEventListener('mouseenter', Swal.stopTimer)
                  toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
            });
          }

        });

    }


/************************************************************************************************** */

const [originalData] = React.useState(data)
const [skipPageReset, setSkipPageReset] = useState(false);

useEffect(() => {
  setSkipPageReset(false)
}, [data]);

// const resetData = () => setData(originalData)

/*****************************************************************************************************/
//Filtros
// Define a default UI for filtering
const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) => {
  // const count               = preGlobalFilteredRows.length
  const [_value, _setValue] = useState(globalFilter)
  const onChange            = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined);
  }, 200)
  return (
    <span>
      <span>Buscar: </span>
      <FontAwesomeIcon icon={faSearch}/>{' '}
      <input
        // value={_value || ""}
        value={_value || globalFilter}
        onChange={e => {
          _setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder="..."
        autoFocus
        style={{
          fontSize: '1.1rem',
          border: '0',
          textAlign: 'left',
          background: 'white',
          borderRadius: '3px',
          border: '1px solid #2a2b2d',
          padding: '2px'
        }}
        className="w-10rem"
        id="buttonRef"
        ref={buttonRef}
      />
    </span>
  )
}

// Define a default UI for filtering--------------------------------------------------------------

const fuzzyTextFilterFn = (rows, id, filterValue) => {
  return matchSorter(rows, filterValue, { keys: [row => row.values[id]] })
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = val => !val


/********************************************************************************************************/
// Create a default prop getter
const defaultPropGetter = () => ({});
const Table = ({ columns, data, getRowProps = defaultPropGetter, }) => {

  const filterTypes = useMemo(
    () => ({
      // Add a new fuzzyTextFilterFn filter type.
      fuzzyText: fuzzyTextFilterFn,
      // Or, override the default text filter to use
      // "startWith"
      text: (rows, id, filterValue) => {
        return rows.filter(row => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    []
  )

  // Use the state and functions returned from useTable to build your UI
  const {
    //Editable
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    // state,
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    //Selectable
    state: { pageIndex, pageSize, selectedRowIds, globalFilter },
    // state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
    //   defaultColumn,
      // use the skipPageReset option to disable page resetting temporarily
    //   autoResetPage: false,
      // autoResetPage: !skipPageReset,
      // updateMyData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      filterTypes,
    },
    useFilters, // useFilters!
    useGlobalFilter, // useGlobalFilter!
    usePagination,
    useRowSelect
  )

  /*********************************************************************************************** */
  useEffect(() => {
    // document.querySelector('#buttonRef').focus()
    const _handleViewData = async () => {
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
      
        //Obtenemos fechas
        const d = new Date();
        // const anio = d.getDate+'/'+(d.getMonth()+1)+'/'+d.getFullYear()
        const anio = d.toLocaleDateString('default');
        const hora = d.toLocaleTimeString('default', {
            // en-US can be set to 'default' to use user's browser settings
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });
      
        const camps = page[0].original;
        const datos_enviar = {
            catalogo         : _catalogo,
            campos           : camps,
            etiqueta         : etiquetas,
            anio             : anio,
            hora             : hora,
            sistema_operativo: funciones.getOS(),
            // etiqueta: etiqueta.current.value
        }

        await axios({
            url    : urlApi+'catalogo/buscaeimprime',
            method : 'POST',
            data   : datos_enviar,
            headers: headers,
            // responseType: 'blob', // important
        }).then(async (response) => {
        
              const x = await funciones._write(log_json, response.data.impresora, response.data.mensaje);

              await setGlobalFilter('') ; 
              buttonRef.current.value = '';

        })
        .catch(function (error) {
          if(error.response.data.message != undefined && error.response.data.message == 'Unauthenticated.'){
            unauthenticated(error);
          }else{
            Swal.fire({
              icon: 'error',
                title: error,
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', Swal.stopTimer)
                    toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            });
          }
        }).then(async () =>{
           
        });
    }
    

    if(page.length == 1){
        _handleViewData();
    }
    
    console.log(page)
}, [page])

useEffect(() => {
   console.log(globalFilter);
}, [globalFilter])



/*******************************************************************************************************/

    // Render the UI for your table
    return (
        <>
          <div className="border border-dark rounded table-responsive mb-4 animate__animated animate__fadeIn" style={{height: '550px'}}>
            <table {...getTableProps()} className="table table-striped table-hover">
              <thead className="sticky-header  bg-white">
                <tr>
                   <th
                    colSpan={visibleColumns.length}
                    style={{
                      textAlign: 'left',
                      backgroundColor: "transparent",
                      borderBottomWidth: '2px',
                      borderBottomColor : 'black',
                      
                    }}
                  >
                    <GlobalFilter
                      preGlobalFilteredRows={preGlobalFilteredRows}
                      globalFilter={globalFilter}
                      setGlobalFilter={setGlobalFilter}
                    />
                  </th>
                </tr>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()} style={{borderBottom: '1px solid gray'}}>
                    {headerGroup.headers.map(column => (
                      <th {...column.getHeaderProps()} 
                        style={{                          
                          borderBottomWidth: '2px',
                          borderBottomColor : 'black',
                        }}
                      >{column.render('Header')}</th>
                    ))}
                  </tr>
                ))}
                 
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps(getRowProps(row))}>
                      {row.cells.map(cell => {
                        const celula = cell.render('Cell').props.value.substr(0, 20);
                        return <td {...cell.getCellProps()}>{celula }</td>
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
            {/* 
              Pagination can be built however you'd like. 
              This is just a very basic UI implementation:
            */}
          </div>
          <div className="row">
            <div className="col-md-2">
              <p>Registros: <b>{preGlobalFilteredRows.length}</b></p>
            </div>
            <div className="col-md-3">
                <nav>
                  <ul className="pagination">
                    <li className="page-item">
                      <button className="page-link" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        {'<<'}
                      </button>{' '}
                    </li>
                    <li className="page-item">
                      <button className="page-link" onClick={() => previousPage()} disabled={!canPreviousPage}>
                        {'<'}
                      </button>{' '}
                    </li>
                    <li className="page-item">
                      <button className="page-link" onClick={() => nextPage()} disabled={!canNextPage}>
                        {'>'}
                      </button>{' '}
                    </li>
                    <li className="page-item">
                      <button className="page-link" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        {'>>'}
                      </button>{' '}
                    </li>
                  </ul>
                </nav>
              </div>
              <div className="col-md-3">
                <span>
                  Pagina{' '}
                  <strong>
                    {pageIndex + 1} de {pageOptions.length}
                  </strong>{' '}
                </span>
              </div>
            <div className="col-md-2">
              <span>
                Ir a la pagina:{' '}
              </span>{' '}
              <input
                type="number"
                defaultValue={pageIndex + 1}
                onChange={e => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0
                  gotoPage(page)
                }}
                className="form-control"
              />
            </div>
            <div className="col-md-2">
              <span>
                Mostrar
              </span>{' '}
              <select
                className="form-select"
                value={pageSize}
                onChange={e => {
                  setPageSize(Number(e.target.value))
                }}
              >
                {[10, 20, 30, 40, 50].map(pageSize => (
                  <option key={pageSize} value={pageSize}>
                    Mostrar {pageSize}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </>
      )
    }
    
    return (
        <>
        <div className="row justify-content-center">
            <div className="col-md-12">                
                <div className="card">
                    <div className="card-body">
                        <div className="d-none">
                            {
                              etiquetas != undefined  &&
                              <>
                                <input 
                                    ref={etiqueta}
                                    value={etiquetas.id}
                                />     
                                <span>
                                  <b>Etiqueta: </b>
                                  {etiquetas.nombre}
                                </span>                       
                              </>
                            }

                          </div>
                        {
                            plus ?
                              <Styles>
                                  <Table 
                                      columns={columns} 
                                      data={data} 
                                      skipPageReset={skipPageReset.current}  
                                      getRowProps={row => ({
                                          onDoubleClick: () => onClickTr(row.original)
                                      })}
                                  />
                              </Styles>
                            :
                            <div className="d-flex align-items-center justify-content-center">
                                <div style={{marginRight: '1rem'}}>
                                    <span>Cargando ...</span>
                                </div>
                                <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                       
                    }
                </div>
            </div>
        </div>
    </div>
        </>
    );
}

export default EncuentraImprime
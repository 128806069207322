import React, {useEffect, useState, useRef, useContext} from 'react'
import axios from 'axios';
import Swal from 'sweetalert2';
/*Globales*/
import {urlApi} from '../../../global/Global';
import { useHistory } from "react-router-dom";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useTranslation } from 'react-i18next';
import ModalValidarT2b from './ModalValidarT2b';
import Paso2 from './Paso2';
import Paso3 from './Paso3';
import UserContext from '../../../userContext/UserContext';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';

const Welcome = ({user, navigation}) => {

    const { t }                     = useTranslation();
    let history = useHistory();
    /* Vista modal Imp */
    const [impresoras, setImpresoras] = useState([]);
    const [pasos, setpasos]           = useState(0);
    // const [pasos, setpasos]           = useState(1);
    // const [pasos, setpasos]           = useState(2);

    const btn_t2b                               = useRef('');
    const [btn_t2bs, setbtn_t2bs]               = useState(false);
    const [elres, setelres]                     = useState(false)
    const [iniciarContador, setiniciarContador] = useState(false);
    // const { seleccionarUser }      = useContext(UserContext);

/**************************************************************************************************/

    const second = () => { 
        console.log(history); 
        console.log(window.history); 

        window.history.pushState(null, document.title, window.location.href);
        window.addEventListener('popstate', function(event) {
            if(window.location.href.toString().includes('welcome')){
                console.log('entra')
                console.log(window.history);
                window.history.pushState(null, document.title, window.location.href);
            }else{
                console.log('no deberia');
            }
        });

        console.log(window.location.pathname);
    }

/**************************************************************************************************/

    const first = async (second) => {
        // return;
        const title = t("welcome.welcome");
        const text  = t("welcome.welcome_text");
        Swal.fire({
            // title: t("welcome.welcome"),
            html: `<h1>
                        <i class="far fa-thumbs-up like pt-4 text-primary"></i>
                    </h1>
                    <h2 class="fw-bold">${title} ${user.name}</h2>
                    <p class="mb-0">${text}<p>
                    `,
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            },
            allowOutsideClick: false,
            confirmButtonText: t("welcome.continue"),
            cancelButtonText : t('welcome.skip'),
            showCancelButton : true,            
        }).then(result =>{
            if(!result.isConfirmed){
                // return false;
                Swal.fire({
                    title: t("welcome.skip"),
                    text: t("welcome.skip_tutorial"),
                    icon: 'info',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: t("welcome.skip"),
                    cancelButtonText: 'No',
                }).then(async (result) => {
                    if (result.isConfirmed) {
                        // window.addEventListener('popstate', function(event){});

                        const log      = await AsyncStorage.getItem('@login');
                        const log_json = JSON.parse(log);
                        const headers  = {
                            'Authorization': log_json.token_type+' '+log_json.access_token,
                            'Content-Type': 'application/json',
                            'X-Requested-With': 'XMLHttpRequest'
                        }
                        await axios.get(urlApi+'usuarios/tutorial', {headers: headers})
                                .then(async (response) => {
                                    console.log(response.data);
                                    let _log = log_json;
                                    if(response.data.response){
                                        _log.tutorial = 1;
                                        _log = JSON.stringify(_log);
                                        await AsyncStorage.removeItem(
                                            '@login'
                                        );
                                        
                                        await AsyncStorage.setItem(
                                                '@login',
                                                _log
                                        );

                                        history.push("/");
                                    }
                            })                                                 
                    }else{
                        first();
                    }
                })
            }else{
                setbtn_t2bs(true);
                btn_t2b.current.click();
                setiniciarContador(true);
            }
        });
    }

/**************************************************************************************************/

    const swalFiltros = () => {
        setelres(true);
        const title = t("welcome.filters_tutorial");
        Swal.fire({
            html: `<p class="mb-0 h3">${title}<p>
                    `,
            showClass: {
                popup: 'animate__animated animate__fadeInDown'
            },
            hideClass: {
                popup: 'animate__animated animate__fadeOutUp'
            },
            allowOutsideClick: false,
            confirmButtonText: "OK",
            showCancelButton : false,            
        }).then(result =>{
                if(result.isConfirmed){
                    setpasos(2)
                }
        });
    }    

/**************************************************************************************************/

    useEffect(() => {
      
        first();
        second();
    }, [])
        

/***************************************************************************************************/

    return (
        <> 
        
        {
            pasos === 0 &&
            <>
                <ModalValidarT2b
                    btn_t2b={btn_t2b}
                    btn_t2bs={btn_t2bs}
                    setbtn_t2bs={setbtn_t2bs}
                    impresoras={impresoras}
                    setImpresoras={setImpresoras}
                    setpasos={setpasos}
                    iniciarContador={iniciarContador}
                    setiniciarContador={setiniciarContador}
                />
                <div className="row justify-content-center">
                    <div className="col-md-12">                
                        <div className="card shadow ">                
                            <div className="card-header bg-card-header ">
                                <div className='row align-items-center'>
                                    <div className='col-md-6 my-2'>
                                        <span className="fw-bold text-white">{t("see_labels.title")}</span>

                                    </div>
                                    

                                    {/* <div className='col-md-6 my-2 text-end elemento-resaltante'> */}
                                    <div className="col-md-6 my-2 text-end">
                                        <a href="#" 
                                            className="btn btn-warning btn-sm fw-bold shadow res-w100 "
                                        > 
                                        <FontAwesomeIcon icon={faPlusCircle} className="me-2"/><span>{t("see_labels.templates")}</span>
                                        </a>
                                    </div>
                                    

                                </div>
                            </div>
                            <div className="card-body">

                            </div>
                        </div>
                    </div>
                </div>
            </>
        } 

        {
            pasos === 1 &&
            <>
            <div className=' loader-cotizador'></div>
            <div className="row justify-content-center">
                <div className="col-md-12">                
                    <div className="card shadow ">                
                        <div className="card-header bg-card-header ">
                            <div className='row align-items-center'>
                                <div className='col-md-6 my-2'>
                                    <span className="fw-bold text-white">{t("see_labels.title")}</span>

                                </div>
                                

                                {/* <div className='col-md-6 my-2 text-end elemento-resaltante'> */}
                                <div className={`${!elres ? "col-md-6 my-2 text-end elemento-resaltante" : "col-md-6 my-2 text-end"}`} >
                                    <a href="#" 
                                        className="btn btn-warning btn-sm fw-bold shadow res-w100 "
                                        onClick={() => swalFiltros()}
                                    > 
                                    <FontAwesomeIcon icon={faPlusCircle} className="me-2"/><span>{t("see_labels.templates")}</span>

                                    </a>
                                </div>
                                

                            </div>
                        </div>
                        <div className="card-body">

                        </div>
                    </div>
                </div>
            </div>
            </>
        }
        
        {
            (pasos === 2) &&
            <>
                <Paso2
                    setpasos={setpasos}
                />
            </>
        }
        {
            (pasos === 3) &&
            <>
                <Paso3
                    impresoras={impresoras}
                    setImpresoras={setImpresoras}
                    setpasos={setpasos}
                />
            </>
        }
       
    </>
    )
}

export default Welcome
import React, {useEffect, useState, useRef} from 'react';
import ReactDOM from "react-dom";
import axios from 'axios';
import {urlApi} from '../../global/Global';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {
    NavLink, Link
} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faPlusCircle, faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import { v4 as uuidv4 } from 'uuid';
import QRCode from "react-qr-code";
import Table from './Table';
import ComprobarVista from '../vistaUsuarioContext/ComprobarVista';

const Dispositivos = () => {

    const MySwal = withReactContent(Swal);
    const [carga, setCarga] = useState(false);
    const [dispositivos, setDispositivos] = useState();
    const [touken, setTouken] = useState('');
    const newToken = useRef();

    const getDispositivos = async () => {
        setCarga(true);  
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
        
        await axios.get(urlApi+'dispositivos', {headers: headers})
                    .then(async (response) =>{
                        console.log(response);
                        setDispositivos(response.data);
                    }).catch((error) => {
                        console.log(error);
                    }).then(() =>{
                        setCarga(false);
                    });            
    }

    useEffect(() => {              
        getDispositivos();
    }, []);
/********************************************************************************************************/

    const crearToken = async () => {
        // setloading(true);
        const token = uuidv4();
        setTouken(token);
        const send_params = {
            token: token
        }
        const log      = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers  = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest'
        }
        // return;
        await axios.post(urlApi+'dispositivos', send_params, {headers: headers})
                .then(response=>{
                    const x = response.data;
                    console.log(response.data);
                    if(response.data.respuesta == 'correcto'){
                        newToken.current.click();         
                        setDispositivos(dispositivos =>
                          [...dispositivos, response.data.dispositivo]  
                        );
                    }else{
                        MySwal.fire({
                            icon : 'error',
                            title: 'Error',
                            text : 'Ocurrio un error, vuelva a intentarlo',
                        });
                    }
                })
                .catch(error => {
                    console.log(error);
                    MySwal.fire({
                        icon : 'error',
                        title: 'Error',
                        text : 'Ocurrio un error, vuelva a intentarlo',
                    });
                }).then(() =>{
                    // setloading(false);
                })

    }
    

/********************************************************************************************************/

    return (
        <>
         < ComprobarVista/>
            {/* <!-- Modal --> */}
            <div style={{zIndex: 10000}} className="modal fade" id="new-token"  tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel-1">Token</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body text-center">
                            <div>
                                <p>
                                    {touken}
                                </p>
                            </div>
                            <div>
                                <QRCode value={touken} />
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>

            <button 
                className="d-none"
                data-bs-toggle="modal" data-bs-target="#new-token"
                ref={newToken}
            ></button>
            {/* <!-- Modal --> */}

        <div className="row justify-content-center">
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header bg-card-header">
                    <span className='text-white fw-bold'>Dispositivos</span> 
                    </div>
                    <div className="card-body">
                        <div className='row'>
                            <div className="col-md-6 mb-2"> 
                                <button
                                        className="d-flex align-items-center btn btn-sm btn-warning fw-bold res-w100  justify-content-center" 
                                        onClick={() => crearToken()}
                                        disabled={carga}
                                        >
                                        <span className='' style={{marginRight: '5px'}}>Generar Token</span>                                
                                        <FontAwesomeIcon icon={faPlusCircle} />
                                </button>   
                            </div>

                            <div className="col-md-6 mb-2 res-per">                                
                                <NavLink
                                    className="btn btn-sm btn-warning fw-bold res-w100"
                                    to="dispositivos/config" 
                                    exact
                                >
                                    <span>Archivo</span> <b>.config</b>
                                </NavLink>
                            </div>  

                            <div className="col-md-12 mb-2">
                                
                                <button
                                    className='btn btn-outline-dark'
                                    onClick={() => getDispositivos()}
                                    disabled={carga}
                                    >
                                    <div className='d-flex flex-column align-items-center'>
                                        <FontAwesomeIcon icon={faSyncAlt} />
                                    </div>
                                </button>
                            </div>
                        </div>                                 
                    {
                    carga ?
                        <div className="row">
                            <div className=" col-md-12 text-center">
                                <div className="spinner-grow text-info" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </div>
                    :
                        <div className="rounded table-responsive">

                            <table className="border table table-striped table-hover table-sm mb-0">
                                <thead>
                                    <tr>
                                        <th scope="col">#</th>
                                        <th scope="col">Dispositivo</th>
                                        <th scope="col">Token</th>
                                        <th scope="col">Opciones</th>
                                    </tr>
                                </thead>                                                                 
                                <tbody>
                                    {
                                        dispositivos != undefined &&
                                            dispositivos.map((e,i) =>
                                                <tr key={'dispositivos-'+i}>    
                                                    <th scope="row">{e.id}</th>
                                                    <td>
                                                        {   
                                                        e.dispositivo == 1 ?
                                                            <p className='text-success m-0'>Activado</p>
                                                        :
                                                            <p className='text-danger m-0'>Sin activar</p>

                                                        }
                                                    </td>
                                                    <td>{e.token}</td>
                                                    <td>
                                                        <div className="row">
                                                            <Table
                                                                dispositivo={e}
                                                                setCarga={setCarga}
                                                                setDispositivos={setDispositivos}
                                                            />
                                                            
                                                        </div>
                                                    </td>
                                                </tr>                        
                                            )
                                    } 
                                </tbody>
                            </table>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

export default Dispositivos

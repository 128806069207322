import React, {useEffect, useState, useRef, useContext, Fragment} from 'react'
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
/*Globales*/
import {urlApi, urlImages} from '../../global/Global';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faMinus, faTrash, faClone, faCog, faEdit, faPrint, faListOl, faPlusCircle, faFileInvoice, faFileSignature} from '@fortawesome/free-solid-svg-icons';
import {
    Link, NavLink
  } from "react-router-dom";
import AsyncStorage from '@react-native-async-storage/async-storage';
import VistaContext from '../vistaUsuarioContext/VistaContext';
import { unauthenticated } from '../../helpers/Helper';
import funciones from '../utilidades/funciones';
import ModalImpresoras from './ModalImpresoras';
import ModalCatalogos from './ModalCatalogos';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import ModalCambiarNombre from './ModalCambiarNombre';

const replacements = {
    'PRICE'      : 'PRECIO',
    'CODE'       : 'CÓDIGO',
    'DESCRIPTION': 'DESCRIPCIÓN',
    'NAME'       : 'NOMBRE',
    'PRICE_1'    : 'PRECIO_1',
    'PRICE_2'    : 'PRECIO_2',
    'PRICE_3'    : 'PRECIO_3',
    'OFFER'      : 'OFERTA',
    'UNIT'       : 'UNIDAD',
    'OBSERVATION': 'OBSERVACIÓN',
    'LOCATION'   : 'UBICACIÓN',
    'QUANTITY'   : 'CANTIDAD',
}
const VerEtiquetas = ({setdownloadPlantilla, setdownloadPlantillaCount}) => {

    const MySwal                          = withReactContent(Swal);
    const [etiquetas, setetiquetas]       = useState([]);
    const [eti, seteti]                   = useState();
    const [catalogos, setcatalogos]       = useState([]);
    const [url, seturl]                   = useState('');
    const [firstloading, setFirstLoading] = useState(false);
    const [loading, setLoading]           = useState(false);
    const [carga, setCarga]               = useState(false);
    const [autoPrint, setautoPrint]       = useState(false)

    const [nombre, setNombre] = useState('')
    const [id, setId]         = useState('')
    
    const btn_ref                  = useRef();
    const modalCambiarNombre       = useRef();
    const clsModalCambiarNombre    = useRef();
    const [_btn_ref, _set_btn_ref] = useState(false);

    const btn_close = useRef();
    /* Vista modal Imp */
    const [impresoras, setImpresoras] = useState([]);
    const btn_ref_imp                 = useRef();
    const btn_close_imp               = useRef();
    const btn_auto_print              = useRef();
    // context de Vista
    const { vista } = useContext(VistaContext);
    const { t }     = useTranslation();
/***********************************************************************************************/

    useEffect(() => {
        setdownloadPlantilla(false);
        setdownloadPlantillaCount(0);
        setLoading(true);
        window.scrollTo(0, 0);
        const getEtiquetas = async (params) => {
            const log = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            }
            await axios.get(urlApi+'etiquetas/index', {headers: headers})
                .then((response) => {
                    //Actualiza estados del formulario
                    setetiquetas(response.data.etiquetas);
                    seturl(response.data.url);
                    // setLoading(false);
                    console.log(response.data);
                })
                .catch((error) => {
                    unauthenticated(error);
                    console.log(error);
                }).then(() =>{
                    setFirstLoading(true);
                }); 
        }

        const getCatalogos = async (params) => {
            const log = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            }
            await axios.get(urlApi+'catalogo/index', {headers: headers})
                .then((response) => {
                    //Actualiza estados del formulario
                    setcatalogos(response.data);
                    // setLoading(false);
                    console.log(response.data);
                })
                .catch((error) => {
                    // unauthenticated(error)
                    console.log(error);
                });  
        }
        
        getEtiquetas();
        getCatalogos();
        setLoading(false);
        

    }, [])

/***********************************************************************************************/

    const deleteEtiqueta = async (params) => {
        // console.log(params)
        const log      = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers  = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }

        const send_data = {
            id: params
        }

        Swal.fire({
            title: t("see_labels.delete"),
            text: t("see_labels.delete_2"),
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t("utilidades.delete"),
            // cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await axios({
                    method: 'delete',
                    url: urlApi+'etiquetas/destroy',
                    data: send_data,
                    headers: headers
                  }).then((response) => {
                      if(response.data.respuesta === 'correcto'){
                        MySwal.fire({
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            icon: 'success',
                            title: t("see_labels.delete_3"),
                            timer: 3000,
                            timerProgressBar: true,
                        })
                        setetiquetas(response.data.etiquetas);
                      }else{
                        MySwal.fire({
                            icon: 'error',
                            title: 'error',
                            text: t("utilidades.error_delete"),
                        })
                      }
                        // console.log(response.data)
                    }).catch(error => { 
                        unauthenticated(error);
                        MySwal.fire({
                            icon: 'error',
                            title: 'error',
                            text: t("utilidades.error_delete"),
                        })           
                        console.log(error);
                  });
            }
          })        
    }

//Imprimir etiquetas********************************************************************************//
    const imprimirEtiquetas = async (etiqueta) => {
        
        // setCarga(true);
        let lista   = '';
        let conteo  = 0;
        let pattern = 'pattern';
        // console.log(x);
        console.log(etiqueta);
        // return;
        if(etiqueta.nueva == "1"){
            const variables = funciones.getVariables(etiqueta.etiqueta, etiqueta.language_id);
            for (const variable of variables) {
                let type;
                if((variable.toLocaleLowerCase() == 'quantity')){
                        pattern='pattern="[0-9]*" inputmode="numeric"';
                        type='numeric';
                        
                }else if((variable.toLocaleLowerCase() == 'price' || variable.toLocaleLowerCase() == 'offer')){
                    pattern='pattern="[0-9]*" inputmode="decimal"';
                    type='numeric';                    
                }   
                else{
                    pattern = 'pattern=""';
                    type='text';
                }
                    lista+=`
                        <div class="col-md-12 mb-2">
                            <label class="fw-bold mb-1">${replacements[variable] || variable}</label>
                            <input ${pattern} type="${type}" name="${variable}" required class="form-control text-center blc-fl" id="swal-input${conteo}" placeholder="${replacements[variable] || variable}">
                        </div>`;
                        // <input ${pattern} type="${type}" name="${variable}" required class="form-control text-center blc-fl" id="swal-input${conteo}" placeholder="${replacements[variable] || variable}">
                conteo++;
            }
        }else{
            etiqueta.configuraciones.map(async (e,i) => {
                console.log(e);
                // if(e.type_config_id == 2){
                if(e.types.front_back == 'front'){
                    if(e.type_config_id == 4){
                        pattern='pattern="[0-9]*" inputmode="numeric"';
                    }else{
                        pattern = 'pattern=""'
                    }
                        lista+=`
                            <div class="col-md-12 mb-2">
                                <label class="fw-bold mb-1">${replacements[e.valor] || e.valor}</label>
                                <input ${pattern} type="${e.types.type}" name="${e.variable}" required class="form-control text-center blc-fl" id="swal-input${conteo}" placeholder="${replacements[e.variable] || e.variable}">
                            </div>`;
                        // lista+=`
                        //     <div class="col-md-12 mb-2">
                        //         <label class="fw-bold mb-1">${e.valor}</label>
                        //         <input ${pattern} type="${e.types.type}" name="${e.variable}" required class="form-control text-center blc-fl" id="swal-input${conteo}" placeholder="${e.variable}">
                        //     </div>`;
                conteo++;
                }
            });
        }

        // return;
        let   jwal          = '';
        let   preguntas     = {};
        const complete_form = t("see_labels.complete_form");
        if(conteo > 0){
        jwal = await MySwal.fire({
            html: `<div class="row">
            <div class="col">
                <span class="errores text-danger text-uppercase fw-bold mb-3 d-none">
                *${complete_form}
                </span>
            </div>  
            </div>  
            <div class="row">
            ${lista}
            </div>`,
            allowOutsideClick: true,
            focusConfirm     : false,
            focusCancel      : false,
            focusDeny        : false,
            confirmButtonText: t("see_labels.print"),
            showCloseButton  : true,
            didOpen          : () =>{
                    if(document.querySelector('#swal-input0')){
                        document.querySelector('#swal-input0').focus();                 
                    }
               
            },  
            preConfirm: () => {
                const x = Array.from(document.querySelectorAll('.blc-fl'));
                x.forEach(e =>{
                    if(e.value.trim() == ''){           
        
                        if(document.querySelector('.errores').classList.contains('d-none')){
                            document.querySelector('.errores').classList.remove('d-none')
                        }
                        MySwal.showValidationMessage(complete_form)
                        // return false;
                    }
                })
                return x;
            }
        });

        if(!jwal.isConfirmed){
            setCarga(false);
            return false;
        } 
        console.log(jwal);
        // console.log(jwal.value);
        // return;
        jwal.value.map((e,i)=>{
            preguntas[e.name] = e.value;
            // console.log(e.name+' - '+e.value);
        })
        
        }else{
            preguntas = '';
        } 

        //Obtenemos fechas
        const d = new Date();
        // const anio = d.getDate+'/'+(d.getMonth()+1)+'/'+d.getFullYear()
        const anio = d.toLocaleDateString('default');
        const hora = d.toLocaleTimeString('default', {
            // en-US can be set to 'default' to use user's browser settings
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });

        const datos_enviar = {
            etiqueta: etiqueta.id,
              // cantidades: cantidad_send,
            preguntas        : preguntas,
            anio             : anio,
            hora             : hora,
            sistema_operativo: funciones.getOS(),
        };

        console.log(preguntas);
        console.log(datos_enviar);

        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization'   : log_json.token_type+' '+log_json.access_token,
            'Content-Type'    : 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
        await axios({
            url    : urlApi+'etiquetas/print',
            method : 'POST',
            data   : datos_enviar,
            headers: headers,
            // responseType: 'blob', // important
        }).then(async (response) =>{
            console.log(response.data);
            if(!response.data.response){
                MySwal.fire({
                    icon: 'info',
                    title: response.data.mensaje,
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                });

                return false;
            }

            console.log(etiqueta.impresora.impresora);
            await funciones._write(log_json, etiqueta.impresora.impresora, response.data.mensaje);
            // setCarga(false);
            // return false;
        })
        .catch(function (error) {
            console.log(error);
            if(error.response?.data.message != undefined && error.response?.data.message == 'Unauthenticated.'){
                unauthenticated(error);
            }else{
                // return;
                MySwal.fire({
                    icon: 'error',
                    title: error,
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                });
            }
    
        }).then(()=>{
            setCarga(false);
        });
    }

/***********************************************************************************************/

    const mostrarCatalogos = (e) => { 
         console.log(e);
         seteti(e);
         _set_btn_ref(true);
        //  btn_ref.current.click();
    }

/***********************************************************************************************/

    const asignarCatalogo = async (e, catalogo) => { 
        console.log(e);
        console.log(eti);
        
        const log      = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers  = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }

        const send_data = {
            etiqueta : eti.id,
            catalogo : catalogo,
            cabeceras: e
        };

        await axios.post(urlApi+'etiquetas/asignar-catalogo', send_data, {headers: headers})
            .then((response) => {
                //Actualiza estados del formulario
                if(response.data.respuesta == 'correcto'){
                    setetiquetas(response.data.etiquetas)
                    Swal.fire({
                        icon: 'success',                            
                        title: response.data.mensaje,
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    })                        
                    console.log(response.data);
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: response.data.respuesta,
                        text: response.data.mensaje
                    })
                }
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: error
                })
                console.log(error);
            });  
    }

/***********************************************************************************************/
    const quitarCatalogo = async (e) => { 
         console.log(e);

         Swal.fire({
            title: '¿Quitar catalogo?',
            text: "Esto no elimina el catalogo, pero si lo desvincula de la etiqueta",
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, quitar',
            cancelButtonText: 'Cancelar'
          }).then(async (result) => {
            if (result.isConfirmed) {

                const log = await AsyncStorage.getItem('@login');
                const log_json = JSON.parse(log);
                const headers = {
                    'Authorization': log_json.token_type+' '+log_json.access_token,
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                }

                const send_data = {
                    etiqueta: e,
                };

                await axios.post(urlApi+'etiquetas/quitar-catalogo', send_data, {headers: headers})
                    .then((response) => {
                        //Actualiza estados del formulario
                        if(response.data.respuesta == 'correcto'){
                            setetiquetas(response.data.etiquetas)
                            Swal.fire({
                                icon: 'success',
                                title: response.data.mensaje,
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000
                            })                        
                            console.log(response.data);
                        }else{
                            Swal.fire({
                                icon: 'error',
                                title: response.data.respuesta,
                                text: response.data.mensaje,
                                
                            })
                        }
                        console.log(response.data);
                    })
                    .catch((error) => {
                        Swal.fire({
                            icon: 'error',
                            title: error
                        })
                        console.log(error);
                    });  


                // btn_close.current.click();
            }
          })

    }

/***********************************************************************************************/

    const clonarEtiqueta = (e) => { 
    
        console.log(e);

         Swal.fire({
            title: t("see_labels.clone_template"),
            icon: 'info',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: t("utilidades.yes"),
            cancelButtonText: 'No'
          }).then(async (result) => {
            if (result.isConfirmed) {

                const log = await AsyncStorage.getItem('@login');
                const log_json = JSON.parse(log);
                const headers = {
                    'Authorization': log_json.token_type+' '+log_json.access_token,
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                }

                const send_data = {
                    etiqueta: e,
                };

                await axios.post(urlApi+'etiquetas/clonar', send_data, {headers: headers})
                    .then((response) => {
                        //Actualiza estados del formulario
                        if(response.data.respuesta == 'correcto'){
                            setetiquetas(response.data.etiquetas)
                            Swal.fire({
                                icon: 'success',
                                title: response.data.mensaje,
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000
                            })                        
                            console.log(response.data);
                        }else{
                            Swal.fire({
                                icon: 'error',
                                title: response.data.respuesta,
                                text: response.data.mensaje,
                                
                            })
                        }
                        console.log(response.data);
                    })
                    .catch((error) => {
                        Swal.fire({
                            icon: 'error',
                            title: error
                        })
                        console.log(error);
                    });  
            }
          })

    }

/****************************************************************************************************/

    const impresora = async (params, primer_asignacion = false) => { 
        setCarga(true);
        console.log(params);
        seteti(params);
        const log         = await AsyncStorage.getItem('@login');
        const log_json    = JSON.parse(log);
        const _text       = await AsyncStorage.getItem('i18nextLng');
        const _impresoras = await funciones._available(log_json, _text);
        console.log(_impresoras);
        if(primer_asignacion){
            setautoPrint(true);
        }
        if(_impresoras != false){
            setImpresoras(_impresoras.data.printer);
            // set_btn_ref;
            btn_ref_imp.current.click();
        }
        setCarga(false);

    }

/***************************************************************************************************/

    const changePrinter = async(params) => { 
        Swal.fire({
            title             : t("see_labels.change_printer"),
            icon              : 'warning',
            showCancelButton  : true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor : '#d33',
            confirmButtonText : 'Ok'
          }).then(async (result) => {
            if (result.isConfirmed) {
              await impresora(params);
            }
          })
     }

/***************************************************************************************************/

    const returnPrint = (params) => { 

        const x = eval('(' + params + ')');
        // console.log(x.name);
        return x.name;

    }


/***************************************************************************************************/

    return (
    <> 

        <ModalCambiarNombre 
            modalCambiarNombre={modalCambiarNombre}
            clsModalCambiarNombre={clsModalCambiarNombre}
            nombre={nombre}
            setNombre={setNombre}
            id={id}
            etiquetas={etiquetas}
            setetiquetas={setetiquetas}
        />
        
        {/* Modal Catalogos  */}
        {
            _btn_ref &&
                <ModalCatalogos
                    btn_ref={btn_ref}
                    catalogos={catalogos}
                    asignarCatalogo={asignarCatalogo}
                    btn_close={btn_close}
                    eti={eti}
                    _set_btn_ref={_set_btn_ref}
                />

        }
        {/* Modal Catalogos  */}
        
        {/* Modal impresoras  */}
            <ModalImpresoras
                btn_ref_imp={btn_ref_imp}
                btn_close_imp={btn_close_imp}
                impresoras={impresoras}
                setImpresoras={setImpresoras}
                funciones={funciones}
                etiqueta={eti}
                setetiquetas={setetiquetas}
                etiquetas={etiquetas}
                autoPrint={autoPrint}
                btn_auto_print={btn_auto_print}
            />
        {/* Fin modal impresoras  */}

        {
        !firstloading
        ? <div className="col-md-12 text-center animate__animated animate__zoomIn">
                <div className="spinner-border text-primary" role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
    

        : <div className="row justify-content-center animate__animated animate__fadeIn">
            <div className="col-md-12">                
                <div className="card shadow ">
                    <div className="card-header bg-card-header ">
                        <div className='row align-items-center'>
                            <div className='col-md-6 my-2'>
                                <span className="fw-bold text-white">{t("see_labels.title")}</span>

                            </div>
                            
                            <div className='col-md-6 my-2 text-end'>
                                <NavLink to="/catalogo" className="btn btn-warning fw-bold shadow res-w100 ">
                                    <FontAwesomeIcon icon={faPlusCircle} className="me-2"/><span>{t("see_labels.templates")}</span>
                                </NavLink>
                            </div>

                        </div>
                    </div>
                    <div className="card-body">

                        {
                            vista &&
                            <div className="row  justify-content-end">
                                <div className="col-md-2 align-items-center mb-3">
                                    <div className='row text-end '>
                                        <div className='col-md-12 mb-3'>
                                            <div>
                                            {/* <div class={`dropdown ${_loading && 'disabled'}`}> */}
                                                <NavLink to="/etiquetas" className="btn btn-sm btn-outline-secondary shadow w-100" type="button" id="dropdownMenuButton1" disabled={carga}>
                                                    <span className='fw-bold'>{t("see_labels.create")} </span>
                                                    
                                                    {/* <FontAwesomeIcon icon={faCog} /> */}
                                                </NavLink>
                                                
                                            </div>
                                        </div>
                                        <div className='col-md-4 mb-3 d-none'>
                                            <NavLink to="/importar-zpl" className="btn btn-dark res-w100 "> 
                                                <span>Importar Plantilla ZPL</span>
                                            </NavLink>                                    
                                        </div>
                                    </div>                               
                                </div>
                            </div>
                        }

                        {
                        !loading ?
                        <>
                            <div className={`${carga ? 'row pb-5' : 'd-none'}`}>
                                <div className="col-md-12 text-center animate__animated animate__zoomIn">
                                    <div className="spinner-border text-info " role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </div>

                            <div className="row row-cols-1 row-cols-md-3 g-4 border-top">
                                {
                                    etiquetas.length > 0 
                                    ?    etiquetas.map((e, i) =>{
                                            return <div className="col" key={`etiquetas-${i}`}>
                                                <div className="card h-100 shadow border border-3 rounded">
                                                    <div className='p-1 shadow-sm'>

                                                        <div className='row justify-content-end text-end'>
                                                            <div className='col-md-5 px-3'>

                                                            {       
                                                                vista &&                                                     
                                                                <div className={`dropdown`}>
                                                                    <a href="#" className={`btn btn-secondary shadow w-auto`} type="button" 
                                                                       id="dropdownMenuButton1" 
                                                                       data-bs-toggle="dropdown" 
                                                                       aria-expanded="false"
                                                                       style={{borderRadius: '20px'}}
                                                                       disabled={carga}
                                                                    >
                                                                        <FontAwesomeIcon icon={faCog}/>
                                                                    </a>
                                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                                        
                                                                        <li>
                                                                        {
                                                                            e.catalogo_id == null ?
                                                                                <a 
                                                                                    className="dropdown-item" 
                                                                                    href="#" 
                                                                                    onClick={() => mostrarCatalogos(e)}
                                                                                >
                                                                                    <div className='d-flex align-items-center'>
                                                                                        <label className='col-2'><FontAwesomeIcon icon={faFileInvoice}/> </label>                                                                                        
                                                                                        <div className='col-md-10'>
                                                                                            <span>{t("see_labels.add_catalog")}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                            :
                                                                                <a 
                                                                                    className="dropdown-item" 
                                                                                    href="#" 
                                                                                    onClick={() => quitarCatalogo(e.id)}
                                                                                >   
                                                                                    <div className='d-flex align-items-center'>
                                                                                        <label className='col-2'><FontAwesomeIcon icon={faMinus} /> </label>                                                                                        
                                                                                        <div className='col-10'>
                                                                                            <span>{t("see_labels.remove_catalog")} </span>
                                                                                        </div>
                                                                                    </div>
                                                                                </a>
                                                                        }
                                                                        </li>
                                                                        <li>                                                                            
                                                                            <a className="dropdown-item"
                                                                                href="#!"
                                                                                onClick={() => {
                                                                                    setNombre(e.nombre)
                                                                                    setId(e.id)
                                                                                    modalCambiarNombre.current.click()
                                                                                }}
                                                                            >
                                                                                <div className='d-flex align-items-center'>
                                                                                    <label className='col-2'><FontAwesomeIcon icon={faFileSignature} /> </label>                                                                                        
                                                                                    <div className='col-10'>
                                                                                        {/* <span>{t("see_labels.edit_template")} </span> */}
                                                                                        <span>Editar nombre</span>
                                                                                    </div>
                                                                                </div>
                                                                                
                                                                            </a>
                                                                        </li>
                                                                        <li>                                                                            
                                                                            <Link className="dropdown-item"
                                                                                to={
                                                                                    {  
                                                                                        pathname:"/edit-label/"+e.id,
                                                                                        state:{etiqueta: e}
                                                                                    }
                                                                                }
                                                                            >
                                                                                <div className='d-flex align-items-center'>
                                                                                    <label className='col-2'><FontAwesomeIcon icon={faEdit} /> </label>                                                                                        
                                                                                    <div className='col-10'>
                                                                                        <span>{t("see_labels.edit_template")} </span>
                                                                                    </div>
                                                                                </div>
                                                                                
                                                                            </Link>
                                                                        </li>
                                                                        <li>                                                                            
                                                                            <a className="dropdown-item" href='#' onClick={() => clonarEtiqueta(e.id)}>
                                                                                <div className='d-flex align-items-center'>
                                                                                    <label className='col-2'><FontAwesomeIcon icon={faClone} /> </label>                                                                                        
                                                                                    <div className='col-10'>
                                                                                        <span>{t("see_labels.clone_template")} </span>
                                                                                    </div>
                                                                                </div>
                                                                                
                                                                            </a>
                                                                        </li>

                                                                        <li>
                                                                            
                                                                            <a className="dropdown-item" href='#' onClick={() => impresora(e.id)}>
                                                                                <div className='d-flex align-items-center'>

                                                                                    <label className='col-2'><FontAwesomeIcon icon={faPrint} /> </label>                                                                                        
                                                                                    <div className='col-10'>
                                                                                        <span>{t("see_labels.print_2")} </span>
                                                                                    </div>

                                                                                </div>
                                                                                
                                                                            </a>
                                                                        </li>

                                                                        {
                                                                            e?.nueva != '1' && 
                                                                            <li>
                                                                                <Link className="dropdown-item"
                                                                                    to={{  
                                                                                        pathname: "/label-configuration/"+e.id,
                                                                                        state   : {etiqueta: e}
                                                                                    }}
                                                                                >
                                                                                    <div className='d-flex align-items-center'>
                                                                                        <label className='col-2'><FontAwesomeIcon icon={faListOl} /> </label>                                                                                        
                                                                                        <div className='col-10'>
                                                                                            <span>{t("header.configuration")}</span>
                                                                                        </div>
                                                                                    </div>
                                                                                    
                                                                                </Link>
                                                                            </li>
                                                                        }

                                                                        <li>
                                                                            <hr className='dropdown-divider'/>                                                        
                                                                        </li>
                                                                        
                                                                        <li>
                                                                            <a className="dropdown-item" href="#" onClick={()=> deleteEtiqueta(e.id)}>
                                                                                <div className='d-flex align-items-center'>
                                                                                    <label className='col-2'><FontAwesomeIcon icon={faTrash} /> </label>                                                                                        
                                                                                    <div className='col-10'>
                                                                                        <span>{t("utilidades.delete")} </span> 
                                                                                    </div>
                                                                                </div>
                                                                            </a>
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                            }
                                                            </div>
                                                        </div>

                                                        <div className='p-1 text-center  rounded bg-transparent'>
                                                            <img src={urlImages+e.image} className="card-img-top w-100 border border-1" alt={e.nombre} />
                                                        </div>
                                                    </div>

                                                    <div className="card-body">
                                                        <div className='row'>
                                                            <div className="accordion accordion-flush  bg-transparent" id={`accordionExample${i}`}>
                                                                <div className="accordion-item bg-transparent">
                                                                    <h2 className="accordion-header" id={`headingOne${i}`}>
                                                                        <button className="accordion-button collapsed bg-transparent p-0" type="button" data-bs-toggle="collapse" data-bs-target={`#collapseOne${i}`} aria-expanded="true" aria-controls={`collapseOne${i}`}>
                                                                            <h5 className="card-title fw-bold mb-0">
                                                                                {e.nombre}
                                                                            </h5>
                                                                        </button>
                                                                    </h2>

                                                                    <div id={`collapseOne${i}`} className="accordion-collapse collapse mt-3" aria-labelledby={`headingOne${i}`} data-bs-parent={`#accordionExample${i}`}>
                                                                        <p className="card-text mb-0">{e.descripcion}</p>
                                                                        <p className="text-muted mb-0 d-none">{t("see_labels.language")}: {e.language.name}</p>
                                                                        {
                                                                            e.medida != null && e.material != null &&
                                                                            <>
                                                                                <p className="text-muted mb-0">{t("see_labels.size")}: {e.medida.name} {e.medida.medida}</p>
                                                                                <p className="text-muted mb-0">Material: {e.material.name}</p>
                                                                            </>
                                                                        }
                                                                        {
                                                                            e?.codigo != null &&
                                                                            <p className="fw-bold  mb-0">Código: {e.codigo}</p>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                                {
                                                                    e.impresora_id != null &&
                                                                    <div className='col-md-12'>
                                                                        <p className="card-text fw-bold fw-bold mt-2 mb-0">
                                                                            {t("see_labels.printer")}: 
                                                                        </p>
                                                                        <button 
                                                                            className="card-text fw-bold btn btn-secondary py-0 px-1 btn-sm text-wrap "
                                                                            type='button'
                                                                            onClick={() => changePrinter(e.id)}
                                                                            disabled={carga}    
                                                                        >
                                                                            {                                                                                
                                                                                returnPrint(e.impresora.impresora)                                                                                    
                                                                            }
                                                                        </button>
                                                                    </div>
                                                                }
                                                                {
                                                                    e.catalogo_id != null &&
                                                                    <p className="card-text text-primary fw-bold">{t("see_labels.has_catalog")}</p>
                                                                }
                                                                <p className='d-none'>Tags: </p>
                                                                <div className='d-flex flex-wrap d-none'>
                                                                    {
                                                                        e.tags.length > 0 &&
                                                                        e.tags.map((e,i) => {
                                                                            // return <ReactTooltip > 
                                                                            return <Fragment key={'targs-'+i}> 
                                                                                        <div  className='mr-2' style={{marginRight: '1rem'}}>
                                                                                                <span
                                                                                                    style={{cursor: 'default'}}
                                                                                                    data-tip={e.name}
                                                                                                    data-for='test'
                                                                                                    className='badge bg-danger rounded-pill text-wrap'>#{e.name}
                                                                                                </span >
                                                                                                {/* <span class="sr-only">{e.name}</span> */}
                                                                                            </div>
                                                                                            <ReactTooltip 
                                                                                                id='test' 
                                                                                                place="top" 
                                                                                                type="dark" 
                                                                                                effect="solid"
                                                                                                className='tooltip-width'
                                                                                            />
                                                                                    </Fragment>
                                                                        })
                                                                    }
                                                                </div>                                                            
                                                        </div>
                                                    </div>
                                                    <div className='p-3 row'>                                                           
                                                        {
                                                            e.catalogo_id == null ?
                                                            <>
                                                                <button 
                                                                    ref={btn_auto_print}
                                                                    disabled={carga}
                                                                    onClick={
                                                                        e.impresora_id == null 
                                                                        ? () => impresora(e.id, true)
                                                                        : () => imprimirEtiquetas(e)
                                                                        
                                                                    }
                                                                    className={
                                                                        e.impresora_id == null 
                                                                        ? 'btn btn-danger btn-xl fw-bold text-uppercase border shadow mb-2 fs-3 text'
                                                                        : 'btn btn-success btn-xl fw-bold text-uppercase border shadow mb-2 fs-3 text' 
                                                                    }
                                                                > 
                                                                {
                                                                     e.impresora_id == null 
                                                                     ? t("see_labels.print_2")
                                                                     : t("see_labels.print")
                                                                }
                                                                
                                                                </button> 
                                                            </>
                                                            :            
                                                            <>
                                                            {
                                                                e.impresora_id != null 
                                                                ? <NavLink 
                                                                    disabled={carga}
                                                                    to={`/vista/${e.catalogo_id}/${e.id}`}
                                                                    className={`${carga ? 'disabled btn btn-success btn-xl fw-bold text-uppercase border shadow mb-2 fs-3 text': 'btn btn-success btn-xl fw-bold text-uppercase border shadow mb-2 fs-3 text'}`}
                                                                > {t("see_labels.print")}</NavLink> 

                                                                :
                                                                <button 
                                                                    disabled={carga}
                                                                    onClick={() => impresora(e.id)}
                                                                    className={
                                                                        e.impresora_id == null 
                                                                        ? 'btn btn-danger btn-xl fw-bold text-uppercase border shadow mb-2 fs-3 text'
                                                                        : 'btn btn-success btn-xl fw-bold text-uppercase border shadow mb-2 fs-3 text' 
                                                                    }                                                                
                                                                    > {t("see_labels.print_2")}
                                                                </button> 

                                                            }
        
                                                            </>                                       
                                                        }                                                                                                     
                                                    </div>
                                                </div>
                                            </div>
                                        })
                                    :   <div className='col-md-12 text-center'>
                                            <p>0 Labels ... </p>
                                        </div>
                                        
                                }
                            </div>                            
                        </>
                        :
                        <div className="row">
                            <div className="col-md-12 text-center">
                                <div className="spinner-border text-primary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </div>
                        }
                    </div>

                </div>
            </div>
        </div>
        
        }
    </>
    )
}

export default VerEtiquetas

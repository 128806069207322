import React, {useEffect, useState, useRef} from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage';
import funciones from '../../utilidades/funciones';
import { t } from 'i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faDownload, faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import Swal from 'sweetalert2';
import _windows from '../../../video/windows.mp4';
import _android from '../../../video/android.mp4';
import _huawei from '../../../video/huawei.mp4';
import UAParser from 'ua-parser-js';

const ModalValidarT2b = ({btn_t2b, btn_t2bs, setbtn_t2bs, impresoras, setImpresoras, setpasos, iniciarContador, setiniciarContador}) => {


    // const [loading, setloading]                     = useState(true);
    const [carga, setcarga]                         = useState(false);
    const [text2barcode, settext2barcode]           = useState(false);
    const [cargaText2barcode, setcargaText2barcode] = useState(false);
    const [cont2b, setcont2b]                       = useState(0);
    const [os, setos]                               = useState('');
    const [osName, setosName]                       = useState('');
    const [contador, setcontador]                   = useState(6);
    const [disbtn, setdisbtn]                       = useState(true)
    const [contador_test, setcontador_test]         = useState(6);
    const [disbtn_test, setdisbtn_test]             = useState(true)

    const btn_paso2                       = useRef('');
    const btn_paso2_cls                   = useRef('');
    const btn_paso3                       = useRef('');
    const btn_paso3_cls                   = useRef('');

    const [recharge, setrecharge] = useState(false);
    //if Huawei device
    const [parser, setparser]         = useState(new UAParser());
    const [isHuawei, setisHuawei]     = useState(false);
    const [_userAgent, set_userAgent] = useState('')

    useEffect(() => {
        let cnt = 6;
        {
            if(iniciarContador){
                setInterval(() => {
                    cnt = cnt-1
                    setcontador(cnt)
                    if(cnt <= 0){
                        setdisbtn(false);
                    }
                }, 1000);
            }
        }
    }, [iniciarContador])
    

/********************************************************************************************/

    useEffect(() => {
      
        const second = () => {

            const userAgent        = window.navigator.userAgent,
                  platform         = window.navigator?.userAgentData?.platform ?? window.navigator.platform,
                  macosPlatforms   = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
                  windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
                  iosPlatforms     = ['iPhone', 'iPad', 'iPod'],
                  os               = null;
      
            set_userAgent(userAgent);

            if (macosPlatforms.indexOf(platform) !== -1) {
                setos("https://labeldictate.com/text2barcode/setup/mac/Text2%20Barcode.dmg");
                setosName('mac');
            } else if (iosPlatforms.indexOf(platform) !== -1) {
                setos("https://labeldictate.com/text2barcode/");
                setosName('ios');
            } else if (windowsPlatforms.indexOf(platform) !== -1) {
                setos("https://labeldictate.com/text2barcode/setup/win/Text2%20Barcode%20Pro%20setup.exe");
                setosName('windows');
            } else if (/Android/.test(userAgent)) {
                setos("https://labeldictate.com/text2barcode/setup/android/text2barcode.apk");
                setosName('android');
            } else if (!os && /Linux/.test(platform)) {
                setos("https://labeldictate.com/text2barcode/");
                setosName('linux');
            }
      
        }

        
        const _isHuawei = () => { 
            const result = parser.getResult();
            console.log(result.device.vendor);
            
            if(result.device.vendor == 'Huawei'){
                setisHuawei(true);
            }

        }
        
        second();
        _isHuawei();
        console.log(window.navigator.userAgent);

    }, [])

    
/********************************************************************************************/
    const rechargePrinters = async () => { 
        setrecharge(true);
        const log      = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const _text    = await AsyncStorage.getItem('i18nextLng');
        // const _text  = t('utilidades.funciones.error_text2barcode');
        const _impresoras = await funciones._available(log_json, _text);
        console.log(_impresoras);
        if(_impresoras != false){
            setImpresoras(_impresoras.data.printer);
            // btn_ref_imp.current.click();
        }
        setrecharge(false);
    }
/********************************************************************************************/

    const tengoText2barcode = async () => {
        setcargaText2barcode(true);
        const log      = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const res      = await funciones._default(log_json);

        if(res === 200){
            const _text    = await AsyncStorage.getItem('i18nextLng');
            const _res      = await funciones._available(log_json, _text, true);
            if(_res != false){
                setcont2b(0);
                settext2barcode(true);
                setImpresoras(_res.data.printer);
                btn_paso2_cls.current.click();
                btn_paso3.current.click();
            }else{
                settext2barcode(false);
                setcont2b(1);
            }
        }else{
            settext2barcode(false);
            setcont2b(1);
        }
        setcargaText2barcode(false);

        console.log(res);

    }

/********************************************************************************************/

    const download = (download = true) => { 
        console.log(os);
        if(download){
            window.open(os);
            // window.open(os, "_blank").focus();
        }
        btn_paso2.current.click();

        //Habilitar boton de test
        let cnt = 6;
        {
            setInterval(() => {
                cnt = cnt-1
                setcontador_test(cnt)
                if(cnt <= 0){
                    setdisbtn_test(false);
                }
            }, 1000);
            
        }
    }

/********************************************************************************************/

    const getImpresoras = async () => {
        setcarga(true);
        const log      = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const _text    = await AsyncStorage.getItem('i18nextLng');
        const res      = await funciones._available(log_json, _text, true);

        console.log(res);
        if(res != false){
            setImpresoras(res.data.printer);
            btn_paso2_cls.current.click();
            btn_paso3.current.click();
        }else{
            Swal.fire({
                icon: 'warning',
                title: t('welcome.start_obligatory_t2b'),
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            });
            // alert('encienda text2barcode');
        }
        setcarga(false);

    }
    // RT420ME2110255039
/********************************************************************************************/

    const test = async (_params) => { 
        console.log(_params);
        const params = JSON.stringify(_params);
        // return;
        const test = `^XA
^PW406
^LL203
^CF0,60
^FT140,120^FDTEST^FS                    
^XZ
`;
        const log      = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const res = await funciones._write(log_json, params, test, true);
        console.log(res.data);
        if(res.data.result){
            btn_paso3_cls.current.click();

            Swal.fire({
                html: `
                        <i class="fas fa-check-circle text-success h1"></i>
                        <h1>${t("welcome.correct_print_title")}</h1>
                        <span>${t("welcome.correct_print_desc")}</span>`
                        ,                
                showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                },
                allowOutsideClick: false,
                confirmButtonText: "OK",
            }).then(result =>{
                if(result.isConfirmed){
                    Swal.fire({
                        html: `
                                <h1>${t("welcome.correct_print_ok")}</h1>`
                                ,                
                        showClass: {
                            popup: 'animate__animated animate__fadeInDown'
                        },
                        hideClass: {
                            popup: 'animate__animated animate__fadeOutUp'
                        },
                        allowOutsideClick: false,
                        confirmButtonText: "OK",
                    }).then(result =>{
                        if(result.isConfirmed){
                            setpasos(1);
                            return false;
                        }
                    });
                }
            });
        }
        console.log(params);

    }

/********************************************************************************************/
  return (
    <>
        {/* <!-- Button trigger modal --> */}
        <button type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#staticBackdrop" ref={btn_t2b}>
            Launch static backdrop modal
        </button>

        {/* <!-- Modal --> */}
        <div className="modal fade" style={{zIndex:10000}} id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header justify-content-center">
                        <h5 className="modal-title fw-bold" id="staticBackdropLabel">
                            {
                                t("welcome.starting_t2b")
                            }
                            <i className="fas fa-power-off onOff" style={{marginLeft: '.5rem'}}></i>
                        </h5>
                        <button type="button" className="btn-close d-none" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        
                        {
                            (osName !== 'android' && _userAgent != 'Mozilla/5.0 AppleWebKit/535.19 Chrome/56.0.0 Mobile Safari/535.19' && _userAgent != 'Dalvik/2.1.0')
                            ? <>
                                <span dangerouslySetInnerHTML={{__html: t('welcome.instructions')}}></span> 
                                <div className='d-flex align-items-center my-3'>
                                    <button 
                                        type='button'
                                        className='btn btn-success btn-sm fw-bold'
                                        onClick={() => tengoText2barcode()}
                                        disabled={cargaText2barcode}
                                    >
                                        {t('welcome.got_text2barcode')}
                                    </button> 
                                    {
                                        cargaText2barcode &&
                                        <div className="spinner-border text-danger" style={{width: '20px', height: '20px', marginLeft: '5px'}} role="status">
                                            <span className="visually-hidden h6">Loading...</span>
                                        </div>
                                    }   
                                </div>
                                {
                                    cont2b > 0 
                                    ?   <p className='mb-3 text-danger fw-bold'>{t('welcome.start_obligatory_t2b')}</p>
                                    :   <p className='mb-3'></p>
                                }                                      
                                <span dangerouslySetInnerHTML={{__html: t('welcome.instructions_2')}}></span>                                               
                                <ul className='mt-4'>
                                    
                                    <li>{t("welcome.step_1")}</li>
                                    <li>{t("welcome.step_2")}</li>
                                    <li>{t("welcome.step_3")}</li>
                                </ul>
                            </>
                            :<>
                                <h5>{t("welcome.android_view")}</h5>
                            </>
                            
                        }
                    </div>
                    {
                        (osName !== 'android' && _userAgent != 'Mozilla/5.0 AppleWebKit/535.19 Chrome/56.0.0 Mobile Safari/535.19' && _userAgent != 'Dalvik/2.1.0')
                        ?   <div className="modal-footer">                                                    
                            {
                                disbtn &&
                                <div className='row w-100 justify-content-center'>
                                    <div className='w-50 text-center'>
                                        <div className='d-flex justify-content-center'>
                                                <p className='mb-0 text-primary h3 mx-2'> {t("welcome.wait")}  { contador}</p>
                                        </div>
                                    </div>
                                </div>
                            }
                        
                            <button onClick={() => download()} 
                                    type='button' 
                                    className="btn btn-primary text-uppercase w-100"
                                    disabled={
                                        (cargaText2barcode == true || disbtn == true) 
                                        ? true
                                        : false
                                    }
                            >
                                <p className='mb-0'>{t("welcome.btn_download")} Text2 Barcode</p>
                                {/* <FontAwesomeIcon icon={faDownload} style={{marginRight: '1rem'}}/> */}
                            </button>                            
                        </div>
                        :   <>
                                <div className="modal-footer">  
                                    <div className='row w-100 justify-content-center'>
                                        <div className='d-flex align-items-center justify-content-center'>
                                            <button 
                                                type='button'
                                                className='btn btn-primary fw-bold d-inline-block '
                                                onClick={() => tengoText2barcode()}
                                                disabled={cargaText2barcode}
                                            >
                                                OK
                                            </button> 
                                            {
                                                cargaText2barcode &&
                                                <div className="spinner-border text-danger" style={{width: '20px', height: '20px', marginLeft: '5px'}} role="status">
                                                    <span className="visually-hidden h6">Loading...</span>
                                                </div>
                                            }   
                                        </div>
                                    </div>
                                </div>
                            </>
                    }
                </div>
            </div>
        </div>  

{/********************************************** PASO 2 *********************************************/}

        {/* <!-- Button trigger modal --> */}
        <button type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#staticBackdrop2" ref={btn_paso2}>
            Launch static backdrop modal
        </button>

        {/* <!-- Modal --> */}
        <div className="modal border fade" style={{zIndex: 10000}} id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel2" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel2">
                           {
                               t("welcome.title_2")
                           }
                        </h5>
                        <button type="button" className="btn-close d-none" data-bs-dismiss="modal" aria-label="Close" ref={ btn_paso2_cls }></button>
                    </div>
                    <div className="modal-body">
                        {
                            carga &&
                                <div className='row'>
                                    <div className="col-md-12 text-center">
                                        <div className="spinner-border text-info" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                        }
                        <ul className='list-group list-group-flush'>
                            <li className='list-group-item'>
                                <h3>1- {t("welcome.video_tutorial")}</h3>
                            </li>
                        </ul>
                        
                        {
                            (osName == 'windows') &&
                                <div className='text-center bg-secondary rounded p-1'>
                                    <video className='rounded border border-3' src={_windows} width={"100%"} autoPlay loop controls></video>
                                </div>
                       }
                       {
                           (osName == 'mac') &&
                               <div className='text-center bg-secondary rounded p-1'>
                                   <video className='rounded border border-3' src={_windows} width={"100%"} autoPlay loop controls></video>
                               </div>

                       }
                       {
                           (osName == 'linux') &&
                               <div className='text-center bg-secondary rounded p-1'>
                                   <video className='rounded border border-3' src={_windows} width={"100%"} autoPlay loop controls></video>
                               </div>

                       }
                       {
                           (osName == 'android') &&
                               <div className='text-center bg-secondary rounded p-1'>
                                   <video className='rounded border border-3' src={_android} width={"100%"} autoPlay loop controls></video>
                               </div>

                       }
                       {
                           (osName == 'ios') &&
                               <div className='text-center bg-secondary rounded p-1'>
                                   <video className='rounded border border-3' src={_windows} width={"100%"} autoPlay loop controls></video>
                               </div>

                       }
                        <hr className='mb-0'/>

                        {
                            //Decive huawei detected
                            isHuawei &&
                            <ul className='list-group list-group-flush'>
                                <li className='list-group-item'>
                                    <h3 className='mb-0'>1.5- {t("welcome.huawei")}</h3>
                                    <h6>{t("welcome.huawei_message")}</h6>

                                    <div className='text-center bg-secondary rounded p-1'>
                                        <video className='rounded border border-3' src={_huawei} width={"100%"} autoPlay loop controls></video>
                                    </div>
                                </li>
                            </ul>
                        }


                        <hr className='mb-0'/>
                        <ul className='list-group list-group-flush'>
                            <li className='list-group-item'>
                                <h3 className='mb-3'>2- Test</h3>

                                {
                                    disbtn_test &&
                                    <div className='row w-100 justify-content-center'>
                                        <div className='w-50 text-center'>
                                            <div className='d-flex justify-content-center'>                                                
                                                    <p className='mb-0 text-primary h3 mx-2'>{t("welcome.wait")} { contador_test}</p>
                                            </div>
                                        </div>
                                    </div>
                                }

                                <button 
                                    disabled={
                                        (carga == true || disbtn_test == true)
                                        ? true
                                        : false
                                    } 
                                    onClick={() => getImpresoras()} 
                                    type='button' 
                                    className="btn btn-primary btn-lg text-uppercase w-100"
                                >
                                    <p className='mb-0'>{t("welcome.test")}</p>
                                    {/* <FontAwesomeIcon icon={faDownload} style={{marginRight: '1rem'}}/> */}
                                </button>
                            </li>
                        </ul>
                        
                    </div>
                    <div className="modal-footer">


                       {/* Sigue los pasos del video y finaliza con una prieba de impresion */}
                       {/* listar con numeros los pasos */}
                    </div>  
                </div>
            </div>
        </div>  

{/********************************************** PASO 3 *********************************************/}

        {/* <!-- Button trigger modal --> */}
        <button type="button" className="d-none" data-bs-toggle="modal" data-bs-target="#staticBackdrop3" ref={btn_paso3}>
            Launch static backdrop modal
        </button>

        {/* <!-- Modal --> */}
        <div className="modal border fade" style={{zIndex: 10000}} id="staticBackdrop3" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel3" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="staticBackdropLabel3">
                           {
                               t("welcome.select_printer")
                           }
                        </h5>
                        <button type="button" className="btn-close d-none" data-bs-dismiss="modal" aria-label="Close" ref={btn_paso3_cls}></button>
                    </div>
                    <div className="modal-body p-0">                        
                        <ol className="list-group list-group-flush list-group-numbered">
                            {
                            !recharge 
                                ?impresoras.length > 0
                                    ?impresoras.map((e,i) =>{
                                        return <li key={`imp-${i}`} onClick={() => test(e)} 
                                                    className="list-group-item list-group-item-action pointer d-flex justify-content-between align-items-start">
                                                    <div className="ms-2 me-auto">
                                                        <div className="fw-bold">{e.name}</div>
                                                            {e.uid}
                                                        </div>
                                                    <span className="badge bg-primary rounded-pill">
                                                        
                                                        {
                                                            e.connection === 'driver' &&
                                                                <i className="fas fa-laptop"></i>
                                                        }
                                                        {
                                                            e.connection === 'bluetooth' &&
                                                                <i className="fab fa-bluetooth-b"></i>
                                                        }
                                                        {
                                                            e.connection === 'network' &&
                                                                <i className="fas fa-wifi"></i>
                                                        }
                                                        {
                                                            e.connection === 'usb' &&
                                                                <i className="fab fa-usb"></i>
                                                        }
                                                        {
                                                            e.connection === 'ttb' &&
                                                                <i className="fas fa-ticket"></i>
                                                        }
                                                        
                                                    </span>
                                                </li>
                                        })
                                    :   <div className='p-5'>
                                            <div className="d-flex justify-content-center align-items-center">   
                                                <p>0 devices found :(</p>
                                            </div>
                                        </div>

                                :   <div className='p-5'>
                                        <div className="d-flex justify-content-center align-items-center">   
                                            <div className="spinner-grow text-primary" role="status" style={{width: "4rem", height: "4rem"}}>
                                                <span className="visually-hidden">Loading...</span>
                                            </div>
                                        </div>
                                    </div>
                                }
                        </ol>                                              
                    </div>
                    <div className="modal-footer d-flex justify-content-center align-items-center">
                        <div>
                            <button 
                                type='button' 
                                className="pointer fw-bold btn btn-outline-primary border border-primary rounded rounded-circle p-3"
                                onClick={() => rechargePrinters()}    
                            >
                                    <i className="fas fa-redo fa-lg"></i>
                            </button>
                        </div>
                    </div>

                </div>
            </div>
        </div>  

    </>
  )
}

export default ModalValidarT2b
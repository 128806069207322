import React, {useState, useEffect, useContext} from 'react'
import {
  NavLink, Link
} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faTrash, faHatWizard, faFile, faCog, faTable  } from '@fortawesome/free-solid-svg-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
/*Globales*/
import {urlApi} from '../../global/Global';
const CatalogosUsuario = (props) => {

    const MySwal = withReactContent(Swal);

    const [catalogos, setCatalogos] = useState([]);
    const [loading, setLoading] = useState(false);
    const [_loading, _setLoading] = useState(false);
    const [idUser, setidUser] = useState(props.match.params.id);
    const [user, setUser] = useState('');
/*************************************************************************************************************************************/
    

    useEffect(() => {
      
      const obtenerCatalogos = async (params) => {
          const log = await AsyncStorage.getItem('@login');
          const log_json = JSON.parse(log);
          const headers = {
              'Authorization': log_json.token_type+' '+log_json.access_token,
              'Content-Type': 'application/json',
              'X-Requested-With': 'XMLHttpRequest',
          }
          await axios.get(urlApi+'usuarios/catalogos/'+idUser, {headers: headers})
                    .then((response) => {
                        //Actualiza estados del formulario
                        setCatalogos(response.data.catalogos);
                        setUser(response.data.usuario);
                        setLoading(false);
                        console.log(response.data);
                    })
                    .catch((error) => {
                        console.log(error);
                    });     
      }

      obtenerCatalogos();
    
      
    }, [])
    
/*************************************************************************************************************************************/

    const eliminarCatalogo = async (params) => { 
        console.log(params);
        // return;
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }

        const send_data = {
            catalogo: params,
            usuario: user.id
        }
        Swal.fire({
            title: '¿Eliminar catalogo?',
            text: "Esta acción no se puede revertir",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, borrar',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await axios({
                    method: 'delete',
                    url: urlApi+'catalogo/destroy',
                    data: send_data,
                    headers: headers
                })
                    .then((response) => {
                        //Actualiza estados del formulario
                        if(response.data.respuesta === 'correcto'){
                            MySwal.fire({
                                icon: 'success',
                                title: 'Correcto',
                                text: 'Se elimino correctamente el catalogo',
                            });
                            setCatalogos(response.data.mensaje);                        
                        }else{
                            MySwal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: response.data.mensaje,
                            });
                        }
                        console.log(response.data);
                    })
                    .catch((error) => {
                        MySwal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: error,
                        });
                        console.log(error);
                }); 
            }
        });
    }
/***************************************************************************************************/

    const descargarCatalogo = async (params) => { 
        // console.log(params)    
        _setLoading(true);
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
        await axios({
            url: urlApi+'catalogo/descargar-catalogo/'+params.id,
            method: 'GET',
            // data: datos_enviar,
            headers: headers,
            responseType: 'blob', // important
        }).then(function (response) {
            console.log(response.data);
          
            const url       = window.URL.createObjectURL(new Blob([response.data]));
            // const url       = window.URL.createObjectURL(new Blob([response.data.archivo]));
            const link      = document.createElement('a');
                  link.href = url;
            link.setAttribute('download', params.nombre); //or any other extension
            // link.setAttribute('download', response.data.nombre); //or any other extension
            document.body.appendChild(link);
            link.click();
            console.log(response.data);

            MySwal.fire({
                icon: 'success',
                title: 'Etiqueta descargada',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            });
        })
        .catch(function (error) {
            MySwal.fire({
                icon: 'error',
                title: 'Ocurrio un error',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            });
            console.log(error);
        }).then(()=>{
            _setLoading(false);
        });
    }

/***************************************************************************************************/


  return (
    <div className="row justify-content-center animate__animated animate__fadeIn">
        <div className="col-md-12">                
            <div className="card shadow-lg">
                <div className="card-header bg-card-header">
                    <span className="fw-bold text-white">Catalogos</span>
                </div>

                <div className="card-body">
                    <div className="row">
                    {/* <div className="row text-end"> */}
                        <div className="col-12 align-items-center mb-3">
                            <h4>Catalogos del usuario: <b>{user.name}</b></h4>
                            <NavLink to="/importar" className={`d-none btn btn-warning btn-sm shadow res-w100 ${_loading && 'disabled'}`}> 
                                <span className='d-none' style={{fontFamily:'Arial, FontAwesome'}}>&#xf3aa; &nbsp;</span> 
                                <span className='fw-bold text-uppercase'>Importar catalogo</span>
                                {/* <span >Importar catalogo a Drive</span> */}
                            </NavLink>
                        </div>
                    </div>
                    {

                    !loading ?
                    <>
                    
                    {
                        catalogos.length > 0 ?
                        catalogos.map((e,i) =>{
                            return <div className="card border rounded shadow-sm mb-1">
                                <div className="card-body">
                                    <div className='row align-items-center'>

                                        <div className='col-md-10'>
                                            <div className='row align-items-center'>
                                                <div className='col-md-2 text-center position-relative'>                                                        
                                                    <FontAwesomeIcon 
                                                        icon={faFile} 
                                                        className="h-100 w-50-100 shadow"
                                                    />
                                                    <FontAwesomeIcon 
                                                        icon={faTable} 
                                                        className="h-100 top-0 position-absolute l-40-46"
                                                        color='white'
                                                    />
                                                </div>
                                                <div className='col-md-10 mt-2'>
                                                    <h4>
                                                        <strong># {i+1}</strong>
                                                    </h4>
                                                    <h5 className="card-title">
                                                        <b>Catalogo: </b>{e.nombre}
                                                    </h5>
                                                    <p className="card-text">
                                                        <b>Encabezado: </b>
                                                        {
                                                            e.encabezado == 0
                                                            ? <span className='m-0'>Sin encabezado</span>
                                                            : <span className='m-0'>Primer linea con encabezado</span>
                                                        }                                                        
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='col-md-2 mt-2'>
                                            <div className={`dropdown ${_loading && 'disabled'}`}>
                                                <a href="#" className="btn btn-secondary dropdown-toggle shadow w-auto" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <span className='d-none'>Opciones </span>
                                                    <FontAwesomeIcon icon={faCog} />
                                                </a>
                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                    <li>
                                                        <Link className="dropdown-item"
                                                            to={
                                                                {  
                                                                    pathname:"/catalogos/update",
                                                                    state:{catalogo: e}
                                                                }
                                                            }
                                                        >
                                                            Actualizar catalogo
                                                        </Link>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item" href="#" onClick={() => descargarCatalogo(e)}>
                                                            Descargar catalogo
                                                        </a>
                                                    </li>
                                                    <li className='d-none'>
                                                        <a className="dropdown-item" href="#">Capturar registros</a>
                                                    </li>
                                                    <li>
                                                        <hr className='dropdown-divider'/>                                                        
                                                    </li>
                                                    <li className='d-none'>
                                                        <Link className="dropdown-item"
                                                                    to={
                                                                        {  
                                                                            pathname:"/catalogos/headers",
                                                                            state:{catalogo: e}
                                                                        }
                                                                    }
                                                        >
                                                            <span>Headers </span> 
                                                            <FontAwesomeIcon icon={faHatWizard}/>
                                                        </Link>                                                            
                                                    </li>
                                                    
                                                    <li>
                                                        <a className="dropdown-item" href="#" onClick={()=> eliminarCatalogo(e)}>
                                                        <span>Eliminar </span> 
                                                        <FontAwesomeIcon icon={faTrash}/>
                                                        </a>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        })
                        :
                        <div className='row'>
                            <hr/>
                            <p className='text-center'>No hay catalogos para mostrar</p>
                        </div>
                    }
                    </>
                    :
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <div className="spinner-border text-primary" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>
                    }
                </div>

            </div>
        </div>
    </div>
  )
}

export default CatalogosUsuario
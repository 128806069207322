import React, {useEffect, useState} from 'react'
import axios from 'axios';
/*Globales*/
import {urlApi} from '../../global/Global';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useHistory } from "react-router-dom";
import Formulario from './Configuraciones/Formulario';
import Precios from './Configuraciones/Precios';
import Saltos from './Configuraciones/Saltos';
import Serializacion from './Configuraciones/Serializacion';
import { t } from 'i18next';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faCog } from '@fortawesome/free-solid-svg-icons';
import { Link } from "react-router-dom";
import Moneda from './Configuraciones/Moneda';


const ConfiguracionEtiqueta = (props) => {

    // const [usuarios, setusuarios] = useState([]);
    const [etiqueta, setEtiqueta]           = useState(null);
    const [idEtiqueta, setidEtiqueta]       = useState(props.match.params.id);
    const [carga, setCarga]                 = useState(false);
    const [variables, setVariables]         = useState([]);
    const [formulario, setFormulario]       = useState([]);
    const [caracteres, setcaracteres]       = useState([]);
    const [precio, setprecio]               = useState('');
    const [moneda, setmoneda]               = useState('');
    const [serializacion, setserializacion] = useState('');
    const [firstCarga, setfirstCarga]       = useState(false);
    const [catalogo, setCatalogo]           = useState(null);
    const [lan, setlan]                     = useState(0);
    let   history                           = useHistory();

    const [form_activo, setform_activo] = useState();

    useEffect(() => {
      
        setCarga(true);
        const first = async () => {
            const log = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            }
            await axios.get(urlApi+'etiquetas/variables/'+idEtiqueta, {headers: headers})
                        .then((response) => {
                            //Actualiza estados del formulario
                            console.log(response.data.etiqueta.language_id);
                            setlan(response.data.etiqueta.language_id);
                            setVariables(response.data.variables);
                        })
                        .catch((error) => {
                            console.log(error);
                        }).then(() => {
                            setCarga(false);
                        });
        }

        const second = async () => { 
         
            const log = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            }

            const send_data = {
                id: idEtiqueta
            }

            await axios.post(urlApi+'etiquetas/show', send_data, {headers: headers})
                        .then((response) => {
                            //Actualiza estados del formulario
                            console.log(response.data);
                            if(response.data.respuesta === 'correcto'){
                                // console.log(response.data.etiquetas.formulario);
                                // console.log(response.data.moneda.valor);
                                setEtiqueta(response.data.etiquetas);
                                setform_activo(response.data.etiquetas.formulario);
                                setFormulario(response.data.formulario);
                                setprecio(response.data.precio);
                                setcaracteres(response.data.caracteres);
                                setmoneda(response.data.moneda);
                                setserializacion(response.data.serializacion);
                                setCatalogo(response.data.catalogo);
                            }else if(response.data.respuesta === 'error'){
                                history.push("/see-labels");
                            }
                            // return;
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                        .then(() =>{
                            setfirstCarga(true);
                        });

        }

        second();
        first();
        
    }, [])

  return (
    <div className="row justify-content-center">
            <div className="col-md-12">
                <div className="card animate__animated animate__fadeIn">
                    <div className="card-header bg-card-header">
                        <div className="row align-items-center">
                            <div className="col-md-6 my-2">
                                <span className="fw-bold text-white">{t("config_label.config_label")}</span>                                
                            </div>
                            <div className='col-md-6 text-end'>
                                
                                <Link className="fw-bold btn btn-sm btn-light"
                                    to={
                                        {  
                                            pathname:"/edit-label/"+idEtiqueta,
                                            state:{etiqueta: idEtiqueta}
                                        }
                                    }
                                >   
                                <div className='d-flex align-items-center'>
                                    <FontAwesomeIcon icon={faCog}/>
                                    <span className='ms-1'>
                                        Ir a editar etiqueta
                                    </span>
    
                                </div>
                                </Link>
    
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                    {
                    carga ?
                        <div className="row">
                            <div className=" col-md-12 text-center">
                                <div className="spinner-grow text-info" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </div>
                        </div>
                    :
                    <>
                        <div className="row">
                            <div className="col-md-4">
                                <h4 className='fw-bold'>{t("config_label.variables")}</h4>
                                <ul>
                                    {
                                        variables.map((e,i) =>{
                                            return <li key={`variables-${i}`}>{e}</li>
                                        }) 
                                    } 
                                </ul>                            
                            </div>
                        </div>

                        <div className="border border-secondary mb-4"></div>    
                        {
                            etiqueta?.nueva != '1' &&                        
                                <div className="row">
                                    <div className="accordion " id="accordionExample">
                                        <div className="accordion-item bg-transparent">
                                            <h2 className="accordion-header" id="headingOne">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                                    <h4 className='fw-bold'>{t("config_label.form")}</h4>
                                                </button>
                                            </h2>

                                            <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                <div className="col-md-12 px-4 pb-4">
                                                    <Formulario
                                                        setFormulario={setFormulario}
                                                        formulario={formulario}
                                                        variables={variables}
                                                        idEtiqueta={idEtiqueta}
                                                        form_activo={form_activo}
                                                        setform_activo={setform_activo}
                                                        catalogo={catalogo}
                                                    />
                                                </div>
                                                {/* <div className='border border-secondary my-4'></div>  */}
                                            </div>
                                        </div>

                                        <div className="accordion-item bg-transparent">
                                            <h2 className="accordion-header" id="headingTwo">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="true" aria-controls="collapseTwo">
                                                    <h4 className='fw-bold'>{t("config_label.prices")}</h4>
                                                </button>
                                            </h2>

                                            <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                <div className=" col-md-12 px-4 pb-4">
                                                    <Precios
                                                        variables={variables}
                                                        idEtiqueta={idEtiqueta}
                                                        precio={precio}
                                                        setprecio={setprecio}
                                                    />
                                                    {/* <div className='border border-secondary my-4'></div>  */}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item bg-transparent">
                                            <h2 className="accordion-header" id="headingFour">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="true" aria-controls="collapseFour">
                                                    {/* <h4 className='fw-bold'>{t("config_label.prices")}</h4> */}
                                                    <h4 className='fw-bold'>Moneda</h4>
                                                </button>
                                            </h2>

                                            <div id="collapseFour" className="accordion-collapse collapse" aria-labelledby="headingFour" data-bs-parent="#accordionExample">
                                                <div className=" col-md-12 px-4 pb-4">
                                                    
                                                    <Moneda
                                                        variables={variables}
                                                        idEtiqueta={idEtiqueta}
                                                        moneda={moneda}
                                                        setmoneda={setmoneda}
                                                        lan={lan}
                                                    />
                                                    
                                                    {/* <div className='border border-secondary my-4'></div>  */}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="accordion-item bg-transparent">
                                            <h2 className="accordion-header" id="headingThree">
                                                <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="true" aria-controls="collapseThree">
                                                    <h4 className='fw-bold'>{t("config_label.line_break")}</h4>
                                                </button>
                                            </h2>
                                            <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                    
                                                <div className="col-md-12  px-4 pb-4">
                                                    <Saltos
                                                        setcaracteres={setcaracteres}
                                                        caracteres={caracteres}
                                                        variables={variables}
                                                        idEtiqueta={idEtiqueta}
                                                    />
                                                    {/* <div className='border border-secondary my-4'></div>  */}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-12 d-none">
                                            <Serializacion
                                                variables={variables}
                                                idEtiqueta={idEtiqueta}
                                                serializacion={serializacion}
                                                setserializacion={setserializacion}
                                                firstCarga={firstCarga}
                                            />
                                            {/* <div className='border border-secondary my-4'></div>  */}
                                        </div>
                                    </div>
                                </div>
                        }
                    </>}
                    </div>
                </div>
            </div>
        </div>
  )
}

export default ConfiguracionEtiqueta
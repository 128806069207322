import React, {useRef} from 'react'
import Dashboard from './Dashboard'
import UltimaImpresion from './UltimaImpresion'
import UltimaSesion from './UltimaSesion'
import UsuariosRegistrados from './UsuariosRegistrados'


const Reportes = () => {

    const btn_modal = useRef(null);
    const btn_close = useRef(null);

    return (
        <>
        
            {/* <!-- Button trigger modal --> */}
            <button ref={btn_modal} type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
            {/* Launch static backdrop modal */}
            </button>

            {/* <!-- Modal --> */}
            <div style={{zIndex: "10001"}} className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-sm">
                    <div className="modal-content border border-dark">
                        <div className="modal-header justify-content-center">

                            <h5 className="modal-title" id="staticBackdropLabel">
                                <div className="spinner-border text-secondary" role="status">
                                    <span className="visually-hidden">Loading...</span>
                                </div>
                            </h5>
                            {/* <button type="button" className="btn-close d-none" data-bs-dismiss="modal" aria-label="Close"></button> */}
                            <button ref={btn_close} type="button" className="btn-close d-none" data-bs-dismiss="modal" aria-label="Close"></button>
                            {/* <button ref={btn_close} type="button" className="btn btn-secondary d-none" data-bs-dismiss="modal">Close</button> */}
                        </div>
                    </div>
                </div>
            </div>



            <div className="card mb-3 animate__animated animate__fadeIn">
                <div className="card-header">
                    <h5 className='text-dark fw-bold text-uppercase rounded m-0'>Reportes</h5>

                </div>
                <div className="card-body bg-white">

                    <div className="row row-cols-1 row-cols-md-3 g-4 ">
                        
                        <UltimaImpresion
                            btn_modal={btn_modal}
                            btn_close={btn_close}
                        />

                        <UltimaSesion
                            btn_modal={btn_modal}
                            btn_close={btn_close}
                        />

                        <UsuariosRegistrados
                            btn_modal={btn_modal}
                            btn_close={btn_close}
                        />
                        

                    </div>
                    <div className='mt-3'>
                        <div className='row'>
                            <Dashboard/>

                        </div>

                    </div>
                </div>
            </div>

        
        </>
        
    )
}

export default Reportes
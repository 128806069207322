import React, {useEffect, useState, useRef, Fragment} from 'react'
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import * as Yup from 'yup';
import axios from 'axios';
/*Globales*/
import {urlApi} from '../../../global/Global';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useHistory } from "react-router-dom";
import ComprobarVista from '../../vistaUsuarioContext/ComprobarVista';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import sweets from './sweets';

const Serializacion = ({variables, idEtiqueta, serializacion, setserializacion, firstCarga}) => {

    const [loading, setloading]               = useState(false);
    const [carga, setcarga]                   = useState(false);
    const [inicioContador, setinicioContador] = useState(1);
    const [incrementador, setincrementador]   = useState(1);

/************************************************************************************* */

    useEffect(() => {
      
        if(firstCarga && serializacion != ''){
            
            const _serializacion = JSON.parse(serializacion.valor);
            console.log( JSON.parse(serializacion.valor) );
            setinicioContador(_serializacion[0]);
            setincrementador(_serializacion[1]);            

        }
    
    }, [firstCarga])
    

/************************************************************************************* */

    //Validador de datos
    const formSchema = Yup.object().shape({
      variable: Yup.string()
          .required('Required'),
      inicioContador: Yup.number()
          .required('Required'),
      incrementador: Yup.number()
          .required('Required'),
    });

/************************************************************************************* */

    const handleChangeSerializacion = (params) => {

      setserializacion({
        ...serializacion,
        'variable': params
      })

    }

/************************************************************************************* */

    const enviarSerializacion = async (params) => { 
        setloading(true);
        console.log(params);
        // return;
        const log      = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers  = {
            'Authorization'   : log_json.token_type+' '+log_json.access_token,
            'Content-Type'    : 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
        await axios.post(urlApi+'etiquetas/configuraciones/4/'+idEtiqueta, params, {headers: headers})
                    .then((response) => {
                        //Actualiza estados del formulario
                        console.log(response.data);
                    })
                    .catch((error) => {
                        console.log(error);
                    }).then(() => {
                        setloading(false);
                    });

    }

/************************************************************************************* */


  return (
    <>
        <div className='row'>
          <div className='col-md-6'>
            <div className='d-flex justify-content-between'>

                <div className="">
                    <h4 className='fw-bold'>Serialización</h4>

                </div>
                <div className=''>
                    <a className="btn btn-sm btn-outline-dark " data-bs-toggle="collapse" href="#collapseExample4" role="button" aria-expanded="false" aria-controls="collapseExample4">
                        <FontAwesomeIcon icon={faChevronDown}/>
                    </a>
                </div>
            </div>
          </div>
        </div>
        <Formik
            enableReinitialize
            initialValues={{
              variable      : 'SERIALIZE',
              inicioContador: inicioContador,
              incrementador : incrementador,
            }}
            validationSchema={formSchema}
            onSubmit={(values, actions) => enviarSerializacion(values, actions)}
        >
        {({ values, errors, touched, handleReset }) => {
                return (
                    <Form className='collapse' id="collapseExample4">
                      <div className='row'>

                        <div className='col-md-2 border-start border-end border-bottom pb-2 d-none'>
                          <label className="form-label fw-bold">Elegir variable</label>
                          <select
                              className='form-select form-select-lg'
                              disabled={loading}
                              name="variables"
                              onChange={(e) => handleChangeSerializacion(e.target.value)}
                              defaultValue={serializacion.variable}
                          >
                            {
                              variables.map((e,i) =>(
                                <option key={`var-${i}`}>{e}</option>
                              ))
                            }
                          </select>
                        </div>

                        <div className='col-md-2'>
                            <label className="form-label fw-bold">Variable</label>
                            <Field 
                                className='form-control form-control-lg' 
                                placeholder='variable' 
                                name={`variable`} 
                                // disabled={loading}
                                disabled
                                required
                            />
                            <ErrorMessage
                                name={`variable`}
                                component='div'
                                className='field-error text-danger'
                            /> 
                        </div>

                        <div className='col-md-2'>
                            <label className="form-label fw-bold">Inicio de contador</label>
                            <Field 
                                className='form-control form-control-lg' 
                                placeholder='inicioContador' 
                                name={`inicioContador`} 
                                disabled={loading}
                                required
                            />
                            <ErrorMessage
                                name={`inicioContador`}
                                component='div'
                                className='field-error text-danger'
                            /> 
                        </div>

                        <div className='col-md-2'>
                            <label className="form-label fw-bold">Incrementador</label>
                            <Field 
                                className='form-control form-control-lg' 
                                placeholder='incrementador' 
                                name={`incrementador`} 
                                disabled={loading}
                                required
                            />
                            <ErrorMessage
                                name={`incrementador`}
                                component='div'
                                className='field-error text-danger'
                            /> 
                        </div>

                      </div>

                      <div className='row mt-4'>
                        <div className='col-md-5'>
                          <button type='submit' className='btn btn-primary btn-lg fw-bold text-uppercase' disabled={loading}>
                              Guardar cambios serialización
                          </button>
                        </div>
                      </div>
                  </Form>
                ); 
            }}
        </Formik>      

    </>
  )
}

export default Serializacion
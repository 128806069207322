import React, {Fragment, useState} from 'react'
import axios from 'axios';
/*Globales*/
import {urlApi} from '../../../global/Global';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import sweets from './sweets';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { t } from 'i18next';

const Formulario = ({setFormulario, formulario, variables, idEtiqueta, form_activo, setform_activo, catalogo}) => {
  
  const [loading, setloading] = useState(false);

/************************************************************************************* */
  const handleChange = (i, e, n = false) => {
    const newFormValues = [...formulario];
    if (!n){
      newFormValues[i][e.target.name] = e.target.value;
      //Lo añade al valor
      newFormValues[i]['valor'] = e.target.value;
      setFormulario(newFormValues);
    }else{
      newFormValues[i]['type_config_id'] = e.target.value;
      setFormulario(newFormValues);
    }
  }

  const addFormFields = () => {
    setFormulario([...formulario, { variable: "", valor: "", type_config_id: "2" }])
  }

  const removeFormFields = (i) => {
    const newFormValues = [...formulario];
    newFormValues.splice(i, 1);
    setFormulario(newFormValues)
  }

  const handleSubmit = async (event) => {
      event.preventDefault();
      let cat = false;
      let ret = false;
      if(catalogo != null){
        await Swal.fire({
          title: 'Se desvinculara catalogo',
          text: "Al guardar los cambios deberá volver a vincular el catalogo a su etiqueta",
          icon: 'info',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Ok'
        }).then((result) => {
          if (result.isConfirmed) {
            cat = true;
          }else{
            ret = true;
          }
        })        
      }
      if(ret) return false;
      setloading(true);

      console.log(formulario);
      console.log(form_activo);

      const send_data ={
        formulario : formulario,
        form_activo: form_activo,
        catalogo   : cat
      }
      // return;
      const log      = await AsyncStorage.getItem('@login');
      const log_json = JSON.parse(log);
      const headers  = {
          'Authorization'   : log_json.token_type+' '+log_json.access_token,
          'Content-Type'    : 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
      }
      await axios.post(urlApi+'etiquetas/configuraciones/2/'+idEtiqueta, send_data, {headers: headers})
                  .then((response) => {
                      //Actualiza estados del formulario
                      console.log(response.data);
                      sweets._success(response);
                  })
                  .catch((error) => {
                      console.log(error);
                      sweets._success(error);
                  }).then(() => {
                      setloading(false);
                  });
  }

  const handleChangeVariable = (params, index) => { 
    console.group('INICIO');
    console.log('form: '+formulario);
    console.log('Index: '+index);
    console.log('params: '+params);
    console.log('params count: '+ formulario.length-1 );
    console.groupEnd('FIN');
        setFormulario(
          formulario.map((e,i) =>{
            console.log(e);
            return index === i 
            ? {...e, "variable": params, "valor": params}
            : e
          })
        )
      console.log(params);

  }

/************************************************************************************* */
  return (
    <>
        <div className='row'>
          <div className='col-md-6'>
            <div className='d-flex justify-content-between'>

                <div className=" d-none">
                    <h4 className='fw-bold'>{t("config_label.form")}</h4>

                </div>
                <div className='d-none'>
                    <a className="btn btn-sm btn-outline-dark " data-bs-toggle="collapse" href="#collapseExample" role="button" aria-expanded="false" aria-controls="collapseExample">
                        <FontAwesomeIcon icon={faChevronDown}/>
                    </a>
                </div>
            </div>
          </div>
        </div>
        {/* <form className='collapse' id="collapseExample" onSubmit={handleSubmit}> */}
        <form  onSubmit={handleSubmit}>
          <div className='row align-items-center py-3'>
            <label htmlFor="form-activo" className="col-md-2 col-form-label">{t("config_label.form")}</label>
            <div className='col-md-6'>
              <select 
                  className="form-select form-select-sm" 
                  aria-label=".form-select-sm example"
                  id="form-activo"
                  value={form_activo}
                  onChange={(e) => setform_activo(e.target.value)}
                  name="form_activo"
                >
                  <option value="0">{t("config_label.disabled")}</option>
                  <option value="1">{t("config_label.active")}</option>
              </select>
            </div>
              
          </div>

          {formulario.map((element, index) => (
            <Fragment  key={index}>
            <div className="row border-bottom py-3">
              

              <div className="col-md-2 border-end">
                <label className='form-label fw-bold'>&nbsp;</label>
                <select 
                    className='form-select form-select-sm'
                    name="variables" 
                    defaultValue={element.variable || '0000'}
                    onChange={e => handleChangeVariable(e.target.value, index)} 
                >
                    <option disabled value={'0000'}> --{t("config_label.support_variables")} --</option>
                  {
                    variables.map((_e,_i) =>{
                        return <option key={`vari-${_i}`} value={_e}>{_e}</option>
                    })
                  }
                </select>                            
              </div>

              <div className="col-md-4">
                <label className='form-label fw-bold'>Info Question</label>
                <input 
                    className='form-control form-control-sm'
                    type="text" 
                    name="valor" 
                    value={element.valor}
                    onChange={e => handleChange(index, e)}
                />                            
              </div>

              <div className="col-md-4">
                <label className='form-label fw-bold'>Variable</label>
                <div className='input-group '>
                    <input
                        className='form-control form-control-sm'
                        type="text" 
                        name="variable" 
                        value={element.variable} 
                        onChange={e => handleChange(index, e)}
                        required
                    />
                    {/* <button type="button"  className="btn btn-danger btn-sm remove" onClick={() => removeFormFields(index)}>-</button>  */}

                </div>
              </div>              
              <div className="col-md-2">
                <label className='form-label fw-bold'>Type</label>
                <div className='input-group '>
                <select 
                  className="form-select form-select-sm" 
                  aria-label="Default select example"
                  name="type_config_id"                  
                  onChange={e => handleChange(index, e, true)}
                  required
                  value={element.type_config_id}
                >
                    <option value="2">Texto</option>
                    <option value="4">Númerico</option>
                </select>
                    <button type="button"  className="btn btn-danger btn-sm remove" onClick={() => removeFormFields(index)}>-</button> 

                </div>
              </div>              
            </div>
            </Fragment>
          ))}
          <div className="d-flex justify-content-between">
              <button className="btn btn-success btn-sm add" type="button" onClick={() => addFormFields()}>Add +</button>
              <button className="btn btn-primary btn-sm submit" type="submit">{t("config_label.save_changes")}</button>
          </div>
      </form>
           
    </>
  )
}

export default Formulario
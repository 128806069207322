import React from 'react'
import { Field } from "formik";

const Checkbox = (props) => {
    // console.log(field);
    return (
        <Field name={props.name} checked={props.checked}>
            {({ field, form }) => (
                
                <label>
                <input
                    type="checkbox"
                    {...props}
                    checked={field.value.includes(props.value)}
                    onChange={() => {
                    if (field.value.includes(props.value)) {
                        const nextValue = field.value.filter(
                        value => value != props.value
                        );
                        form.setFieldValue(props.name, nextValue);
                    } else {
                        const nextValue = field.value.concat(props.value);
                        form.setFieldValue(props.name, nextValue);
                    }
                    }}
                />
                {/* {props.checked} */}
                </label>
            )}
        </Field>
    )
    
}

export default Checkbox

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';

/************************************************************************/
const sweets = {
    _success: async function(response){ // Tipo post

        const MySwal = withReactContent(Swal);
        // let x = '' ;

        MySwal.fire({
            icon: response.data.icon,
            title: response.data.message,
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 6000,
            timerProgressBar: true,
            didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        });
        // return false;
    
        
    },
/************************************************************************/
    _error: async function(error){ // Tipo post

        const MySwal = withReactContent(Swal);
        console.log(error);
        MySwal.fire({
            icon: 'error',
            title: 'error',
            text : error,
            // allowOutsideClick: false,
            
        }).then(result =>{
            if (result.isConfirmed){
                window.location.href = '/';
            }
        });
        
    },
}

export default sweets;
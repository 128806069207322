import React, {useEffect, useState} from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
/*Globales*/
import {url as urlno, urlApi} from '../global/Global'
import { useHistory } from "react-router-dom";

const Password = (props) => {

    const url = urlApi+'auth/find/'+props.match.params.token;
    /*Sweet alert*/
    const MySwal                = withReactContent(Swal);
    const [token, setToken]     = useState('');
    const [loading, setLoading] = useState(true);
    const [carga, setCarga]     = useState(false);
    //Redirect
    let history = useHistory();
/*******************************************************************************************************/

    useEffect(() => {
        
        const obtenerUrl = async () => {
            
            const headers = {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            }
            // return;
            await axios.get(url, {headers: headers})
                        .then(async (response) =>{
                            if(response.data.respuesta ==='correcto'){
                                setToken(response.data.message)
                            }
                                console.log(response.data);
                        }).catch((error) => {
                                console.log(error);
                        }).then(() => {
                            setLoading(false);
                        })
        }
            obtenerUrl();
    }, [])

/*******************************************************************************************************/

    const sendPassword = async (params) => {
        setCarga(true);
        params.email = token.email;
        params.token = token.token;
        console.log(params);

        const headers = {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
        // return;
        await axios.post(urlApi+'auth/reset', params, {headers: headers})
                    .then(async (response) =>{
                        if(response.data.respuesta ==='correcto'){
                            MySwal.fire({
                                icon: 'success',
                                title: 'Contraseña recuperada',
                                text: 'La contraseña cambio exitosamente',
                            });
                            history.push('/');
                        }else{
                            MySwal.fire({
                                icon: 'error',
                                title: 'Ocurrio un error',
                                text: response.data.message,
                            });
                        }
                            console.log(response.data);
                    }).catch((error) => {
                            console.log(error);
                            MySwal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: 'Ocurrio un error vuelva a intentarlo',
                            });
                    }).then(() => {
                        setCarga(false);
                    })
    }
    
/*******************************************************************************************************/

    //Validador de datos
    const formSchema = Yup.object().shape({
        password: Yup.string()
            .min(8)
            .trim()
            .required('Campo requerido'),
        password_confirmation: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Las contraseñas deben ser iguales')
            .required('Campo requerido'),
        });

/*******************************************************************************************************/
    return (
        <div className="row justify-content-center pdr">
            <div className="col-md-10">
                <div className="card">
                    <div className="card-header">
                        <span className="fw-bold">Recuperar contraseña</span>
                    </div>
                    <div className="card-body">                 
                        {
                            loading ?
                                <div className="text-center">
                                    <div className="spinner-grow text-dark" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            :
                                token === '' ?
                                    <div className="text-center">
                                        <h2>Token invalido o inexistente</h2>
                                    </div>
                                :
                                <Formik
                                        initialValues={{
                                            password: '',
                                            password_confirmation: '',
                                        }}
                                        validationSchema={formSchema}
                                        onSubmit={(values, actions) => sendPassword(values, actions)}
                                >
                                    <Form>
                                        <div className="mb-3">
                                            <label htmlFor="floatingPassword" className="" >Contraseña</label>
                                            <Field type="password" className="form-control" id="floatingPassword" placeholder="Contraseña" name="password"/>
                                            <ErrorMessage
                                                name='password'
                                                component='div'
                                                className='field-error text-danger'
                                            />
                                        </div>

                                        <div className="mb-3">
                                            <label htmlFor="floatingPassword2" className="" >Confirmar Contraseña</label>
                                            <Field type="password" className="form-control" id="floatingPassword2" placeholder="Confirmar contraseña" name="password_confirmation"/>
                                            <ErrorMessage
                                                name='password_confirmation'
                                                component='div'
                                                className='field-error text-danger'
                                            />
                                        </div>
                                        
                                        <button className="w-100 btn btn-lg btn-primary" type="submit" disabled={carga}>
                                            <span style={{marginRight: '5px'}}> Recuperar contraseña </span>
                                            {
                                                carga &&
                                                <>
                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    <span className="visually-hidden">Loading...</span>
                                                </>

                                            }
                                        </button>
                                        <p className="mt-5 mb-3 text-muted">© 2017–2021</p>
                                    </Form>
                                </Formik>
                        }
                    </div>                        
                </div>
            </div>
        </div>
    )
}

export default Password

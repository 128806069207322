
export default (state,action) => {
    switch (action.type) {
        case 'SELECCIONAR_USER':
            return {
                ...state,
                user: action.payload
            }
        // case 'SELECCIONAR_VISTA':
        //     return {
        //         ...state,
        //         vista: action.payload
        //     }

        default:
            return state;
    }
}
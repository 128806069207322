import React, {useEffect, useState} from 'react';
import web2barcode from '../../img/Logo.png';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import useWindowDimensions from './windowsDimensions';
import {
    Link
} from "react-router-dom";
const HeaderHome = () => {

    const { height, width }   = useWindowDimensions();
    const { t, i18n }         = useTranslation();
    const [drops, setdrops]   = useState(false);
    const [cuenta, setCuenta] = useState(0);
    useEffect(() => {

        console.log(height + ' ' + width)
        if(width >= 992){
            setdrops(true);
        }else{
            setdrops(false);
        }
    }, [width])
    

  return (
    <nav className="navbar navbar-expand-lg navbar-light fixed-top px-5 border-bottom border-secondary" style={{backgroundColor: '#44ab53'}}>
        <div className="container-fluid">
            <Link className="navbar-brand" to="/" onClick={() => setCuenta(0)}>
                <img src={web2barcode} className='img-fluid border rounded' width={80}/>
            </Link>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavAltMarkup" aria-controls="navbarNavAltMarkup" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse align-items-center" id="navbarNavAltMarkup">
                <div className="navbar-nav me-auto mb-2 mb-lg-0">

                </div>

                {/* <div> */}
                    <div className="dropdown "  style={{marginRight: '3rem'}}>
                        <Link className="link-dark me-3" to="/design" target="_blank" role="button">
                            Cotiza tu diseño
                        </Link>
                        <a className="link-dark dropdown-toggle" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                            Language
                        </a>

                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                            <li>
                                <a
                                    onClick={()=> i18n.changeLanguage('en')} 
                                    className="dropdown-item link-primary" href="#">
                                        en-US
                                </a>
                            </li>
                            <li>
                                <a
                                    onClick={()=> i18n.changeLanguage('es')} 
                                    className="dropdown-item link-primary" href="#">
                                        es-MX
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div style={{marginRight: '5rem'}} className={`${drops ? "mt-0" : "mt-3"}`}>
                        <button 
                            onClick={() => setCuenta(1)}
                            className='btn btn-primary fw-bold text-uppercase border'>
                            { t("main.login") }                             
                        </button>
                    </div>
                {/* </div> */}

            </div>
        </div>
    </nav>
  )
}

export default HeaderHome
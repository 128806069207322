import React, {useEffect, useState, useRef} from 'react';
import axios from 'axios';
/*Globales*/
import {urlApi} from '../../../global/Global';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faDownload, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import ComprobarVista from '../../vistaUsuarioContext/ComprobarVista';
import Swal from 'sweetalert2';
import { unauthenticated } from '../../../helpers/Helper';
import { useTranslation } from 'react-i18next';
import { Formik, Field, Form, ErrorMessage  } from 'formik';
import * as Yup from 'yup';
import Checkbox from '../../layouts/Checkbox';
import funciones from '../../utilidades/funciones';

const Paso2 = ({setpasos}) => {

    const [url, seturl]           = useState('');
    const [loading, setLoading]   = useState(false);
    const { t }                   = useTranslation();
    const [database, setdatabase] = useState([]);

    const [ftps, setftps]         = useState([]);

    const [filtros, setfiltros] = useState({});
    const btn_filtros           = useRef(null);
    const btn_filtros_cls       = useRef(null);
    const btn_filtrar           = useRef(null);

    const [clickOnFtps, setclickOnFtps]   = useState(false);
    const [removeClass, setRemoveClass]   = useState(false);
    const [removeClass2, setRemoveClass2] = useState(false);
    const [removeClass3, setRemoveClass3] = useState(false);
    
    const [basic, setbasic] = useState([1])
    const sendFilters       = useRef(null);

    useEffect(() => {          
        const getFtp = async (params) => {
            setLoading(true);
            const log = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            }
            await axios.get(urlApi+'etiquetas/connect-ftp', {headers: headers})
                        .then((response) => {
                            //Actualiza estados del formulario
                            // setLoading(false);
                            setftps(response.data.etiquetas);
                            seturl(response.data.url);
                            console.log(response.data);
                        })
                        .catch((error) => {
                            unauthenticated(error);
                            console.log(error);
                        }).then(()=>{
                            setLoading(false);
                        });    
        }

        const getFiltros = async() => {

            const log = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            }
            await axios.get(urlApi+'etiquetas/filtros', {headers: headers})
                        .then((response) => {
                            //Actualiza estados del formulario
                            // setLoading(false);
                            setfiltros(response.data);       
                            setdatabase([{"id": 1, "name": "Con base de datos"}, {"id": 0, "name": "Sin base de datos"}]);                                    
                            console.log(response.data);
                        })
                        .catch((error) => {
                            console.log(error);
                        });        

        }
        
        getFtp();
        getFiltros();

    }, []); // Use effect
    
//Descargar etiqueta a usuario----------------------------------------------------------------
    const handleClick = async (e) => { 
        setLoading(true);
        setRemoveClass2(true);
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }

        const send_data = {
            etiqueta: e.id
        }

        await axios.post(urlApi+'etiquetas/download', send_data,{headers: headers})
                    .then((response) => {
                        //Descarga la etiqueta para el usuario   

                        Swal.fire({
                            icon     : 'info',
                            title    : t('welcome.redirect'),
                            text     : t('welcome.redirect_download'),
                            showClass: {
                                popup: 'animate__animated animate__fadeInDown'
                            },
                            hideClass: {
                                popup: 'animate__animated animate__fadeOutUp'
                            },
                            showConfirmButton: true,
                            allowOutsideClick: false,
                            // timer            : 3000,
                            // timerProgressBar : true,
                        }).then(res =>{
                            if(res.isConfirmed){
                                if(response.data.response){
                                    setpasos(3);
                                    // setLoading(false);
                                }else{
                                    setpasos(1);
                                }
                            }
                        });
                        // setftps(response.data.etiquetas);
                        console.log(response.data);
                    })
                    .catch((error) => {
                        Swal.fire({
                            icon: 'error',
                            title: error,
                            showClass: {
                                popup: 'animate__animated animate__fadeInDown'
                            },
                            hideClass: {
                                popup: 'animate__animated animate__fadeOutUp'
                            },
                            showConfirmButton: false,
                            allowOutsideClick: false,
                            timer: 3000,
                            timerProgressBar: true,
                        });
                        console.log(error);
                    }).then(() =>{
                        window.scrollTo(0, 0);
                    });   
    }
//---------------------------------------------------------------------------------------------

const getFtpFilters = async (params) => {
    setLoading(true);
    setRemoveClass3(false);
    console.log(params);
    btn_filtros.current.click();
    // setRemoveClass2(true);
    setclickOnFtps(true);
    // return;

    const log = await AsyncStorage.getItem('@login');
    const log_json = JSON.parse(log);
    const headers = {
        'Authorization': log_json.token_type+' '+log_json.access_token,
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    }
    await axios.post(urlApi+'etiquetas/connect-ftp-filters', params, {headers: headers})
                .then((response) => {
                    //Actualiza estados del formulario
                    console.log(response.data);
                    setftps(response.data.etiquetas);                       
                    // setLoading(false);
                    return;
                })
                .catch((error) => {
                    console.log(error);
                }).then(() =>{
                    setLoading(false);
                    window.scrollTo(0, 0);
                });        

}

//---------------------------------------------------------------------------------------------

    const handleBtnFiltros = async () => {

        setRemoveClass(true);
        // btn_filtros_cls.current.click();
        // btn_filtros.current.classList.remove('elemento-resaltante-filtro-btn');
        // btn_filtros.current.classList.add('disabled');
        setRemoveClass2(true);
            await Swal.fire({
                icon     : 'info',
                title    : t("welcome.filters"),
                text     : t("welcome.filters_text"),
                showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                },
                showConfirmButton: true,
                allowOutsideClick: false,
            }).then(res =>{
                if(res.isConfirmed)
                {
                    setRemoveClass3(true);
                    setRemoveClass2(false);
                }
            });

        // btn_filtros.current.

    }
//---------------------------------------------------------------------------------------------

    const resetearForm = (resetForm) => { 
        resetForm();
        setbasic([]);        
        sendFilters.current.click();
    }

//---------------------------------------------------------------------------------------------


 //Validador de datos
 const formSchema = Yup.object().shape({
    material: Yup.array(),
    medidas : Yup.array(),
    tags    : Yup.array(),
    database: Yup.array(),
    lenguaje: Yup.array(),
    basica  : Yup.array(),
});

//---------------------------------------------------------------------------------------------

  return (
    <>
        <div className=' loader-cotizador'></div>

         <div className="card mb-3">
            <div className="card-header">
                <h5 className='text-dark fw-bold text-uppercase rounded m-0'>{t("catalog.templates")}</h5>
            </div>

            <div className={`${!removeClass2 ? "card-body bg-white elemento-resaltante-filtro" : "card-body bg-white"}`}>
                <div className='d-flex align-items-center mb-3'>
                    <h5 className="card-title text-success fw-bold mb-0">{t("catalog.filters")}</h5>
                    {
                        !removeClass
                        ?  <button 
                            ref={ btn_filtros }
                            onClick={() => handleBtnFiltros()}
                            // className="btn btn-sm btn-success elemento-resaltante-filtro-btn" 
                            className="btn btn-sm btn-success _elemento-resaltante-filtro-btn" 
                            style={{marginLeft: '1rem'}}
                            type="button" 
                            data-bs-toggle="collapse" 
                            data-bs-target="#collapseExample" 
                            aria-expanded="false" 
                            aria-controls="collapseExample"
                        >
                            <i className="fas fa-chevron-down "></i>                
                        </button>
                        :
                        <button 
                            ref={ btn_filtros }
                            // className="btn btn-sm btn-success elemento-resaltante-filtro-btn" 
                            className="btn btn-sm btn-success"
                            style={{marginLeft: '1rem'}}
                            type="button" 
                            data-bs-toggle="collapse" 
                            data-bs-target="#collapseExample" 
                            aria-expanded="false" 
                            aria-controls="collapseExample"
                            // disabled
                        >
                            <i className="fas fa-chevron-down "></i>                
                        </button>
                    }

                </div>

                <Formik
                    enableReinitialize
                    initialValues={{
                        "material": [],
                        "medidas" : [],
                        "tags"    : [],
                        "database": [],
                        "lenguaje": [],
                        "basica"  : basic,
                    }}
                    validationSchema={formSchema}
                    onSubmit={(values, actions) => getFtpFilters(values, actions)}
                    render={({ values, errors, touched, handleReset, resetForm }) => {
                    return (
                        <Form
                            className="collapse"
                            id="collapseExample"
                            onChange={() => sendFilters.current.click()}
                        >
                            <div className="row border-bottom pb-3">
                                <div className="col-md-4">
                                    <div>                                    
                                        <label htmlFor="material" className="form-label fw-bold">Material</label>
                                        {
                                            Object.keys(filtros).length > 0 &&
                                            filtros.materiales.map((element,index) => {
                                                return <div  key={"a"+index}>
                                                        <div className="form-check">
                                                            <Checkbox 
                                                                name="material" 
                                                                className="form-check-input" 
                                                                value={element.id} 
                                                                id={"material"+index} 
                                                            />
                                                            <label className="form-check-label" htmlFor={"material"+index}>
                                                                {element.name}
                                                            </label>
                                                        </div>
                                                    </div>
                                            })
                                        }
                                        <ErrorMessage
                                            name='material'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>

                                    <div className='mt-3 pt-2 border-top'>
                                        <label htmlFor="database" className="form-label fw-bold">Base de datos</label>
                                        {
                                            database.length > 0 &&
                                            database.map((element,index) => {
                                                return <div key={"e"+index}>
                                                        <div className="form-check">
                                                            <Checkbox 
                                                                disabled
                                                                name="database" 
                                                                className="form-check-input" 
                                                                value={element.id} 
                                                                id={"database"+index} 
                                                            />
                                                            <label className="form-check-label" htmlFor={"database"+index}>
                                                                {element.name}
                                                            </label>
                                                        </div>
                                                    </div>
                                            })
                                        }
                                        <ErrorMessage
                                            name='database'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>
                                </div>

                                <div className="col-md-4 border-change">
                                    <label htmlFor="medidas" className="form-label fw-bold">Medidas</label>
                                    <div className='row'>                                        
                                        
                                        {
                                            Object.keys(filtros).length > 0 &&
                                            filtros.medidas.map((element,index) => {
                                                return <div className='col-md-4' key={"b"+index}>
                                                        <div className="form-check d-flex align-items-center">
                                                            <Checkbox 
                                                                name="medidas" 
                                                                className="form-check-input" 
                                                                value={element.id} 
                                                                id={"medidas"+index} 
                                                            />
                                                            <label className="form-check-label" htmlFor={"medidas"+index}>
                                                                {element.name} {element.medida} <b>({funciones.inToMm(element)} mm)</b>
                                                            </label>
                                                        </div>
                                                    </div>
                                            })
                                        }
                                        
                                        <ErrorMessage
                                            name='medidas'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>
                                </div>

                                <div className="col-md-4">

                                    <div>                                    
                                        <label htmlFor="tags" className="form-label fw-bold">Proposito</label>
                                        <div className='row'>                                        
                                            <div className='col-md-6'>
                                                <div className="form-check d-flex align-items-center">
                                                    <Checkbox 
                                                        name="basica" 
                                                        className="form-check-input" 
                                                        value={1} 
                                                        id="basica" 
                                                    />
                                                    <label className="form-check-label" htmlFor="basica">
                                                        Básica
                                                    </label>
                                                </div>
                                            </div>
                                            {
                                                Object.keys(filtros).length > 0 &&
                                                filtros.tags.map((element,index) => {
                                                    return <div className='col-md-6'  key={"b"+index}>
                                                            <div className="form-check d-flex align-items-center">
                                                                <Checkbox 
                                                                    name="tags" 
                                                                    className="form-check-input" 
                                                                    value={element.id} 
                                                                    id={"tags"+index} 
                                                                />
                                                                <label className="form-check-label" htmlFor={"tags"+index}>
                                                                    {element.name}
                                                                </label>
                                                            </div>
                                                        </div>
                                                })
                                            }
                                            
                                            <ErrorMessage
                                                name='tags'
                                                component='div'
                                                className='field-error text-danger'
                                            />
                                        </div>
                                    </div>

                                    <div className='mt-3 pt-2 border-top'>                                    
                                        <label htmlFor="lenguaje" className="form-label fw-bold">Lenguaje</label>
                                        <div className='row'>                                                                                
                                            {
                                                Object.keys(filtros).length > 0 &&
                                                filtros.lenguajes.map((element,index) => {
                                                    return <div className='col-md-5 '  key={"d"+index}>
                                                            <div className="form-check d-flex align-items-center">
                                                                <Checkbox 
                                                                    name="lenguaje" 
                                                                    className="form-check-input " 
                                                                    value={element.id} 
                                                                    id={"lenguaje"+index} 
                                                                />
                                                                <label className="form-check-label wrap-text" htmlFor={"lenguaje"+index}>
                                                                    {element.name}
                                                                </label>
                                                            </div>
                                                        </div>
                                                })
                                            }
                                            
                                            <ErrorMessage
                                                name='lenguaje'
                                                component='div'
                                                className='field-error text-danger'
                                            />
                                        </div>
                                    </div>

                                </div>
                                
                            </div>
                            <div className='row mt-3'>
                                    <div className='col-md-4'>
                                        {
                                        // removeClass3 
                                        //     ?   <button
                                        //             type='submit'
                                        //             className="btn btn-success fw-bold w-100 _elemento-filtrar-btn d-none"

                                        //             >
                                        //             {t("catalog.filter")}
                                        //         </button>

                                        //     :   <button
                                        //             type='submit'
                                        //             className="btn btn-success fw-bold w-100 d-none"
                                        //             ref={sendFilters}
                                        //         >
                                        //             {t("catalog.filter")}
                                        //         </button>
                                        <>
                                        
                                            <button
                                                type='button'
                                                className="btn btn-success fw-bold w-100 mb-3"
                                                onClick={() =>{
                                                    btn_filtros.current.click();
                                                    window.scrollTo(0,0);
                                                }}
                                            >
                                                <i className="fas fa-chevron-up"></i>                
                                            </button>
                                            <button
                                                type='submit'
                                                className="btn btn-success fw-bold w-100 d-none"
                                                ref={sendFilters}
                                            >
                                                {t("catalog.filter")}
                                            </button>
                                        </>
                                        }
                                    </div>
                                    {

                                    removeClass3
                                    ?   <div className='col-md-4'>
                                            <button
                                                type='button'
                                                className='btn btn-outline-success btn-sm fw-bold w-100 _elemento-filtrar-btn'
                                                onClick={() => resetearForm(resetForm)}
                                            >
                                                {t("catalog.clean")}
                                            </button>
                                        </div>
                                    :   <div className='col-md-4'>
                                            <button
                                                type='button'
                                                className='btn btn-outline-success btn-sm fw-bold w-100'
                                                onClick={() => resetearForm(resetForm)}
                                            >
                                                {t("catalog.clean")}
                                            </button>
                                    </div>

                                    }
                            </div>
                        </Form>
                    )}}
                />
                
            </div>
        </div>
        <div className="row row-cols-1 row-cols-md-3">                        
            {
            ftps.length > 0 ?
                ftps.map((e,i) =>{
                    return e.catalogo_id == null &&
                    <div 
                        key={"ftps"+i}
                        className={`${(!loading && clickOnFtps) ? "col elemento-resaltante2 card-padding": "col card-padding"}`} style={{marginBottom: '7rem'}}>
                        <div className="card h-100 shadow rounded">
                            <div className='p-1 shadow-sm '>
                                <img src={url+'/'+e.image} className="card-img-top p-2 border-bottom" alt={e.nombre} />
                            </div>
                            <div className="card-body">
                                <h5 className="card-title fw-bold">{e.nombre}</h5>
                                <p className="card-text mb-0">Lenguaje: {e.language.name}</p>
                                <p className="card-text mb-0">Desc: {e.descripcion}</p>
                                {
                                    e.medida!= null &&
                                    <p className="card-text mb-0">Size: {e.medida.name} {e.medida.medida}</p>
                                }
                                {
                                    e.material!= null &&
                                    <p className="card-text mb-0">Material: {e.material.name}</p>
                                }
                            </div>
                            <div className='p-3'>
                                <div className='row'>
                                

                                    <button 
                                        disabled={loading}
                                        onClick={() => handleClick(e)}
                                        className='btn btn-primary btn-sm fw-bold text-uppercase border shadow'
                                    >
                                        <span>{t("welcome.btn_download")}</span> 
                                        <FontAwesomeIcon icon={faDownload} style={{marginLeft: '5px'}}/>
                                        {
                                            loading &&
                                            <span className="spinner-border spinner-border-sm" style={{marginLeft: '.5rem'}} role="status" aria-hidden="true"></span>
                                        }
                                    </button>

                                
                                </div>
                            </div>
                        </div>
                    </div>                                    
                })
                :
                loading 
                    ? <div className="col-md-12 text-center">
                        <div className="spinner-border text-primary" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                    : <p>Not found result</p>
            }            
        </div>
    </>
  )
}

export default Paso2
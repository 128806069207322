import React, {useEffect, useState} from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import {urlApi} from '../../global/Global';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useHistory } from "react-router-dom";

const EditUsuario = (props) => {

    const [usuario, setUsuario] = useState('');
    let   history                         = useHistory();
    /*Sweet alert*/
    const MySwal = withReactContent(Swal);
    const [idUsuario, setidUsuario] = useState(props.match.params.id);
    //
    const [cuenta, setCuenta] = useState(false);
    const [carga, setCarga]   = useState(false);
    const [roles, setroles]   = useState([]);
    const [intialValues, setintialValues] = useState({});
/******************************************************************************************************/

    useEffect(() => {
        // console.log(usuario);
        
        const getUsuario = async () => { 
    
            if(props.location.state == undefined){
    
                const log = await AsyncStorage.getItem('@login');
                const log_json = JSON.parse(log);
                const headers = {
                    'Authorization': log_json.token_type+' '+log_json.access_token,
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                }
                // const send_data = {
                //     id: idUsuario
                // }
                await axios.get(urlApi+'usuarios/'+idUsuario, {headers: headers})
                            .then((response) => {
                                //Actualiza estados del formulario
                                console.log(response.data);
                                if(response.data.respuesta === 'correcto'){
                                    setUsuario(response.data.usuarios);
                                    setintialValues({
                                        email          : response.data.usuarios.email,
                                        name           : response.data.usuarios.name,
                                        password       : '',
                                        passwordConfirm: '',
                                        role           : response.data.usuarios.roles[0].id
                                    });
                                }else if(response.data.respuesta === 'error'){
                                    history.push("/usuarios");
                                    // alert('asd')
                                }
                                // return;
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                
            }else{
                setUsuario(props.location.state.usuario);
                setintialValues({
                    email          : props.location.state.usuario.email,
                    name           : props.location.state.usuario.name,
                    password       : '',
                    passwordConfirm: '',
                    role           : props.location.state.usuario.roles[0].id
                });
            }        


        }

        const getRoles = async () => { 
            const log      = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers  = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            }
            await axios.get(urlApi+'usuarios/roles', {headers: headers})
                        .then((response) => {
                            //Actualiza estados del formulario
                            setroles(response.data);
                        })
                        .catch((error) => {
                            console.log(error);
                        })
                        .then(() =>{
                            setCarga(false);
                        });
        }

        getRoles();
        getUsuario();

    }, []);

/******************************************************************************************************/

    //Validador de datos
  const formSchema = Yup.object().shape({
    email: Yup.string()
        .trim()
        .email()
        .required('Campo requerido'),
    name: Yup.string()
        .trim()
        .required('Campo requerido'),
    role: Yup.number()
        .typeError('Campo númerico requerido')
        .min(1, 'Min value 1.')
        .max(30, 'Max value 30.')
        .required('Campo requerido'),
    password: Yup.string()
        .min(8)
        .trim(),
    passwordConfirm: Yup.string()
        .oneOf([Yup.ref('password'), null], 'Las contraseñas deben ser iguales')
    });

/******************************************************************************************************/
    const actualizarUsuario = async (params, actions) => {
        console.log(params);
        
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
        console.log(headers);
        await axios.put(urlApi+'usuarios/'+usuario.id, params, {headers: headers})
        .then((response) => {
            //Actualiza estados del formulario
            if(response.data.respuesta ==='correcto'){
                MySwal.fire({
                    icon : 'success',
                    title: 'Correcto',
                    text : response.data.message,
                });
                setCuenta(false);
            }
            if(response.data.respuesta ==='error'){
                let texto = '<ul>';
                Object.keys(response.data.errors).map((e,i) =>{
                    if(e === 'email'){
                        texto += '<li>Email invalido o duplicado</li>';
                    }
                    if(e === 'password'){
                        texto += '<li>Contraseña invalida o menor a 8 caracteres</li>';
                    }
                    texto += '</ul>';
                });
                MySwal.fire({
                    icon: 'error',
                    title: 'Error!',
                    html: texto,
                });
            }
            console.log(response.data);
        })
        .catch((error) => {
                MySwal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: 'Ocurrio un error en la acción',
                  })
            console.log(error);
        })
        .then(() =>{
            setCarga(false);
        });
    }

/******************************************************************************************************/
    return (
        <div className="row justify-content-center animate__animated animate__fadeIn">
                <div className="col-md-6">
                    <div className="card">
                        <div className="card-header bg-card-header">
                            <span className="fw-bold text-white">Crear cuenta</span>
                        </div>
                        <div className="card-body">
                            <Formik
                                    enableReinitialize
                                    initialValues={intialValues}
                                    validationSchema={formSchema}
                                    onSubmit={(values, actions) => actualizarUsuario(values, actions)}
                            >
                                <Form>
                                    <h1 className="h3 mb-3 fw-normal">Actualizar datos</h1>

                                    <div className="form-floating mb-3">
                                        <Field type="email" className="form-control" id="floatingInput" placeholder="name@example.com" name="email"/>
                                        <label htmlFor="floatingInput">Email</label>
                                        <ErrorMessage
                                            name='email'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>

                                    <div className="form-floating mb-3">
                                        <Field type="text" className="form-control" id="floatingInput2" placeholder="namexample" name="name"/>
                                        <label htmlFor="floatingInput2">Name</label>
                                        <ErrorMessage
                                            name='name'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>

                                    <div className="form-floating mb-3">
                                        <Field type="password" className="form-control" id="floatingPassword" placeholder="Password" name="password"/>
                                        <label htmlFor="floatingPassword">Contraseña</label>
                                        <ErrorMessage
                                            name='password'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>

                                    <div className="form-floating mb-3">
                                        <Field type="password" className="form-control" id="floatingPassword2" placeholder="Password" name="passwordConfirm"/>
                                        <label htmlFor="floatingPassword2">Confirmar Contraseña</label>
                                        <ErrorMessage
                                            name='passwordConfirm'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>

                                    
                                    <div className="mb-3">
                                        <label htmlFor="role" className="form-label fw-bold">Rol</label>
                                        <Field 
                                            as="select" 
                                            className="form-select select-custom" 
                                            name='role' 
                                            disabled={carga}
                                        >

                                        {
                                            roles!=undefined &&
                                            roles.map((element,index) => {
                                                    return <option
                                                                value={element.id} 
                                                                key={index}
                                                                >{element.name}
                                                            </option>
                                            })
                                        }

                                        </Field>
                                        
                                        <ErrorMessage
                                            name='language'
                                            component='div'
                                            className='field-error text-danger'
                                        />
                                    </div>

                                    <button className="w-100 btn btn-lg btn-dark " type="submit">Guardar datos</button>
                                    <p className="mt-5 mb-3 text-muted">© 2022</p>
                                </Form>     
                            </Formik>        
                        </div>                        
                    </div>
                </div>
            </div>
    )
}

export default EditUsuario

import React, {useEffect, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faFire, faRocket, faLeaf } from '@fortawesome/free-solid-svg-icons'
import Swal from 'sweetalert2'
import { useTranslation } from 'react-i18next';
import bridgegap from '../img/bridgegap.png'
import HeaderHome from './layouts/HeaderHome';
import useWindowDimensions from './layouts/windowsDimensions';
import styled from 'styled-components';
import ayuda from '../img/ayuda.png';
const Main = ({setCuenta}) => {

    const { t }             = useTranslation();
    const { height, width } = useWindowDimensions();
    const [drops, setdrops] = useState(false);
/******************************************************************************************* */

    useEffect(() => {

        if(width >= 768){
            setdrops(true);
        }else{
            setdrops(false);
        }
    }, [width])
    

/******************************************************************************************* */
    useEffect(() => {
        
        // if(document.cookie.indexOf('no_mostrar_name2=')==-1){
        //     //Alerta de sistema actualmente en mantenimiento COMENTAR SI YA NO HAY MANTENIMIENTO
        //     Swal.fire({
        //     html: ` 
        //             <div class="text-center">
        //                 <i class="far fa-hand-peace h2 gif_lado_lado"></i>
        //                 <i class="fas fa-grin-beam h1 animate__animated animate__backInDown like"></i>
        //             </div>
        //             <h2 class="border-bottom fw-bold pb-4 mb-4 animate__animated animate__zoomIn animate__delay-1s">
        //                 <span>${t("main.welcome")}<span>
        //             </h2>
        //     <p class="h4 animate__animated animate__zoomIn animate__delay-1s">${t("main.welcome_text")}</p>`,
        //     showCloseButton: false,
        //     allowOutsideClick: false,
        //     showCancelButton: false,
        //     showClass: {
        //         popup: 'animate__animated animate__fadeIn'
        //     },
        //     hideClass: {
        //         popup: 'animate__animated animate__fadeOut'
        //     }
        //     }).then((result) => {
        //         let expiresdate =  new Date('2068', '1', '02', '11', '20');
        //         let cookievalue = "no_mostrar_value2";
        //         document.cookie = "no_mostrar_name2=" + encodeURIComponent( cookievalue ) + ";expires=" + expiresdate.toUTCString()+'; SameSite=Strict';
        // });
        // }else{
        //     console.log(document.cookie);
        // }

    }, [])
    
/*******************************************************************************************/
    return (
    <>
    <div className='principal'>
        
        <HeaderHome
            setCuenta={setCuenta}
        />

        <div className="container" style={{marginTop: '11rem'}}>

            <div className="card card-main mb-5">
                <div className="card-body">
                    <div className="row px-5 py-3 animate__animated animate__fadeIn">
                        <div className="col-md-6 justify-content-center">
                            <div className='text-center'>
                                {
                                    !drops &&
                                    <div className='row justify-content-end mb-3'>
                                        <div className='col-md-12'>
                                            <button 
                                                onClick={() => setCuenta(1)}
                                                className='btn btn-success btn-lg fw-bold text-uppercase mt-3 w-100'>
                                                { t("main.login") }                             
                                            </button>

                                        </div>
                                    </div>
                                }
                                <div className='row justify-content-center'>
                                    <div className='col-md-12'>
                                        <iframe 
                                            width={"100%"} 
                                            className='text-center'
                                            height="500" 
                                            src="https://www.tiktok.com/embed/7095136428211866886" 
                                            title="Labeldictate video" 
                                            frameBorder="0" 
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen>
                                        </iframe>
                                        
                                    </div>
                                </div>
                            </div>
                            {/* <blockquote className="tiktok-embed" cite="https://www.tiktok.com/@oscarguillermoaco6/video/7095136428211866886" data-video-id="7095136428211866886" style={{maxWidth: "605px", minWidth: "325px"}} > <section> <a target="_blank" title="@oscarguillermoaco6" href="https://www.tiktok.com/@oscarguillermoaco6">@oscarguillermoaco6</a> <a title="empremdimiento" target="_blank" href="https://www.tiktok.com/tag/empremdimiento">#empremdimiento</a> <a title="productividad" target="_blank" href="https://www.tiktok.com/tag/productividad">#productividad</a> <a title="tecnologia" target="_blank" href="https://www.tiktok.com/tag/tecnologia">#tecnologia</a> <a title="ventas" target="_blank" href="https://www.tiktok.com/tag/ventas">#ventas</a> <a title="retail" target="_blank" href="https://www.tiktok.com/tag/retail">#retail</a> <a title="precios" target="_blank" href="https://www.tiktok.com/tag/precios">#precios</a> <a title="tienda" target="_blank" href="https://www.tiktok.com/tag/tienda">#tienda</a> <a title="ribetec" target="_blank" href="https://www.tiktok.com/tag/ribetec">#ribetec</a> <a target="_blank" title="♬ Girls Like You - Vitamin String Quartet" href="https://www.tiktok.com/music/Girls-Like-You-6906777284070377474">♬ Girls Like You - Vitamin String Quartet</a> </section> </blockquote> <script async src="https://www.tiktok.com/embed.js"></script> */}
                        </div>
                        <div className="col-md-6 text-end">
                            {
                                drops &&
                                <div className='row justify-content-end'>
                                    <div className='col-md-12'>
                                        <button 
                                            onClick={() => setCuenta(1)}
                                            className='btn btn-success btn-lg fw-bold text-uppercase mt-3 w-100'>
                                            { t("main.login") }                             
                                        </button>

                                    </div>
                                </div>

                            }
                            <h1 className='text-wrap display-1'>
                                { t("main.industry_printing") }
                            </h1>
                            <h4>
                                { t("main.and_more") }
                            </h4>
                        </div>
                        <div className='text-end'>
                        </div>
                    </div>

                </div>
            </div>

            <section className='text-center mb-5'>
                <img src={bridgegap} className='img-fluid w-100'/>
            </section>

        
            <hr/>

            <section>
                
                <div className='my-5'>
                    <div className="d-flex justify-content-center align-items-center">
                        <FontAwesomeIcon icon={faFire} className="fa-2x"/>
                        <h3 className='display-6 mb-0 mr-0' style={{marginLeft: 5}}> {t("main.thermal_print")}</h3>
                    </div>
                    
                    <div className='d-flex justify-content-center'>
                        <ul className='h6'>
                            <li>{t("main.thermal_print_1")}</li>
                            <li>{t("main.thermal_print_2")}</li>
                            <li>{t("main.thermal_print_3")}</li>
                            <li>{t("main.thermal_print_4")}</li>
                            <li>{t("main.thermal_print_5")}</li>
                        </ul>
                    </div>
                </div>

            </section>
            <hr/>
            <section>                
                <div className="card card-main my-5">
                    <div className='card-body'>
                        <div className="row">
                            <div className='col-md-6'>
                                    <img src={ayuda} className='img-fluid border rounded' alt='página https://ayuda.labeldictate.com'/>
                            </div>
                            <div className="col-md-6">
                                <div className='d-flex flex-column justify-content-center align-items-center h-100'>
                                    <h2 className='fw-bold text-center mt-5'>
                                        ¿Necesitas ayuda? 
                                    </h2>
                                    <a 
                                        className='btn btn-success btn-lg fw-bold text-uppercase mt-3 w-100' 
                                        style={{marginBottom: '6rem'}}
                                        href='https://ayuda.labeldictate.com'
                                        target='_blank'    
                                    >
                                        <span className='fw-bold'>Ingresar</span>
                                    </a>

                                </div>
                            </div>
                            <div className="col-md-6">

                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </div>

        <footer className="mt-auto py-4 bg-dark box-sh">
            <div className="container">
                <div className="d-flex justify-content-between">
                    <div className="text-white text-center w-100">
                        <p className="m-0">© Copyright <strong>Label Dictate Latam S.A. de C.V.</strong> Todos los derechos reservados.</p>
                        <p className="m-0 text-muted">Independencia 1018 I-201, Parques del Bosque, Tlaquepaque, Jalisco, México. 45609</p>
                        <span className="text-muted">
                            Diseñado por <a href="/" className="link-secondary">Label Dictate Latam S.A. de C.V</a> 
                        </span>
                        <p className="text-muted m-0">
                            <a href="https://labeldictate.com/?c=privacy" className="link-secondary">Privacy Policy</a> 
                        </p>
                    </div>
                </div>
            </div>
        </footer>

    </div>
    </>
  )
}



export default Main
import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {urlApi} from '../../global/Global';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ComprobarVista from '../vistaUsuarioContext/ComprobarVista';
const Suscripciones = ({setUser, user}) => {
    
    let x = '';
    //SweetAlert2
    const MySwal = withReactContent(Swal);
    const MySwal2 = withReactContent(Swal);
    const [carga, setCarga] = useState(false);
//******************************************************************************************** */
    //Funcion sweetalert de compras
    const sweetAlert = (title, html, allowOutsideClick = true, showloading = false, icon='') => {
        MySwal.fire({
           title: title,
           icon: icon,
           html: html,
           allowOutsideClick: allowOutsideClick,
           didOpen: () => {
               if(showloading){
                   MySwal.showLoading();
               }
           }
       })
   }
/*******************************************************************************************************/

    const pago = async () => {

        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
        
        const send_data = {
            price: 1999
        }
        await axios.post(urlApi+'suscripciones', send_data, {headers: headers})
                    .then(async (response) =>{
                        console.log(response.data);
                        const w =  window.open(response.data.url, '_blank');
                        sweetAlert('Realizando compra', 'Tomara solo unos minutos', false, true);
                        var interval = window.setInterval(function() {
                            try {
                                if (w == null || w.closed) {
                                    window.clearInterval(interval);
                                    comprobarPago();
                                }
                            }
                            catch (e) {
                            }
                        }, 1000);
                    }).catch((error) => {
                        console.log(error);
                    }).then(() =>{
                        // setCarga(false);
                    });    
    }     

/*******************************************************************************************************/

    const comprobarPago = async () => {

        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
        
        await axios.get(urlApi+'suscripciones/comprobar', {headers: headers})
                    .then(async (response) =>{
                        console.log(response.data);
                        if (response.data.suscripcion_id == null) {
                            MySwal.close();
                            sweetAlert('Error', 'Ocurrio un error, vuelva a intentarlo', true, false, 'error');
                        }else if(response.data.suscripcion_id != null){
                            
                            x                = user;
                            x.suscripcion_id = response.data.suscripcion_id;

                            setUser({...user, suscripcion_id : response.data.suscripcion_id})
                            console.log(x);
                            await AsyncStorage.removeItem('@login');
                            await AsyncStorage.setItem(
                                '@login',
                                JSON.stringify(x)
                            );
                            MySwal.close();
                            sweetAlert('Compra realizada correctamente', 'Suscripción completada', true, false, 'success');
                        }
                    }).catch((error) => {
                        console.log(error);
                    }).then(() =>{
                        // setCarga(false);
                    });    
    }     

/*******************************************************************************************************/

    const cancelar = async () => {

        MySwal.fire({
            icon: 'question',
            title: '¿Quieres cancelar tu suscripción?',
            showCancelButton: true,
            confirmButtonText: 'Si, cancelar',
            cancelButtonText: `No, regresar`,
        }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                MySwal.fire({
                    icon: 'warning',
                    title: '¿ESTAS SEGURO?',
                    html: '<p class="text-danger ">'+
                          '¡Una vez realizada la acción no podras deshacerla!</p>',
                    showCancelButton: true,
                    confirmButtonText: 'Si, cancelar',
                    cancelButtonText: `No, regresar`,
                }).then(async (result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        setCarga(true);
                        sweetAlert('Cancelando suscripción', 'Tomara solo unos momentos', false, true);
                        /* RESPUESTA  */
                        const log = await AsyncStorage.getItem('@login');
                        const log_json = JSON.parse(log);
                        const headers = {
                            'Authorization': log_json.token_type+' '+log_json.access_token,
                            'Content-Type': 'application/json',
                            'X-Requested-With': 'XMLHttpRequest',
                        }
        
                        await axios.get(urlApi+'suscripciones/cancelar',  {headers: headers})
                                    .then(async (response) =>{
                                        console.log(response.data);
                                        x                = user;
                                        x.suscripcion_id = null;

                                        setUser({...user, suscripcion_id : null})
                                        console.log(x);
                                        await AsyncStorage.removeItem('@login');
                                        await AsyncStorage.setItem(
                                            '@login',
                                            JSON.stringify(x)
                                        );
                                        await MySwal2.fire({
                                            icon : 'success',
                                            title: 'Correcto',
                                            text : 'Suscripción cancelada'
                                        })
                                    }).catch(async (error) => {
                                        console.log(error);
                                        await MySwal2.fire({
                                            icon : 'error',
                                            title: 'Error',
                                            text : 'Ocurrió un error, intentelo nuevamente'
                                        })
                                    }).then(() =>{
                                        setCarga(false);
                                        MySwal.close();
                                    });  
                        /* RESPUESTA  */
                    } // fin del if
                })    //fin del then    
            } //fin primer if
        }) //   fin primer then                  
    }        

/*******************************************************************************************************/
    
    useEffect(() => {
        console.log(user);
    }, [])

/*******************************************************************************************************/
    return (
        <div className="row justify-content-center animate__animated animate__fadeIn">
             < ComprobarVista/>
            <div className="col-md-12">                
                <div className="card">
                    <div className="card-header bg-card-header">
                        <span className="fw-bold text-white">Suscripción</span>
                    </div>

                    <div className="card-body">
                        <div className="row">
                            <h2>Próximamente</h2>
                            {/* {
                                user.suscripcion_id == null ?
                                <>
                                    <div className="col-12">
                                        <p className='text-danger fw-bold mb-0'>
                                            Actualmente no cuenta con una suscripcion
                                        </p>
                                        <p>
                                            Si necesita una cuenta premium haga click en el siguiente enlace para poder suscribirse
                                        </p>     
                                        <button 
                                            className='btn btn-outline-success '
                                            onClick={() => pago()}    
                                        >
                                            Suscribirse 9.99$
                                        </button>
                                    </div>
                                </>                           
                                :
                                <>
                                    <div className="col-12">
                                        <p className='text-success fw-bold mb-0'>
                                            Actualmente ya cuenta con una suscripcion
                                        </p>
                                        <p>
                                            Si necesita una cuenta cancelar la suscripción haga click en el siguiente enlace;
                                        </p>     
                                        <button 
                                            className='btn btn-outline-secondary'
                                            onClick={() => cancelar()}    
                                            disabled={carga}
                                        >
                                            Cancelar suscripción
                                        </button>
                                    </div>
                                </>
                            } */}

                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Suscripciones

import React, {useState, useEffect} from 'react';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from 'axios';
/*Globales*/
import {urlApi} from '../../global/Global';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Formik, Form } from 'formik';
import { useHistory } from "react-router-dom";
import ComprobarVista from '../vistaUsuarioContext/ComprobarVista';
import { t } from 'i18next';

const UpdateCatalogo = (props) => {

    const [catalogo, setcatalogo]   = useState(props.location.state.catalogo);
    const [csv, setcsv]             = useState('');
    const [extension, setextension] = useState('');
    const [filename, setfilename]   = useState('');
    const [loading, setloading]     = useState(false);
    const MySwal                    = withReactContent(Swal);
    let   history                   = useHistory();
    /*-------------------------------------------------------*/
    useEffect(() => {

    //   console.log(catalogo);
        // console.log(props.user.role)
    }, []);

    useEffect(() => {
        if(csv != ''){
            sobreEscribirCatalogo();
        }
    }, [csv])

/*********************************************************************************************************/

    const handlecsv = (e) => {
        setcsv(e.target.files[0]);
        const extension= e.target.files[0].name.substring(e.target.files[0].name.lastIndexOf('.')+1, e.target.files[0].name.length) || e.target.files[0].name;
        setextension(extension);
        const name= e.target.files[0].name.substring(0, e.target.files[0].name.indexOf('.'));
        setfilename(name);
    }

/*********************************************************************************************************/

    const sobreEscribirCatalogo = async () => {

        if (csv==='') {
            document.getElementById("formFileSm").value = "";
            setcsv('');
            setextension('');
            setfilename('');
            MySwal.fire({
                icon             : 'info',
                title            : 'Debe subir un archivo csv o xls',
                toast            : true,
                position         : 'top-end',
                showConfirmButton: false,
                timer            : 3000,
                timerProgressBar : true,
                didOpen: (toast) => {
                toast.addEventListener('mouseenter', MySwal.stopTimer)
                toast.addEventListener('mouseleave', MySwal.resumeTimer)
                }
            })
            return;
        }

        setloading(true);
        setTimeout(() => {}, 400);
        
        let formData = new FormData();
        formData.append('archivo', csv);
        formData.append('datas', 'local');
        formData.append('data', JSON.stringify(catalogo) );

        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization'   : log_json.token_type+' '+log_json.access_token,
            'Content-Type'    : 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
        await axios({
            method: 'post',
            url: urlApi+'catalogo/sobreescribir',
            data: formData,
            headers: headers
          }).then((response) => {
              if(response.data.respuesta === 'correcto'){
                 MySwal.fire({
                    icon             : 'success',
                    title            : 'Catalogo Actualizado',
                    toast            : true,
                    position         : 'top-end',
                    showConfirmButton: false,
                    timer            : 3000,
                    timerProgressBar : true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', MySwal.stopTimer)
                        toast.addEventListener('mouseleave', MySwal.resumeTimer)
                    }
                });
                if(props.user.role !== 1){
                    history.push("/catalogos");
                }else{
                    history.push("/precatalogos");
                }
              }
                console.log(response.data)
            }).catch(error => {            
                console.log(error)
          }).then(() =>{
            setloading(false);
          });
        // console.log(formData)
    }

/*********************************************************************************************************/

    return (
        <>
            <div className="row justify-content-center">
                {
                    props.user.role === 2 &&
                    <ComprobarVista/>
                }
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-header bg-card-header">
                            <span className='fw-bold text-white'>{t("import.t_import")}</span>
                        </div>
                        <div className="card-body">
                            <Formik
                                // enableReinitialize
                                initialValues={{
                                    // observaciones: observaciones
                                }}
                                onSubmit={(values, actions) => sobreEscribirCatalogo(values, actions)}
                            >
                                <Form>
                                    <div className="row">
                                        <label htmlFor="formFile" className="form-label">Selecciona un catalogo desde tu dispositivo en formato <b>CSV </b>o<b> XLS</b></label>
                                        <div className="mb-3 files2">
                                            <div className="mb-3 files">
                                                <input disabled={loading} className="form-control" id="formFileSm" type="file" name="csv" onChange={handlecsv} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" required/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-6 d-none">
                                            <button type="submit" className="btn btn-dark " disabled={loading}>Guardar y enviar</button>                                                
                                        </div>
                                        {
                                            loading &&
                                            <div className="col-md-12 text-center">
                                                <div className="spinner-border text-primary" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </Form>
                            </Formik> 
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default UpdateCatalogo
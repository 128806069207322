import React, {useState} from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
/*Globales*/
import {urlApi} from '../../../global/Global';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import sweets from './sweets';
import { t } from 'i18next';

const Precios = ({variables, idEtiqueta, precio, setprecio}) => {

    const [loading, setloading] = useState(false);
    const [carga, setcarga]     = useState(false);
    const seperador = ['.', ',', "'", '`', ' '];

/************************************************************************************* */

  //Validador de datos
  const formSchema = Yup.object().shape({
    precio: Yup.string()
        .trim('')
        .required('Required'),
    valor: Yup.string()
        .required('Required'),
  });

/************************************************************************************* */

  const handleChangePrecio = (params) => {

    setprecio({
      ...precio,
      'variable': params
    })

  }

/************************************************************************************* */

  const enviarPrecio = async (params) => { 
      setloading(true);
      console.log(params);
      const log      = await AsyncStorage.getItem('@login');
      const log_json = JSON.parse(log);
      const headers  = {
          'Authorization'   : log_json.token_type+' '+log_json.access_token,
          'Content-Type'    : 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
      }
      await axios.post(urlApi+'etiquetas/configuraciones/3/'+idEtiqueta, params, {headers: headers})
                  .then((response) => {
                      //Actualiza estados del formulario
                      console.log(response.data);
                      sweets._success(response);
                  })
                  .catch((error) => {
                      console.log(error);
                      sweets._error(error);
                  }).then(() => {
                      setloading(false);
                  });

  }

/************************************************************************************* */

  return (
    <>
        <div className='row'>
          <div className='col-md-6'>
            <div className='d-flex justify-content-between'>

                <div className="d-none">
                    <h4 className='fw-bold'>{t("config_label.prices")}</h4>

                </div>
                <div className=' d-none'>
                    <a className="btn btn-sm btn-outline-dark " data-bs-toggle="collapse" href="#collapseExample2" role="button" aria-expanded="false" aria-controls="collapseExample2">
                        <FontAwesomeIcon icon={faChevronDown}/>
                    </a>
                </div>
            </div>
          </div>
        </div>
        <Formik
            enableReinitialize
            initialValues={{
              precio: precio.variable,
              valor : precio.valor || ".",
            }}
            validationSchema={formSchema}
            onSubmit={(values, actions) => enviarPrecio(values, actions)}
        >
            {({ values, errors, touched, handleReset }) => {
                return (
                    <Form>
                      <div className='row py-3'>

                        <div className='col-md-2 border-start border-end border-bottom'>
                          <label className="form-label fw-bold">{t("config_label.choose_var")}</label>
                          <select
                              className='form-select form-select-sm'
                              disabled={loading}
                              name="variables"
                              onChange={(e) => handleChangePrecio(e.target.value)}
                              defaultValue={'0000'}
                          >
                            <option disabled value={'0000'}> --{t("config_label.support_variables")} --</option>

                            {
                              variables.map((e,i) =>(
                                <option key={`var-${i}`}>{e}</option>
                              ))
                            }
                          </select>
                        </div>

                        <div className='col-md-2'>
                            <label className="form-label fw-bold">Variable</label>
                            <Field 
                                className='form-control form-control-sm' 
                                placeholder='variable' 
                                name={`precio`} 
                                disabled={loading}
                            />
                            <ErrorMessage
                                name={`precio`}
                                component='div'
                                className='field-error text-danger'
                            /> 
                        </div>

                        <div className='col-md-3'>
                            <label className="form-label fw-bold">{t("config_label.penny_separator")}</label>
                            <Field 
                                as="select"
                                className='form-select form-select-sm' 
                                placeholder='variable' 
                                name={`valor`} 
                                disabled={loading}
                            >
                              {
                                seperador.map((e,i) =>{
                                  return <option key={'seeparador-'+i} value={e}>{e}</option>
                                })
                              }  
                            </Field>
                            <ErrorMessage
                                name={`valor`}
                                component='div'
                                className='field-error text-danger'
                            /> 
                        </div>

                      </div>

                      <div className='row mt-4'>
                        <div className='col-md-5'>
                          <button type='submit' className='btn btn-primary btn-sm fw-bold text-uppercase' disabled={loading}>
                            {t("config_label.save_prices")}
                          </button>
                        </div>
                      </div>
                  </Form>
                ); 
            }}
        </Formik>      

    </>
  )
}

export default Precios
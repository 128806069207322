import React,{useEffect, useState, useContext} from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
/*Globales*/
import {urlApi} from '../../global/Global';
import {gapi} from 'gapi-script';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import ComprobarVista from '../vistaUsuarioContext/ComprobarVista';
import UserContext from '../../userContext/UserContext';
const Headers = (props) => {

    const [catalogo, setcatalogo] = useState(props.location.state.catalogo);
    const [arreglo, setArreglo] = useState(['', 'DESCRIPCION', 'PRECIO', 'CANTIDAD', 'CODIGO', 'CODIGO_INT', 'OFERTA', 'UNIDAD', 'UBICACION', 'OBSERVACION']);
/**************************************************************************************************/
    
    // Client ID and API key from the Developer Console
    const CLIENT_ID = '191181423961-lqknnrp0amj9pj0o4d16r8aafu611le9.apps.googleusercontent.com';
    const API_KEY   = 'AIzaSyBIK-3IqFaUg03I0elDDLpqUO4UaKIQ6RY';

    // Array of API discovery doc URLs for APIs used by the quickstart
    const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"];

    // Authorization scopes required by the API; multiple scopes can be
    // included, separated by spaces.
    const SCOPES = 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile';
    //
    const [signedInUser, setSignedInUser] = useState('');
    const [isLoadingGoogleDriveApi, setIsLoadingGoogleDriveApi] = useState(false);

    const MySwal = withReactContent(Swal);
    const [loading, setloading] = useState(false);
    const { user } = useContext(UserContext);
/*******************************************************************************/
/***************************** DRIVE API ***************************************/
/*******************************************************************************/

    const updateSigninStatus = (isSignedIn) => {
        if (isSignedIn) {
            // Set the signed in user
            setSignedInUser(gapi.auth2.getAuthInstance().currentUser);
            // setIsLoadingGoogleDriveApi(false);
            console.log(gapi.auth2.getAuthInstance().currentUser.le.wt);
            // list files if user is authenticated
            // listFiles();
        } else {
            // prompt user to sign in
            // handleAuthClick();
        }
    };

    /**
     *  Initializes the API client library and sets up sign-in state
     *  listeners.
     */
    const initClient = () => {
        setIsLoadingGoogleDriveApi(true);
        gapi.client
            .init({
                apiKey: API_KEY,
                clientId: CLIENT_ID,
                discoveryDocs: DISCOVERY_DOCS,
                scope: SCOPES,
            })
            .then(
                function () {
                    // Listen for sign-in state changes.
                    gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);

                    // Handle the initial sign-in state.
                    updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
                },
                function (error) {
                    console.log(error);
                }
            );
    };

    const handleClientLoad = () => {
        gapi.load('client:auth2', initClient);
    };

/**************************************************************************************************/
/**************************************************************************************************/
/**************************************************************************************************/
    
    useEffect(() => {
        // handleClientLoad();
        console.log(catalogo);
    }, []);
    
/***************************************************************************************************/

    const carpetaLddata = async (params) => {
        let carpeta = '';
        let id_carpeta = '';        
        await gapi.client.drive.files.list({ 
            pageSize: 1,
            fields: 'nextPageToken, files(id, name, mimeType, modifiedTime)',
            'q': "mimeType = 'application/vnd.google-apps.folder' and trashed = false and name contains 'LDDATA'",
        }).then(res =>{
            console.log(res);
            carpeta = JSON.parse(res.body);
        });
        if(carpeta.files.length <= 0){

            const fileMetadata = {
                'name': 'LDDATA',
                'mimeType': 'application/vnd.google-apps.folder'
            };
              
            await gapi.client.drive.files.create({ 
                resource: fileMetadata,
                fields: 'id'
            }).then(res =>{
                console.log(res);
                carpeta = JSON.parse(res.body);
            });

            id_carpeta = carpeta.id;
            return id_carpeta;
        }else{
            id_carpeta = carpeta.files[0].id;
            return id_carpeta;
        }

    }
/*******************************************************************************/
    const actualizarDrive = async (csv) => {
        /**OBTENEMOS LA CARPETA LDDATA */
        let id_carpeta = '';
        let drive_id   = '';
        let _name      = catalogo.nombre.replace('.csv', '');
            _name      = _name.replace('.CSV', '')+'.headers';
            id_carpeta = await carpetaLddata();
            // console.log(_name);
        // return;
        let _config = '';

        await gapi.client.drive.files.list({ 
            pageSize: 1,
            fields: 'nextPageToken, files(id, name, mimeType, modifiedTime)',
            q: `mimeType = 'text/plain' and '${id_carpeta}' in parents and trashed = false and name contains '${_name}'`,
        }).then(res =>{
            // console.log(res.body);
            _config = JSON.parse(res.body);
            console.log(_config.files);
        });
        
        /* COMPROBAMOS QUE EXISTA EL ARCHIVO */
        /* Si existe lo actualizamos, si no existe, lo creamos y cambiamos el ID en la base de datos */
        let fileMetadata = {};
        if(_config.files.length <= 0){
            fileMetadata = {
                name    : _name,
                mimeType: 'text/plain',
                parents : [id_carpeta]
            };
        }else{
            fileMetadata = {
                name    : _name,
                mimeType: 'text/plain',
                
            };
            drive_id = _config.files[0].id
        }
       /*CREAMOS _CONFIGURACION DEL ARCHIVO */
        const headers = {
            'Authorization': 'Bearer '+gapi.auth.getToken().access_token,
        }

        console.log(drive_id);    

        let formData = new FormData();
        formData.append("metadata", new Blob([JSON.stringify(fileMetadata)], { type: 'application/json' }));
        formData.append("file", csv);

        if(_config.files.length <= 0){
            
            await axios({
                method: 'POST',
                url: 'https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart',
                headers: headers,
                data: formData,
                }).then(async response =>{
                        
                    console.log(response.data);
                    const send_data ={
                        id_drive: response.data.id,
                        catalogo: catalogo
                    }
                    /* actualizamos el id_drive */
                    const log = await AsyncStorage.getItem('@login');
                    const log_json = JSON.parse(log);
                    const headers = {
                        'Authorization': log_json.token_type+' '+log_json.access_token,
                        'Content-Type': 'application/json',
                        'X-Requested-With': 'XMLHttpRequest',
                    }            
                    await axios.post(urlApi+'catalogo/update', send_data, {headers: headers})
                                .then((response) => {
                                    MySwal.fire({
                                        icon: 'success',
                                        title: 'Guardado en drive',
                                        toast: true,
                                        position: 'top-end',
                                        showConfirmButton: false,
                                        timer: 3000,
                                        timerProgressBar: true,
                                        didOpen: (toast) => {
                                            toast.addEventListener('mouseenter', Swal.stopTimer)
                                            toast.addEventListener('mouseleave', Swal.resumeTimer)
                                        }
                                    });                                                         
                                }).catch(error =>{
                                        console.log(error);
                                        MySwal.fire({
                                            icon: 'warning',
                                            title: 'Error',
                                            text: 'err',
                                            // text: err.response.data.error.message,
                                            confirmButtonColor: '#3085d6',
                                            cancelButtonColor: '#d33',
                                            allowOutsideClick: false
                                        })
                                });                                                 
                   
                }).catch(err =>{
                    console.log(err);
                    // console.log(err.response.data.error.message);
                    MySwal.fire({
                        icon: 'warning',
                        title: 'Error',
                        text: 'err',
                        // text: err.response.data.error.message,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        allowOutsideClick: false
                    })
                }).then(() =>{
                    setloading(false);
                });

        }else{
            await axios({
                method: 'PATCH',
                url: 'https://www.googleapis.com/upload/drive/v3/files/'+drive_id+'?uploadType=multipart',
                headers: headers,
                data: formData,
                }).then(response =>{
                console.log(response.data);
                MySwal.fire({
                    icon: 'success',
                    title: 'Creado y guardado en drive',
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                        toast.addEventListener('mouseenter', Swal.stopTimer)
                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                });
            }).catch((err) =>{
                console.log(err);
                // console.log(err.response.data.error.message);
                MySwal.fire({
                    icon: 'warning',
                    title: 'Error',
                    text: 'err',
                    // text: err.response.data.error.message,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    allowOutsideClick: false
                })
            }).then(() =>{
                setloading(false);
            }); 
        }

    }
    

/***************************************************************************************************/

    const actualizarCabeceras = async (e) => {
        e.preventDefault();
        setloading(true);
        console.log(catalogo.only_plantillas);

        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
        await axios.post(urlApi+'catalogo/headers', catalogo, {headers: headers})
                   .then(async(response) => {
                      // setLoading(false);
                        if(response.data.respuesta == 'correcto'){
                                /*CREAMOS EL ARCHIVO */
                                // const blob = new Blob([response.data.config]);
                                // await actualizarDrive(blob);                            
                                // setcatalogo(response.data.catalogo);
                                MySwal.fire({
                                    icon: 'success',
                                    title: 'Actualizado correctamente',
                                    toast: true,
                                    position: 'top-end',
                                    showConfirmButton: false,
                                    timer: 3000,
                                    timerProgressBar: true,
                                    didOpen: (toast) => {
                                        toast.addEventListener('mouseenter', Swal.stopTimer)
                                        toast.addEventListener('mouseleave', Swal.resumeTimer)
                                    }
                                });                
                        }
                        console.log(response.data);
                   })
                   .catch((error) => {
                        console.log(error);
                        setloading(false);
                        MySwal.fire({
                            icon: 'warning',
                            title: 'Error',
                            text: 'error, vuelva a intentarlo',
                            // text: err.response.data.error.message,
                            confirmButtonColor: '#3085d6',
                            cancelButtonColor: '#d33',
                            allowOutsideClick: false
                        })
                   }).then(() =>{
                        setloading(false);
                   });     
    }

/***************************************************************************************************/

    const changeValues = (value, i) => {
        // document.querySelector(`#inlineCheckbox${i}${j}`).checked = value;
        console.log(value);
        console.log(i);
        // return;
        const x = catalogo.only_plantillas.map((item, c) => {
                return c == i
                ? { ...item,  nombre: value }
                : item
        })

        setcatalogo(catalogo =>({
            ...catalogo,
            ['only_plantillas']: x
        }))

        console.log(x);
    }

/***************************************************************************************************/

    return (
        <>
        {
            user.role != 1 &&
                <ComprobarVista/>
        }
        <div className="row justify-content-center">
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header bg-card-header">
                        <span className="fw-bold text-white">Catalogos</span>
                    </div>

                    <div className="card-body">
                        <div className="row">
                            <div className="col-6 align-items-center mb-3">
                               <form>
                                    {
                                        catalogo != undefined &&
                                            catalogo.only_plantillas.map((e,i) => {
                                                    return <div className="mb-3" key={'inputs'+i}>
                                                        <label htmlFor="exampleFormControlInput1" className="form-label">Nombre en documento: <b>{e.nombre_real}</b> - Columna: <b>{e.fila}</b></label>
                                                        <select 
                                                            disabled={loading}
                                                            className='form-select'
                                                            onChange={(e) => changeValues(e.target.value, i)}
                                                            defaultValue={e.nombre}
                                                        >
                                                            {
                                                                arreglo.map((item,j) =>{
                                                                    return <option
                                                                        key={'options'+j}
                                                                        // selected={
                                                                        //     e.nombre == item
                                                                        //     ? 'selected'
                                                                        //     : ''
                                                                        // }
                                                                    >{item}
                                                                    </option>
                                                                })
                                                                
                                                            }
                                                        </select>
                                                        
                                                        <input 
                                                            type="text" 
                                                            className="form-control d-none" 
                                                            id="exampleFormControlInput1" 
                                                            value={e.nombre} 
                                                            required
                                                            onChange={(e) => changeValues(e.target.value, i)}    
                                                        />
                                                    </div>                                                                                              
                                            })
                                    }

                                    <button 
                                        disabled={loading}
                                        onClick={(e) => actualizarCabeceras(e)}
                                        type='submit'
                                        className='btn btn-dark  mt-3'
                                    ><span style={{marginRight: '5px'}} >Actualizar cabeceras</span>
                                    {
                                        loading &&
                                        <>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            <span className="visually-hidden">Loading...</span>
                                        </>
                                    }                                                                                  
                                    </button>
                               </form>
                            </div>

                            <div className="col-6">
                                {
                                    arreglo.map((e,i) =>{
                                         return <div key={'cop'+i}>
                                            {
                                                catalogo.only_plantillas.filter(item => item.nombre == e).length == 0 &&                                            
                                                    <p className='text-dark fw-bold m-0'>{e}</p>
                                            }
                                            {
                                                catalogo.only_plantillas.filter(item => item.nombre == e).length  == 1 &&
                                                    <p className='text-success fw-bold m-0'>{e}</p>
                                            }
                                            {
                                                catalogo.only_plantillas.filter(item => item.nombre == e).length  > 1 &&
                                                    <p className='text-danger fw-bold m-0'>{e}</p>
                                            }
                                         </div>
                                    })
                                }   

                            </div>

                        </div>
                        
                    </div>

                </div>
            </div>
        </div>
        </>
    )
}

export default Headers

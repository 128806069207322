import React, {useState, useEffect, useContext, useRef} from 'react'
import {
  NavLink, Link
} from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faTrash, faHatWizard, faFile, faCog, faTable  } from '@fortawesome/free-solid-svg-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
/*Globales*/
import {urlApi} from '../../global/Global';
import ComprobarVista from '../vistaUsuarioContext/ComprobarVista';
import UserContext from '../../userContext/UserContext';
import { unauthenticated } from '../../helpers/Helper';
import { t } from 'i18next';
import VideoTutorialCatalogos from './VideoTutorialCatalogos';

const Catalogos = () => {

    const MySwal = withReactContent(Swal);

    const [catalogos, setCatalogos] = useState([]);
    const [loading, setLoading]     = useState(false);
    const [_loading, _setLoading]   = useState(false);
    const { user }                  = useContext(UserContext);

    const btn_t2b       = useRef(null)
    const btn_paso2_cls = useRef(null)
/*************************************************************************************************************************************/
    const obtenerCatalogo = async (params) => {
      const log = await AsyncStorage.getItem('@login');
      const log_json = JSON.parse(log);
      const headers = {
          'Authorization': log_json.token_type+' '+log_json.access_token,
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
      }
      await axios.get(urlApi+'catalogo/index', {headers: headers})
                .then((response) => {
                    //Actualiza estados del formulario
                    setCatalogos(response.data);
                    setLoading(false);
                    console.log(response.data);
                })
                .catch((error) => {
                    unauthenticated(error)
                    console.log(error);
                });     
    }
/*************************************************************************************************************************************/

    const eliminarCatalogo = async (params) => { 
        console.log(params);
        // return;
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }

        const send_data = {
            catalogo: params
        }
        Swal.fire({
            title: '¿Eliminar catalogo?',
            text: "Esta acción no se puede revertir",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, borrar',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await axios({
                    method: 'delete',
                    url: urlApi+'catalogo/destroy',
                    data: send_data,
                    headers: headers
                })
                    .then((response) => {
                        //Actualiza estados del formulario
                        if(response.data.respuesta === 'correcto'){
                            MySwal.fire({
                                icon: 'success',
                                title: 'Se elimino correctamente el catalogo',
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                            });
                            setCatalogos(response.data.mensaje);                        
                        }else{
                            MySwal.fire({
                                icon: 'error',
                                title: response.data.mensaje,
                                toast: true,
                                position: 'top-end',
                                showConfirmButton: false,
                                timer: 3000,
                                timerProgressBar: true,
                            });
                        }
                        console.log(response.data);
                    })
                    .catch((error) => {
                        MySwal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: error,
                        });
                        console.log(error);
                }); 
            }
        });
    }
/***************************************************************************************************/

    const descargarCatalogo = async (params) => { 
        // console.log(params)    
        _setLoading(true);
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
        await axios({
            url: urlApi+'catalogo/descargar-catalogo/'+params.id,
            method: 'GET',
            // data: datos_enviar,
            headers: headers,
            responseType: 'blob', // important
        }).then(function (response) {
            console.log(response.data);
           
            const url       = window.URL.createObjectURL(new Blob([response.data]));
            // const url       = window.URL.createObjectURL(new Blob([response.data.archivo]));
            const link      = document.createElement('a');
                  link.href = url;
            link.setAttribute('download', params.nombre); //or any other extension
            // link.setAttribute('download', response.data.nombre); //or any other extension
            document.body.appendChild(link);
            link.click();
            console.log(response.data);

            MySwal.fire({
                icon: 'success',
                title: 'Etiqueta descargada',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            });
        })
        .catch(function (error) {
            MySwal.fire({
                icon: 'error',
                title: 'Ocurrio un error',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            });
            console.log(error);
        }).then(()=>{
            _setLoading(false);
        });
    }

/***************************************************************************************************/
//-----------------------
    useEffect(() => {
        window.scrollTo(0,0);
        setLoading(true);
        obtenerCatalogo();
    }, [])
/****************************************************************************************************/
    return (
        <>

        <VideoTutorialCatalogos 
            btn_t2b={btn_t2b}
            btn_paso2_cls={btn_paso2_cls}
        />
        {
            user.role != 1 &&
                <ComprobarVista/>
        }
            <div className="row justify-content-center animate__animated animate__fadeIn">
                <div className="col-md-12">                
                    <div className="card shadow-lg">
                        <div className="card-header bg-card-header">
                            <span className="fw-bold text-white">{t("catalogs.catalogs_title")}</span>
                        </div>

                        <div className="card-body">
                            <div className="row text-end">
                                <div className="col-12 align-items-center mb-3">
                                    <NavLink to="/importar" className={`btn btn-warning btn-sm shadow res-w100 ${_loading && 'disabled'}`}> 
                                        <span className='d-none' style={{fontFamily:'Arial, FontAwesome'}}>&#xf3aa; &nbsp;</span> 
                                        <span className='fw-bold text-uppercase'>{t("catalogs.import")}</span>
                                        {/* <span >Importar catalogo a Drive</span> */}
                                    </NavLink>
                                </div>
                                <div className='col-12 align-items-center mb-3'>
                                    <button className='btn btn-sm btn-outline-primary' type='button' onClick={() => btn_t2b.current.click()}>
                                        {t("import.how_to_use")}
                                    </button>
                                </div>


                            </div>
                            {

                            !loading ?
                            <>
                            
                            {
                                catalogos.length > 0 ?
                                catalogos.map((e,i) =>{
                                    return <div className="card border rounded shadow-sm mb-1" key={'catalogos-'+i}>
                                        <div className="card-body">
                                            <div className='row align-items-center'>

                                                <div className='col-md-6 border-bottom pb-3 mb-3'>
                                                    <div className='row align-items-center'>
                                                        <div className='col-md-2 text-center position-relative'>                                                        
                                                            <FontAwesomeIcon 
                                                                icon={faFile} 
                                                                className="h-100 w-50-100 shadow"
                                                            />
                                                            <FontAwesomeIcon 
                                                                icon={faTable} 
                                                                className="h-100 top-0 position-absolute l-40-46"
                                                                color='white'
                                                            />
                                                        </div>
                                                        <div className='col-md-10 mt-2'>
                                                            <h4>
                                                                <strong># {i+1}</strong>
                                                            </h4>
                                                            <h5 className="card-title">
                                                                <b>{t("catalogs.catalog")}: </b>{e.nombre}
                                                            </h5>
                                                            <p className="card-text">
                                                                <b>{t("catalogs.header")}: </b>
                                                                {
                                                                    e.encabezado == 0
                                                                    ? <span className='m-0'>{t("catalogs.not_first_row")}</span>
                                                                    : <span className='m-0'>{t("catalogs.first_row")}</span>
                                                                }                                                        
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className='col-md-4'>
                                                        <span className='fw-bold'>{t("import.related_label")} : </span>
                                                        <ul className="list-group list-group-numbered list-group-flush">
                                                            {
                                                                e.etiquetas.length > 0
                                                                ?   e.etiquetas.map((el, il) =>{
                                                                        return <li className="list-group-item bg-transparent" key={'etir-'+il}>{el.nombre}</li>                                                            
                                                                    })
                                                                :   <span className="bg-transparent">{t("import.not_related")} :( </span>
                                                            }
                                                        </ul>                                                    
                                                </div>

                                                <div className='col-md-2 mt-2'>
                                                    <div className={`dropdown ${_loading && 'disabled'}`}>
                                                        <a href="#" className="btn btn-secondary dropdown-toggle shadow w-auto" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                                            <span className='d-none'>Opciones </span>
                                                            <FontAwesomeIcon icon={faCog} />
                                                        </a>
                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            <li>
                                                                <Link className="dropdown-item"
                                                                    to={
                                                                        {  
                                                                            pathname:"/catalogos/update",
                                                                            state:{catalogo: e}
                                                                        }
                                                                    }
                                                                >
                                                                    {t("catalogs.update")}
                                                                </Link>
                                                            </li>
                                                            <li>
                                                                <a className="dropdown-item" href="#" onClick={() => descargarCatalogo(e)}>
                                                                    {t("catalogs.download")}
                                                                </a>
                                                            </li>
                                                            <li className='d-none'>
                                                                <a className="dropdown-item" href="#">Capturar registros</a>
                                                            </li>
                                                            <li>
                                                                <hr className='dropdown-divider'/>                                                        
                                                            </li>
                                                            <li>
                                                                <Link className="dropdown-item d-none"
                                                                            to={
                                                                                {  
                                                                                    pathname:"/catalogos/headers",
                                                                                    state:{catalogo: e}
                                                                                }
                                                                            }
                                                                >
                                                                    <span>{t("catalogs.header")}s </span> 
                                                                    <FontAwesomeIcon icon={faHatWizard}/>
                                                                </Link>                                                            
                                                            </li>
                                                            
                                                            <li>
                                                                <a className="dropdown-item" href="#" onClick={()=> eliminarCatalogo(e)}>
                                                                <span>{t("utilidades.delete")} </span> 
                                                                <FontAwesomeIcon icon={faTrash}/>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                })
                                :
                                <div className='row'>
                                    <hr/>
                                    <p className='text-center'>{t("catalogs.not_found")}</p>
                                </div>
                            }
                            </>
                            :
                            <div className="row">
                                <div className="col-md-12 text-center">
                                    <div className="spinner-border text-primary" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                </div>
                            </div>
                            }
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}

export default Catalogos

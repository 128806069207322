import React, {useState, useEffect} from 'react'
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
/*Globales*/
import {urlApi} from '../../../global/Global';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import sweets from './sweets';
import { t } from 'i18next';
const Moneda = ({variables, idEtiqueta, moneda, setmoneda, lan}) => {

    const [loading, setloading] = useState(false);
    const [carga, setcarga]     = useState(false);
    const _moneda = [{valor: '_24', text:'$'}, {valor: 'Q', text:'Q'}, {valor: 'L', text:'L'}, 
                     {valor: '_80', text:'€'}, {valor: 'S', text:'S'}, {valor: '_a5', text:'¥'}, 
                     {valor: '_a3', text:'£'}, {valor: '_a2', text:'₡'}
                    ];
   

/************************************************************************************* */

    //Validador de datos
    const formSchema = Yup.object().shape({
        moneda: Yup.string()
            .required('Required'),
    });

/************************************************************************************* */

  const enviarMoneda = async (params) => { 
      setloading(true);
      console.log(params);
      const log      = await AsyncStorage.getItem('@login');
      const log_json = JSON.parse(log);
      const headers  = {
          'Authorization'   : log_json.token_type+' '+log_json.access_token,
          'Content-Type'    : 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
      }
      await axios.post(urlApi+'etiquetas/configuraciones/5/'+idEtiqueta, params, {headers: headers})
                  .then((response) => {
                      //Actualiza estados del formulario
                      console.log(response.data);
                      sweets._success(response);
                  })
                  .catch((error) => {
                      console.log(error);
                      sweets._error(error);
                  }).then(() => {
                      setloading(false);
                  });

  }

    return (
        <div className='mt-3'>
            {/* {
                lan != 1 &&
                <div>
                    <h3 className='text-uppercase'>{t("config_label.only_zpl")}</h3>
                </div>
            } */}

            {/* {
                lan == 1 &&
                <> */}
                <div>
                    <h3>{t("config_label.currency")} ({t("config_label.only_zpl")})</h3>
                </div>
                        <Formik
                        enableReinitialize
                        initialValues={{
                            // moneda : moneda.valor || "$",
                            moneda : moneda.valor || "_24",
                        }}
                        validationSchema={formSchema}
                        onSubmit={(values, actions) => enviarMoneda(values, actions)}
                    >
                    {({ values, errors, touched, handleReset }) => {
                    return (
                        <Form>
                            <div className='row align-items-center py-3'>
                                <div className='mb-2'>
                                    <p className='mb-0'>{t("config_label.structure")}:</p>
                                    <span>^CI27^FH^FD<b>$</b>^FS</span>
                                </div>
                                <label htmlFor="form-activo" className="col-md-1 col-form-label">$ </label>
                                <div className='col-md-2 d-flex align-items-center'>
                                    <span className='fw-bold me-2'>=</span>
                                    <Field 
                                        as="select"
                                        className="form-select form-select-sm" 
                                        aria-label=".form-select-sm example"
                                        name="moneda"
                                    >
                                        {
                                            _moneda.map((e,i) =>{
                                                return <option key={'seeparador-'+i} value={e.valor}>{e.text}</option>
                                            })
                                        }  
                                    </Field>
                                </div>                
                            </div>
                            <div className='row mt-2'>
                                    <div className='col-md-5'>
                                        <button type='submit' className='btn btn-primary btn-sm fw-bold text-uppercase' disabled={loading}>
                                            {/* {t("config_label.save_prices")} */}
                                            Guardar
                                        </button>
                                    </div>
                            </div>
                        </Form>
                        ); 
                    }}
                    </Formik>
                {/* </>
            } */}
            
        </div>
    )
}

export default Moneda
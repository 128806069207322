import React from 'react'

const Skeleton = () => {
  return (
    <>
        <div className='principal'>
            <div className='body'>
                <div className="container-load">
                    <div className="yellow"></div>
                    <div className="red"></div>
                    <div className="blue"></div>
                    <div className="violet"></div>
                </div>
            </div>
        </div>
    </>
  )
}

export default Skeleton
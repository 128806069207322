import React, { useReducer, useEffect } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';

import VistaReducer from './VistaReducer';
import VistaContext from './VistaContext';

const VistaState = props => {


    useEffect(() => {
      
        const comprobarVista = async() => { 
        
            const log = await AsyncStorage.getItem('@vista');
            console.log(log);
            if(log == null){
                seleccionarVista(false);
                await AsyncStorage.setItem('@vista', "falses");
            }else{
                const val = log == "true";
                if(val){
                    await AsyncStorage.setItem('@vista', "trues");
                }else{
                    await AsyncStorage.setItem('@vista', "falses");
                }
                seleccionarVista(val);
            }
        }

        comprobarVista();
        // await AsyncStorage.setItem(
        //         '@login',
        //         jsonValue
        //     );
      
    }, [])
    

    // Crear state inicial
    const initialState = {
        vista: false
    }

    // useReducer con dispatch  para ejecutar las funciones
    const [ state, dispatch ] = useReducer(VistaReducer, initialState);


    // Selecciona el Producto que el usuario desea ordenar
    const seleccionarVista = vista => {
        dispatch({
            type: 'SELECCIONAR_VISTA',
            payload: vista
        })
    } 

    return (
        <VistaContext.Provider
            value={{
                vista: state.vista,
                seleccionarVista,
            }}
        >
            {props.children}
        </VistaContext.Provider>
    )
}

export default VistaState;
import React, {useEffect, useContext} from 'react';
import VistaContext from '../vistaUsuarioContext/VistaContext';
import { useHistory } from "react-router-dom";

const ComprobarVista = () => {
    const { vista } = useContext(VistaContext);
    let history = useHistory();

    useEffect(() => {
      
        if(!vista) history.push('/see-labels');

    }, [])
    
  return (
    <></>
  )
}

export default ComprobarVista
import React, {useState, useEffect, useContext, useRef} from 'react'
import AsyncStorage from '@react-native-async-storage/async-storage';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {urlApi} from '../../global/Global';
import {
  Link ,NavLink
} from "react-router-dom";
import { useHistory } from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faMoneyBill, faBarcode, faMobileAlt, faUsers, faFileInvoice, faCreditCard, faCode, faSlidersH, faTicketAlt, faGripLines, faThList, faUsersCog, faPrint, faFileSignature, faMobile } from '@fortawesome/free-solid-svg-icons';
import {gapi} from 'gapi-script';
import { version } from '../../../package.json';
import MenuHeader from './MenuHeader';
import Modal from './Modal';
import VistaContext from '../vistaUsuarioContext/VistaContext';
import UserContext from '../../userContext/UserContext';
import { useTranslation } from 'react-i18next';
import web2barcode from '../../../src/img/Logo.png'
import Mantenimiento from './Mantenimiento';
const Header = ({exist, user, setExist, setUser, mantenimiento, fechaMan, downloadPlantilla, downloadPlantillaCount}) => {

/***************************************************************************/
    const { t, i18n } = useTranslation();

    const MySwal = withReactContent(Swal);
    let history = useHistory();
    const [signedInUser, setSignedInUser] = useState('');
    /*--------------*/
    const [isLoadingGoogleDriveApi, setIsLoadingGoogleDriveApi] = useState(false);
    // Client ID and API key from the Developer Console
    const CLIENT_ID = '191181423961-lqknnrp0amj9pj0o4d16r8aafu611le9.apps.googleusercontent.com';
    const API_KEY   = 'AIzaSyBIK-3IqFaUg03I0elDDLpqUO4UaKIQ6RY';

    // Array of API discovery doc URLs for APIs used by the quickstart
    const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"];

    // Authorization scopes required by the API; multiple scopes can be
    // included, separated by spaces.
    const SCOPES = 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile';
    //Tamaño para saber si es movil o no
    const [isMobile, setIsMobile] = useState(false);
    const [urlString, seturlString] = useState('');

    /* Estado global */
    const { seleccionarVista, vista } = useContext(VistaContext);
    const { seleccionarUser } = useContext(UserContext);

    const cambiar_vista = useRef(null);
    const [userAgent, setuserAgent] = useState('')
    //
/***************************************************************************/

  const cambiarVista = () => { 
  
    cambiar_vista.current.click();

  }

/***************************************************************************/

  

/***************************************************************************/
    const handleResize = () => {
      if (window.innerWidth < 720) {
          setIsMobile(true)
          console.log('mobile')
        } else {
          setIsMobile(false)
          console.log('PC')
      }
    }
/***********************************************************************************/

/***********************************************************************************/

    useEffect(() => {     
      
      if(process.env.NODE_ENV !== 'development') {
        handleClientLoad();
      }

      handleResize();
      window.addEventListener("resize", handleResize);
      setuserAgent( window.navigator.userAgent);
      // second();
    }, [])

/***********************************************************************************/

    const handleSignOutClick = (event) => {
        // setListDocumentsVisibility(false);
        if(signedInUser != ''){
          gapi.auth2.getAuthInstance().signOut();
        }
        // setDocuments([]);    
        setSignedInUser('');
    };

/***************************************************************************/
  const updateSigninStatus = (isSignedIn) => {
        if (isSignedIn) {
            // Set the signed in user
            setSignedInUser(gapi.auth2.getAuthInstance().currentUser);
            // setIsLoadingGoogleDriveApi(false);
            console.log(gapi.auth2.getAuthInstance().currentUser.le.wt);
        } else {
            // prompt user to sign in
            // handleAuthClick();
        }
    };


  /**
   *  Initializes the API client library and sets up sign-in state
   *  listeners.
   */
  const initClient = () => {
      setIsLoadingGoogleDriveApi(true);
      gapi.client
          .init({
              apiKey: API_KEY,
              clientId: CLIENT_ID,
              discoveryDocs: DISCOVERY_DOCS,
              scope: SCOPES,
          })
          .then(
              function () {
                  // Listen for sign-in state changes.
                  gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);

                  // Handle the initial sign-in state.
                  updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
              },
              function (error) {
                  console.log(error);
              }
          );
  };

  const handleClientLoad = () => {
      gapi.load('client:auth2', initClient);
  };
/*************************************************************************************************/
    const logout = async (e) => {
        e.preventDefault();
        // console.log(user);
        let headers = '';
        try {
          const log = await AsyncStorage.getItem('@login');
          const log_json = JSON.parse(log);
          if(log_json !==null){
            headers = {
              'Authorization': log_json.token_type+' '+log_json.access_token,
              'Content-Type': 'application/json',
              'X-Requested-With': 'XMLHttpRequest'
            }
          }
                    
        } catch (error) {
          console.log(error);
        }
           
        MySwal.fire({
          title:  t("header.logout") + "?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: t("utilidades.yes"),
          cancelButtonText: 'No'
        }).then(async (result) => {
          if (result.isConfirmed) {
                await axios.get(urlApi+'auth/logout', {headers: headers})
                        .then(async (response) =>  { 
                          
                          console.log('correcto');
                        })
                        .catch(error => {
                          console.log(error);
                        })
                        .then(async () =>{
                          await AsyncStorage.removeItem(
                            '@login'
                            );
                          handleSignOutClick();
                          setUser('');
                          setExist(false);
                          seleccionarVista(false);
                          seleccionarUser({});
                          history.push('/')
                        });
            }//Result
          });//then
                        
    }
/*************************************************************************************************/

    return (
      <>
      {/* <Mantenimiento/> */}
      <div className={exist ? '' : 'd-none'}>
          <header className="header" id="header">
              <div className="header_toggle"> 
                  <i className='bx bx-menu' id="header-toggle"></i> 
              </div>
              
              <div className="dropdown">
                    {
                      exist &&
                      <>
                      <div className='d-flex'>
                        <MenuHeader
                            isMobile={isMobile}
                            setIsMobile={setIsMobile}
                            urlString={urlString}
                            seturlString={seturlString}
                        />
                          <div style={{marginLeft: '1rem'}}>
                            <a className="dropdown-toggle mb-0 btn btn-sm btn-dark w-100" data-bs-auto-close="outside" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                {
                                  user.name != undefined 
                                  ? user.name.length > 9
                                    ? `${user.name.toString().substring(0,9)}...`
                                    : user.name
                                  : <span>-</span>
                                }
                            </a>
                            <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                <li>
                                    <Link to="/about" className="dropdown-item"> 
                                      {t("home.about")}
                                      {/* Acerca de ... {t('Welcome to React')} */}
                                    </Link>
                                </li>
                                <li>
                                  <div className="dropdown-item dropend">
                                      <a className="dropdown-toggle  link-dark" href='#' id="dropdownMenuButton" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                          Language
                                      </a>
                                      <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                          <div className="dropdown">
                                          <a
                                              onClick={()=> i18n.changeLanguage('en')} 
                                              className="dropdown-item link-primary" href="#">
                                                  en-US
                                          </a>
                                          <a
                                              onClick={()=> i18n.changeLanguage('es')} 
                                              className="dropdown-item link-primary" href="#">
                                                  es-MX
                                          </a>
                                          </div>
                                      </div>
                                  </div>
                                </li>
                                <li>
                                    <Link to="/configuracion" className="dropdown-item"> 
                                      {t("header.configuration")}
                                      {/* Acerca de ... {t('Welcome to React')} */}
                                    </Link>
                                </li>
                                <li><hr className="dropdown-divider" /></li>
                                <li>
                                    <a className="dropdown-item" href="/#" onClick={(e) => logout(e)}> {t("header.logout")}</a>
                                </li>                            
                            </ul>
                          </div>
                        </div>

                      </>                                
                    }
              </div>
          </header>
        <div className="l-navbar" id="nav-bar">
            <nav className="nav">                                     
                    <div> 
                      <NavLink to="/" exact className="_nav_logo"> 
                        <img
                            id='nav_logo'
                            className='p-1 img-fluid bg-secondary rounded'
                            src={web2barcode}
                            alt='web2barcode'
                            width={100}
                        />
                      </NavLink>
                      <NavLink to="/" exact className="nav_logo"> 
                        <img
                            id='nav_logo'
                            className='img-fluid' 
                            src='https://labeldictate.com/assets/favicon.ico' 
                            alt='web2barcode'
                            width={28}
                        />
                      </NavLink>
                          <div className="nav_list menu-icons"> 
                                {
                                  user.role != 1 &&
								  <>
                                    <NavLink to="/see-labels"  
                                        className={
                                          downloadPlantilla 
                                          ? "nav_link mb-1 download-plantilla"
                                          : "nav_link mb-1"                                      
                                        }
                                        
                                      > 
                                        <div style={{position: 'relative'}}>
                                          <FontAwesomeIcon icon={faBarcode} className='nav_icon'/>  
                                            {
                                              downloadPlantilla &&
                                              <div style={{
                                                backgroundColor: 'red',
                                                color: 'white',
                                                borderRadius: '10px',
                                                position: 'absolute',
                                                top:-20,
                                                left:-20,
                                                textAlign: 'center',
                                                width: '25px'
                                              }}>
                                                <span>{downloadPlantillaCount}</span>
                                              </div>
                                            }
                                        </div>
                                        <span className="nav_name">{t("header.my_labels")}</span>
                                    </NavLink> 
                                    {/* <NavLink to="/design"  
                                        className="nav_link mb-1"                                    
                                    > 
                                        <div>											
                                            <FontAwesomeIcon icon={faMoneyBill} className='nav_icon'/>  
                                        </div>
                                        <span className="nav_name">Cotiza tu diseño</span>
                                    </NavLink>  */}

									
								  </>
                                }
                                 
                              {
                              vista &&
                                  <NavLink to="/catalogos"  className="nav_link mb-0"> 
                                      <div>                              
                                          <FontAwesomeIcon icon={faFileInvoice} className='nav_icon' />  
                                      </div>
                                      <span className="nav_name">{
                                      
                                      t("header.my_catalogs").length > 15
                                        ? t("header.my_catalogs").substring(0,15)+'...'
                                        : t("header.my_catalogs")
                                      }
                                      </span>
                                  </NavLink> 
                                }
                                  {/* <NavLink to="/usuarios/adm"  className="nav_link mb-0 d-none"> 
                                      <div>                              
                                          <FontAwesomeIcon icon={faUsersCog} className='nav_icon'/>  
                                      </div>
                                      <span className="nav_name">{
                                      
                                        t("header.my_users").length > 15
                                          ? t("header.my_users").substring(0,15)+'...'
                                          : t("header.my_users")
                                      }
                                      </span>
                                  </NavLink>  */}
                                  {/* <NavLink to="/configuracion"  className="nav_link mb-0 d-none"> 
                                      <div>                              
                                          <FontAwesomeIcon icon={faPrint} className='nav_icon'/>  
                                      </div>
                                      <span className="nav_name">{
                                      
                                      t("header.configuration").length > 15
                                        ? t("header.configuration").substring(0,15)+'...'
                                        : t("header.configuration")
                                      }
                                      </span>
                                  </NavLink>  */}
                                  {/* <div className='border-bottom border-secondary mx-auto my-1 d-none'></div>
                                  <NavLink to="/dispositivos"  className="nav_link mb-1 d-none"> 
                                      <div>  
                                          <FontAwesomeIcon icon={faMobileAlt} className='nav_icon'/>  
                                      </div>
                                      <span className="nav_name">SMP</span>
                                  </NavLink> */}
                                  {/* <NavLink to="/suscripciones"  className="nav_link mb-1 d-none"> 
                                      <div> 
                                          <FontAwesomeIcon icon={faCreditCard} className='nav_icon'/>  
                                      </div>
                                      <span className="nav_name">{
                                      
                                      t("header.suscription").length > 15
                                        ? t("header.suscription").substring(0,15)+'...'
                                        : t("header.suscription")
                                      }
                                      </span>
                                  </NavLink>  */}
                                  <NavLink to="/design"  
                                        className="nav_link mb-1"                                    
                                > 
                                    <div>											
                                        <FontAwesomeIcon icon={faMoneyBill} className='nav_icon'/>  
                                    </div>
                                    <span className="nav_name">Cotiza tu diseño</span>
                                </NavLink>
                                {
                                vista &&
                                <>
                                  <div className='border-bottom border-secondary mx-auto my-1'></div>
                                  {
                                    (userAgent != 'Mozilla/5.0 AppleWebKit/535.19 Chrome/56.0.0 Mobile Safari/535.19' && userAgent != 'Dalvik/2.1.0') &&
                                    <>
                                      <a href="https://payment.labeldictate.com" target="_blank"  className="nav_link mb-1" > 
                                          <div> 
                                              <FontAwesomeIcon icon={faSlidersH} className='nav_icon'/>  
                                          </div>
                                          <span className="nav_name">{
                                          
                                          t("header.aplications").length > 15
                                            ? t("header.aplications").substring(0,15)+'...'
                                            : t("header.aplications")
                                          }
                                          </span>
                                      </a> 
                                      <a href="https://labeldictate.com/text2barcode" target="_blank" className="nav_link mb-1" > 
                                          <div> 
                                              <FontAwesomeIcon icon={faTicketAlt} className='nav_icon'/>  
                                          </div>
                                          <span className="nav_name">Text2 Barcode</span>
                                      </a> 
                                    
                                    </>
                                  }
                                </>
                              }
                              {/* Usuario administrador  */}
                              {
                                user.role == 1 &&
                                <>
                                  <div className='border-bottom border-secondary mx-auto my-1'></div>
                                  <NavLink to="/usuarios"  className="nav_link mb-1"> 
                                      <div> 
                                          <FontAwesomeIcon icon={faUsers} className='nav_icon'/>  
                                      </div>
                                      <span className="nav_name">Usuarios</span>
                                  </NavLink> 
                                  <NavLink to="/languages/replaces"  className="nav_link mb-1">
                                      <div>  
                                          <FontAwesomeIcon icon={faCode} className='nav_icon'/>  
                                      </div>
                                      <span className="nav_name">Lenguajes</span>
                                  </NavLink> 
                                  <NavLink to="/preformatos"  className="nav_link mb-1">
                                      <div>  
                                          <FontAwesomeIcon icon={faGripLines} className='nav_icon'/>  
                                      </div>
                                      <span className="nav_name">Formatos Web</span>
                                  </NavLink> 
                                  <NavLink to="/precatalogos"  className="nav_link mb-1">
                                      <div>  
                                          <FontAwesomeIcon icon={faThList} className='nav_icon'/>  
                                      </div>
                                      <span className="nav_name">Catalogos Web</span>
                                  </NavLink> 
                                  <NavLink to="/reportes"  className="nav_link mb-1">
                                      <div>  
                                          <FontAwesomeIcon icon={faFileSignature} className='nav_icon'/>  
                                      </div>
                                      <span className="nav_name">Reportes</span>
                                  </NavLink> 
                                </>
                              }
                              {/* Usuario administrador  */}
                          </div>
                    </div> 
                
                <div className='text-white p-1 m-2'  id='w2bcv'>
                     <p className='mb-0'>Web2 Barcode </p>
                     <b>v{ version }</b>

                      <p className="text-muted m-0">
                        <a href="https://labeldictate.com/?c=privacy" target="_blank" className="link-secondary">{t("home.privacy")}</a> 
                      </p>
                </div>

            </nav>
        </div>      

          {            
            /* <!-- Modal --> */
            exist &&
            <Modal 
              urlString={urlString}
            />                      
          }

      </div>
      </>
    )
}

export default Header

import React, {useState, useRef, Fragment, useEffect} from 'react'
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import * as Yup from 'yup';
import axios from 'axios';
/*Globales*/
import {urlApi} from '../../global/Global';
import AsyncStorage from '@react-native-async-storage/async-storage';
import { useHistory } from "react-router-dom";
/**/
import ComprobarVista from '../vistaUsuarioContext/ComprobarVista';
import { t } from 'i18next';

const CreatePrecatalogo = () => {

    let history = useHistory();

    const [csv, setcsv]                       = useState('');
    const [extension, setextension]           = useState('');
    const [filename, setfilename]             = useState('');
    const [pasos, setpasos]                   = useState(0);
    const [nombre, setnombre]                 = useState('');
    const [cabeceras, setcabeceras]           = useState([]);
    const [changeCabecera, setchangeCabecera] = useState([]);
    const [campos, setcampos]                 = useState([]);
    const [_lineHead, _setlineHead]           = useState(true);

    const [nombreuuid, setnombreuuid]   = useState('');
    const [columnas, setcolumnas]       = useState();
    const [importacion, setimportacion] = useState('local');
    const [drive, setDrive]             = useState('');

    const MySwal = withReactContent(Swal);
    // const [arreglo, setArreglo] = useState(['-x-', 'DESCRIPCION', 'PRECIO', 'CANTIDAD', 'CODIGO', 'CODIGO_INT', 'OFERTA', 'UNIDAD', 'UBICACION', 'OBSERVACION']);
    const [arreglo, setArreglo] = useState(['DESCRIPCION', 'PRECIO', 'CANTIDAD', 'CODIGO', 'CODIGO_INT', 'OFERTA', 'UNIDAD', 'UBICACION', 'OBSERVACION']);
    // const [arreglo, setArreglo] = useState(['', 'DESCRIPCION', 'PRECIO', 'CANTIDAD', 'CODIGO', 'CODIGO_INT', 'OFERTA', 'UNIDAD', 'UBICACION', 'OBSERVACION']);
    const [respaldo, setRespaldo] = useState([]);
    // const [respaldo, setRespaldo] = useState(['DESCRIPCION', 'PRECIO', 'CANTIDAD', 'CODIGO', 'CODIGO_INT', 'OFERTA', 'UNIDAD', 'UBICACION', 'OBSERVACION']);
    const handlecsv = (e) => {
        setcsv(e.target.files[0]);
        const extension= e.target.files[0].name.substring(e.target.files[0].name.lastIndexOf('.')+1, e.target.files[0].name.length) || e.target.files[0].name;
        setextension(extension);
        const name= e.target.files[0].name.substring(0, e.target.files[0].name.indexOf('.'));
        setfilename(name);
    }
    let cabecerasNoreal = new Array();
    const itemsRef = useRef([]);
    const [loading, setloading] = useState(false);

/******************************************************************************************************************************/

    useEffect(() => {
    }, [])


/*******************************************************************************/
    useEffect(() => {
        if(csv != ''){
            comprobarCatalogo();
        }
    }, [csv])

/*******************************************************************************/
    const comprobarCatalogo = async (params) => {
        console.log(csv);
        console.log(extension);
        console.log(filename);
        // return;
        if (csv==='') {
            document.getElementById("formFileSm").value = "";
            setcsv('');
            setextension('');
            setfilename('');
            MySwal.fire({
                icon: 'info',
                title: 'Debe subir un archivo csv o xls',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                toast.addEventListener('mouseenter', MySwal.stopTimer)
                toast.addEventListener('mouseleave', MySwal.resumeTimer)
                }
            })
            return;
        }
        setloading(true);
        setTimeout(() => {}, 400);
        
        let formData = new FormData();
        formData.append('archivo', csv);
        formData.append('datas', 'local');
        const options = {
            onUploadProgress: (progressEvent) => {
                const {loaded, total} = progressEvent;
                let percent = Math.floor( (loaded*100) / total);
                console.log( `${loaded}kb of ${total}kb | ${percent}%` );
            }
        }
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
        // await axios.post(urlApi+'catalogo/comprobar', formData, options, {headers: headers})
        await axios.post(urlApi+'catalogo/comprobar', formData, {headers: headers})
          .then((response) => {
                // }//Si no hay configuraion previa traemos los datos
                if(response.data.respuesta ==='correcto'){
                // else if(response.data.respuesta ==='correcto'){
                    
                    setnombre(response.data.nombre);
                    setcabeceras(response.data.cabeceras);
                    setchangeCabecera(new Array(response.data.cabeceras.length));
                    setcampos(response.data.campos);
                    setnombreuuid(response.data.nombreuuid);
                    setcolumnas(response.data.columnas);
                    console.log(response.data.campos);
                    itemsRef.current = itemsRef.current.slice(0, response.data.cabeceras.length);
                    setpasos(1);
                    setloading(false);
                    
                }else if(response.data.respuesta ==='extension'){
                    MySwal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: 'Extensión no valida',
                    });
                    setloading(false);
                }else{
                    MySwal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: response.data.mensaje,
                    });
                    setloading(false);
                }
                console.log(response.data);
            })
            .catch((error) => {
                MySwal.fire({
                    icon: 'error',
                    title: 'Error!',
                    text: error,
                  });
                  setloading(false);
                console.log(error);
                setloading(false);
            });
    }
/*******************************************************************************/

    const handleClickArreglo = (params, j, i) => {
        console.log(params.target.textContent);
        console.log(j);
        console.log(i);
        console.log(changeCabecera);
        let x = changeCabecera;
        // itemsRef.current[i].value = params.target.textContent;
        
        const result = changeCabecera.some(element => element == params.target.textContent)
        // console.log(result);
        if(!result){
            setRespaldo(respaldo =>(
                 [...respaldo, params.target.textContent]
            ));
        }else{

            console.log('else');
            // x = changeCabecera.filter(element => element != params.target.textContent)
            x = changeCabecera.map(element => {
                    return element == params.target.textContent
                    ? ''
                    : element
                })
            setchangeCabecera(x);

        }
        // console.log(changeCabecera[i]+' <=> '+ params.target.textContent)
        if(changeCabecera[i] == params.target.textContent){
            x[i] = '';
        }else{
            x[i] = params.target.textContent;
        }
        setchangeCabecera(x);
        // console.log(changeCabecera);
        // console.log(respaldo);
    }    

/*******************************************************************************/

    const enviarCatalogo = async (params) => {
        setloading(true);
        console.log(params);
        // console.log(itemsRef.current[1].value);
       
        // itemsRef.current.forEach(element => {
        //     cabecerasNoreal.push(element.value);
        // });
                
        let encabezado = 0;
        if(_lineHead){
            encabezado = 1;
        }
        const datos = {
            catalogo         : params.nombre,
            cabeceras        : cabeceras,
            // cabeceras_no_real: cabecerasNoreal,
            cabeceras_no_real: changeCabecera,
            csv              : nombreuuid,
            encabezado       : encabezado,
            columnas         : columnas,
            importacion      : importacion,
            drive_id         : drive,
            catalogo_web     : 0
        }
        console.log(cabeceras);
        console.log(cabecerasNoreal);
        console.log(changeCabecera);
        console.log(csv);
        console.log(datos);
        // return;
        // return;
        const options = {
            onUploadProgress: (progressEvent) => {
                const {loaded, total} = progressEvent;
                let percent = Math.floor( (loaded*100) / total);
                console.log( `${loaded}kb of ${total}kb | ${percent}%` );
            }
        }

        console.log(datos);
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
        // await axios.post(urlApi+'catalogo/store', datos, options, {headers: headers})
        await axios.post(urlApi+'catalogo/store', datos, {headers: headers})
                .then((response) => {
                    console.log(response.data);
                    if(response.data.respuesta === 'correcto'){

                        MySwal.fire({
                            icon: 'success',
                            title: 'Guardado correctamente',
                            toast: true,
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                            didOpen: (toast) => {
                                toast.addEventListener('mouseenter', Swal.stopTimer)
                                toast.addEventListener('mouseleave', Swal.resumeTimer)
                            }
                        });
                        setcsv('');
                        setextension('');
                        setfilename('');
                        setpasos(0);
                        setloading(false);
                        history.push("/precatalogos");

                    }
                }).catch(error =>{
                        console.log(error)
                }).then(() =>{
                    cabecerasNoreal = [];
                    // setchangeCabecera(new Array(cabeceras.length));
                    // setloading(false);
                });
    }
    

/*******************************************************************************/

    const lineHead = () => {
        _setlineHead(!_lineHead);
    }


/*******************************************************************************/

    //Validador de datos
    const formSchema = Yup.object().shape({
        
    });


/*******************************************************************************/
    return (
    
        <div className="row justify-content-center animate__animated animate__fadeIn">
            <div className="col-md-12">
                {
                    pasos === 0 &&
                    <>
                        <div className="card">
                            <div className="card-header bg-card-header">
                                <span className='fw-bold text-white'>{t("import.t_import")}</span>
                            </div>
                            <div className="card-body">
                                {
                                    <>                    
                                        <Formik
                                            initialValues={{
                                            }}
                                            onSubmit={(values, actions) => comprobarCatalogo(values, actions)}
                                        >
                                            <Form>
                                                <div className="row">
                                                    {/* <label htmlFor="formFile" className="form-label">Selecciona un catalogo desde tu dispositivo en formato <b>CSV </b>o<b> XLS</b></label> */}
                                                    <label htmlFor="formFile" className="form-label" dangerouslySetInnerHTML={{__html: t('import.select_file')}}></label>
                                                    <div className='files2'>
                                                        <div className="mb-3 files">
                                                            <input disabled={loading} className="form-control" id="formFileSm" type="file" name="csv" onChange={handlecsv} accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel" required/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-6 d-none">
                                                        <button type="submit" className="btn btn-dark " disabled={loading}>Guardar y enviar</button>                                                
                                                    </div>
                                                    {
                                                        loading &&
                                                        <div className="col-md-12 text-center">
                                                            <div className="spinner-border text-primary" role="status">
                                                                <span className="visually-hidden">Loading...</span>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </Form>
                                        </Formik>                                    
                                    </>
                                }
                                                                
                            </div>
                        </div>
                        
                    </>
                }

                {
                    pasos === 1 &&                
                    <div className="card animate__animated animate__fadeIn">
                        <div className="card-header bg-dark">
                            <span className=" text-white">{t("import.title_import")}</span>
                        </div>
                        <div className="card-body">
                                            
                            <div className="row">
                            <Formik
                                enableReinitialize
                                initialValues={{
                                    nombre: nombre
                                }}
                                validationSchema={formSchema}
                                onSubmit={(values, actions) => enviarCatalogo(values, actions)}
                            >
                                <Form>
                                    <div className="row">
                                        <div className="row mb-3 align-items-center">
                                            <div className="col-md-4">
                                                <div className="col-sm-10">
                                                <label htmlFor="nombre" className="col-sm-12 col-form-label">{t("login.login_2.name")}</label>
                                                    <Field type="text" className="form-control" name="nombre" id="nombre" disabled={loading}/>
                                                    <ErrorMessage
                                                        name='nombre'
                                                        component='div'
                                                        className='field-error text-danger'
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <label htmlFor="nombre" className="col-sm-12 col-form-label">&nbsp;</label>
                                                <div className="form-check form-switch">
                                                    <input 
                                                        className="form-check-input" 
                                                        type="checkbox" 
                                                        id="flexSwitchCheckChecked" 
                                                        checked={_lineHead}
                                                        onChange={lineHead}
                                                        disabled={loading}
                                                    />
                                                    <label className="form-check-label" htmlFor="flexSwitchCheckChecked">{t("import.use_header")}</label>
                                                </div>
                                            </div>
                                            <div className="col-md-4 text-end">
                                                <label htmlFor="nombre" className="col-sm-12 col-form-label">&nbsp;</label>
                                                <button 
                                                    type="submit" 
                                                    className="btn btn-dark "
                                                    disabled={loading}
                                                >
                                                    
                                                   <span>
                                                        {t("import.save_template")}   
                                                    </span> 
                                                </button>
                                            </div>
                                        </div>                                            
                                    </div>     
                                    {
                                        loading &&
                                        <div className="row">
                                            <div className="col-md-12 text-center">
                                                <div className="spinner-border text-primary" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                            {/* <p className='mb-0 fw-bold'>{t("catalogs.header")}s</p> */}
                                    <div className="row mb-3">
                                        <div className="col-md-12 ">
                                            <div className='table-responsive border border-secondary border-3 rounded rounded-3 p-0'>                                            
                                                <table className="table table-striped table-bordered mb-0">
                                                    <thead>
                                                        <tr>
                                                            <td className='text-center headers-inputs fw-bold' colSpan={cabeceras.length+1}>
                                                                <span>COLUMNS</span>
                                                            </td>
                                                        </tr>
                                                        <tr className='bb-3'>
                                                            {
                                                                columnas.length > 0 &&
                                                                columnas.map((e,i)=>{
                                                                    return <th className='headers-inputs' scope="col" key={"columna-"+i}>
                                                                            <span 
                                                                                className="headerss-inputs"
                                                                            >{e}</span>
                                                                    </th>
                                                                })
                                                            }
                                                        </tr>
 
                                                        <tr className='d-none'>
                                                            <th colSpan={cabeceras.length+1} className="text-start">
                                                                <span>{t("catalogs.select_header")}</span>
                                                            </th>
                                                        </tr >
                                                        <tr className='d-none'>
                                                            {   
                                                                cabeceras.map((e,i) =>{
                                                                return <th scope="col" key={"cabecera-"+i} style={{background: '#47b805'}}>
                                                                            <div className="dropdown">
                                                                                <a 
                                                                                    className="dropdown-toggle link-dark" 
                                                                                    href="#" 
                                                                                    role="button" 
                                                                                    id="dropdownMenuLink" 
                                                                                    data-bs-toggle="dropdown" 
                                                                                    aria-expanded="false"
                                                                                >
                                                                                    <span className='m-0'>{changeCabecera[i]}</span>
                                                                                </a>

                                                                                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                                                    
                                                                                    {
                                                                                        arreglo.map((em,j) =>{
                                                                                            return <Fragment key={"sess-"+j}>
                                                                                                {
                                                                                                    em != '' &&                                                                                                            
                                                                                                    <li>
                                                                                                        
                                                                                                        <a 
                                                                                                            className="dropdown-item link-dark" 
                                                                                                            href="#"
                                                                                                            onClick={(e) => handleClickArreglo(e, j, i)}
                                                                                                        >
                                                                                                            { 
                                                                                                            changeCabecera.filter(item => item == em).length == 0 &&
                                                                                                                <p className='text-dark fw-bold m-0'>{em}</p>
                                                                                                            }
                                                                                                            { 
                                                                                                            changeCabecera.filter(item => item == em).length >= 1 &&
                                                                                                                <p className='text-danger fw-bold m-0'>{em}</p>
                                                                                                            }
                                                                                                            {/* { em } */}
                                                                                                        </a>
                                                                                                    </li>
                                                                                                }                                                                                                                                                                                                        
                                                                                                </Fragment>
                                                                                        })
                                                                                    }

                                                                                </ul>
                                                                            </div>                                                                                                                                            
                                                                        </th>
                                                                })
                                                            }
                                                        
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {   
    
                                                            <tr>
                                                                {
                                                                _lineHead 
                                                                ? cabeceras.map((e,i) =>{
                                                                    return <td key={"_cabecera-"+i}>
                                                                                {e}
                                                                        </td>
                                                                })
                                                                : cabeceras.map((e,i) =>{
                                                                    return <td key={"_cabecera_-"+i}>
                                                                                --- &nbsp;
                                                                        </td>
                                                                })
                                                                }
                                                            </tr>
                                                            
                                                        }
                                                        {
                                                            

                                                            campos.map((e,i) =>{
                                                                return <tr key={"campos"+i}>
                                                                    {
                                                                        <>
                                                                        {
                                                                            e.map((en,id) =>{
                                                                                return <td key={"entei-"+id}>{en}</td> 
                                                                            })
                                        
                                                                        }
                                                                        </>
                                                                    }
                                                                    </tr>  
                                                            })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>                                  
                                </Form>
                            </Formik>
                            </div>

                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default CreatePrecatalogo

import React, {useState, useRef, useEffect} from 'react';
import { Formik, Field, Form, ErrorMessage, FieldArray } from 'formik';
import * as Yup from 'yup';
import TextareaAutosize from 'react-textarea-autosize';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import axios from 'axios';
/*Globales*/
import {urlApi} from '../../global/Global';
import AsyncStorage from '@react-native-async-storage/async-storage';

const ImportarZpl = () => {

    const MySwal                = withReactContent(Swal);
    const [archivo, setarchivo] = useState('');
    const [text, settext]       = useState('');
    const [paso, setpaso]       = useState(1);

    const arch = useRef();

    const [nombre, setnombre]       = useState('');
    const [languages, setLanguages] = useState([]);
    const [loading, setloading]     = useState(false);

    const [arr, setarr] = useState([]);
    

/****************************************************************************************** */

    useEffect(() => {

        const getLanguages = async (params) => {
            const log = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            }
            await axios.get(urlApi+'languages/index', {headers: headers})
                        .then((response) => {
                            //Actualiza estados del formulario
                            setLanguages(response.data);
                            // setLoading(false);
                            console.log(response.data);
                            console.log(response.data);
                        })
                        .catch((error) => {
                            console.log(error);
                        });     
        }
        
        getLanguages();
        
    }, [])

    useEffect(() => {
        
        if(archivo != ''){
            setloading(true);
            setTimeout(() => {
                comprobarArchivo();
            }, 400);        
        }
        
    }, [archivo])

/****************************************************************************************** */

    const comprobarArchivo = async() => {
                                    
        setTimeout(() => {}, 400);                
            if(archivo == ''){
                arch.current.classList.remove('d-none');
                return;
            }
            else{
                if(!arch.current.classList.contains('d-none')){
                    arch.current.classList.add('d-none');
                }
            }
            const reader = new FileReader();

            reader.onload = async (e) => { 
                const _text = (e.target.result)
                settext(_text)
                setnombre(archivo.name)
                setpaso(2);
            };
            reader.readAsText(archivo);
            setloading(false);
    }

/****************************************************************************************** */

    const enviarEtiqueta = async (params) => {

        console.log(params);
        // return;
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
        await axios({
            method: 'post',
            url: urlApi+'etiquetas/store',
            data: params,
            headers: headers
          }).then((response) => {
              if(response.data.respuesta === 'correcto'){  

                MySwal.fire({
                    icon: 'success',
                    title: 'Correcto',
                    text: 'Se creo la etiqueta correctamente',
                });

                setpaso(1);
              }
              else{
                MySwal.fire({
                    icon: 'error',
                    title: 'error',
                    text: 'Error al crear etiqueta',
                })
              }
                // console.log(response.data)
            }).catch(error => { 
                MySwal.fire({
                    icon: 'error',
                    title: 'error',
                    text: 'Error al crear etiqueta',
                })           
                console.log(error)
          });


    }
/*************************************************************************************************/
    //Validador de datos
    const formSchema = Yup.object().shape({
        nombre: Yup.string()
            .required('Campo requerido'),
        etiqueta: Yup.string()
            .required('Campo requerido'),
        language: Yup.string()
            .required('Campo requerido'),
    });
/*************************************************************************************************/
    return (
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="card">
                <div className="card-header bg-card-header">
                    <span className="fw-bold text-white">Importar archivo (plantillas zpl / txt / prn)</span>
                </div>
                <div className="card-body">
                    {
                        paso === 1 &&
                        <form className="mb-3">
                            <div className="row">
                                <div className="mb-3 files">
                                    <label htmlFor="formFile" className="form-label fw-bold">Cargar archivo</label>
                                    <input disabled={loading} required className="form-control form-control-sm" accept=".zpl, .txt, .prn" id="formFileSm" type="file" name="image" onChange={(e) => setarchivo(e.target.files[0])} />
                                    <div className='field-error text-danger d-none' ref={arch}>
                                        <span>¡Cargue un archivo!</span>
                                    </div>
                                </div>
                            </div>
                            <div className="d-flex align-items-center d-none">
                                <button 
                                    // ref={btn}
                                    disabled={loading} 
                                    className="btn btn-dark "
                                    type="button" 
                                    style={{marginRight: '1rem'}}
                                >Cargar
                                </button>
                            </div>
                            {
                                loading &&
                                <div className='row'>
                                    <div className="col-md-12 text-center">
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            }
                        </form>
                    }

                    {
                        paso === 2 &&
                        <>                        
                            <Formik
                                enableReinitialize
                                initialValues={{
                                    nombre: nombre,
                                    etiqueta : text,
                                    language : '1',
                                    lista: arr
                                }}
                                validationSchema={formSchema}
                                onSubmit={(values, actions) => enviarEtiqueta(values, actions)}
                                render={({ values, errors, touched, handleReset }) => {
                                return (
                                <Form>
                                    <div className="row">
                                        <div className="col-md-8 mb-3">
                                            <div className="row mb-2">
                                                <div className="col-md-12">
                                                    <button className="btn btn-success col-md-12 text-uppercase fw-bold" type="submit">Enviar etiqueta</button>                                        
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-md-6">
                                                    <label className="form-label fw-bold">Nombre Etiqueta</label>
                                                    {/* <input onChange={(e) => setnombre(e.target.value)} type="text" name="nombre" className="form-control" aria-describedby="emailHelp" value={nombre}/> */}
                                                    <Field type="text" name="nombre" className="form-control" aria-describedby="emailHelp" />
                                                    <ErrorMessage
                                                        name='nombre'
                                                        component='div'
                                                        className='field-error text-danger'
                                                    />
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="language" className="form-label fw-bold">Lenguaje</label>
                                                    <Field 
                                                        as="select"
                                                        className="form-select" 
                                                        name='language'
                                                    >

                                                    {
                                                        languages!=undefined &&
                                                        languages.map((element,index) => {
                                                                return <option
                                                                            value={element.id} 
                                                                            key={index}                                                                    
                                                                            >{element.name}
                                                                        </option>
                                                        })
                                                    }

                                                    </Field>
                                                    <ErrorMessage
                                                        name='language'
                                                        component='div'
                                                        className='field-error text-danger'
                                                    />
                                                </div>
                                            </div>
                                            
                                            <div className="form-floating">
                                                <p className="fw-bold">Etiqueta</p>
                                                <Field as="textarea" className="form-control" name="etiqueta" id="etiqueta" style={{height: '400px'}}/>
                                                <ErrorMessage
                                                    name='etiqueta'
                                                    component='div'
                                                    className='field-error text-danger'
                                                />
                                            </div>
                                        </div>

                                        <div className="col-md-4 border-left-top">                                    
                                            <FieldArray
                                                name="lista"
                                                render={({insert, remove, push}) => ( 
                                                <>
                                                    <div className="row p-1 mb-1">
                                                            
                                                        <div className="col-md-9">
                                                            <label className="form-label fw-bold">Configuraciones</label>
                                                        </div>
                                                        <div className="col-md-3">
                                                            <button
                                                                type="button"
                                                                className="btn btn-sm btn-primary form-control-plaintext"
                                                                onClick={() => push({ variable: "", configuracion: "1", valor: "" })}
                                                                disabled={loading}
                                                            >+</button>
                                                        </div>
                                                    </div>
                                                    <div className="p-2 border rounded text-muted " style={{backgroundColor: 'whitesmoke'}}>
                                                    {values.lista.map((element, index) => (
                                                        <div className='row' key={index}>
                        
                                                            <div className='col-md-12'>
                                                                <label className="form-label fw-bold">Variable</label>
                                                                <Field className='form-control form-control-sm' 
                                                                    placeholder='variable' 
                                                                    name={`lista.${index}.variable`} 
                                                                    disabled={loading}
                                                                />
                                                                <ErrorMessage
                                                                    name={`lista.${index}.variable`}
                                                                    component='div'
                                                                    className='field-error text-danger'
                                                                /> 

                                                                <label className="form-label fw-bold">Configuración</label>
                                                                <Field 
                                                                    as="select"
                                                                    className='form-select form-select-sm'
                                                                    disabled={loading}
                                                                    name={`lista.${index}.configuracion`} 
                                                                >
                                                                    <option value={"1"}>Caracteres</option>
                                                                    <option value={"2"}>Pregunta</option>
                                                                    <option value={"3"}>Año</option>
                                                                    <option value={"4"}>Mes</option>
                                                                    <option value={"5"}>Día</option>
                                                                </Field>

                                                                <label className="form-label fw-bold">Valor</label>
                                                                <Field className='form-control form-control-sm' 
                                                                    placeholder='valor'
                                                                    name={`lista.${index}.valor`} 
                                                                    disabled={loading}
                                                                    
                                                                />
                                                            </div>
                                                            <div className='col-md-12 mt-2'>
                                                                <button className="btn btn-sm btn-danger fw-bold w-25" 
                                                                        type="button" 
                                                                        onClick={() => remove(index)}
                                                                        disabled={loading}
                                                                >-</button>
                                                            </div>
                                                            
                                                            <div className='col-md-12'>
                                                                <hr/>
                                                            </div>

                                                        </div>
                                                    ))}
                                                    </div>
                                                </>
                                                )}
                                            />
                                        </div>
                                        {/* End fielarray */}
                                    </div>
                                </Form>
                                ); 
                                }}
                            />
                        </>
                    }
                </div>
            </div>
          </div>
      </div>
    )
}

export default ImportarZpl
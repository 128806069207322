import React, {useEffect, useState, useRef, Fragment} from 'react'
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
/*Globales*/
import {urlApi} from '../../global/Global';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faGlobeAmericas, faMinus, faTrash, faPlus, faClone, faCog, faEdit, faPrint, faListOl } from '@fortawesome/free-solid-svg-icons';
import {
    Link, NavLink
  } from "react-router-dom";
import AsyncStorage from '@react-native-async-storage/async-storage';
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import ReactTooltip from 'react-tooltip';
import ModalCatalogos from '../etiquetas/ModalCatalogos';


const VerPreformatos = ({user}) => {

    const MySwal                      = withReactContent(Swal);
    const [loading, setLoading]       = useState(false);
    const [ftps, setftps]             = useState();
    const [url, seturl]               = useState('');
    const [catalogos, setcatalogos]   = useState([]);
    const [impresoras, setimpresoras] = useState([]);
    const [code, setCode]             = useState('');

    const [eti, seteti] = useState();
    const btn_close     = useRef();
    const btn_ref       = useRef();
    const print_ref     = useRef();

    const [_btn_ref, _set_btn_ref] = useState(false);

    useEffect(() => {          
        const getFtp = async (params) => {
            const log = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            }
            await axios.get(urlApi+'etiquetas/connect-ftp-admin', {headers: headers})
                        .then((response) => {
                            //Actualiza estados del formulario
                            // setLoading(false);
                            setftps(response.data.etiquetas);
                            seturl(response.data.url);
                            console.log(response.data);
                        })
                        .catch((error) => {
                            console.log(error);
                        });     
        }

        const getCatalogos = async (params) => {
            const log = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            }
            await axios.get(urlApi+'catalogo/precatalogos', {headers: headers})
                .then((response) => {
                    //Actualiza estados del formulario
                    setcatalogos(response.data);
                    // setLoading(false);
                    console.log(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });  
        }

        const getImpresoras = async () => {

            try {
                const log      = await AsyncStorage.getItem('@login');
                const log_json = JSON.parse(log);
                const x = await axios.get('http://localhost:'+log_json.puerto+'/available');
                setimpresoras(x.data.printer)
                // return x;
            } catch (error) {
                console.log(error);
                return false;
            }
        
        }
        
        getFtp();
        getCatalogos();
        getImpresoras();

    }, []); // Use effect

/***********************************************************************************************/

    const deletePreformato = async (params) => {
        console.log(params)
        // return;
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
        Swal.fire({
            title: '¿Eliminar plantilla / formato web?',
            text: "Esta acción no se puede revertir",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si, borrar',
            cancelButtonText: 'Cancelar'
        }).then(async (result) => {
            if (result.isConfirmed) {
                await axios({
                    method: 'post',
                    url: urlApi+'etiquetas/delete',
                    data: params,
                    headers: headers
                }).then((response) => {
                    if(response.data.respuesta === 'correcto'){
                        MySwal.fire({
                            icon: 'success',
                            title: 'Correcto',
                            text: 'Se elimino el formato / plantilla',
                        })
                        setftps(response.data.etiquetas);
                    }else{
                        MySwal.fire({
                            icon: 'error',
                            title: 'error',
                            text: 'Ocurrio un error en la eliminacion',
                        })
                    }
                        // console.log(response.data)
                    }).catch(error => { 
                        MySwal.fire({
                            icon: 'error',
                            title: 'error',
                            text: 'Ocurrio un error en la eliminacion',
                        })           
                        console.log(error);
                });
            }
        })
    }

/***********************************************************************************************/

    const asignarCatalogo = async (e, catalogo) => { 
        console.log(e);

        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }

        const send_data = {
            etiqueta : eti.id,
            catalogo : catalogo,
            cabeceras: e
        };

        await axios.post(urlApi+'etiquetas/asignar-catalogo', send_data, {headers: headers})
            .then((response) => {
                //Actualiza estados del formulario
                if(response.data.respuesta == 'correcto'){
                    setftps(response.data.etiquetas)
                    Swal.fire({
                        icon: 'success',                            
                        title: response.data.mensaje,
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000
                    })                        
                    console.log(response.data);
                }else{
                    Swal.fire({
                        icon: 'error',
                        title: response.data.respuesta,
                        text: response.data.mensaje
                    })
                }
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: error
                })
                console.log(error);
            });  

    }

/***********************************************************************************************//***********************************************************************************************/
    const quitarCatalogo = async (e) => { 
        console.log(e);

        Swal.fire({
           title: '¿Quitar catalogo?',
           text: "Esto no elimina el catalogo, pero si lo desvincula de la etiqueta",
           icon: 'info',
           showCancelButton: true,
           confirmButtonColor: '#3085d6',
           cancelButtonColor: '#d33',
           confirmButtonText: 'Si, quitar',
           cancelButtonText: 'Cancelar'
         }).then(async (result) => {
           if (result.isConfirmed) {

               const log = await AsyncStorage.getItem('@login');
               const log_json = JSON.parse(log);
               const headers = {
                   'Authorization': log_json.token_type+' '+log_json.access_token,
                   'Content-Type': 'application/json',
                   'X-Requested-With': 'XMLHttpRequest',
               }

               const send_data = {
                   etiqueta: e,
               };

               await axios.post(urlApi+'etiquetas/quitar-catalogo', send_data, {headers: headers})
                   .then((response) => {
                       //Actualiza estados del formulario
                       if(response.data.respuesta == 'correcto'){
                           setftps(response.data.etiquetas)
                           Swal.fire({
                               icon: 'success',
                               title: response.data.mensaje,
                               toast: true,
                               position: 'top-end',
                               showConfirmButton: false,
                               timer: 3000
                           })                        
                           console.log(response.data);
                       }else{
                           Swal.fire({
                               icon: 'error',
                               title: response.data.respuesta,
                               text: response.data.mensaje,
                               
                           })
                       }
                       console.log(response.data);
                   })
                   .catch((error) => {
                       Swal.fire({
                           icon: 'error',
                           title: error
                       })
                       console.log(error);
                   });  

               btn_close.current.click();
           }
         })

   }

    const mostrarCatalogos = (e) => { 
        console.log(e);
        seteti(e);
        // btn_ref.current.click();
        _set_btn_ref(true);
    }

/***************************************************************************************************/
    const impresora = async () => { 
        console.log(print_ref.current);
    }

//Imprimir etiquetas********************************************************************************//
    const imprimirEtiquetas = async (etiqueta) => {
        console.log(print_ref.current.value)
        // return;
        setLoading(true);
        let lista   = '';
        let conteo  = 0;
        let pattern = 'pattern';
        // console.log(x);
        console.log(etiqueta);
        // return;
        etiqueta.configuraciones.map(async (e,i) => {
            console.log(e);
            // if(e.type_config_id == 2){
                if(e.types.front_back == 'front'){
                    if(e.type_config_id == 4){
                        pattern='pattern="[0-9]*" inputmode="numeric"';
                    }else{
                        pattern = 'pattern=""'
                    }
                        lista+=`
                            <div class="col-md-12 mb-2">
                                <label class="fw-bold mb-1">${e.valor}</label>
                                <input ${pattern} type="${e.types.type}" name="${e.variable}" required type="text" class="form-control text-center blc-fl" id="swal-input${i}" placeholder="${e.variable}">
                            </div>`;
                conteo++;
                }
        });
        // return;
        let jwal = '';
        let preguntas = {};
        if(conteo > 0){
        jwal = await MySwal.fire({
            html: `<div class="row">
            <div class="col">
                <span class="errores text-danger text-uppercase fw-bold mb-3 d-none">
                *Llene los campos
                </span>
            </div>  
            </div>  
            <div class="row">
            ${lista}
            </div>`,
            allowOutsideClick: true,
            // showCancelButton: true,        
            confirmButtonText: 'Imprimir',       
            showCloseButton: true,
            preConfirm: () => {

            const x = Array.from(document.querySelectorAll('.blc-fl'));
            x.forEach(e =>{
                if(e.value.trim() == ''){
                    if(document.querySelector('.errores').classList.contains('d-none')){
                        document.querySelector('.errores').classList.remove('d-none')
                    }
                    MySwal.showValidationMessage('Llene los campos')
                    // return false;
                }
            })
            return x;
            // return document.querySelectorAll('.blc-fl');
            }
        });

        // console.log(JSON.parse(jwal.value));
        if(!jwal.isConfirmed){
            setLoading(false);
            return false;
        } 
        console.log(jwal);
        // console.log(jwal.value);
        // return;
        jwal.value.map((e,i)=>{
            preguntas[e.name] = e.value;
            // console.log(e.name+' - '+e.value);
        })
        
        }else{
            preguntas = '';
        } 

        //Obtenemos fechas
        const d = new Date();
        // const anio = d.getDate+'/'+(d.getMonth()+1)+'/'+d.getFullYear()
        const anio = d.toLocaleDateString('default');
        const hora = d.toLocaleTimeString('default', {
            // en-US can be set to 'default' to use user's browser settings
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        });

        const datos_enviar = {
            etiqueta: etiqueta.id,
            // cantidades: cantidad_send,
            preguntas: preguntas,
            anio     : anio,
            hora     : hora
        };

        console.log(preguntas);
        console.log(datos_enviar);

        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
        await axios({
            url: urlApi+'etiquetas/print',
            method: 'POST',
            data: datos_enviar,
            headers: headers,
            // responseType: 'blob', // important
        }).then(function (response) {
            console.log(response.data);
            // return;
            const currentdate = new Date();
            const datetime = currentdate.getDate() + "/"
            + (currentdate.getMonth()+1)  + "/" 
            + currentdate.getFullYear() + " - "  
            + currentdate.getHours() + ":"  
            + currentdate.getMinutes() + ":" 
            + currentdate.getSeconds();
        
            const url       = window.URL.createObjectURL(new Blob([response.data.mensaje]));
            const link      = document.createElement('a');
                link.href = url;
            link.setAttribute('download', datetime+'.txt'); //or any other extension
            document.body.appendChild(link);
            link.click();
            console.log(response.data);

            MySwal.fire({
                icon: 'success',
                title: 'Etiqueta descargada',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            });
        })
        .catch(function (error) {
            console.log(error);
            // return;
            MySwal.fire({
                icon: 'error',
                title: 'Ocurrio un error',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
                }
            });

        }).then(()=>{
            setLoading(false);
        });
    }
/***********************************************************************************************/

    const setSearch = async(params) => { 
        setCode(params.code);

        const _code = params.code;

        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }

        await axios.get(`${urlApi}etiquetas/connect-ftp-admin?code=${_code}`, {headers})
        .then((response) => {
            //Actualiza estados del formulario
            // setLoading(false);
            setftps(response.data.etiquetas);
            seturl(response.data.url);
            console.log(response.data);
        })
        .catch((error) => {
            console.log(error);
        });     

    }

/***********************************************************************************************/
        
  return (
    <>

         {/* Modal Catalogos  */}
         {
            _btn_ref &&
                <ModalCatalogos
                    btn_ref={btn_ref}
                    catalogos={catalogos}
                    asignarCatalogo={asignarCatalogo}
                    btn_close={btn_close}
                    eti={eti}
                    _set_btn_ref={_set_btn_ref}
                />

        }
        {/* Modal Catalogos  */}

          {/* <!-- Button trigger modal --> */}
        <button 
            ref={btn_ref}
            type="button" 
            className="btn btn-primary d-none" 
            data-bs-toggle="modal" 
            data-bs-target="#staticBackdrop"
        >
            Launch static backdrop modal
        </button>

        {/* <!-- Modal --> */}
        <div style={{zIndex: 10000}} className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title fw-bold " id="staticBackdropLabel">Pre Catalogos WEB</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                <div className="modal-body">
                    <ul className="list-group list-group-flush list-group-numbered">
                        {
                            catalogos.length > 0 &&
                            catalogos.map((e,i) =>{
                                return <li className="list-group-item" key={`list-${i}`}>
                                            <a href="#" 
                                               onClick={() => asignarCatalogo(e.id)} 
                                            >
                                                {e.nombre}
                                            </a>
                                        </li>
                            })

                        }
                    </ul>
                </div>
                    <div className="modal-footer">
                        <button 
                            type="button" 
                            className="btn btn-secondary" 
                            data-bs-dismiss="modal"
                            ref={btn_close}
                        >Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
        {/* Fin de modal */}

        <div className='border-bottom border-light mb-3 '>
            <h2 className='text-dark fw-bold text-uppercase shadow p-1 rounded'>Catalogo de plantillas / formatos web</h2>
        </div>
        <div className='row mb-4'>
            <div className='col-md-6 d-none'>
                <select ref={print_ref} className="form-select form-select-sm" aria-label=".form-select-sm example" defaultValue={'0000'}>
                    <option value={'0000'}>-- SELECT A PRINTER --</option>

                    {
                        impresoras.length > 0 &&
                            impresoras.map((e,i) =>{
                                return <option value={JSON.stringify(e)} key={i}>{e.name}</option>
                            })
                    }
                </select>
            </div>
            <div className='col-md-6 text-start'>
                <NavLink 
                    to="/etiquetas"
                    className='btn btn-warning btn-sm fw-bold text-uppercase shadow res-w100 '
                >
                    Crear Formato / plantilla web
                </NavLink>
            </div>
        </div>

        <div className='row mb-4'>
            <Formik
                initialValues={{ 
                    code: code,
                }}
                onSubmit={(values, actions) => setSearch(values, actions)}
                enableReinitialize
            >
                <Form>
                    <div className="row align-items-center mb-3">
                        <label htmlFor="code" className="col-sm-4 col-form-label col-form-label-sm">Busqueda de formatos</label>
                        <div className="col-sm-8">
                            <div class="input-group">
                                <Field 
                                    type="text" 
                                    className="form-control form-control-sm" 
                                    placeholder="Busqueda"
                                    name="code"
                                    id="code"
                                />         
                                <button class="btn btn-sm btn-outline-secondary" type="submit" id="button-addon1">Buscar</button>                            
                            </div>                                        
                        </div>
                    </div>
                </Form>
            </Formik>
        </div>

        <div className='card'>
            <div className='card-body'>            
                <div className="row row-cols-1 row-cols-md-3 g-4 animate__animated animate__fadeIn">
                    {
                        ftps != undefined ?
                            ftps.length > 0 ?
                            ftps.map((e, i) =>{
                                return <div className="col" key={`ftps-${i}`}>
                                    <div className="card h-100 shadow rounded ">

                                    <div className='p-1 shadow-sm'>
                                            <div className='row justify-content-end text-end'>
                                                <div className='col-md-5 px-3'>
                                                    <div className={`dropdown`}>
                                                        <a href="#" className={`btn btn-secondary shadow w-auto`} type="button" 
                                                            id="dropdownMenuButton1" 
                                                            data-bs-toggle="dropdown" 
                                                            aria-expanded="false"
                                                            style={{borderRadius: '20px'}}
                                                            disabled={loading}
                                                            // disabled={carga}
                                                        >
                                                            <FontAwesomeIcon icon={faCog}/>
                                                        </a>
                                                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                                            
                                                            <li>
                                                            {
                                                                e.catalogo_id == null ?
                                                                    <a 
                                                                        className="dropdown-item" 
                                                                        href="#" 
                                                                        onClick={() => mostrarCatalogos(e)}
                                                                    >
                                                                        <div className='d-flex align-items-center'>
                                                                            <label className='col-2'><FontAwesomeIcon icon={faPlus} /> </label>                                                                                        
                                                                            <div className='col-md-10'>
                                                                                <span>Agregar catalogo </span>
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                                :
                                                                    <a 
                                                                        className="dropdown-item" 
                                                                        href="#" 
                                                                        onClick={() => quitarCatalogo(e.id)}
                                                                    >   
                                                                        <div className='d-flex align-items-center'>
                                                                            <label className='col-2'><FontAwesomeIcon icon={faMinus} /> </label>                                                                                        
                                                                            <div className='col-10'>
                                                                                <span >Quitar catalogo </span>
                                                                            </div>
                                                                        </div>
                                                                    </a>
                                                            }
                                                            </li>
                                                            <li>
                                                                
                                                                <Link className="dropdown-item"
                                                                    to={
                                                                        {  
                                                                            pathname:"/edit-label/"+e.id,
                                                                            state:{etiqueta: e}
                                                                        }
                                                                    }
                                                                >
                                                                    <div className='d-flex align-items-center'>
                                                                        <label className='col-2'><FontAwesomeIcon icon={faEdit} /> </label>                                                                                        
                                                                        <div className='col-10'>
                                                                            <span>Editar etiqueta </span>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </Link>
                                                            </li>
                                                            <li>                                                                            
                                                                {/* <a className="dropdown-item d-none" href='#' onClick={() => clonarEtiqueta(e.id)}> */}
                                                                <a className="dropdown-item d-none" href='#' onClick={() => console.log(e.id)}>
                                                                    <div className='d-flex align-items-center'>
                                                                        <label className='col-2'><FontAwesomeIcon icon={faClone} /> </label>                                                                                        
                                                                        <div className='col-10'>
                                                                            <span>Clonar etiqueta </span>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </a>
                                                            </li>

                                                            <li>
                                                                
                                                                <a className="dropdown-item d-none" href='#' onClick={() => impresora(e.id)}>
                                                                {/* <a className="dropdown-item d-none" href='#' onClick={() => console.log(e.id)}> */}
                                                                    <div className='d-flex align-items-center'>

                                                                        <label className='col-2'><FontAwesomeIcon icon={faPrint} /> </label>                                                                                        
                                                                        <div className='col-10'>
                                                                            <span>Impresora </span>
                                                                        </div>

                                                                    </div>
                                                                    
                                                                </a>
                                                            </li>

                                                            <li>
                                                                
                                                                <Link className="dropdown-item"
                                                                    to={
                                                                        {  
                                                                            pathname:"/label-configuration/"+e.id,
                                                                            state:{etiqueta: e}
                                                                        }
                                                                    }
                                                                >
                                                                    <div className='d-flex align-items-center'>
                                                                        <label className='col-2'><FontAwesomeIcon icon={faListOl} /> </label>                                                                                        
                                                                        <div className='col-10'>
                                                                            <span>Configuración </span>
                                                                        </div>
                                                                    </div>
                                                                    
                                                                </Link>
                                                            </li>

                                                            <li>
                                                                <hr className='dropdown-divider'/>                                                        
                                                            </li>
                                                            
                                                            <li>
                                                                <a className="dropdown-item" href="#" onClick={()=> deletePreformato(e)}>
                                                                    <div className='d-flex align-items-center'>
                                                                        <label className='col-2'><FontAwesomeIcon icon={faTrash} /> </label>                                                                                        
                                                                        <div className='col-10'>
                                                                            <span>Eliminar </span> 
                                                                        </div>
                                                                    </div>
                                                                </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                        {/* <div className='p-1 shadow-sm '>
                                            <img src={url+'/'+e.image} className="card-img-top p-2 border-bottom" alt={e.nombre} />
                                        </div> */}
                                        <div className='p-1 text-center shadow-sm rounded bg-transparent'>
                                            <img src={url+'/'+e.image} className="card-img-top w-100 border border-1" alt={e.nombre} />
                                        </div>
                                        <div className="card-body">
                                            <h5 className="card-title">{e.nombre}</h5>
                                            <p className="card-text m-0"><b>Lenguaje:</b> {e.language.name}</p>
                                            <p className="card-text m-0"><b>Descripcion:</b> {e.descripcion}</p>
                                            <p className="card-text m-0"><b>Display:</b> {e.ocultar ? 'Oculto' : 'Mostrando'}</p>
                                            <p className="card-text m-0 border-bottom border-top"><b>Código:</b> {e.codigo}</p>
                                            {
                                                (e.medida != null) && 
                                                <p className="text-muted mb-0">Medidas: {e.medida.name} {e.medida.medida}</p>
                                            }
                                            {
                                                (e.material != null) && 
                                                <p className="text-muted mb-0">Material: {e.material.name}</p>
                                            }
                                            {
                                                <>
                                                    <span className="card-text" style={{marginRight: '5px'}}>
                                                        Plantilla / formato web
                                                    </span>
                                                    <FontAwesomeIcon icon={faGlobeAmericas} className="text-primary"/>
                                                </>
                                            }
                                            {
                                                e.catalogo_id != null &&
                                                <p className="card-text text-primary fw-bold">Tiene catalogo</p>
                                            }
                                            <p className='d-none'>Tags: </p>
                                            <div className='d-flex flex-wrap'>
                                            {
                                                (e.basica == 1 || e.basica == '1')&&
                                                <> 
                                                    <div className='mr-2' style={{marginRight: '1rem'}}>
                                                        <span
                                                            style={{cursor: 'default'}}
                                                            data-tip="Básica"
                                                            data-for='basic'
                                                            className='badge bg-danger rounded-pill text-wrap'>#Básica
                                                        </span >
                                                        {/* <span class="sr-only">{e.name}</span> */}
                                                    </div>
                                                    <ReactTooltip 
                                                        id='basic' 
                                                        place="top" 
                                                        type="dark" 
                                                        effect="solid"
                                                        className='tooltip-width'
                                                    />
                                                </>
                                            }
                                                {

                                                    e.tags.length > 0 &&
                                                    e.tags.map((e,i) => {
                                                        return <Fragment key={'targs-'+i}> 
                                                                    <div  className='mr-2' style={{marginRight: '1rem'}}>
                                                                            <span
                                                                                style={{cursor: 'default'}}
                                                                                data-tip={e.name}
                                                                                data-for='test'
                                                                                className='badge bg-danger rounded-pill text-wrap'>#{e.name}
                                                                            </span >
                                                                            {/* <span class="sr-only">{e.name}</span> */}
                                                                        </div>
                                                                        <ReactTooltip 
                                                                            id='test' 
                                                                            place="top" 
                                                                            type="dark" 
                                                                            effect="solid"
                                                                            className='tooltip-width'
                                                                        />
                                                                </Fragment>
                                                    })
                                                }
                                            </div>

                                        </div>
                                        <div className='p-3'>
                                            <div className='row  d-none'>
                                                <div className='col-6'>
                                                    <Link                                         
                                                        to={
                                                            {  
                                                                pathname:"/edit-label/"+e.id,
                                                                state:{etiqueta: e}
                                                            }
                                                        }
                                                        className="w-100 py-3 btn btn-success btn-sm fw-bold text-uppercase border shadow mb-2"
                                                    > 
                                                        <span>Editar</span>
                                                    </Link>
                                                </div>
                                                <div className='col-6'>
                                                    <button 
                                                        disabled={loading}
                                                        onClick={() => deletePreformato(e)}
                                                        className='w-100 py-3 btn btn-danger btn-sm fw-bold text-uppercase border shadow mb-2'
                                                    > Eliminar</button>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                
                                                    {
                                                        e.catalogo_id == null ?
                                                        <>
                                                            <div className='col-12'>
                                                                <button 
                                                                    disabled={loading}
                                                                    onClick={() => imprimirEtiquetas(e)}
                                                                    className='w-100 py-3 btn btn-secondary btn-sm fw-bold text-uppercase border shadow mb-2'
                                                                > Test Print</button>
                                                            </div>
                                                        </>
                                                        :
                                                        <>
                                                            <div className='col-12'>
                                                            <NavLink 
                                                                disabled={loading}
                                                                to={`/vista/${e.catalogo_id}/${e.id}`}
                                                                className={`${loading ? 'disabled btn btn-secondary btn-sm fw-bold text-uppercase border shadow mb-2 w-100 py-3': 'btn btn-secondary btn-sm fw-bold text-uppercase border shadow mb-2 w-100 py-3'}`}
                                                            > Test Print</NavLink> 
                                                                {/* <Link 
                                                                    disabled={loading}
                                                                    to={
                                                                        {  
                                                                            pathname:"/vista",
                                                                            state:{catalogo: e.catalogo_id, etiqueta: e.id, nombre_etiqueta: e.nombre }
                                                                        }
                                                                    }
                                                                    className='w-100 py-3 btn btn-secondary btn-sm fw-bold text-uppercase border shadow mb-2'
                                                                > Test Print <FontAwesomeIcon icon={faGlobeAmericas} className="text-white"/>
                                                                </Link>  */}
                                                            </div>
                                                        </>
                                                    }                                                                                    
                                                                                        
                                            </div>

                                        </div>

                                    </div>
                                </div>
                                })

                                :
                                    <div className="col-md-12 text-center">
                                            <span className="text">No hay Formatos :(</span>
                                    </div>
                                :
                                    <div className="col-md-12 text-center">
                                        <div className="spinner-border text-primary" role="status">
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                            
                                
                    }
                </div>
            </div>
        </div>
    </>
  )
}

export default VerPreformatos
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
export function unauthenticated(error){
    const MySwal = withReactContent(Swal);
    console.log(error.response);
    // return (
    error.response != undefined &&
        ( error.response?.data.message == 'Unauthenticated.' ||  error.response?.data.message == 'Unauthenticated') &&
            MySwal.fire({
                icon: 'info',
                title: 'Sesión expirada',
                text : 'La sesión a expirado o a iniciado sesion en otro equipo.',
                allowOutsideClick: false,
                showClass: {
                    popup: 'animate__animated animate__wobble'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutDown'
                }
            }).then(result =>{
                if (result.isConfirmed){
                    window.location.href = '/';
                }
            });
    // )    

}
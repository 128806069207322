import React, {useState} from 'react'
import { Link } from "react-router-dom";
import axios from 'axios';
import {urlApi} from '../../global/Global';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faCogs, faTrashAlt, faEdit, faTicketAlt, faFileArchive } from '@fortawesome/free-solid-svg-icons'
const Table = ({usuario, setCarga, setusuarios}) => {        
    /*Sweet alert*/
    const MySwal = withReactContent(Swal);
/********************************************************************************************************/
    const eliminarUsuario = async() => {
        console.log(usuario);
        MySwal.fire({
            title: 'Eliminar',
            html: '¿Eliminar este correo? <b>'+usuario.email+'</b>',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si',
            cancelButtonText: 'No'
        }).then(async (result) => {
            if (result.isConfirmed) {
                setCarga(true);
                const log = await AsyncStorage.getItem('@login');
                const log_json = JSON.parse(log);
                const headers = {
                    'Authorization': log_json.token_type+' '+log_json.access_token,
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest'
                }
                await axios.delete(urlApi+'adminuser/'+usuario.id, {headers: headers})
                        .then(response=>{
                            console.log(response.data);
                            if(response.data.respuesta === 'correcto'){
                                MySwal.fire({
                                    icon : 'success',
                                    title: 'Correcto',
                                    text : 'Se elimino correctamente',
                                });        
                                setusuarios(response.data.usuarios);       
                            }else{
                                MySwal.fire({
                                    icon : 'error',
                                    title: 'Error',
                                    text : 'Ocurrio un error, vuelva a intentarlo',
                                });
                            }
                        })
                        .catch(error => {
                            console.log(error);
                            MySwal.fire({
                                icon : 'error',
                                title: 'Error',
                                text : 'Ocurrio un error, vuelva a intentarlo',
                            });
                        }).then(() =>{
                            setCarga(false);
                        });
                }
        });
    }
    
/********************************************************************************************************/
    return (
        <>

            <div className="dropdown">
                <a className="btn btn-secondary w-100" href="#" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                    <FontAwesomeIcon icon={faCogs} />    
                </a>

                <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <li>
                        <Link className="dropdown-item"
                            to={
                                {  
                                    pathname:"/usuarios/adm/edit/"+usuario.id,
                                    state:{usuario: usuario}
                                }
                            }

                        >
                            <div className='d-flex align-items-center'>
                                <label className='col-2'><FontAwesomeIcon icon={faEdit} /> </label>                                                                                        
                                <div className='col-10'>
                                    <span>Editar usuario </span>
                                </div>
                            </div>
                        </Link>
                    </li> 

                    <li>
                        <a 
                            href="#" 
                            className="dropdown-item"
                            onClick={() => eliminarUsuario()}
                        >

                            <div className='d-flex align-items-center'>
                                <label className='col-2'><FontAwesomeIcon icon={faTrashAlt} /> </label>                                                                                        
                                <div className='col-10'>
                                    <span>Eliminar usuario </span>
                                </div>
                            </div>
                        </a>
                    </li>
                    
                </ul>
            </div>
        </>
    )
}

export default Table

import React, { useReducer, useEffect } from 'react';
import UserReducer from './UserReducer';
import UserContext from './UserContext';

const UserState = props => {


    useEffect(() => {
        
        if(Object.keys(props.user).length > 0){
          seleccionarUser(props.user);
        }

    }, [props.user])
        
    // Crear state inicial
    const initialState = {
        user: {}
    }

    // useReducer con dispatch  para ejecutar las funciones
    const [ state, dispatch ] = useReducer(UserReducer, initialState);

    // Selecciona el Producto que el usuario desea ordenar
    const seleccionarUser = user => {
        dispatch({
            type: 'SELECCIONAR_USER',
            payload: user
        })
    } 

    return (
        <UserContext.Provider
            value={{
                user: state.user,
                seleccionarUser,
            }}
        >
            {props.children}
        </UserContext.Provider>
    )
}

export default UserState;
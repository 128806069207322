import i18n from "i18next";
// import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import resources from "./languages";
// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)

// const options = {
//     // order and from where user language should be detected
//     order: ['querystring', 'cookie', 'localStorage', 'sessionStorage', 'navigator', 'htmlTag', 'path', 'subdomain'],
  
//     // keys or params to lookup language from
//     lookupQuerystring: 'lng',
//     lookupCookie: 'i18next',
//     lookupLocalStorage: 'i18nextLng',
//     lookupSessionStorage: 'i18nextLng',
//     lookupFromPathIndex: 0,
//     lookupFromSubdomainIndex: 0,
  
//     // cache user language on
//     caches: ['localStorage', 'cookie'],
//     excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
//   }
  const options = {
    order: ['localStorage', 'navigator'],
    caches: ['localStorage']
    // lookupQuerystring: 'lng'
  }

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector) // passes i18n down to react-i18next
  .init({
    
    // lng: "es", // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    // detection: options,
    // fallbackLng: 'en',
    detection: options,
    resources,
    // ns: ['common'],
    // defaultNS: 'common',
    // fallbackLng: 'en',
    // supportedLngs: ['en', 'es', 'fr'],
    // debug: true,
    // interpolation: {
      // escapeValue: false // react already safes from xss
    // }    
  });

  export default i18n;
  // export default i18n;
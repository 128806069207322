import React, {useState, useEffect, useRef} from 'react'
import axios from 'axios';
import {urlApi} from '../../global/Global';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Chart from 'chart.js/auto';
const Dashboard = () => {


    const ctx = useRef(null);
    const [chart, setchart] = useState(null);


    const [dashboard, setdashboard] = useState([]);
    const [users, setusers]         = useState(0);

    useEffect(() => {
      
        // setCarga(true);
        const getDashboard = async () => {
            
            const log = await AsyncStorage.getItem('@login');
            const log_json = JSON.parse(log);
            const headers = {
                'Authorization': log_json.token_type+' '+log_json.access_token,
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            }
            
            await axios.get(urlApi+'reportes/dashboard', {headers: headers})
                        .then(async (response) =>{
                            console.log(response.data);
                            setdashboard(response.data.last_signin);
                            setusers(response.data.users);

                            
                            const data = {
                                labels: response.data.labels,
                                datasets: [{
                                  label: 'Total',
                                  data: response.data.data,
                                  backgroundColor: [
                                    'rgba(255, 99, 132, 0.2)',
                                    'rgba(255, 159, 64, 0.2)',
                                    'rgba(75, 192, 192, 0.2)',
                                  ],
                                  borderColor: [
                                    'rgb(255, 99, 132)',
                                    'rgb(255, 159, 64)',
                                    'rgb(75, 192, 192)',
                                  ],
                                  borderWidth: 1
                                }]
                            };

                            setchart(new Chart(ctx.current, {
                                type: 'pie',
                                data: data,
                                options: {
                                    scales: {
                                        y: {
                                            beginAtZero: true
                                        }
                                    }
                                }
                              })
                            );
                        }).catch((error) => {
                            console.log(error);
                        }).then(() =>{
                            // setCarga(false);
                        });            
        }

        getDashboard();
      
    }, [])
    

    return (
        <>
            

        <div className="col animate__animated animate__fadeIn">
            <div className="card h-100 rounded">
                <div className='card-header bg-white'>
                    <h5 className='fw-bold text-center'>
                        Dashboard
                    </h5>

                </div>
                <div className="card-body bg-white">

                    <div className='row'>
                        <div className='col-md-6 mb-4'>
                            <h6>Sesiones iniciadas en: </h6>
                            {
                                dashboard.length > 0 &&
                                dashboard.map((e,i) =>{

                                    return <div className='d-flex align-items-center border-bottom'>
                                                <label className='col-5 fw-bold'>{e.name}: </label>
                                                <div className='col-2'>
                                                    <span>{e.total}</span>
                                                </div>
                                            </div>
                                    
                                })
                            }
                            <canvas ref={ctx} width="400" height="400">

                            </canvas>
                        </div>
                        <div className='col-md-6'>
                            <div className='text-center'>
                                <span>Usuarios registrados: </span>
                                <p className='h1'>
                                    {users}
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        </>
    )
}

export default Dashboard
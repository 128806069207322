import React, {useState, useRef, useEffect} from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import axios from 'axios';
/*Globales*/
import {urlApi} from '../../global/Global';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';

const Preformatos = (props) => {

    const [preformato, setpreformato] = useState(props.location.state.preformato);
    const MySwal = withReactContent(Swal);

    const [loading, setloading] = useState(false);
    const [image, setimage] = useState('');
    const [fileUrl, setFileUrl] = useState(null);
    const [preformatoImagen, setpreformatoImagen] = useState(props.location.state.preformato.image);

    const url_image = process.env.NODE_ENV !== 'production' ? 'https://webtobarcodeback.test/images/' : 'https://webtobarcodeback.labeldictate.com/images/';
    
/*************************************************************************************************/

    useEffect(() => {          
      console.log(preformato);
    }, [])
    

/*************************************************************************************************/

    const processImage = (e) => {
        const imageFile = e.target.files[0];
        const imageUrl = URL.createObjectURL(imageFile);
        setFileUrl(imageUrl)
        setimage(e.target.files[0])
    }

/*************************************************************************************************/

    const enviarPreformato = async (params, actions) => {
    
        setloading(true);
        console.log(params);
        let formData = new FormData();
        formData.append('imagen', image);
        formData.append('data', JSON.stringify(params));

        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }

        await axios({
            method: 'post',
            url: urlApi+'preformatos/actualizar/'+preformato.id,
            data: formData,
            headers: headers
        }).then((response) => {   
                    //Si existe una configuracion previa nos pregunat si sobreescribimos o actualizamos
                    if(response.data.respuesta ==='correcto'){
                        document.getElementById("formFileSm").value = "";
                        setimage('');
                        if(response.data.imagen != ''){
                            setpreformatoImagen(response.data.imagen);
                            setFileUrl(null);
                        }
                        MySwal.fire({
                            icon: 'success',
                            title: 'Correcto',
                            text: response.data.mensaje,
                        });

                        setloading(false);
                    }else{
                        MySwal.fire({
                            icon: 'error',
                            title: 'Error!',
                            text: response.data.mensaje,
                        });
                        setloading(false);
                    }
                    console.log(response.data);
                })
                .catch((error) => {
                    MySwal.fire({
                        icon: 'error',
                        title: 'Error!',
                        text: 'Ocurrio un error en la acción',
                    });
                    setloading(false);
                    console.log(error);
                });

    }

/*************************************************************************************************/
    //Validador de datos
    const formSchema = Yup.object().shape({
        name: Yup.string()
            .required('Campo requerido'),
        description: Yup.string()
            .required('Campo requerido')
    });

/*************************************************************************************************/
    return (
        <div className="row justify-content-center">
            <div className="col-md-10">                
                <div className="card">
                    <div className="card-header bg-card-header">
                        <span className="fw-bold text-white">Etiquetas</span>
                    </div>
                    <Formik
                        initialValues={{
                            name       : preformato.name,
                            description: preformato.description,
                        }}
                        validationSchema={formSchema}
                        onSubmit={(values, actions) => enviarPreformato(values, actions)}
                     >
                        <Form>
                            <div className="card-body">
                                <div className="row mb-3">                                                                
                                    <div className="col-md-12">
                                        <div className="row mb-3">
                                            <div className="col-md-6">
                                                <label htmlFor="name" className="form-label fw-bold">name</label>
                                                <Field 
                                                    type="text" 
                                                    className="form-control" 
                                                    name='name' 
                                                    placeholder="ejemplo: etiqueta-catalogo" 
                                                    disabled={loading}
                                                />
                                                <ErrorMessage
                                                    name='name'
                                                    component='div'
                                                    className='field-error text-danger'
                                                />
                                            </div>
                                            <div className='col-md-6'>
                                                <div className="file color">
                                                    <label htmlFor="formFile" className="form-label fw-bold">Imagen previsualización</label>
                                                    <input disabled={loading} className="form-control" id="formFileSm" type="file" name="csv" onChange={(e) => processImage(e)} accept="image/*"/>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='row'>

                                            <div className='col-md-6'>
                                                {
                                                    fileUrl != null &&
                                                        <FontAwesomeIcon icon={faTimes}  className="text-danger"/>
                                                }
                                                <div 
                                                    className={fileUrl== null ? "border rounded" : "border rounded filter-gray"}                                                      
                                                >
                                                    <img className='img-fluid' src={url_image+preformatoImagen}/>
                                                </div>
                                            </div>

                                            <div className='col-md-6'>
                                                
                                                {
                                                    fileUrl != null &&
                                                    <>
                                                        <FontAwesomeIcon icon={faCheck} className="text-success"/>
                                                        <div className='border rounded'>
                                                            <img className='img-fluid' src={fileUrl}/>
                                                        </div>
                                                    </>
                                                }
                                            </div>

                                            
                                        </div>

                                        <div className='row'>
                                            <div className='col-md-12'>
                                                <label className="form-label fw-bold">Etiqueta zpl, espl</label>
                                                <div className="form-floating">
                                                    {/* <textarea className="form-control" placeholder="Leave a comment here" id="floatingTextarea2" style={{height: '400px'}}></textarea> */}
                                                    <Field as="textarea" disabled={loading} className="form-control" name="description" id="description" style={{height: '400px'}}/>
                                                    <ErrorMessage
                                                        name='description'
                                                        component='div'
                                                        className='field-error text-danger'
                                                    />

                                                </div>
                                            </div>
                                            
                                        </div>

                                    
                                        <div className="my-3">
                                            <button className="btn btn-dark "
                                                    disabled={loading}
                                                    type="submit"
                                            >Actualizar Preformato</button>    
                                        </div>
                                    </div>
                                        
                                
                                </div>
                            </div>
                        </Form>
                    </Formik>
                </div>
            </div>
        </div>
    )
}

export default Preformatos
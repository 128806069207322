import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {urlApi} from '../../global/Global';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {
     NavLink
} from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from 'formik'
import * as Yup from 'yup'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons'
import Table from './Table';
import DataTable from 'react-data-table-component';
import CustomLoader from './CustomLoader'
const Usuarios = () => {

    const [carga, setCarga]       = useState(false);
    const [usuarios, setUsuarios] = useState();
    const [query, setQuery]       = useState('')
    //
    const [loading, setLoading]                             = useState(false);
    const [totalRows, setTotalRows]                         = useState(0);
    const [perPage, setPerPage]                             = useState(50);
    const [resetPaginationToggle, setResetPaginationToggle] = useState(false)
    // const paginationComponentOptions                        = usePaginationComponentOptions();
/********************************************************************************************************
 * @TODO Funcion para cambiar Filas
 * @param {number} newPerPage 
 * @param {number} page 
 */
    const setSearch = async(params) => { 

        setCarga(true);
        setQuery(params.query);

        const _query = params.query;

        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }

        await axios.get(`${urlApi}usuarios?page=1&per_page=${perPage}&query=${_query}`, {headers})
                .then(async (response) =>{
                    console.log(response.data);
                    setUsuarios(response.data.Users);
                    setTotalRows(response.data.Count);
                }).catch((error) => {
                    console.log(error);
                }).then(() =>{
                    setCarga(false);
                });

    }
/********************************************************************************************************
 * @TODO Funcion para cambiar Filas
 * @param {number} newPerPage 
 * @param {number} page 
 */

const handlePerRowsChange = async (newPerPage, page) => {
    setCarga(true);
    const log = await AsyncStorage.getItem('@login');
    const log_json = JSON.parse(log);
    const headers = {
        'Authorization': log_json.token_type+' '+log_json.access_token,
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    }
            
    await axios.get(`${urlApi}usuarios?page=${page}&per_page=${newPerPage}&query=${query}`, {headers})
                .then(async (response) =>{
                    console.log(response.data);
                    setUsuarios(response.data.Users);
                    setPerPage(newPerPage);
                }).catch((error) => {
                    console.log(error);
                }).then(() =>{
                    setCarga(false);
                });  
};
/*******************************************************************************************************/
    const getUsuarios = async (page) => {
        setCarga(true);
        
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
        
        await axios.get(`${urlApi}usuarios?page=${page}&per_page=${perPage}&query=${query}`, {headers})
            .then(async (response) =>{
                console.log(response.data);
                setUsuarios(response.data.Users);
                setTotalRows(response.data.Count);
            }).catch((error) => {
                console.log(error);
            }).then(() =>{
                setCarga(false);
            });             
    }
/** */
    useEffect(() => {
        getUsuarios(1);
    }, [])
    /** */
    const handlePageChange = page => {
        getUsuarios(page);
    };
/********************************************************************************************************/

    const customStyles = {
            table: {
                borderRadius: '80px', // Ajusta el valor según sea necesario
            },
    };    

/********************************************************************************************************
 *  @TODO Columnas
 */
    const columns = [
        {
            name: 'Nombre',
            cell: (row, index, column, id) => {
                return <>                          
                        <p className='text-capitalize mb-0'>{row.name} </p>
                    </>
            },
        },
        {
            name: 'Correo',
            cell: (row, index, column, id) => {
                return <>                          
                        <p className='mb-0'>{row.email} </p>
                    </>
            },
        },
        {
            name: 'Roles',
            cell: (row, index, column, id) => 
                    row.roles.map((e,i) =>{
                        return <p className='text-capitalize mb-0' key={'i'+i}>{e.description}</p>
                    })            
        },
        {
            name: 'Configuración',
            cell: (row, index, column, id) => 
                <Table
                    usuario={row}
                    setCarga={setCarga}
                    setUsuarios={setUsuarios}
                />
            
        },
    ]

/*******************************************************************************************************/
    return (
        <div className="row justify-content-center animate__animated animate__fadeIn">
            <div className="col-md-12">
                <div className="card">
                    <div className="card-header bg-card-header">
                        <div className="row align-items-center">
                            <div className="col-md-6 my-2">
                                <span className="fw-bold text-white">Usuarios</span>
                                
                            </div>
                            <div className='col-md-6 my-2 text-end'>
                                <NavLink to="/usuarios/create" className="btn btn-warning btn-sm fw-bold shadow res-w100 "> 
                                    <span>Crear usuario</span>
                                </NavLink>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">

                        <Formik
                            initialValues={{ 
                                query: query,
                            }}
                            onSubmit={(values, actions) => setSearch(values, actions)}
                            enableReinitialize
                        >
                            <Form>
                                <div className="row align-items-center mb-3">
                                    <label htmlFor="query" className="col-sm-4 col-form-label col-form-label-sm">Correo o Nombre</label>
                                    <div className="col-sm-8">
                                        <div class="input-group">
                                            <Field 
                                                type="text" 
                                                className="form-control form-control-sm" 
                                                placeholder="Correo o nombre"
                                                name="query"
                                                id="query"
                                            />         
                                            <button class="btn btn-sm btn-outline-secondary" type="submit" id="button-addon1">Buscar</button>                            
                                        </div>                                        
                                    </div>
                                </div>
                            </Form>
                        </Formik>

                        <DataTable
                            // title=" "
                            customStyles={customStyles}
                            columns={columns}
                            data={usuarios}

                            fixedHeader
                            fixedHeaderScrollHeight="550px"
                            progressPending={carga}
                            // paginationResetDefaultPage={resetPaginationToggle}
                            pagination
                            paginationPerPage={perPage}
                            paginationServer
                            paginationTotalRows={totalRows}
                            onChangeRowsPerPage={handlePerRowsChange}
                            onChangePage={handlePageChange}
                            // paginationComponentOptions={paginationComponentOptions}
                            paginationRowsPerPageOptions={[50, 100, 200, 500]}
                            progressComponent={<CustomLoader />}
                            dense
                        />                      
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Usuarios

import React, {useState} from 'react'
import axios from 'axios';
import {urlApi} from '../../global/Global';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faQrcode, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import QRCode from "react-qr-code";
const Table = ({dispositivo, setCarga, setDispositivos}) => {        
    /*Sweet alert*/
    const MySwal = withReactContent(Swal);
    const [touken, settouken] = useState(dispositivo.token);
/********************************************************************************************************/
    const desvincularDispositivo = async() => {
        console.log(dispositivo);
        MySwal.fire({
            title: '¿Eliminar token?',
            html: '¿Desea eliminar este dispositivo? <b></b>',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Si',
            cancelButtonText: 'No'
        }).then(async (result) => {
            if (result.isConfirmed) {
                setCarga(true);
                const log = await AsyncStorage.getItem('@login');
                const log_json = JSON.parse(log);
                const headers = {
                    'Authorization': log_json.token_type+' '+log_json.access_token,
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest'
                }
                await axios.delete(urlApi+'dispositivos/'+dispositivo.id, {headers: headers})
                        .then(response=>{
                            console.log(response.data);
                            if(response.data.respuesta === 'correcto'){
                                MySwal.fire({
                                    icon : 'success',
                                    title: 'Correcto',
                                    text : 'Se elimino correctamente',
                                });        
                                setDispositivos(response.data.dispositivos);       
                            }else{
                                MySwal.fire({
                                    icon : 'error',
                                    title: 'Error',
                                    text : 'Ocurrio un error, vuelva a intentarlo',
                                });
                            }
                        })
                        .catch(error => {
                            console.log(error);
                            MySwal.fire({
                                icon : 'error',
                                title: 'Error',
                                text : 'Ocurrio un error, vuelva a intentarlo',
                            });
                        }).then(() =>{
                            setCarga(false);
                        });
                }
        });
    }
    
/********************************************************************************************************/

    const verQr = (params) => {
        
    }
    

/********************************************************************************************************/
    return (
        <>
            {/* <!-- Modal --> */}
            <div style={{zIndex: 10000}} className="modal fade" id={'dispositivo-'+dispositivo.id} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Token</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body text-center">
                            <QRCode value={touken} />
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Modal --> */}

            <div className="col-md-6 text-center">
                <button 
                    className="btn btn-outline-dark"
                    data-bs-toggle="modal" data-bs-target={'#dispositivo-'+dispositivo.id}
                >
                        <FontAwesomeIcon icon={faQrcode} />  <span className="fw-bold"></span>     
                </button>
            </div>
            <div className="col-md-6 text-center">
                <a 
                    href="#" 
                    className="btn btn-danger"
                    onClick={() => desvincularDispositivo()}
                >
                        <FontAwesomeIcon icon={faTrashAlt} />  <span className="fw-bold"></span>     
                </a>
            </div>
        </>
    )
}

export default Table

import React, {useState, useEffect} from 'react';
// import {gapi} from 'gapi-script';
import axios from 'axios';
/*Globales*/
import {urlApi} from '../global/Global';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useTranslation } from 'react-i18next';
import funciones from './utilidades/funciones';
import { GoogleOAuthProvider, GoogleLogin, useGoogleLogin, hasGrantedAnyScopeGoogle  } from '@react-oauth/google';
import jwt_decode from "jwt-decode";
const Google = ({setExist, setUser, carga, setCarga}) => {

    /*Sweet alert*/
    const MySwal = withReactContent(Swal);
    const { t } = useTranslation();
/*******************************************************************/
    const [signedInUser, setSignedInUser] = useState('');
    /*--------------*/
    // const [isLoadingGoogleDriveApi, setIsLoadingGoogleDriveApi] = useState(false);
/*******************************************************************/
    // Client ID and API key from the Developer Console
    const CLIENT_ID = '215741810169-7f1dbk6hlafe6pg8gj379tbb77sg8adu.apps.googleusercontent.com';
    // const API_KEY   = 'AIzaSyBIK-3IqFaUg03I0elDDLpqUO4UaKIQ6RY';

    // Array of API discovery doc URLs for APIs used by the quickstart
    // const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"];

    // Authorization scopes required by the API; multiple scopes can be
    // included, separated by spaces.
    // const SCOPES = 'https://www.googleapis.com/auth/drive';
    // const SCOPES = 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile';

/*************************************************************************************************/
    
const signup = async (_gapi) => {
    
    const params = {        
        cuenta           : 0,
        name             : _gapi.name,
        email            : _gapi.email,
        password         : _gapi.sub,
        google           : 1,
        sistema_operativo: funciones.getOS(),
    }
    // // "114287165541481431223"
    
    console.log(params);
    await axios.post(urlApi+'auth/signup', params)
    .then((response) => {
        //Actualiza estados del formulario
        if(response.data.respuesta ==='correcto'){
            login(params);
            // setCarga(false);
        }
        if(response.data.respuesta ==='error'){
            let texto = '<ul>';
            Object.keys(response.data.errors).map((e,i) =>{
                if(e === 'email'){
                    texto += '<li>Email invalido o duplicado</li>';
                }
                if(e === 'password'){
                    texto += '<li>Contraseña invalida o menor a 8 caracteres</li>';
                }
                texto += '</ul>';
            });
            MySwal.fire({
                icon: 'error',
                title: 'Error!',
                html: texto,
            });
            setCarga(false);
        }
        console.log(response.data);
    })
    .catch((error) => {
            MySwal.fire({
                icon: 'error',
                title: 'Error!',
                text: 'Ocurrio un error en la acción',
              })
        console.log(error);
    })
    .then(() =>{
        // setCarga(false);
    });
}
/*****************************************************************************************************/

const login = async (params, actions) => {
    const headers = {
      'Content-Type': 'application/json',
      'X-Requested-With': 'XMLHttpRequest',
    }
    params.sistema_operativo = funciones.getOS();
    await axios.post(urlApi+'auth/login', params, {headers: headers})
                .then(async (response) =>{
                    const jsonValue = JSON.stringify(response.data);
                    await AsyncStorage.setItem(
                            '@login',
                            jsonValue
                        );
                        setExist(true);
                        setUser(response.data);
                        MySwal.fire({
                            icon: 'success',
                            title: 'Bienvenido',
                            text: response.data.name,
                        });
                }).catch((error) => {
                    setCarga(false);
                        MySwal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: 'No existen coincidencias',
                        });
                        // console.log(error);
                });
}

/*******************************************************************************/
/***************************** DRIVE API ***************************************/
/*******************************************************************************/

    /**
     *  Sign in the user upon button click.
     */
    //  const handleAuthClick = async (event) => {
     const handleAuthClick = useGoogleLogin({
        // handleSignOutClick();
        onSuccess: async credentialResponse => {
            console.log(credentialResponse);
            const decoded = await axios.get('https://www.googleapis.com/oauth2/v3/userinfo',{
                headers: { Authorization: `Bearer ${credentialResponse.access_token}`}
            })
            console.log(decoded.data)
            // console.log(decoded) // bar
            // _setGapi(decoded.data);
            await signup(decoded.data);
            await setCarga(false);
        },
        onError: async () => {
            console.log('Login Failed');
            await setCarga(false);
        },
        onNonOAuthError: async nonOAuthError =>{
                await setCarga(false);
                return false;
        },
        width: '1200',
        flow: 'implicit',
        prompt: 'consent',
    });

    /**
     *  Called when the signed in status changes, to update the UI
     *  appropriately. After a sign-in, the API is called.
     */

    /**
     *  Sign out the user upon button click.
     */
    const handleSignOutClick = (event) => {
        // setListDocumentsVisibility(false);
        // gapi.auth2.getAuthInstance().signOut();
        // // setDocuments([]);    
        // setSignedInUser('');
    };

/***************************************************************************/
    const updateSigninStatus = (isSignedIn) => {
        // if (isSignedIn) {
        //     // Set the signed in user
        //     setSignedInUser(gapi.auth2.getAuthInstance().currentUser);
        //     // setIsLoadingGoogleDriveApi(false);
        //     console.log(gapi.auth2.getAuthInstance().currentUser.le.wt);
        //     // console.log(gapi.auth2.getAuthInstance().currentUser);
        // } else {
        //     // prompt user to sign in
        //     // handleAuthClick();
        // }
    };


    /**
     *  Initializes the API client library and sets up sign-in state
     *  listeners.
     */
    const initClient = () => {
    //     // setIsLoadingGoogleDriveApi(true);
    //     gapi.client
    //         .init({
    //             apiKey: API_KEY,
    //             clientId: CLIENT_ID,
    //             discoveryDocs: DISCOVERY_DOCS,
    //             scope: SCOPES,
    //         })
    //         .then(
    //             function () {
    //                 // Listen for sign-in state changes.
    //                 gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);

    //                 // Handle the initial sign-in state.
    //                 updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
    //             },
    //             function (error) {
    //                 console.log(error);
    //             }
    //         );
    };

    const handleClientLoad = () => {
        // gapi.load('client:auth2', initClient);
    };

/***************************************************************************/

    return (
        
        <>
            <div>
                {/* <GoogleOAuthProvider clientId={CLIENT_ID}> */}
                    {/* <GoogleLogin
                        onSuccess={async credentialResponse => {
                            console.log(credentialResponse);
                            const decoded = jwt_decode(credentialResponse.credential);
                            console.log(decoded) // bar
                            _setGapi(decoded);
                            await signup();
                            await setCarga(false);
                        }}  
                        onError={async () => {
                            console.log('Login Failed');
                            await setCarga(false);
                        }}
                        width='1200'
                    /> */}
                    <button 
                        className="w-100 btn btn-lg btn-light bg-white border mb-3" 
                        type="button"
                        onClick={async () => {
                            await setCarga(true);
                            await handleAuthClick();
                        }}
                        disabled={carga}
                    >
                        <div className='d-flex align-items-center justify-content-center'>
                            <img src='https://developers-dot-devsite-v2-prod.appspot.com/identity/sign-in/g-normal.png'/>
                            <span>{t("login.login_1.login_google")}</span>
                        </div>
                    </button>  
                {/* </GoogleOAuthProvider> */}

            </div>

        </>
    )
}

export default Google

import React, {useEffect, useState} from 'react'
import funciones from '../utilidades/funciones'
import AsyncStorage from '@react-native-async-storage/async-storage';
/*Globales*/
import {urlApi} from '../../global/Global';
import axios from 'axios';
import Swal from 'sweetalert2';
import { useTranslation } from 'react-i18next';

const ModalImpresoras = ({btn_ref_imp, btn_close_imp, impresoras, setImpresoras, etiqueta, setetiquetas, refreshEtiqueta = true, etiquetas, autoPrint = false, btn_auto_print = false}) => {

    const { t }                       = useTranslation();
    const [recharge, setrecharge]     = useState(false);
    const [print_auto, setprint_auto] = useState(false);
    const [dis, setdis]               = useState(false);
    // useEffect(() => {
      
    //     if(print_auto){
    //         btn_auto_print.current.click();
    //     }
      
    // }, [print_auto])
        
    const rechargePrinters = async () => { 
        setrecharge(true);
        const log      = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const _text    = await AsyncStorage.getItem('i18nextLng');
        // const _text  = t('utilidades.funciones.error_text2barcode');
        const _impresoras = await funciones._available(log_json, _text);
        console.log(_impresoras);
        if(_impresoras != false){
            setImpresoras(_impresoras.data.printer);
            // btn_ref_imp.current.click();
        }
        setrecharge(false);
    }


    const vincularImpresoraEtiqueta = async (params) => { 
        setdis(true);
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }

        const send_data = {
            etiqueta: etiqueta,
            impresora: params,
            refresh : refreshEtiqueta
        };

        await axios.post(urlApi+'impresora', send_data, {headers: headers})
            .then((response) => {
                Swal.fire({
                    icon: response.data.icon,
                    title: response.data.message,
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                });
                if(response.data.response){
                    setetiquetas(response.data.etiquetas);
                    setprint_auto(true);
                }
                btn_close_imp.current.click();
                console.log(response.data);
            })
            .catch((error) => {
                Swal.fire({
                    icon: 'error',
                    title: error,
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000
                });
                console.log(error);
            }).then(() =>{
                setdis(false);
            });  

    }

    return (
    <>
        {/* <!-- Button trigger modal --> */}
        <button 
            ref={btn_ref_imp}
            type="button" 
            className="btn btn-primary d-none" 
            data-bs-toggle="modal" 
            data-bs-target="#impstaticBackdrop"
        >
            Launch static backdrop modal
        </button>

        {/* <!-- Modal --> */}
        {/* <div style={{zIndex: 10000}} className="modal fade modal-dialog-scrollable" id="impstaticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="impstaticBackdropLabel" aria-hidden="true"> */}
        <div style={{zIndex: 10002}} className="modal fade modal-dialog-scrollable" id="impstaticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="impstaticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title fw-bold " id="impstaticBackdropLabel">{t("see_labels.printer")}s</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                <div className="modal-body p-0">                        
                        <ol className="list-group list-group-flush list-group-numbered">
                            {
                            !recharge
                             ? impresoras.length > 0 ?
                                impresoras.map((e,i) =>{
                                    return <li 
                                                key={`list-imp-${i}`} 
                                                onClick={() => vincularImpresoraEtiqueta(e)} 
                                                className={`${dis ? "disabled list-group-item list-group-item-action pointer d-flex justify-content-between align-items-start" :  "list-group-item list-group-item-action pointer d-flex justify-content-between align-items-start"}`}>
                                                <div className="ms-2 me-auto">
                                                    <div className="fw-bold">{e.name}</div>
                                                        {e.uid}
                                                </div>
                                                <span className="badge bg-primary rounded-pill">
                                                    
                                                    {
                                                        e.connection === 'driver' &&
                                                            <i className="fas fa-laptop"></i>
                                                    }
                                                    {
                                                        e.connection === 'bluetooth' &&
                                                            <i className="fab fa-bluetooth-b"></i>
                                                    }
                                                    {
                                                        e.connection === 'network' &&
                                                            <i className="fas fa-wifi"></i>
                                                    }
                                                    {
                                                        e.connection === 'usb' &&
                                                            <i className="fab fa-usb"></i>
                                                    }
                                                    {
                                                        e.connection === 'ttb' &&
                                                            <i className="fas fa-ticket"></i>
                                                    }
                                                    
                                                </span>
                                            </li>
                                
                                })
                                :   <div className='p-5'>
                                        <div className="d-flex justify-content-center align-items-center">   
                                            <p>0 devices found :(</p>
                                        </div>
                                    </div>


                            :   <div className='p-5'>
                                    <div className="d-flex justify-content-center align-items-center">   
                                        <div className="spinner-grow text-primary" role="status" style={{width: "4rem", height: "4rem"}}>
                                            <span className="visually-hidden">Loading...</span>
                                        </div>
                                    </div>
                                </div>
                            }
                        </ol>                                              
                    </div>
                    <div className="modal-footer d-flex justify-content-center align-items-center">
                        <div>
                            <button 
                                type='button' 
                                className="pointer fw-bold btn btn-outline-primary border border-primary rounded rounded-circle p-3"
                                onClick={() => rechargePrinters()}    
                            >
                                    <i className="fas fa-redo fa-lg"></i>
                            </button>
                        </div>

                        <button 
                            type="button" 
                            className="btn btn-secondary d-none" 
                            data-bs-dismiss="modal"
                            ref={btn_close_imp}
                        >Cerrar</button>
                    </div>
                </div>
            </div>
        </div>
        {/* Fin de modal */}
    </>
  )
}

export default ModalImpresoras
import React, {useRef, useState, useEffect} from 'react'
import { useTranslation } from 'react-i18next';
import AsyncStorage from '@react-native-async-storage/async-storage';
import Swal from 'sweetalert2';
import {urlApi} from '../../global/Global';
import axios from 'axios';
import { Formik, Field, Form, useFormikContext } from 'formik';
import * as Yup from 'yup';
import funciones from '../utilidades/funciones';

const ModalCatalogos = ({btn_ref, catalogos, asignarCatalogo, btn_close, eti, _set_btn_ref}) => {

    const { t }                      = useTranslation();
    const btn_config_catalogos       = useRef(null);
    const btn_close_config_catalogos = useRef(null);

    const [respuesta, setrespuesta] = useState({});
    const [cabeceras, setcabeceras] = useState([]);
    const [configs, setconfigs]     = useState([]);
    const [catalogo, setcatalogo]   = useState(null);

    const [validarConfigs, setvalidarConfigs] = useState(false);

    const [_initialValues, _setinitialValues] = useState({});

/************************************************************************************************/
    useEffect(() => {      
        btn_ref.current.click();
    }, [])
    
/************************************************************************************************/

    const AutoSubmitToken = () => {
        // Grab values and submitForm from context
        const { values, submitForm } = useFormikContext();
        useEffect(() => {
            // Submit the form imperatively as an effect as soon as form values.token are 6 digits long
            console.log(values)

            cabeceras.map((cabeza,j)=>{
                document.querySelectorAll('.columnacambio-'+j.toString()).forEach(element =>{
                    element.classList.remove('bg-secondary');                        
                });
            })

            Object.values(values).map((e,i)=>{
                console.log(e);
                const arr = e.split('-');
                // let result = e.substring(e.length - 1);
                let result = arr[1];
                // console.log( parseInt(result) )
                // console.log( result)
                document.querySelectorAll('.columnacambio-'+result).forEach(element =>{
                    element.classList.add('bg-secondary');
                });
                
            });
        }, [values, submitForm]);

        return null;
    };

/************************************************************************************************/

    const enviarConfiguracion = async (params) => {
        console.log(params);
        
        if(eti.nueva != '1'){

            if(configs.length !== Object.keys(params).length){
                setvalidarConfigs(true);
                return false;
            }else{
                setvalidarConfigs(false);
            }
        }else{
            let minus = 0;
            minus = (configs.includes('quantity') || configs.includes('QUANTITY')) && -1;
            console.log(Object.keys(params).length )
            if(configs.length+(minus) !== Object.keys(params).length){
                setvalidarConfigs(true);
                return false;
            }else{
                setvalidarConfigs(false);
            }
        }
        
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization'   : log_json.token_type+' '+log_json.access_token,
            'Content-Type'    : 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
        const send_data = {
            configs  : configs,
            cabeceras: params,
            nueva    : (eti.nueva == '1') ? true: false,
            etiqueta : eti.id
        }
        await axios.post(urlApi+'catalogo/guardar-configurar-catalogo', send_data, {headers: headers})
                    .then((response) => {
                        //Actualiza estados del formulario
                        if(response.data.response){
                            console.log(response.data)
                            btn_close_config_catalogos.current.click();
                            setTimeout(() => {
                                _set_btn_ref(false);
                            }, 200);
                            asignarCatalogo(params, catalogo);
                        }else{
                            Swal.fire({
                                icon: 'error',
                                title: response.data.respuesta,
                                text: response.data.mensaje
                            })
                        }
                    })
                    .catch((error) => {
                        Swal.fire({
                            icon: 'error',
                            title: error
                        })
                        console.log(error);
                    }); 
    }

/************************************************************************************************/

    const configurarCatalogo = async (params) => {
        setcatalogo(params)
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
        const send_data = {
            etiqueta: eti.id,
            configs : eti.configuraciones
        }
        await axios.post(urlApi+'catalogo/configurar-catalogo/'+params, send_data, {headers: headers})
                    .then((response) => {
                        //Actualiza estados del formulario
                        if(response.data.response){
                            console.log(response.data)
                            setrespuesta(response.data)
                            setcabeceras(response.data.plantillas)
                            if(eti.nueva != '1'){
                                setconfigs(response.data.configuraciones)
                            }else{
                                const variables = funciones.getVariables(eti.etiqueta, eti.language_id);
                                setconfigs(variables);
                            }
                            btn_config_catalogos.current.click();
                            
                        }else{
                            Swal.fire({
                                icon: 'error',
                                title: response.data.respuesta,
                                text: response.data.mensaje
                            })
                        }
                    })
                    .catch((error) => {
                        Swal.fire({
                            icon: 'error',
                            title: error
                        })
                        console.log(error);
                    });
    }

/************************************************************************************************/

    return (
        <>
        {/* <!-- Button trigger modal  MODAL PARA CATALOGOS--> */}
            <button 
                ref={btn_ref}
                type="button" 
                className="btn btn-primary d-none" 
                data-bs-toggle="modal" 
                data-bs-target="#staticBackdrop"
            ></button>

            {/* <!-- Modal --> */}
            <div style={{zIndex: 10000}} className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
                <div className="modal-dialog">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title fw-bold " id="staticBackdropLabel">{t("utilidades.catalogs")}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                    <div className="modal-body">
                        <ul className="list-group list-group-flush list-group-numbered">
                            {
                                catalogos.length > 0 &&
                                catalogos.map((e,i) => {
                                    return <li className="list-group-item" key={`list-${i}`}>
                                                <a href="#" 
                                                onClick={() => configurarCatalogo(e.id)} 
                                                >
                                                    {e.nombre}
                                                </a>
                                            </li>
                                })

                            }
                        </ul>
                    </div>
                        <div className="modal-footer">
                            <button 
                                type="button" 
                                className="btn btn-secondary" 
                                data-bs-dismiss="modal"
                                ref={btn_close}
                            >{t("utilidades.close")}</button>
                        </div>
                    </div>
                </div>
            </div>
        {/* Fin de modal */}


        {/* <!-- Button trigger modal  MODAL PARA CONFIGURAR CATALOGOS--> */}
            <button 
                ref={btn_config_catalogos}
                type="button" 
                className="btn btn-primary d-none" 
                data-bs-toggle="modal" 
                data-bs-target="#staticBackdrop2"
            ></button>

            {/* <!-- Modal --> */}
            <div style={{zIndex: 10000}} className="modal fade" id="staticBackdrop2" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel2" aria-hidden="true">
            <Formik
                enableReinitialize
                initialValues={_initialValues}
                onSubmit={(values, actions) => enviarConfiguracion(values, actions)}
            >                
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title fw-bold pb-0" id="staticBackdropLabel2">{t("utilidades.catalogs")}</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={() => setTimeout(() => {_set_btn_ref(false)}, 200) }></button>
                        </div>
                    <div className="modal-body pt-0">
                        <div className='my-3'>

                            <div className="row align-items-center">
                                <div className="col-md-4 d-none">
                                    {
                                        Object.keys(respuesta).length > 0 &&
                                            respuesta.image_catalogo != null &&
                                            <>
                                                <p className='mb-0 text-center'>Image reference</p>
                                                <div className='text-center border border-2 rounded bg-light'>
                                                    <img 
                                                        src={`${respuesta.url}/${respuesta.image_catalogo}`} 
                                                        className="img-fluid border-end border-start border-2"
                                                        alt="Image"
                                                        // width={400}
                                                    />
                                                </div>
                                            </>
                                    }
                                </div>
                                {/* TABLE O TABLA DE CATALOGO  */}

                                {/* <div className='col-md-8'> */}
                                <div className='col-md-12'>
                                    <p className='text-center mb-0 mt-2'>Vista previa del cátalogo <b>{respuesta.nombreuuid}</b></p>
                                    
                                    <div className="table-responsive border px-0  border border-secondary border-1 rounded">
                                        <table className="table table-success table-bordered table-sm mb-0">
                                            <thead>
                                                <tr>
                                                    {
                                                        Object.keys(respuesta).length > 0 &&
                                                        <td className='text-center bb-3 fw-bold' colSpan={Object.keys(cabeceras).length+1}>
                                                            COLUMNS
                                                        </td>
                                                    }
                                                </tr>
                                                <tr className='bb-3'>                                                    
                                                    {
                                                        Object.keys(respuesta).length > 0 &&
                                                        respuesta.columnas.map((e, i) => {
                                                        return <th                                                                     
                                                                    className='text-center' 
                                                                    style={{backgroundColor: '#0d8950'}}
                                                                    llave={'llave-'+i}
                                                                    scope="col" 
                                                                    key={'columnas-'+i}
                                                                >
                                                            <span 
                                                            className='text-center text-break d-inline-block' 
                                                            style={{width: '10rem'}}>{e}</span></th>
                                                        })                                            
                                                    }                         
                                                </tr>
                                                <tr>
                                                    {
                                                        Object.keys(respuesta).length > 0 &&
                                                        respuesta.cabeceras.map((e, i)=>{
                                                            return <td 
                                                                        className={`text-center columnacambio-${i}`}
                                                                        scope="row" 
                                                                        key={'cabeceras-'+i}
                                                                    >
                                                                    <span className='text-center text-break d-inline-block' style={{width: '10rem'}}>
                                                                        {e.slice(0,15)}
                                                                        </span>
                                                                    </td>
                                                        })
                                                    }
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                Object.keys(respuesta).length > 0 &&
                                                respuesta.campos.map((e, i)=>{
                                                    return <tr className='text-center' scope="row" key={'campos-'+i}>
                                                        {
                                                            e.map((campo, j) =>{
                                                                return <td scope="row" key={'jjj'+j} className={`columnacambio-${j}`}>
                                                                    <span style={{width: '15rem'}}>
                                                                    {
                                                                        campo.length > 30
                                                                        ? `${campo.slice(0,30)} ...`
                                                                        : campo
                                                                    }
                                                                    </span>
                                                                </td>
                                                            })
                                                        }
                                                        </tr>
                                                })
                                            }
       
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                
                                {/* TABLE O TABLA DE CATALOGO FIN */}
                            </div>

                            <div className="row mb-2 py-2 border-top border-dark text-center my-4">
                                <div className="col-md-12 fw-bold">Asigna una columna de la hoja de calculo (excel o csv)</div>
                            </div>
                            <Form>
                            <AutoSubmitToken/>

                            <div className="row">
                                <div className="col-md-4 mb-3">
                                {
                                    Object.keys(respuesta).length > 0 &&
                                        <>
                                            <p className='mb-0 text-center d-none'>Image reference</p>
                                            <div className='text-center border border-2 rounded bg-light'>
                                                <img 
                                                    src={
                                                        respuesta.image_catalogo != null 
                                                        ?`${respuesta.url}/${respuesta.image_catalogo}`
                                                        :`${respuesta.url}/${respuesta.image}`
                                                        } 
                                                    className="img-fluid border-end border-start border-2"
                                                    alt="Image"
                                                    // width={400}
                                                />
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className='col-md-8'>
                                {
                                    eti.nueva != "1"
                                    ?
                                        configs.length > 0
                                        ?   configs.map((e, i)=>{
                                            return  <div className='row mb-2 pb-2 border-bottom' key={'configss-'+i}>
                                            <div className='col-md-4'>
                                                <div className='d-flex justify-content-between align-items-center'>                                                                                    
                                                    <span 
                                                        className="form-control form-control-sm"
                                                        style={{backgroundColor: 'rgb(201, 201, 201)'}} 
                                                    >{e.variable}</span>                                                                                
                                                </div>
                                            </div>
                                            <div className='col-md-8'>
                                                <div className='d-flex justify-content-between align-items-center'>                                                                                    

                                                    <Field 
                                                        className="form-select form-select-sm" 
                                                        aria-label=".form-select-sm example" 
                                                        name={`cabeceras-${i}`}
                                                        as="select"
                                                        defaultValue={'0000'}
                                                    >
                                                        <option value={'0000'} disabled>-- SELECT A COLUMN --</option>
                                                        {
                                                        cabeceras.length > 0 &&
                                                            cabeceras.map((e, i)=>{
                                                                return <option value={[e.id+'-'+i]} key={'cabeceras-s-'+i}>Column {e.fila}</option>                                                       
                                                                // return <option value={[respuesta.columnas[i]+'-'+e.id+'-'+i]} key={'cabeceras-s-'+i}>Column {e.fila}</option>                                                       
                                                            })
                                                        }
                                                    </Field> 
                                                </div>
                                            </div>
                                        </div>
                                        })
                                        :   <div className="col-md-12">
                                                <div className='field-error text-danger fw-bold text-uppercase' >
                                                    <p className='mb-0'>No se puede seleccionar un catalogo para este formato de Etiqueta / Ticket</p>
                                                </div>
                                            </div>
                                    :
                                    configs.length > 0
                                    ?   configs.map((e, i)=>{
                                        return e.toLowerCase() != 'quantity' &&
                                        <div className='row mb-2 pb-2 border-bottom' key={'configss-'+i}>
                                            <div className='col-md-4'>
                                                <div className='d-flex justify-content-between align-items-center'>                                                                                    
                                                    <span 
                                                        className="form-control form-control-sm"
                                                        style={{backgroundColor: 'rgb(201, 201, 201)'}} 
                                                    >{e}</span>                                                                                
                                                </div>
                                            </div>
                                            <div className='col-md-8'>
                                                <div className='d-flex justify-content-between align-items-center'>
                                                    <Field 
                                                        className="form-select form-select-sm" 
                                                        aria-label=".form-select-sm example" 
                                                        name={`cabeceras-${i}`}
                                                        as="select"
                                                        defaultValue={'0000'}
                                                    >
                                                        <option value={'0000'} disabled>-- SELECT A COLUMN --</option>
                                                        {
                                                        cabeceras.length > 0 &&
                                                            cabeceras.map((e, i)=>{
                                                                return <option value={[e.id+'-'+i]} key={'cabeceras-s-'+i}>Column {e.fila}</option>                                                       
                                                                // return <option value={[respuesta.columnas[i]+'-'+e.id+'-'+i]} key={'cabeceras-s-'+i}>Column {e.fila}</option>                                                       
                                                            })
                                                        }
                                                    </Field> 
                                                </div>
                                            </div>
                                        </div>
                                    })
                                    :   <div className="col-md-12">
                                            <div className='field-error text-danger fw-bold text-uppercase' >
                                                <p className='mb-0'>No se puede seleccionar un catalogo para este formato de Etiqueta / Ticket</p>
                                            </div>
                                        </div>
                                }
                                </div>
                            </div>
                                <div className="row">
                                    <div className="col-md-12 text-center">
                                        {
                                            validarConfigs &&
                                            <div
                                                name='cabecer'
                                                className='field-error text-danger fw-bold text-uppercase'
                                            >
                                                <p className='mb-0'>Por favor asigne todas los campos de la etiqueta</p>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="row justify-content-end">
                                    {
                                        configs.length > 0 &&
                                        <div className="col-md-4">
                                            <button type='submit' className='btn btn-primary btn-lg w-100 fw-bold text-uppercase'>Guardar</button>
                                        </div>
                                        
                                    }
                                </div>
                            </Form>                             
                        </div>                    
                    </div>
                        <div className="modal-footer">
                            <button 
                                type="button" 
                                className="d-none" 
                                data-bs-dismiss="modal"
                                ref={btn_close_config_catalogos}
                            ></button>
                        </div>
                    </div>
                </div>
                </Formik>  
            </div>
        {/* Fin de modal */}

        </>
    )
}

export default ModalCatalogos
import React from 'react'

const CustomLoader = () => {
  return (
    <div className='row'>
        <div className="col-md-12">
            <div className="text-center">
                <div className="spinner-grow text-success m-4" style={{width: "10rem", height: "10rem"}} role="status">
                    <span className="visually-hidden">Loading...</span>
                </div>
            </div>
        </div>
    </div>
  )
}

export default CustomLoader
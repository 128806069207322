import React, {useState, Fragment} from 'react'
import axios from 'axios';
/*Globales*/
import {urlApi} from '../../../global/Global';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import sweets from './sweets';
import { t } from 'i18next';

const Saltos = ({setcaracteres, caracteres, variables, idEtiqueta}) => {
  
  const [loading, setloading] = useState(false);

/************************************************************************************* */
  const handleChange = (i, e, number = false) => {
    if(number){
      if(!e.target.validity.valid){
        return false;
      }
    }
    const newFormValues = [...caracteres];
    newFormValues[i][e.target.name] = e.target.value;
    //Lo añade al valor
    // newFormValues[i]['valor'] = e.target.value;
    setcaracteres(newFormValues);
  }

  const addFormFields = () => {
    setcaracteres([...caracteres, { variable: "", valor: "" }])
  }

  const removeFormFields = (i) => {
    const newFormValues = [...caracteres];
    newFormValues.splice(i, 1);
    setcaracteres(newFormValues)
  }

  const handleSubmit = async (event) => {
      event.preventDefault();
      setloading(true);

      console.log(caracteres);
      const log      = await AsyncStorage.getItem('@login');
      const log_json = JSON.parse(log);
      const headers  = {
          'Authorization'   : log_json.token_type+' '+log_json.access_token,
          'Content-Type'    : 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
      }
      await axios.post(urlApi+'etiquetas/configuraciones/1/'+idEtiqueta, caracteres, {headers: headers})
                  .then((response) => {
                      //Actualiza estados del caracteres
                      console.log(response.data);
                      sweets._success(response);
                  })
                  .catch((error) => {
                      console.log(error);
                      sweets._success(error);
                  }).then(() => {
                      setloading(false);
                  });
  }

  const handleChangeVariable = (params, index) => { 
    console.group('INICIO');
    console.log('form: '+caracteres);
    console.log('Index: '+index);
    console.log('params: '+params);
    console.log('params count: '+ caracteres.length-1 );
    console.groupEnd('FIN');
        setcaracteres(
          caracteres.map((e,i) =>{
            console.log(e);
            return index === i 
            ? {...e, "variable": params}
            : e
          })
        )
      console.log(params);

  }

/************************************************************************************* */
  return (
    <>
        <div className='row'>
          <div className='col-md-6'>
            <div className='d-flex justify-content-between'>

                <div className="d-none">
                    <h4 className='fw-bold'>{t("config_label.line_break")}</h4>

                </div>
                <div className='d-none'>
                    <a className="btn btn-sm btn-outline-dark " data-bs-toggle="collapse" href="#collapseExample3" role="button" aria-expanded="false" aria-controls="collapseExample3">
                        <FontAwesomeIcon icon={faChevronDown}/>
                    </a>
                </div>
            </div>
          </div>
        </div>
        {/* <form  className='collapse' id="collapseExample3" onSubmit={handleSubmit}> */}
        <form  onSubmit={handleSubmit}>
          {caracteres.map((element, index) => (
            <Fragment key={index}>
            <div className="row border-bottom py-3">
              

              <div className="col-md-2 border-end">
                <label className='form-label fw-bold'>&nbsp;</label>
                <select 
                    className='form-select form-select-sm'
                    name="variables" 
                    defaultValue={element.variable || '0000'}
                    onChange={e => handleChangeVariable(e.target.value, index)} 
                >
                    <option disabled value={'0000'}> --{t("config_label.support_variables")}--</option>
                  {
                    variables.map((_e,_i) =>{
                        return <option key={`vari-${_i}`} value={_e}>{_e}</option>
                    })
                  }
                </select>                            
              </div>
              

              <div className="col-md-3">
                <label className='form-label fw-bold'>Variable</label>                
                    <input
                        className='form-control form-control-sm'
                        type="text" 
                        name="variable" 
                        value={element.variable} 
                        onChange={e => handleChange(index, e)}
                        required
                    />                
              </div>      

              <div className="col-md-3">
                <label className='form-label fw-bold'>{t("config_label.chr_4_line")}</label>
                <div className='input-group '>
                  <input 
                      className='form-control form-control-sm'
                      type="text" 
                      pattern="[0-9]*"
                      name="valor" 
                      value={element.valor} 
                      onChange={e => handleChange(index, e, true)}
                      required
                  />                            
                  <button type="button"  className="btn btn-danger btn-sm remove" onClick={() => removeFormFields(index)}>-</button> 
                </div>
              </div>


            </div>
            </Fragment>
          ))}
          <div className="d-flex justify-content-between mt-3">
              <button className="btn btn-success btn-sm add" type="button" onClick={() => addFormFields()}>Add +</button>
              <button className="btn btn-primary btn-sm submit" type="submit">{t("config_label.save_changes")}</button>
          </div>
      </form>
           
    </>
  )
}

export default Saltos
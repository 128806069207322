import { Formik, Field, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import React, { useState } from 'react'
import axios from 'axios';
import {urlApi, urlImages} from '../../global/Global';
import { useRef } from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
const ModalCambiarNombre = ({clsModalCambiarNombre, modalCambiarNombre, nombre, setNombre, id, etiquetas, setetiquetas}) => {

    const [data, setData]  = useState({});
    const secondModal      = useRef(null);
    const actualizarNombre = async (params) => { 
     
        params.id = id;
        const log      = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers  = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
        await axios.post(`${urlApi}etiquetas/cambiar-nombre`, params, {headers})
                    .then(res =>{
                            console.log(res.data)
                            setData(res.data);
                            setetiquetas(
                                etiquetas.map(item => 
                                    item.id === id 
                                    ? {...item, nombre : res?.data?.data?.nombre || '-'}
                                    : item 
                            ))
                    }).catch(err =>{                            
                            console.log(err)
                            setData(err?.response?.data || err);
                    }).then(() =>{
                        secondModal.current.click();
                    })
        
    }

     //Validador de datos
     const formSchema = Yup.object().shape({
        nombreCambio: Yup.string()
            .required('Campo requerido'),
    });
  return (
    <>
        {/* <!-- Button trigger modal --> */}
        <button type="button" className="btn btn-primary d-none" data-bs-toggle="modal" data-bs-target="#modalCambiarNombre" ref={modalCambiarNombre}>
        </button>

        {/* <!-- Modal --> */}
        <Formik
            enableReinitialize
            initialValues={{
                nombreCambio : nombre
            }}
            validationSchema={formSchema}
            onSubmit={(values, actions) => actualizarNombre(values, actions)}
            ><Form>
                <div className="modal fade" style={{zIndex: 10000}} id="modalCambiarNombre" tabIndex="-1" aria-labelledby="modalCambiarNombreLabel" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="modalCambiarNombreLabel">Actualiza el nombre de tu etiqueta</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" ref={clsModalCambiarNombre}></button>
                            </div>
                        <div className="modal-body">
                            <div className="mb-3">
                                <label htmlFor="nombreCambio" className="form-label">Nombre</label>
                                <Field type="text" 
                                        className="form-control" 
                                        id="nombreCambio" 
                                        placeholder="nombre" 
                                        name='nombreCambio'
                                />
                                <ErrorMessage
                                    name='nombreCambio'
                                    component='div'
                                    className='field-error text-danger'
                                />
                            </div>

                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                            <button type="sumbit" className="btn btn-primary">Guardar cambios</button>
                        </div>
                        </div>
                    </div>
                </div>
            </Form>

        </Formik>
        <button className="btn btn-primary d-none" data-bs-target="#success-error" data-bs-toggle="modal" ref={secondModal}></button>
        <div className="modal fade"  style={{zIndex: 10000}}  id="success-error" aria-hidden="true" aria-labelledby="success-error-label" tabIndex="-1">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="success-error-label">{data.response || '-'}</h1>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        {data.message || '-'}
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Cerrar</button>
                    </div>
                </div>
            </div>
        </div>

    </>
  )
}

export default ModalCambiarNombre
import React, {useRef, useEffect, useContext, useState} from 'react';
import AsyncStorage from '@react-native-async-storage/async-storage';
import VistaContext from '../vistaUsuarioContext/VistaContext';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { useHistory } from "react-router-dom";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons';
 /* Estado global */
import UserContext from '../../userContext/UserContext';
import { useTranslation } from 'react-i18next';

const MenuHeader = ({isMobile, setIsMobile, urlString, seturlString}) => {
    
    let modal = useRef(null);
    // context de Vista
    const { seleccionarVista, vista } = useContext(VistaContext);
    const MySwal                      = withReactContent(Swal);
    const [usir, setusir]             = useState('');
    let   history                     = useHistory();
    const { user }                    = useContext(UserContext);
    const { t } = useTranslation();
/******************************************************************************/

    useEffect(() => {
        // console.log("jeje",user);
        setusir(user)
    }, [user])
    

/******************************************************************************/

    const handleModal = (params) => { 
        // alert(params);
        seturlString(params);
        modal.current.click();
    }
    
/******************************************************************************/

    const handleVista = async (params) => {

        if(!params){
            MySwal.fire({
                title: t("menu_header.view_administrator"),
                text: t("menu_header.precaution"),
                icon: 'warning',
                showCancelButton: true,
                showCloseButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: t("menu_header.sure"),
                // cancelButtonText: 'Cancelar',
                focusCancel: true,
              }).then(async (result) => {
                if (result.isConfirmed) {
                    await AsyncStorage.setItem('@vista', !vista);
                    seleccionarVista(!vista)
                }
              });
        }else{

            MySwal.fire({
                title: t("menu_header.back"),
                icon: 'info',
                confirmButtonColor: '#3085d6',
                confirmButtonText: t("menu_header.confirm_back"),
                // showCancelButton: false,
                showCloseButton: true,
                focusConfirm: true
            }).then(async (result) => {
                if (result.isConfirmed) {
                    await AsyncStorage.setItem('@vista', !vista);
                    seleccionarVista(!vista);
                    history.push('/see-labels');
                }
            });            
        }
        
    }

/******************************************************************************/
  return (
        <div className=''>
                {/* <!-- Button trigger modal --> */}
                <button 
                    type="button" 
                    className="btn btn-primary d-none" 
                    data-bs-toggle="modal" 
                    data-bs-target="#exampleModal"
                    ref={modal}
                >
                    Launch demo modal
                </button>
            <a 
                href='https://ayuda.labeldictate.com'
                target='_blnak'
                className='btn btn-sm btn-warning me-3'>
                <span>
                    Ayuda LD
                </span>
            </a>
            {

                usir!= '' &&
                    (usir.role!= 1 && usir.role!=3) &&
                        <>
                        {
                            vista 
                            ? <button 
                                className="nav-item btn btn-sm btn-primary "
                                onClick={() => handleVista(vista)}
                                // onClick={() => console.log(vista)}
                            ><FontAwesomeIcon icon={faEye} style={{marginRight: '0.5rem'}}/>{t("menu_header.simple_mode")}</button>
                            : <button 
                                className="nav-item btn btn-sm btn-danger "
                                onClick={() => handleVista(vista)}
                                // onClick={() => console.log(vista)}
                            ><FontAwesomeIcon icon={faEyeSlash} style={{marginRight: '0.5rem'}}/>{t("menu_header.admin_mode")}</button>
                        }
                        </>
            }
        </div>
  )
}

export default MenuHeader
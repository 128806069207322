import React, {useEffect, useState, useRef} from "react";
import styled from "styled-components";
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGroupBy,
  useExpanded,
  useRowSelect,
  useGlobalFilter,
  useAsyncDebounce,
  useResizeColumns,
  useFlexLayout
} from "react-table";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
// import {urlApi} from '../../global/Global';
import axios from 'axios';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { faCheck} from '@fortawesome/free-solid-svg-icons';
import AsyncStorage from '@react-native-async-storage/async-storage';
import {gapi} from 'gapi-script';

// tr{
//   td {
//     :last-child {
//       position:sticky;
//       right:0px;
//       z-index: 100;
//       background:#c3bdbd;
//       border-left: 1px solid black;
//     }
//   }
//   th {
//       position:sticky;
//       right:0px;
//       top:0px;
//       z-index: 10000;
//       background:white;
//   }
//   th {
//     :last-child {
//       background:#c3bdbd;
//       border-left: 1px solid black;
//     }
//   }
// }        
// },

// table {

//   input {
//     font-size: 1rem;
//     padding: 0;
//     margin: 0;
//     border: 0;
//     background-color: transparent;
//   },
//   input[type=checkbox]{
//     width: auto!important;
//   },
  
//   tr{
//     td {
//       :last-child {
//         position:sticky;
//         right:0px;
//         z-index: 100;
//         background:#c3bdbd;
//         border-left: 1px solid black;
//       }
//     }
//     th {
//         position:sticky;
//         right:0px;
//         top:0px;
//         z-index: 10000;
//         background:white;
//     }
//     th {
//       :last-child {
//         background:#c3bdbd;
//         border-left: 1px solid black;
//       }
//     }
//   }        
// },

/*Globales*/
import {urlApi} from '../../global/Global';
import { Meta } from "antd/lib/list/Item";
import { file } from "@babel/types";
const Styles = styled.div`

table {
  input {
    font-size: 1rem;
    padding: 0;
    margin: 0;
    border: 0;
    background-color: transparent;
    width: 70%;
  },
  input[type=checkbox]{
    width: auto!important;
  },
    
  tr{
    td {
      :last-child {
        position:sticky;
        right:0px;
        z-index: 1000;
        background:#c3bdbd;
        border-left: 1px solid black;
      }
    }
    th {
        position:sticky;
        right:0px;
        top:0px;
        z-index: 1059;
        background:white;
      }
    th {
      :last-child {
        background:#c3bdbd;
        border-left: 1px solid black;
      }
    }
  }        

}
`
// const Styles = styled.div`
 
//     padding: 1rem;
//     ${'' /* These styles are suggested for the table fill all available space in its containing element */}
//     display: block;
//     ${'' /* These styles are required for a horizontaly scrollable table overflow */}
//     overflow: auto;

//   .table {
//     border-spacing: 0;
//     border: 1px solid black;

//     input {
//       font-size: 1rem;
//       padding: 0;
//       margin: 0;
//       border: 0;
//       background-color: transparent;
//       width: 100px
//     }
//     .input {
//       font-size: 1rem;
//       padding: 0;
//       margin: 0;
//       border: 0;
//       background-color: transparent;
//       width: 50px
//     }
//     .thead {
//       ${'' /* These styles are required for a scrollable body to align with the header properly */}
//       overflow-y: auto;
//       overflow-x: hidden;
//     }

//     .tbody {
//       ${'' /* These styles are required for a scrollable table body */}
//       overflow-y: scroll;
//       overflow-x: hidden;
//       height: 250px;
//     }

//     .tr {
//       :last-child {
//         .td {
//           border-bottom: 0;
//         }
//       }
//       border-bottom: 1px solid black;
//     }
//     .tdp {
//       :last-child {
//         position:sticky;
//         right:0px;
//         z-index: 100;
//         background:#c3bdbd;
//         border-left: 1px solid black;
//       }
//     }
//     .thp {
//         background:#c3bdbd;
//         border-left: 1px solid black;
//     }
//     .th,
//     .td {
//       margin: 0;
//       padding: 0.5rem;
//       border-right: 1px solid black;

//       ${'' /* In this example we use an absolutely position resizer,
//        so this is required. */}
//       position: relative;

//       :last-child {
//         border-right: 0;
//       }

//       .resizer {
//         right: 0;
//         background: blue;
//         width: 10px;
//         height: 100%;
//         position: absolute;
//         top: 0;
//         z-index: 1;
//         ${'' /* prevents from scrolling while dragging on touch devices */}
//         touch-action :none;

//         &.isResizing {
//           background: red;
//         }
//       }
//     }
//   }

// `;


 // Define a default UI for filtering
 const GlobalFilter = ({
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
}) => {
  const count = preGlobalFilteredRows.length
  const [_value, _setValue] = useState(globalFilter)
  const onChange = useAsyncDebounce(value => {
    setGlobalFilter(value || undefined)
  }, 200)

  return (
    <span>
      Buscar:{' '}
      <input
        value={_value || ""}
        onChange={e => {
          _setValue(e.target.value);
          onChange(e.target.value);
        }}
        placeholder={`${count} Registros...`}
        style={{
          fontSize: '1.1rem',
          border: '0',
        }}
      />
    </span>
  )
}

// Be sure to pass our updateMyData and the skipReset option
function Table({ columns, 
                 data, 
                updateMyData, 
                skipReset, 
                setSelectedRows, 
                setSelectedRowsId, 
                headers, 
                _cantidades,
                _setCantidades,
                mas,
                setmas
            }) {
  // Create an editable cell renderer
    const EditableCell = ({
      value: initialValue,
      row: { index },
      column: { id },
      updateMyData, // This is a custom function that we supplied to our table instance
      editable
    }) => {
      // We need to keep and update the state of the cell normally
      const [value, setValue] = React.useState(initialValue);
      const onChange = (e) => {
        const num_col = headers.length-1
        const columna = 'col'+num_col;
        const re = /^\d*\.?\d*$/;
        console.group('update');
        console.log(index);
        console.log(id);
        console.log(headers);
        console.log(e.target.value);
        console.groupEnd('end update');
        let bool = false;

        headers.map(element => {
          if(element.accessor === id){
            if(element.Header === 'Cantidad' || element.Header.includes('Precio') ){
              if (!re.test(e.target.value)) {
                bool = true;
              }
            }
          }
        });
        
        if(bool){
          return false;
        }

        setValue(e.target.value);
      };

      // We'll only update the external data when the input is blurred
      const onBlur = () => {
        updateMyData(index, id, value);
      };

      // If the initialValue is changed externall, sync it up with our state
      React.useEffect(() => {
        setValue(initialValue);
      }, [initialValue]);

      if (!editable) {
        return `${initialValue}`;
      }

      return <input value={value} onChange={onChange} onBlur={onBlur} />;
    };
  
  const defaultColumn = React.useMemo(
    () => ({
      // Let's set up our default Filter UI
      Filter: GlobalFilter,
      // And also our default editable cell
      Cell: EditableCell,
      //
      // minWidth: 200, // minWidth is only used as a limit for resizing
      // width: '100%', // width is used for both the flex-basis and flex-grow
      // maxWidth: 400, // maxWidth is only used as a limit for resizing
      // minWidth: 100,
      // width: '100%',
      // maxWidth: 300,
    }),
    []
  );

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    // The rest of these things are super handy, too ;)
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    selectedFlatRows,
    
    state: {
      pageIndex,
      pageSize,
      sortBy,
      groupBy,
      expanded,
      globalFilter,
      selectedRowIds
    }
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      // updateMyData isn't part of the API, but
      // anything we put into these options will
      // automatically be available on the instance.
      // That way we can call this function from our
      // cell renderer!
      updateMyData,
      // We also need to pass this so the page doesn't change
      // when we edit the data.
      autoResetPage: false,
      // autoResetPage: !skipReset,
      autoResetSelectedRows: !skipReset,
      disableMultiSort: true
    },
    useFilters,
    useGlobalFilter,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect,
    useResizeColumns,
    useFlexLayout,
    
    // Here we will use a plugin to add our selection column
    (hooks) => {
      hooks.visibleColumns.push((columns) => {
        return [
          {
            id: "selection",
            //Size
            disableResizing: true,
            // minWidth: 100,
            // width: '100%',
            // maxWidth: 300,
            // Make this column a groupByBoundary. This ensures that groupBy columns
            // are placed after it
            groupByBoundary: true,
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            )
          },
          ...columns
        ];
      });
    }
  );

  React.useEffect(() => {
    setSelectedRows(selectedFlatRows.map((e) => e.original));
    setSelectedRowsId(selectedRowIds);
    console.log(selectedRowIds);
    // setSelectedRowsId(selectedFlatRowsId.map((e) => e.original));
  }, [selectedFlatRows]);

  useEffect(() => {
    // console.log('cambio bro')
    // console.log(pageSize);
    setmas(pageSize*pageIndex);
  }, [pageIndex])

  const changeCantidades = (value, con) => {
        const re = /^\d*\.?\d*$/;
        if (!re.test(value)) {
            return false;
        }

        _setCantidades(
            _cantidades.map((e,i) =>
                i === con 
                ? e = value
                : e
            )
        )
  }
  
  // Render the UI for your table
  return (
    <>
    <div className="border rounded table-responsive mb-4" style={{height: '550px'}}>
      <table {...getTableProps()}  className="table table-striped">
        <thead className="sticky-header">
        <tr
          style={{
            position:'sticky',
            top:'0px',
            backgroundColor:'white'
          }}
        >
          <th
            colSpan={visibleColumns.length}
            style={{
              textAlign: 'left',
              backgroundColor: "transparent"
            }}
          >
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
              />
            </th>
          </tr>

              {headerGroups.map(headerGroup => (
                    // <div {...headerGroup.getHeaderGroupProps()} className="tr">
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map(column => {
                        return <>
                          {/* <div {...column.getHeaderProps()} className="th"> */}
                          <th {...column.getHeaderProps()}>
                            {column.render('Header')}
                          </th>
                          {/* </div> */}
                          {/* {column.canResize && (
                            <div
                                {...column.getResizerProps()}
                                className={`resizer ${
                                  column.isResizing ? 'isResizing' : ''
                                }`}
                            />
                            )
                          } */}
                          </>
                        })}
                      <th colSpan="1" role="columnheader">Cantidad</th>
                      {/* <div colSpan="1" role="columnheader" className="thp">Cantidad</div> */}
                    </tr>
                    // </div>
                  ))}
          {/* {headerGroups.map(headerGroup => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map(column => (
                    <th {...column.getHeaderProps()}>
                      {column.render('Header')}
                    </th>
                  ))}
                  <th colSpan="1" role="columnheader">Cantidad</th>
                </tr>
              ))} */}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()}>
                    {cell.isGrouped ? (
                        // If it's a grouped cell, add an expander and row count
                        <>
                        <span {...row.getToggleRowExpandedProps()}>
                            {row.isExpanded ? "👇" : "👉"}
                        </span>{" "}
                        {cell.render("Cell", { editable: false })} (
                        {row.subRows.length})
                        </>
                    ) : cell.isAggregated ? (
                        // If the cell is aggregated, use the Aggregated
                        // renderer for cell
                        cell.render("Aggregated")
                    ) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                        // Otherwise, just render the regular cell
                        cell.render("Cell", { editable: true })
                    )}
                    </td>
                  );
                })}
                {/* <div role="cell" className="tdp"> */}
                <td role="cell">
                    <input className="input" value={_cantidades[i+mas]} onChange={(e) => changeCantidades(e.target.value, i+mas)}/>
                    {/* <input className="input" value={_cantidades[i+mas]} onChange={(e) => changeCantidades(e.target.value, i+mas)}/> */}
                </td>
                {/* </div> */}
              </tr>
            );
          })}
        </tbody>
      </table>
      {/*
        Pagination can be built however you'd like.
        This is just a very basic UI implementation:
      */}
      </div>
      <div className="row">
          <div className="col-md-3">
              <nav>
                <ul className="pagination">
                  <li className="page-item">
                    <button className="page-link" onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                      {'<<'}
                    </button>{' '}
                  </li>
                  <li className="page-item">
                    <button className="page-link" onClick={() => previousPage()} disabled={!canPreviousPage}>
                      {'<'}
                    </button>{' '}
                  </li>
                  <li className="page-item">
                    <button className="page-link" onClick={() => nextPage()} disabled={!canNextPage}>
                      {'>'}
                    </button>{' '}
                  </li>
                  <li className="page-item">
                    <button className="page-link" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                      {'>>'}
                    </button>{' '}
                  </li>
                </ul>
              </nav>
            </div>
            <div className="col-md-3">
              <span>
                Página{' '}
                <strong>
                  {pageIndex + 1} de {pageOptions.length}
                </strong>{' '}
              </span>
            </div>
          <div className="col-md-3">
            <span>
              Ir a la página:{' '}
            </span>{' '}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={e => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0
                gotoPage(page)
              }}
              className="form-control"
            />
          </div>
          <div className="col-md-3">
            <span>
              Mostrar
            </span>{' '}
            <select
              className="form-select"
              value={pageSize}
              onChange={e => {
                setPageSize(Number(e.target.value))
              }}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  Mostrar {pageSize}
                </option>
              ))}
            </select>
          </div>
          <pre className="d-none">
          <code>
            {JSON.stringify(
              {
                pageIndex,
                pageSize,
                pageCount,
                canNextPage,
                canPreviousPage,
                sortBy,
                groupBy,
                expanded: expanded,
                selectedRowIds: selectedRowIds,
                selectedRows: selectedFlatRows.map((d) => d.original)
              },
              null,
              2
            )}
          </code>
        </pre>
      </div>
    </>
  );
}
/**************************************************************************************************/
const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input type="checkbox" ref={resolvedRef} {...rest} />
      </>
    );
  }
);


/*************************************************************************************************/
const Test = ({catalogo, user}) => {

/*******************************************************************/
    // Client ID and API key from the Developer Console
    const CLIENT_ID = '191181423961-lqknnrp0amj9pj0o4d16r8aafu611le9.apps.googleusercontent.com';
    const API_KEY = 'AIzaSyBIK-3IqFaUg03I0elDDLpqUO4UaKIQ6RY';

    // Array of API discovery doc URLs for APIs used by the quickstart
    const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/drive/v3/rest"];

    // Authorization scopes required by the API; multiple scopes can be
    // included, separated by spaces.
    const SCOPES = 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile';
    const [signedInUser, setSignedInUser] = useState('');
    const [isLoadingGoogleDriveApi, setIsLoadingGoogleDriveApi] = useState(false);

/*******************************************************************/

    const [loading, setloading] = useState(false);

    const MySwal = withReactContent(Swal);
    const etiqueta = useRef();
    const [data, setData] = React.useState([]);
    const [headers, setheaders] = useState([]);
    const [plus, setplus] = React.useState(false);
    const [_plus, set_plus] = React.useState(false);
    const [originalData] = React.useState(data);
    const [etiquetas, setetiquetas]     = useState();
    const [modCantidad, setmodCantidad] = useState(0);
    const [_cantidades, _setCantidades] = useState([]);
    const [mas, setmas] = useState(0);

    //Added this hook to know what's selected when I submit a form or something
    const [selectedRows, setSelectedRows] = React.useState([]);
    const [selectedRowsId, setSelectedRowsId] = React.useState([]);

    const [local_drive, set_local_drive] = useState('local');
/*****************************************************************************************************/
  useEffect(() => {
        console.log(user);
      const getCatalogo = async (params) => {
          const log = await AsyncStorage.getItem('@login');
          const log_json = JSON.parse(log);
          const headers = {
              'Authorization': log_json.token_type+' '+log_json.access_token,
              'Content-Type': 'application/json',
              'X-Requested-With': 'XMLHttpRequest',
          }
          await axios.post(urlApi+'catalogo/show', catalogo, {headers: headers} )
              .then(async (response) => {
                  setData(response.data.campos);
                  console.log(response.data);
                  _setCantidades(response.data.cantidad);
                  // response.data.headers.map((e,i) => {
                  response.data.headers.map((e,i) => {
                    console.log(e);
                      let n = capitalizarPrimeraLetra(e);
                      setheaders(headers => [...headers, {"Header": n, 'accessor': 'col'+i, filter: 'fuzzyText'}])
                  });
                  setplus(true);
              })
              .catch((error) => {
                  console.log(error);
              });     
      }

      const getEtiqueta = async (params) => {
        const log = await AsyncStorage.getItem('@login');
        const log_json = JSON.parse(log);
        const headers = {
            'Authorization': log_json.token_type+' '+log_json.access_token,
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
        }
        await axios.get(urlApi+'etiquetas/index', {headers: headers})
            .then(async (response) => {
                setetiquetas(response.data);
                console.log(response.data);
                set_plus(true);
            })
            .catch((error) => {
                console.log(error);
            });             
      }      
      handleClientLoad();
      getCatalogo();
      getEtiqueta();
      
  }, []);
  
/********************************************************************************************************/

    /**
     *  Sign in the user upon button click.
     */
     const handleAuthClick = (event) => {
        gapi.auth2.getAuthInstance().signIn();
    };

    /**
     *  Called when the signed in status changes, to update the UI
     *  appropriately. After a sign-in, the API is called.
     */

    /**
     *  Sign out the user upon button click.
     */
    const handleSignOutClick = (event) => {
      MySwal.fire({
        title: '¿Cerrar sesión de Google?',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
            // setListDocumentsVisibility(false);
            gapi.auth2.getAuthInstance().signOut();
            // setDocuments([]);    
            setSignedInUser('');
        }
      })
    };

/********************************************************************************************************/
    const updateSigninStatus = (isSignedIn) => {
        if (isSignedIn) {
            // Set the signed in user
            setSignedInUser(gapi.auth2.getAuthInstance().currentUser);
            // setIsLoadingGoogleDriveApi(false);
            console.log(gapi.auth2.getAuthInstance().currentUser.le.wt);
        } else {
            // prompt user to sign in
            // handleAuthClick();
        }
    };

    /**
     *  Initializes the API client library and sets up sign-in state
     *  listeners.
     */
    const initClient = () => {
        setIsLoadingGoogleDriveApi(true);
        gapi.client
            .init({
                apiKey: API_KEY,
                clientId: CLIENT_ID,
                discoveryDocs: DISCOVERY_DOCS,
                scope: SCOPES,
            })
            .then(
                function () {
                    // Listen for sign-in state changes.
                    gapi.auth2.getAuthInstance().isSignedIn.listen(updateSigninStatus);

                    // Handle the initial sign-in state.
                    updateSigninStatus(gapi.auth2.getAuthInstance().isSignedIn.get());
                },
                function (error) {
                    console.log(error);
                }
            );
    };

    const handleClientLoad = () => {
        gapi.load('client:auth2', initClient);
    };

/*****************************************************************************************************************************/

    const selectDoc = async(doc, name) => {
        let _drive = '';
        // setloading(true);
        console.log(doc);
        await gapi.client.drive.files.get({ 
            fileId: doc,
            alt: 'media'
        }).then(res =>{
            console.log(res);
            // console.log( unescape(encodeURI(res.body)));
            // _drive = unescape(encodeURI(res.body));
            _drive = res.body;
            enviarCatalogo(_drive, name);
            // enviarCatalogo(res.body, name);
        });

    }  

/**************************************************************************************************/

    const capitalizarPrimeraLetra = (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }

/**************************************************************************************************/
  const enviarCatalogo = async () => {
  
    setloading(true);
    const cantidad_send = new Array();
    for (const i in selectedRowsId) {
        cantidad_send.push(_cantidades[i]);
    }

    const datos_enviar = {
        campos    : selectedRows,
        catalogo  : catalogo,
        etiqueta  : etiqueta.current.value,
        cantidades: cantidad_send
    };

    if(cantidad_send.length > 1){
      const cant = Object.keys(datos_enviar.campos[selectedRows.length-2]).length;
      if(cant !== Object.keys(datos_enviar.campos[selectedRows.length-1]).length ){
        const num = cant-1;
        const col = 'col'+num
        datos_enviar.campos[selectedRows.length-1][col] = '';
      }
    }
    // console.log(datos_enviar);
    // return;
    const log = await AsyncStorage.getItem('@login');
    const log_json = JSON.parse(log);
    const headers = {
        'Authorization': log_json.token_type+' '+log_json.access_token,
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    }
    await axios({
        url: urlApi+'catalogo/imprimirvista',
        method: 'POST',
        data: datos_enviar,
        headers: headers,
        responseType: 'blob', // important
    }).then(function (response) {
        const currentdate = new Date();
        const datetime = currentdate.getDate() + "/"
        + (currentdate.getMonth()+1)  + "/" 
        + currentdate.getFullYear() + " - "  
        + currentdate.getHours() + ":"  
        + currentdate.getMinutes() + ":" 
        + currentdate.getSeconds();
        console.log(response)
        const url       = window.URL.createObjectURL(new Blob([response.data]));
        const link      = document.createElement('a');
              link.href = url;
        link.setAttribute('download', datetime+'.txt'); //or any other extension
        document.body.appendChild(link);
        link.click();
        console.log(response.data);

        MySwal.fire({
          icon: 'success',
          title: 'Etiqueta descargada',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer)
          toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
      });
    })
    .catch(function (error) {
      MySwal.fire({
        icon: 'error',
        title: 'Ocurrio un error',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
        toast.addEventListener('mouseenter', Swal.stopTimer)
        toast.addEventListener('mouseleave', Swal.resumeTimer)
        }
    });
        console.log(error);
  
    }).then(()=>{
      setloading(false);
    });
  
  }
/*************************************************************************************************/

  const guardarCambios = async (params) => {
      // guardarcambios
      // setloading(true);
      console.log(local_drive);
      // return;
      console.log(data);
      const datos_enviar = {
        campos     : data,
        catalogo   : catalogo,
        cantidades : _cantidades,
        importacion: params,
        headers    : headers,
        local_drive: local_drive
      };
      console.log(datos_enviar);
      // return;
  
      const log = await AsyncStorage.getItem('@login');
      const log_json = JSON.parse(log);
      const headerss = {
          'Authorization': log_json.token_type+' '+log_json.access_token,
          'Content-Type': 'application/json',
          'X-Requested-With': 'XMLHttpRequest',
      }

      let responseType = '';
      
      if (local_drive === 'drive') {
        responseType = 'blob';
      }else{
        responseType = 'json'
      }
      // if (catalogo.importacion === 'drive') {
      //   responseType = 'blob';
      // }else{
      //   responseType = 'json'
      // }

      await axios({
        url: urlApi+'catalogo/guardarcambios',
        method: 'POST',
        data: datos_enviar,
        headers: headerss,
        // responseType: responseType, // important
    }).then(function (response) {
        if (local_drive === 'drive') {
            const csv = new Blob([response.data.contents]);
            console.log(response.data)
            // const csv = response.data;
            updateFile(csv, catalogo.drive_id, catalogo.importacion, catalogo.nombre);
        }else{
          MySwal.fire({
                icon: 'success',
                title: 'Guardado localmente',
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
                didOpen: (toast) => {
                toast.addEventListener('mouseenter', Swal.stopTimer)
                toast.addEventListener('mouseleave', Swal.resumeTimer)
              }
          });
        }
    })
    .catch(function (error) {
      MySwal.fire({
            icon: 'error',
            title: 'Ocurrio un error',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer)
            toast.addEventListener('mouseleave', Swal.resumeTimer)
          }
      });
      console.log(error);
    }).then(()=>{
      setloading(false);
    });
  }
  


/*************************************************************************************************/

  const setCantidades = () => {
    setloading(true);
    setTimeout(() => {
      console.log('stop');
        
      skipResetRef.current = true;
      const x = _cantidades;
      console.log(selectedRowsId);
      for(var i in selectedRowsId){
            x[i] = modCantidad
        }

        console.log(x);
        _setCantidades(x);
      setloading(false);
    }, 200);
  
  }
  
/*************************************************************************************************/

  const onlyNumbers = (params) => {
    const re = /^\d*\.?\d*$/;
    if (!re.test(params)) {
        return false;
    }

    setmodCantidad(params);
  }
/*************************************************************************************************/

  const updateFile = async (fileData, drive_id, importacion, name) => {
      // console.log(fileData);
      const headers = {
        'Authorization': 'Bearer '+gapi.auth.getToken().access_token,
      }
      const metadata = { 
        name    : name.replace('.CSV', '')+'.csv',
        mimeType: "text/csv",
      };
      let formData = new FormData();
      formData.append("metadata", new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
      formData.append("file", fileData);
      // if(importacion==='drive'){
        // await axios.patch('https://www.googleapis.com/upload/drive/v3/files/'+drive_id+'?uploadType=media', fileData, {headers: headers})
        await axios({
          method: 'PATCH',
          url: 'https://www.googleapis.com/upload/drive/v3/files/'+drive_id+'?uploadType=multipart',
          headers: headers,
          data: formData,
        }).then(response =>{
          console.log(response.data);
          MySwal.fire({
            icon: 'success',
            title: 'Guardado en drive',
            toast: true,
            position: 'top-end',
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
            didOpen: (toast) => {
              toast.addEventListener('mouseenter', Swal.stopTimer)
              toast.addEventListener('mouseleave', Swal.resumeTimer)
            }
        });
        }).catch((err) =>{
          console.log(err.response.data.error.message);
          MySwal.fire({
              icon: 'warning',
              title: 'Error',
              text: err.response.data.error.message,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#d33',
              allowOutsideClick: false
          })
        });
     
  }

/*************************************************************************************************/

  const columns = React.useMemo(
    () => headers,
    [headers]
  )

  //Added this hook to know what's selected when I submit a form or something
  // const [selectedRows, setSelectedRows] = React.useState([]);

  // We need to keep the table from resetting the pageIndex when we
  // Update data. So we can keep track of that flag with a ref.
  const skipResetRef = React.useRef(false);

  // When our cell renderer calls updateMyData, we'll use
  // the rowIndex, columnId and new value to update the
  // original data
  const updateMyData = (rowIndex, columnId, value) => {
    // We also turn on the flag to not reset the page
   

    skipResetRef.current = true;
    setData((old) =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...row,
            [columnId]: value
          };
        }
        return row;
      })
    );
  };

  // After data changes, we turn the flag back off
  // so that if data actually changes when we're not
  // editing it, the page is reset
  React.useEffect(() => {
    skipResetRef.current = false;
  }, [data]);

  // Let's add a data resetter/randomizer to help
  // illustrate that flow...
  const resetData = () => {
    // Don't reset the page when we do this
    skipResetRef.current = true;
    setData(originalData);
  };

  return (
    <>
      <div className="card-body">
        {/* row */}
        <div className="row align-items-center">

          <div className="col-md-12 mb-3">
            <div className="row">
                <div className="col-md-6">
                  <h5 className="text-uppercase fw-bold">{catalogo.nombre}</h5>
                </div>
                <div  className="col-md-6 res-per">                
                  <button 
                      className="btn btn-primary fw-bold"
                      type="button"
                      onClick={() => enviarCatalogo()}
                      disabled={loading}
                      >Imprimir
                  </button>
                </div>
            </div>
          <hr/>
          </div>

          <div className="col-md-12">
            {
              user.google == 0 ?
              <>
              {              
                signedInUser =='' ?
                  <>
                      <div>
                          <p className='mb-0 fw-bold text-danger text-uppercase'>
                              Debes iniciar sesión con una cuenta de Google para guardar el CSV en Drive
                          </p>
                      </div>
                      <button 
                          className="btn btn-light bg-white border my-3" 
                          type="button"
                          onClick={() => handleAuthClick()}
                      >
                          <div className='d-flex align-items-center justify-content-center'>
                              <img src='https://developers-dot-devsite-v2-prod.appspot.com/identity/sign-in/g-normal.png'/>
                              <span>Iniciar sesión con Google</span>
                          </div>
                          {/* <FontAwesomeIcon icon={fabGoogle} size="3x"/> Iniciar sesión con Google */}
                      </button>
                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <button 
                                    className="btn btn-dark fw-bold"
                                    type="button"
                                    onClick={() => guardarCambios(catalogo.importacion)}
                                    disabled={loading}
                                >
                                  {
                                      <span>Guardar archivo</span>
                                  }              
                              </button>
                            </div>
                            <div className="col-md-6 mb-3">
                              <select className="form-select" aria-label="Default select example" onChange={(e) => set_local_drive(e.target.value)}>
                                <option value="local">Localmente</option>
                                <option value="" disabled>Google Drive</option>
                              </select>
                            </div>

                      </div>
                  </>
                  :
                  <>
                  <div className='col-md-6 d-flex align-items-center mb-3'>
                        <button 
                            data-bs-toggle="tooltip" data-bs-placement="top" title="Cerrar Sesión"
                            className="btn bg-white border rounded"
                            onClick={() => handleSignOutClick()}
                            disabled={loading}
                        ><img src='https://developers-dot-devsite-v2-prod.appspot.com/identity/sign-in/g-normal.png'/>
                        {
                            signedInUser != '' &&
                            <span>{signedInUser.le.wt.cu}</span>
                        }
                        </button>
                    </div>
                    <div className="row">
                          <div className="col-md-6 mb-3">
                              <button 
                                  className="btn btn-dark fw-bold"
                                  type="button"
                                  onClick={() => guardarCambios(catalogo.importacion)}
                                  disabled={loading}
                              >
                                {
                                    <span>Guardar archivo</span>
                                }              
                            </button>
                          </div>
                          <div className="col-md-6 mb-3">
                            <select className="form-select" aria-label="Default select example" onChange={(e) => set_local_drive(e.target.value)}>
                              <option value="local">Localmente</option>
                              <option value="drive">Google Drive</option>
                            </select>
                          </div>

                    </div>
                  </>
                  }
                </>
                :
                <>
                  <div className="row">
                      <div className="col-md-6 mb-3">
                          <button 
                              className="btn btn-dark fw-bold"
                              type="button"
                              onClick={() => guardarCambios(catalogo.importacion)}
                              disabled={loading}
                          >
                            {
                                <span>Guardar archivo</span>
                            }              
                        </button>
                      </div>
                      <div className="col-md-6 mb-3">
                        <select className="form-select" aria-label="Default select example" onChange={(e) => set_local_drive(e.target.value)}>
                          <option value="local">Localmente</option>
                          <option value="drive">Google Drive</option>
                        </select>
                      </div>

                  </div>
                </>
            }
          </div>

        </div>
        {/* Row */}
        <hr/>
        <div className="row align-items-center mb-1">

            <div className="col-md-6 mb-3">
              <div className="row align-items-center">
                <label className="col-sm-4 col-form-label fw-bold">Selecciona tu etiqueta: </label>
                <div className=" col-sm-8">
                  <select 
                      className="form-select" 
                      aria-label="Default select example" 
                      ref={etiqueta}
                  >
                  {
                      etiquetas !== undefined  &&
                          etiquetas.map((e,i) =>{
                              return <option key={'et'+i} value={e.id}>{e.nombre}</option>
                          })
                      }
                  </select>                                
                </div>
              </div>
            </div>

            <div className="col-md-6 mb-3">
                <div className="row justify-content-end align-items-center">
                    <label className="col-sm-4 col-form-label fw-bold">Cantidad: </label>
                    <div className="col-md-6">
                        <div className="input-group">
                            <input 
                                className="form-control" 
                                type="text" 
                                placeholder="Cantidad" 
                                aria-label="default input example"
                                pattern="[0-9]*"
                                value={modCantidad}
                                onChange={(e => onlyNumbers(e.target.value))}    
                            />
                            <button 
                                className="btn btn-outline-secondary d-flex align-items-center" 
                                type="button" 
                                id="button-addon2"
                                onClick={() => setCantidades()}
                                disabled={loading}
                            >
                                <FontAwesomeIcon icon={faCheck} />
                            {
                              loading &&
                                <div style={{marginLeft: '1rem'}}>
                                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                  <span className="visually-hidden">Loading...</span>
                                </div>
                            }
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {
        (plus && _plus) ?
          <Styles>
            {/* <button onClick={resetData}>Reset Data</button> */}
            <Table
              columns={columns}
              data={data}
              updateMyData={updateMyData}
              skipReset={skipResetRef.current}
              setSelectedRows={setSelectedRows}
              setSelectedRowsId={setSelectedRowsId}
              headers={headers}
              _cantidades={_cantidades}
              _setCantidades={_setCantidades}
              mas={mas}
              setmas={setmas}
            />
          </Styles>
          :
          <div className="d-flex align-items-center justify-content-center">
              <div style={{marginRight: '1rem'}}>
                  <span>Cargando ...</span>
              </div>
              <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">Loading...</span>
              </div>
          </div>
      }
      </div>
    </>
  );
}


export default Test;